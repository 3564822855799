import {
  codatPlatformNamesToLogsPaths,
  TCodatSettingsPaths,
  TLogsPaths,
  TSettingsPaths,
} from 'routes/IntegrationsRouteUtil';
import { TPlatformNames } from 'types';

interface PrebookLinkParams {
  step?: string;
  invoiceId?: string;
  predefinedBuyCurrency?: string;
  predefinedSellCurrency?: string;
  predefinedBuyAmount?: string;
  predefinedSellAmount?: string;
  predefinedDate?: string;
}

interface CurrencyExchangeLinkParams {
  step?: string;
  invoiceId?: string;
  predefinedBuyCurrency?: string;
  predefinedSellCurrency?: string;
  predefinedBuyAmount?: string;
  predefinedSellAmount?: string;
  predefinedDate?: string;
  predefinedRateContractId?: string;
}

interface TransferLinkParams {
  predefinedRateContractId?: string;
  predefinedBuyCurrency?: string;
  predefinedSellCurrency?: string;
  predefinedBuyAmount?: string;
  invoiceId?: string;
  step?: string;
  bulkId?: string;
}

interface SimpleTransferLinkParams {
  predefinedCurrency?: string;
}

interface PrebooksOverviewLinkParams {
  currency?: string;
  contractId?: string;
  hedgeId?: string;
  cancelContractId?: string;
  deleteContractId?: string;
}

interface PaymentRunLinkParams {
  step: string;
}
interface InvoicesLinkParams {
  currency: string;
  tab: string;
  filter?: string;
  search?: string;
  contractId?: string;
  hedgeId?: string;
  cancelContractId?: string;
  deleteContractId?: string;
}

interface TransactionsLinkParams {
  transferId?: string;
  contractId?: string;
  bulkPaymentId?: string;
}

interface IntegrationSettingsLinkParams {
  step?: string;
  firstIntegration?: boolean;
}

interface BulkUploadLinkParams {
  combineSameContacts?: boolean;
  invoicesIds?: string;
}

interface SettingsLinkParams {
  anchor?: string;
  tab?: string;
}

interface DashboardLinkParams {
  contractId?: string;
}

interface RisksLinkParams {
  currency: string;
}

export interface LinkParams extends TSettingLinkParams, TLogPathLinkParams {
  '/app/prebook': PrebookLinkParams;
  '/app/currency-exchange?step=1': CurrencyExchangeLinkParams;
  '/app/transfers': TransferLinkParams;
  '/app/simple-transfer': SimpleTransferLinkParams;
  '/app/invoices': InvoicesLinkParams;
  '/app/invoices/prebookings': PrebooksOverviewLinkParams;
  '/app/invoices/payment-run': PaymentRunLinkParams;
  '/app/transactions': TransactionsLinkParams;
  '/app/settings': SettingsLinkParams;
  '/app/dashboard': DashboardLinkParams;
  '/app/settings/xero': IntegrationSettingsLinkParams;
  '/app/bulk-upload': BulkUploadLinkParams;
  '/app/logs/xero': {};
  '/app/new-client/xero': {};
  '/app/my-clients': {};
  '/login': {};
  '/app/payment-runs': {};
  '/app/risks': RisksLinkParams;
  '/app/reports': {};
}

type TSettingLinkParams = Record<TSettingsPaths, IntegrationSettingsLinkParams>;
type TLogPathLinkParams = Record<TLogsPaths, {}>;

export const getLink = <K extends keyof LinkParams, V extends LinkParams[K]>(
  baseUrl: K,
  params?: V,
  anchor?: string
) => {
  if (!params) {
    return baseUrl;
  }

  const queryParams = new URLSearchParams();

  Object.keys(params).forEach(
    (key) => params[key] && queryParams.append(key, params[key])
  );

  return `${baseUrl}?${queryParams.toString()}${anchor ?? ''}`;
};

// page specific link getters
export const getPrebookPageLink = (args?: PrebookLinkParams) =>
  getLink('/app/prebook', args);

export const getTransactionPageLink = (args?: TransactionsLinkParams) =>
  getLink('/app/transactions', args);

export const getPaymentRunsPageLink = () => getLink('/app/payment-runs');

export const getXeroSettingsPageLink = (args?: IntegrationSettingsLinkParams) =>
  getLink('/app/settings/xero', args);

export const getSettingsPageLink = (
  args?: SettingsLinkParams,
  anchor?: string
) => getLink('/app/settings', args, anchor);

export const getQuickBooksSettingsPageLink = (
  args?: IntegrationSettingsLinkParams
) => getLink('/app/settings/quickbooks', args);

export const getCodatSettingsLink = (
  path: TCodatSettingsPaths,
  args?: IntegrationSettingsLinkParams
) => getLink(path, args);

export const getSageSettingsPageLink = (args?: IntegrationSettingsLinkParams) =>
  getLink('/app/settings/sage', args);

export const getXeroLogsPageLink = () => getLink('/app/logs/xero');

export const getCodatLogsPageLink = (platformName: TPlatformNames) =>
  getLink(codatPlatformNamesToLogsPaths[platformName]);

export const getInvoicesPageLink = (args: InvoicesLinkParams) =>
  getLink('/app/invoices', args);

export const getPrebookingsPageLink = (args?: PrebooksOverviewLinkParams) =>
  getLink('/app/invoices/prebookings', args);

export const getPaymentRunPageLink = (args?: PaymentRunLinkParams) =>
  getLink('/app/invoices/payment-run', args);

export const getDashboardPageLink = (args?: DashboardLinkParams) =>
  getLink('/app/dashboard', args);

export const getTransfersPageLink = (args?: TransferLinkParams) =>
  getLink('/app/transfers', args);

export const getXeroNewClientLink = () => getLink('/app/new-client/xero');
export const getMyClientsLink = () => getLink('/app/my-clients');

export const getLoginPageLink = () => getLink('/login');
export const getRisksPageLink = (args?: RisksLinkParams) =>
  getLink('/app/risks', args);
export const getReportsPageLink = () => getLink('/app/reports');
