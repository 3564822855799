import { IExternalHedge } from 'types/externalHedges';
import db from 'services/firestore';
import { openQueryWithTimestamp } from 'utils';

export interface SubscribeToExternalHedgesParams {
  entityId: string;
  callback: (hedges: IExternalHedge[]) => void;
}

export const subscribeToExternalHedges = ({
  entityId,
  callback,
}: SubscribeToExternalHedgesParams) => {
  return db
    .collection('externalHedges')
    .where('_owner', '==', entityId)
    .orderBy('_created')
    .onSnapshot(
      (query) => {
        callback(openQueryWithTimestamp(query));
      },
      (error) =>
        console.log('Failed to subscribe to externalHedges. Error: ', error)
    );
};
