import { FC } from 'react';

import { Paragraph, StaleInfo, ContextMenu, Row, Col } from '..';
import {
  StaleRecipientWrapper,
  TitleParagraph,
  CurrencyParagraph,
} from './StaleRecipient.styles';
import {
  RECIPIENT_TYPE,
  IRecipient,
  SWIFT_TYPE,
  TRANSFER_TYPE,
  ICurrency,
  RECIPIENT_STATUS,
} from 'types';
import {
  getCountryCodeByCurrency,
  parseIntoCurrencyString,
  isRecipientSwift,
  isRecipientDisabled,
} from 'utils';
import { isMobile } from 'react-device-detect';
import { useStoreState } from 'state';

interface OwnProps {
  isInvoice?: boolean;
  selectedRecipient?: IRecipient;
  recipient: IRecipient;
  sellCurrency: ICurrency;
  transferType?: TRANSFER_TYPE;
  setRecipient?: (recipient: IRecipient | null) => void;
  onEditRecipient?: (recipient: IRecipient) => void;
  setShowDeleteRecipient?: (flag: boolean) => void;
  setRecipientIdForDelete?: (id: string) => void;
  withContextMenu?: boolean;
  checkDisabled?: boolean;
}

const StaleRecipient: FC<OwnProps> = ({
  selectedRecipient,
  recipient,
  transferType,
  sellCurrency,
  setRecipient,
  onEditRecipient,
  setShowDeleteRecipient,
  setRecipientIdForDelete,
  withContextMenu = true,
  checkDisabled,
}) => {
  const { currencies } = useStoreState((state) => state.CurrenciesState);

  const { getPriorityFee } = useStoreState((state) => state.ReferenceDataState);

  const isSelected = selectedRecipient?.id === recipient?.id;
  const isSwift = isRecipientSwift(recipient);
  const isDisabled = transferType
    ? isRecipientDisabled(transferType, recipient, isSwift, !!checkDisabled)
    : false;

  const fee =
    (recipient &&
      recipient.swiftType &&
      parseIntoCurrencyString(
        getPriorityFee(
          recipient.swiftType,
          sellCurrency.code,
          recipient.accountCountry
        ) || undefined
      )) ||
    null;

  return (
    recipient && (
      <StaleRecipientWrapper
        role="button"
        key={recipient.id}
        isSelected={isSelected}
        isDisabled={isDisabled}
        onClick={() =>
          !isDisabled && setRecipient?.(isSelected ? null : recipient)
        }
      >
        <Row
          style={{
            marginBottom: 10,
          }}
        >
          <TitleParagraph
            style={{
              marginRight: 'auto',
              marginBottom: 0,
            }}
          >
            <svg>
              <use
                xlinkHref={
                  recipient.recipientEntityType === RECIPIENT_TYPE.company
                    ? '#briefcase-ico'
                    : '#user-ico'
                }
              />
            </svg>
            <span>{recipient.recipientName}</span>
          </TitleParagraph>
          {recipient.status === RECIPIENT_STATUS.draft && (
            <StaleInfo
              mode={isMobile ? 'click' : 'hover'}
              placement="top"
              trigger={
                <svg>
                  <use xlinkHref="#warning-yellow-ico" />
                </svg>
              }
            >
              <Paragraph>
                Recipient has missing information. Please edit the recipient and
                save so it can be used here.
              </Paragraph>
            </StaleInfo>
          )}

          {withContextMenu && (
            <ContextMenu
              list={[
                {
                  id: 1,
                  title: 'Details',
                  icon: 'edit-ico',
                  onClick: (e) => {
                    e.stopPropagation();
                    onEditRecipient?.(recipient);
                  },
                },
                {
                  id: 2,
                  title: 'Delete',
                  icon: 'delete-ico',
                  onClick: (e) => {
                    e.stopPropagation();
                    setShowDeleteRecipient?.(true);
                    setRecipientIdForDelete?.(recipient.id);
                  },
                },
              ]}
            />
          )}
        </Row>

        <Row style={{ alignItems: 'flex-end' }}>
          <Col>
            {isSwift && (
              <Paragraph>
                {`SWIFT / BIC: `}
                <Paragraph as="span" error={!recipient.bicSwift}>
                  {recipient.bicSwift ?? 'missing'}
                </Paragraph>
              </Paragraph>
            )}
            <Paragraph>
              {`${isSwift ? 'Account:' : 'IBAN:'}`}

              <Paragraph as="span" error={!recipient.accountNumber}>
                {recipient.accountNumber
                  ? ` *******${recipient.accountNumber?.substr(
                      recipient.accountNumber.length - 4
                    )}`
                  : ` missing`}
              </Paragraph>
            </Paragraph>
          </Col>

          <CurrencyParagraph>
            {recipient.currency}
            <svg>
              <use
                xlinkHref={`#${getCountryCodeByCurrency(
                  recipient.currency,
                  currencies
                )}`}
              />
            </svg>
          </CurrencyParagraph>
        </Row>
        {isSwift && fee && (
          <Row>
            <Paragraph className="bank-name">{recipient.bankName}</Paragraph>
            <CurrencyParagraph>
              {`${sellCurrency.symbol} ${fee} fee ${
                recipient.swiftType === SWIFT_TYPE.ours ? '(ours)' : '(shared)'
              }`}
              <svg>
                <use xlinkHref={`#recipient_swift`} />
              </svg>
            </CurrencyParagraph>
          </Row>
        )}
      </StaleRecipientWrapper>
    )
  );
};

export default StaleRecipient;
