import styled from 'styled-components';
import { Row } from '../Row/Row';
import { Paragraph } from '../Typography/Typography';

export const InputBaseWrapper = styled(Row)<{
  focused: boolean;
  error: boolean;
  disabled?: boolean;
}>`
  position: relative;
  border-radius: ${({ theme }) => theme.borderRadius.m};
  background-color: ${({ theme }) => theme.color.greyLight_3};
  flex: 1;
  align-self: stretch;
  max-height: 52px;
  box-shadow: ${({ focused, error, theme }) =>
    focused && !error && `0 0 0 1px ${theme.color.emeraldDark}`};
  box-shadow: ${({ error, theme }) => error && `0 0 0 1px ${theme.color.red}`};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
`;

export const Input = styled.input<{
  disabled?: boolean;
}>`
  font-weight: 500;
  font-family: 'gilroy', Verdana, sans-serif;
  font-size: ${({ theme }) => theme.fontSize.s};
  padding-left: ${({ theme }) => theme.spacing.m};
  padding-top: ${({ theme }) => theme.spacing.m};
  z-index: 1;
  flex: 1;
  min-height: 52px;
  color: ${({ disabled, theme }) => disabled && theme.color.grey};
`;

export const MovingLabel = styled(Paragraph)<{
  moved: boolean;
  disabled?: boolean;
}>`
  position: absolute;
  transition: all 0.3s ease;
  top: ${({ theme }) => theme.spacing.m};
  left: ${({ theme }) => theme.spacing.m};
  font-size: ${({ theme }) => theme.fontSize.s};
  top: ${({ theme, moved }) => moved && theme.spacing.xs};
  font-size: ${({ theme, moved }) => moved && theme.fontSize.xs};
  font-weight: 400;
  color: ${({ disabled, theme }) => disabled && theme.color.grey};
`;

export const InputRightButton = styled.button`
  display: flex;
  align-items: center;
  margin-right: ${({ theme }) => theme.spacing.m};
`;

export const InputErrorMessage = styled(Paragraph)`
  color: ${({ theme }) => theme.color.red};
  font-size: ${({ theme }) => theme.fontSize.xs};
`;
