import { StaleButtonCopy, Col, Paragraph, Subtitle } from 'components';
import { RowInfo } from 'components/shared/RowInfo/RowInfo.styles';
import { FC } from 'react';
import { useStoreState } from 'state';
import { ITransfer } from 'types';
import { Notify, parseIntoCurrencyString } from 'utils';

interface OwnProps {
  transfer: ITransfer;
}

const PaymentCredentials: FC<OwnProps> = ({ transfer }) => {
  const { userEntity } = useStoreState(({ UserState }) => UserState);
  const { currencyByCode } = useStoreState(
    ({ CurrenciesState }) => CurrenciesState
  );
  const { fundingAccountByCurrency } = useStoreState(
    ({ ReferenceDataState }) => ReferenceDataState
  );

  const fundingAccount = fundingAccountByCurrency(transfer?.sellCurrency);

  return (
    <Col>
      <Subtitle variant="bold" mb>{`Please pay ${
        currencyByCode(transfer.sellCurrency)?.symbol
      } ${parseIntoCurrencyString(
        transfer.payAmount,
        currencyByCode(transfer.sellCurrency)?.precision
      )} to`}</Subtitle>

      <RowInfo>
        <Paragraph>Account name</Paragraph>
        <Paragraph variant="bold">The Currency Cloud Limited</Paragraph>
      </RowInfo>

      {transfer.sellCurrency !== 'GBP' ? (
        <>
          <RowInfo>
            <Paragraph>SWIFT / BIC</Paragraph>

            <StaleButtonCopy
              showIcon
              onClick={() => {
                navigator.clipboard.writeText(
                  fundingAccount?.accountNumber ?? ''
                );
                Notify.success('Copied SWIFT / BIC to clipboard!');
              }}
            >
              <Paragraph variant="bold">{fundingAccount?.bicSwift}</Paragraph>
            </StaleButtonCopy>
          </RowInfo>
          <RowInfo>
            <Paragraph>IBAN</Paragraph>
            <StaleButtonCopy
              showIcon
              onClick={() => {
                navigator.clipboard.writeText(fundingAccount?.sortCode ?? '');
                Notify.success('Copied IBAN to clipboard!');
              }}
            >
              <Paragraph variant="bold">{fundingAccount?.iban}</Paragraph>
            </StaleButtonCopy>
          </RowInfo>
        </>
      ) : (
        <>
          <RowInfo>
            <Paragraph>Account Number</Paragraph>

            <StaleButtonCopy
              showIcon
              onClick={() => {
                navigator.clipboard.writeText(
                  fundingAccount?.accountNumber ?? ''
                );
                Notify.success('Copied account number to clipboard!');
              }}
            >
              <Paragraph variant="bold">
                {fundingAccount?.accountNumber}
              </Paragraph>
            </StaleButtonCopy>
          </RowInfo>
          <RowInfo>
            <Paragraph>Sort Code</Paragraph>
            <StaleButtonCopy
              showIcon
              onClick={() => {
                navigator.clipboard.writeText(fundingAccount?.sortCode ?? '');
                Notify.success('Copied sort code to clipboard!');
              }}
            >
              <Paragraph variant="bold">{fundingAccount?.sortCode}</Paragraph>
            </StaleButtonCopy>
          </RowInfo>
        </>
      )}

      <RowInfo>
        <Paragraph>Reference</Paragraph>
        <StaleButtonCopy
          showIcon
          onClick={() => {
            navigator.clipboard.writeText(
              userEntity.externalRefs.ccShortReference
            );
            Notify.success('Copied reference to clipboard!');
          }}
        >
          <Paragraph variant="bold">
            {userEntity.externalRefs.ccShortReference}
          </Paragraph>
        </StaleButtonCopy>
      </RowInfo>
      <RowInfo justifyContent="flex-end">
        <Paragraph error>
          Please ensure the reference is
          <br />
          added to your transfer details
        </Paragraph>
      </RowInfo>

      <RowInfo>
        <Paragraph>Bank name</Paragraph>
        <Paragraph>Barclays Bank plc</Paragraph>
      </RowInfo>
      <RowInfo>
        <Paragraph>Bank address</Paragraph>
        <Paragraph>1 Churchill Place, London, E14 546</Paragraph>
      </RowInfo>

      {transfer.sellCurrency === 'GBP' && (
        <>
          <RowInfo>
            <Paragraph>SWIFT / BIC</Paragraph>
            <Paragraph>{fundingAccount?.bicSwift}</Paragraph>
          </RowInfo>
          <RowInfo>
            <Paragraph>IBAN</Paragraph>
            <Paragraph>{fundingAccount?.iban}</Paragraph>
          </RowInfo>
        </>
      )}
    </Col>
  );
};

export default PaymentCredentials;
