import styled, { css } from 'styled-components';
import {
  Button,
  ButtonStyleLink,
  StaleHint,
  StaleParagraphBold,
  StaleParagraphMedium,
  StaleSubTitleMedium,
  Paragraph,
  StaleTitleH3,
  StaleTitleH5,
  StaleContainer,
  StaleOverflowScroll,
} from 'components';

export const SignUpWrap = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    padding-top: 0;
    height: 100%;
    box-shadow: inset 0 80px 0 0 ${theme.color.dark};

    @media (min-width: ${theme.breakpoint.largeMin}px) {
      flex-direction: row;
    }

    ${StaleContainer} {
      padding: 16px 16px 12px;
    }

    .rounded {
      background: ${theme.color.white};

      @media (max-width: ${theme.breakpoint.largeMax}px) {
        border-radius: 12px 12px 0 0;
        padding: 14px 0 0;
        box-shadow: 0 -3px 9px -3px rgba(0, 0, 0, 0.1);
      }
    }
  `
);

export const SignUpNav = styled.div(
  ({ theme }) => css`
    background: ${theme.color.dark};
    padding: 8px 16px;
    color: ${theme.color.white};
    min-height: 56px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (min-width: ${theme.breakpoint.largeMin}px) {
      padding: 80px 70px;
      flex: 0 0 50%;
      max-width: 50%;
    }
    @media (min-width: ${theme.breakpoint.extraLargeMin}px) {
      padding: 100px 154px;
    }
    @media (max-width: ${theme.breakpoint.largeMax}px) {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 12;
    }

    .icon {
      margin: 68px auto 65px;
      fill: ${theme.color.white};
    }

    .slick-slider {
      @media (max-width: ${theme.breakpoint.largeMax}px) {
        display: none;
      }

      .wrap {
        display: flex;
        flex-direction: column;
      }
    }

    ${StaleTitleH3} {
      margin-bottom: 9px;
    }

    ${StaleTitleH5} {
      @media (max-width: ${theme.breakpoint.largeMax}px) {
        text-align: center;
      }
      @media (min-width: ${theme.breakpoint.largeMin}px) {
        font-size: 36px;
        line-height: 42.2px;
        background: -webkit-linear-gradient(
          360deg,
          #cfeeea 1.31%,
          #e7edd0 27.56%,
          #facba0 70.76%,
          #ffb8bd 98.26%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    .back {
      position: absolute;
      right: 16px;
      top: 16px;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;

      svg {
        fill: ${theme.color.white};
      }

      &.previous {
        display: flex;
        align-items: center;
        left: 12px;
        right: inherit;
        right: initial;

        svg {
          margin-right: 4px;
        }
      }
    }

    .item {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: ${theme.color.grey};
      position: relative;
      padding: 6px 22px 2px 0;

      &:not(:last-of-type):after {
        content: '';
      }

      &:after {
        width: 0;
        height: 0;
        border-top: 4.5px solid transparent;
        border-left: 6px solid ${theme.color.grey};
        border-bottom: 4.5px solid transparent;
        position: absolute;
        top: 3px;
        right: 8px;
        bottom: 1px;
        margin: auto;
      }

      &.passed {
        color: ${theme.color.white};

        &:after {
          border-left-color: ${theme.color.white};
        }
      }
      &.current {
        color: ${theme.color.emeraldDark};
      }
    }

    ${ButtonStyleLink} {
      color: ${theme.color.white};

      svg {
        fill: ${theme.color.white};
      }
    }

    ${StaleParagraphMedium} {
      margin-top: 24px;
    }
  `
);

export const SignUpContent = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    flex: 0 1 auto;
    overflow: auto;
    height: 100%;
    background: ${theme.color.white};

    @media (min-width: ${theme.breakpoint.largeMin}px) {
      flex: 0 0 50%;
      max-width: 50%;
      justify-content: center;
    }

    & > ${StaleOverflowScroll} {
      padding: 14px 0 16px;

      & > div:not(.notification):first-child {
        @media (max-width: ${theme.breakpoint.largeMax}px) {
          padding-top: 0;
        }
      }
    }

    .cross {
      position: absolute;
      right: 28px;
      top: 28px;
      z-index: 10;

      @media (min-width: ${theme.breakpoint.extraLargeMin}px) {
        right: 48px;
        top: 48px;
      }
    }

    .back {
      margin-bottom: 44px;

      svg {
        margin: 0 16px 0 -4px;
      }
    }

    .block {
      padding: 10px 16px 0;
      position: relative;

      @media (min-width: ${theme.breakpoint.largeMin}px) {
        padding: 70px;
      }
      @media (min-width: ${theme.breakpoint.extraLargeMin}px) {
        padding: 92px 154px;
      }

      & + .block {
        border-top: 1px solid ${theme.color.greyLight_2};
      }

      .row {
        align-items: center;
        justify-content: space-between;
        margin-top: 8px;

        ${StaleParagraphMedium} {
          display: flex;
          align-items: center;
        }
        .text${StaleParagraphMedium}, .checkbox ${StaleParagraphMedium} {
          display: inline;
        }
      }

      ${StaleHint} {
        padding: 0;
        min-height: 21px;
        display: inline;
      }

      ${StaleTitleH5},
      ${StaleSubTitleMedium} {
        margin-bottom: 8px;

        & + div > button {
          margin-bottom: 8px;
        }
      }

      ${StaleParagraphBold} {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        min-height: 21px;

        button {
          margin: -2px 0 0 4px;
        }
      }

      p:last-child {
        margin-bottom: 0;
      }
    }

    .checkbox {
      background: ${theme.color.greyLight_4};
      border: 1px solid ${theme.color.greyLight_2};
      border-radius: 8px;
      min-height: 44px;
      padding: 8px 8px 8px 35px;
      position: relative;
      flex: 0 0 32%;
      max-width: 32%;
      font-size: 12px;
      margin-bottom: 16px;

      &:before {
        content: '';
        width: 16px;
        height: 16px;
        position: absolute;
        left: 12px;
        top: 12px;
        border-radius: ${theme.borderRadius.round};
        background: ${theme.color.emeraldDark};
        border: 2px solid ${theme.color.emeraldDark};
        box-shadow: inset 0 0 0 7px ${theme.color.white};
        transition: box-shadow 0.2s linear;
      }

      &.checked {
        &:before {
          box-shadow: inset 0 0 0 2px ${theme.color.white};
        }

        span,
        ${StaleParagraphMedium} {
          font-weight: 700;
        }
      }

      span {
        display: inline-block;
      }
    }

    .save {
      width: 100%;
      background: ${theme.color.greyLight_4};
      border-radius: 8px;
      min-height: 40px;
      padding: 8px 44px 8px 16px;
      color: ${theme.color.grey};
      margin-bottom: 16px;
      position: relative;
      line-height: 24px;

      &.active {
        color: ${theme.color.greyDark};
        background: ${theme.color.emeraldLight};

        span {
          color: ${theme.color.dark};
          padding-left: 6px;
        }

        svg {
          fill: ${theme.color.dark};
        }
      }

      svg {
        position: absolute;
        right: 16px;
        top: 8px;
        width: 24px;
        height: 24px;
        fill: ${theme.color.grey};
      }
    }

    .explained {
      padding-bottom: 40px;

      &-table {
        display: flex;
        background: ${theme.color.greyLight_4};
        border: 1px solid ${theme.color.greyLight_1};
        color: ${theme.color.greyDark};
        border-radius: 8px;

        ${StaleParagraphBold},
        ${StaleParagraphMedium} {
          color: ${theme.color.dark};
        }

        .col {
          min-height: 64px;
          padding: 12px;
          flex: 0 1 100%;
          position: relative;

          &:not(:last-of-type) {
            border-right: 1px solid ${theme.color.greyLight_1};

            &:before {
              content: '';
              position: absolute;
              top: 0;
              right: -8px;
              bottom: 0;
              margin: auto;
              width: 16px;
              height: 16px;
              border-radius: ${theme.borderRadius.round};
              background: ${theme.color.white};
              border: 1px solid ${theme.color.greyLight_1};
              text-align: center;
              line-height: 17px;
              color: ${theme.color.dark};
              font-weight: 500;
              font-size: 17px;
            }
          }

          &:nth-last-of-type(2) {
            padding-left: 15px;
            padding-right: 16px;

            &:before {
              content: '=';
            }
          }

          &:first-of-type {
            &:before {
              content: '+';
            }
          }

          &:last-of-type {
            text-align: right;

            ${StaleParagraphBold} {
              justify-content: flex-end;
            }
          }
        }
      }

      &-info {
        padding: 12px 0 0 20px;
        margin: 8px 0 0 15px;
        position: relative;

        &:before {
          content: '';
          position: absolute;
          top: -8px;
          left: 0;
          height: 100%;
          width: 1px;
          background: ${theme.color.greyLight_1};
        }

        .item {
          position: relative;

          &:not(:last-of-type) {
            padding-bottom: 15px;
          }

          &:before {
            content: '';
            position: absolute;
            top: 1px;
            left: -27px;
            width: 16px;
            height: 16px;
            border-radius: ${theme.borderRadius.round};
            background: ${theme.color.white};
            border: 1px solid ${theme.color.greyLight_1};
            text-align: center;
            line-height: 17px;
            color: ${theme.color.dark};
            font-weight: 500;
            font-size: 17px;
          }

          &:first-of-type:before {
            content: '+';
          }
          &:last-of-type:before {
            content: '=';
          }
        }

        ${StaleParagraphBold} {
          margin-bottom: 1.5px;
        }

        .loader {
          animation: loading 1.2s linear infinite;
        }

        @keyframes loading {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(360deg);
          }
        }
      }
    }

    .notification {
      padding: 16px 12px 15px 48px;
      border-radius: 10px;
      color: ${theme.color.white};
      background: ${theme.color.emeraldDark};
      margin: 0 16px 14px;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        left: 18px;
        top: 20px;
        width: 18px;
        height: 8px;
        border-bottom: 2px solid ${theme.color.white};
        border-left: 2px solid ${theme.color.white};
        transform: rotate(-45deg);
      }

      ${StaleParagraphBold} {
        margin-bottom: 7px;
      }
    }

    .download {
      margin: 12px 0;
      display: block;
      padding: 13px 0;
      display: flex;
      align-items: center;
    }

    .react-date-picker {
      width: 100vw;
      left: -16px;

      &__clear-button {
        display: none;
      }

      &__inputGroup {
        &__input {
          padding: 0;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.002em;
          color: ${theme.color.dark};
        }

        &__month {
          appearance: none;
          -moz-appearance: none;
          -webkit-appearance: none;
          width: 28px;
        }
      }

      &__wrapper {
        border: 0;
        position: absolute;
        right: 16px;
        top: -32px;
      }
    }

    ${Button} {
      margin: 36px 0 24px;
    }
  `
);

export const Field = styled.div(
  ({ theme }) => css`
    margin-top: 16px;

    ${Paragraph} {
      margin-bottom: 14px;
    }
  `
);
