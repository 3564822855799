import { FC, InputHTMLAttributes } from 'react';
import { Wrapper } from './StaleButtonCopy.styles';

interface OwnProps {
  onClick?: (...args: any) => any;
  showIcon?: boolean;
  autoFocus?: InputHTMLAttributes<HTMLInputElement>['autoFocus'];
}

const StaleButtonCopy: FC<OwnProps> = ({
  onClick,
  autoFocus = false,
  showIcon = false,
  children,
}) => {
  return (
    <Wrapper autoFocus={autoFocus} onClick={onClick}>
      {children}

      {showIcon && (
        <svg>
          <use xlinkHref="#copy-ico" />
        </svg>
      )}
    </Wrapper>
  );
};

export default StaleButtonCopy;
