import { Row } from 'components';
import styled from 'styled-components';

export const ValidationWrapper = styled(Row)`
  flex-wrap: wrap;
  margin-top: ${({ theme }) => theme.spacing.m};
`;

export const ValidationItem = styled(Row)`
  width: 50%;
`;
