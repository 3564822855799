import { FC, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Title, StaleInputSelect, Paragraph } from 'components';
import { saveCodatSettings, saveXeroSettings } from 'services/firebase';
import useXero from 'hooks/useXero';
import { Notify } from 'utils';
import { useTheme } from 'styled-components';
import { ERROR_MESSAGES } from 'variables';
import useCodatIntegration from 'hooks/useCodatIntegration';
import { useStoreState } from 'state';
import { TPlatformNames } from 'types/integrations';
import { platformNamesToIntegrationDocIds } from 'utils/integrations';
import Button from '../Button/Button';

const monthsOptions = [
  { id: 3, value: 3, name: '3 months' },
  { id: 6, value: 6, name: '6 months' },
  { id: 9, value: 9, name: '9 months' },
  { id: 12, value: 12, name: '12 months' },
  { id: 24, value: 24, name: '24 months' },
];

interface IImportPeriod {
  id: number;
  value: number;
  name: string;
}

interface Inputs {
  monthsInThePastToQueryFor: IImportPeriod | null;
}

interface OwnProps {
  savedValues?: string[];
  onContinue: () => void;
  platformName: TPlatformNames;
}

const StepHistoricalData: FC<OwnProps> = ({
  savedValues,
  onContinue,
  platformName,
}) => {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const { integrationsSummary } = useStoreState((state) => state.UserState);

  const { onImportData: onImportXeroData } = useXero();
  const { onImportData: onImportCodatData } = useCodatIntegration({
    // TODO look into why integrationsSummary?.[platformName]?.platformName has a type of any and not union of ICodatPlatformNames
    platformNames: [integrationsSummary?.[platformName]?.platformName],
  });

  const integrationDocId = platformNamesToIntegrationDocIds[platformName];

  const onImportDataFunction =
    integrationDocId === 'xero' ? onImportXeroData : onImportCodatData;

  const existingMonthsInThePastToQueryFor = monthsOptions.find(
    (options) => options.value === 24
  );

  const {
    handleSubmit,
    watch,
    formState: { errors },
    control,
  } = useForm<Inputs>({
    mode: 'all',
    defaultValues: {
      monthsInThePastToQueryFor: existingMonthsInThePastToQueryFor,
    },
  });

  const monthsInThePastToQueryForValue = watch('monthsInThePastToQueryFor');

  const onSubmit = async ({ monthsInThePastToQueryFor }) => {
    try {
      setIsLoading(true);

      const response =
        integrationDocId === 'xero'
          ? await saveXeroSettings({
              settings: {
                settingsType: 'Xero',
                monthsInThePastToQueryFor: monthsInThePastToQueryFor.value,
                excludedCurrencies: savedValues,
              },
            })
          : await saveCodatSettings({
              settings: {
                settingsType: 'Codat',
                monthsInThePastToQueryFor: monthsInThePastToQueryFor.value,
                excludedCurrencies: savedValues,
              },
            });

      onImportDataFunction();

      if (response?.data?.success) {
        onContinue();
      } else {
        Notify.error(response?.data?.message ?? '');
        setIsLoading(false);
      }
    } catch (error) {
      Notify.error(error.response?.data?.error);
      setIsLoading(false);
    }
  };

  return (
    <>
      <Title mb mbValue={theme.spacing.xl}>
        How much historical data shall we import?
      </Title>

      <Paragraph mb mbValue={theme.spacing.xl}>
        Our historical “Currency healthcheck” helps you review how well you have
        managed costs and risks from foreign cashflows.
      </Paragraph>

      <Paragraph mb mbValue={theme.spacing.xl}>
        We also need to import outstanding invoices and bills created or
        modified in the past. How much history shall we import:
      </Paragraph>

      <form id="historical-data-form" onSubmit={handleSubmit(onSubmit)}>
        <Controller
          id="monthsInThePastToQueryFor"
          name="monthsInThePastToQueryFor"
          control={control}
          defaultValue={null}
          rules={{
            required: ERROR_MESSAGES.requiredField,
          }}
          render={({ onChange }) => {
            return (
              <StaleInputSelect
                id="monthsInThePastToQueryFor"
                name="monthsInThePastToQueryFor"
                label="Import period"
                view="moving"
                data={monthsOptions}
                selected={monthsInThePastToQueryForValue}
                onSelect={(item) => {
                  onChange(item);
                }}
                strategy="fixed"
                error={errors.monthsInThePastToQueryFor?.message}
              />
            );
          }}
          error={errors.monthsInThePastToQueryFor?.message}
        />
      </form>

      <Button
        mt
        mtValue={theme.spacing.xl}
        isLoading={isLoading}
        disabled={isLoading}
        form="historical-data-form"
      >
        Continue
      </Button>
    </>
  );
};

export default StepHistoricalData;
