import {
  FC,
  useState,
  Dispatch,
  SetStateAction,
  useMemo,
  useRef,
  useEffect,
  InputHTMLAttributes,
} from 'react';
import cx from 'classnames';
import _get from 'lodash.get';

import { IMaskInput } from 'react-imask';
import { StaleInputSelectList } from 'components';
import { Wrapper } from './StaleInputConvert.styles';
import { ICurrency } from 'types';
import { Masks } from 'utils';

interface OwnProps {
  id?: string;
  value: number;
  onChange: Dispatch<SetStateAction<number>>;
  onFocus?: () => any;
  onBlur?: () => any;
  selectedValue: ICurrency | null;
  onSelect: (currency: ICurrency) => void;
  currencies: ICurrency[];
  readOnlyCurrency?: boolean;
  error?: string;
  autoFocus?: InputHTMLAttributes<HTMLInputElement>['autoFocus'];
}

const StaleInputConvert: FC<OwnProps> = ({
  id = '',
  value,
  onChange,
  selectedValue,
  onSelect = () => {},
  onFocus = () => {},
  onBlur,
  currencies,
  readOnlyCurrency = false,
  error = '',
  autoFocus = false,
}) => {
  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [isFocused, setIsFocused] = useState(false);

  const inputRef = useRef<HTMLInputElement>();

  const filteredCurrencies = useMemo(
    () =>
      currencies.filter((item) =>
        Object.values(item).some(
          (value) =>
            typeof value === 'string' &&
            value.toLocaleLowerCase().includes(searchValue.toLowerCase())
        )
      ),
    [searchValue, currencies]
  );

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.addEventListener('change', onFocus);
    }

    return () => {
      if (inputRef.current) {
        inputRef.current.removeEventListener('change', onFocus);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const precision = useMemo(() => _get(selectedValue, 'precision', 2), [
    selectedValue,
  ]);

  return (
    <Wrapper error={!!error} focused={isFocused}>
      <div className="wrap">
        <svg className="country">
          <use xlinkHref={`#${selectedValue?.countryCode}`} />
        </svg>

        <IMaskInput
          id={id}
          // @ts-ignore
          inputRef={(ref) => (inputRef.current = ref || undefined)}
          mask={Number}
          radix="."
          thousandsSeparator=","
          inputMode="decimal"
          className="input"
          unmask="typed"
          value={
            value
              ? Masks.numberToCurrencyNumberString(value, precision)
              : undefined
          }
          autoComplete="off"
          placeholder="0.00"
          onAccept={onChange}
          onFocus={() => {
            onFocus?.();
            setIsFocused(true);
          }}
          onBlur={() => {
            onBlur?.();
            setIsFocused(false);
          }}
          autoFocus={autoFocus}
        />

        <button
          disabled={readOnlyCurrency}
          className={cx('select', readOnlyCurrency && 'read-only')}
          onClick={readOnlyCurrency ? undefined : () => setOpen(!open)}
        >
          {selectedValue?.code}
          {!readOnlyCurrency && (
            <svg className="i-arrow">
              <use xlinkHref="#arrow-down" />
            </svg>
          )}
        </button>

        {open && (
          <StaleInputSelectList
            withClose={true}
            withSearch={true}
            searchValue={searchValue}
            onSearch={(e) => setSearchValue(e.target.value)}
            onSelect={onSelect}
            selectedValue={selectedValue}
            data={filteredCurrencies.map((item) => ({
              ...item,
              icon: item.countryCode.toLowerCase(),
            }))}
            title="Select currency to convert"
            onClose={() => {
              setSearchValue('');
              setOpen(!open);
            }}
          />
        )}
      </div>

      {error && <span className="error">{error}</span>}
    </Wrapper>
  );
};

export default StaleInputConvert;
