import { CSSProperties } from 'react';
import styled from 'styled-components';

import { spacingCss, SpacingCssProps } from '../Spacing/Spacing.styles';

/* 
  TODO: try to avoid omitting translate, now throws TS error
*/
export type FlexProps = Omit<CSSProperties, 'translate'> & SpacingCssProps;

export const Flex = styled.div<FlexProps>`
  display: flex;
  flex: ${({ flex }) => flex};
  flex-basis: ${({ flexBasis }) => flexBasis};
  justify-content: ${({ justifyContent }) => justifyContent};
  align-items: ${({ alignItems }) => alignItems};
  align-content: ${({ alignContent }) => alignContent};
  align-self: ${({ alignSelf }) => alignSelf};
  flex-direction: ${({ flexDirection }) => flexDirection};
  flex-flow: ${({ flexFlow }) => flexFlow};
  flex-grow: ${({ flexGrow }) => flexGrow};
  flex-shrink: ${({ flexShrink }) => flexShrink};
  flex-wrap: ${({ flexWrap }) => flexWrap};
  order: ${({ order }) => order};
  gap: ${({ gap }) => gap};
  column-gap: ${({ columnGap }) => columnGap};
  row-gap: ${({ rowGap }) => rowGap};

  ${spacingCss}
`;
