import { FC, Suspense, useMemo } from 'react';
import { ThemeProvider } from 'styled-components';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import IdleTimer from 'react-idle-timer';

import { defaultTheme } from 'settings/themes';
import { GlobalStyle } from 'components/styles';
import {
  Login,
  ResetPassword,
  SignUp,
  IdVerification,
  ResetPasswordConfirm,
  PageNotFound,
  UserInvite,
} from 'pages';
import { useStoreActions, useStoreState } from 'state';
import {
  StaleInfoHeader,
  Notifications,
  StaleSessionTimeout,
  Layout,
  Sprite,
} from 'components';
import { ApplicationRoutes } from 'routes';
import { PrivateRoute, withErrorBoundary } from 'hoc';
import { isEmulated, Notify } from 'utils';
import { DEFAULT_SESSION_TIMEOUT } from 'variables';
import MFASetup from 'pages/MFASetup/MFASetup';
import auth from 'services/auth';
import useBootstrap from './useBootstrap';
import SignUpXero from 'pages/SignUpXero/SignUpXero';
import Auth from 'pages/Auth/Auth';

const NO_GLOBAL_STYLES_PATHNAMES = ['/privacy-policy', '/terms-and-conditions'];

const App: FC = () => {
  useBootstrap();
  const { isSessionExpired } = useStoreState((state) => state.SessionState);
  const { systemVariables } = useStoreState(
    (state) => state.ReferenceDataState
  );

  const {
    userId,
    isUserRegistrationDone,
    isEntityOnboarded,
    isEntityEnabled,
  } = useStoreState(({ UserState }) => UserState);

  const { setIsSessionExpired } = useStoreActions(
    (actions) => actions.SessionState
  );

  const location = useLocation<{
    from: {
      pathname: string;
    };
  }>();
  const isPhoneVerified = auth.currentUser?.multiFactor.enrolledFactors.find(
    (item) => item.factorId === 'phone'
  );

  const isGlobalStylesDisabled = useMemo(
    () => NO_GLOBAL_STYLES_PATHNAMES.includes(location.pathname),
    [location]
  );

  return (
    <>
      <Sprite />
      <GlobalStyle disabled={isGlobalStylesDisabled} />
      {userId && (
        <IdleTimer
          timeout={
            1000 *
            60 *
            (systemVariables?.sessionTimeout || DEFAULT_SESSION_TIMEOUT)
          }
          debounce={1000}
          onIdle={() => {
            setIsSessionExpired(true);
          }}
          stopOnIdle
        />
      )}
      <ThemeProvider theme={defaultTheme}>
        <Notifications ref={(ref) => Notify.setNotificationRef(ref)} />
        {isSessionExpired && <StaleSessionTimeout />}

        <StaleInfoHeader />

        <Layout>
          <Suspense fallback={<div>Loading...</div>}>
            <Switch>
              <Redirect exact from="/" to="/login" />
              <Route
                exact
                strict
                path="/idverification/:entityId/:applicantId"
                component={IdVerification}
              />
              <Route exact path="/reset-password" component={ResetPassword} />
              <Route
                exact
                path="/reset-password-confirm"
                component={ResetPasswordConfirm}
              />
              <Route exact path="/login" component={Login} />
              <Route exact path="/signup/xero" component={SignUpXero} />

              {!isUserRegistrationDone && (
                <Route exact path="/sign-up" component={SignUp} />
              )}

              <Route exact path="/reset-password" component={ResetPassword} />

              <Route exact path="/invite/:token" component={UserInvite} />

              {!!userId &&
                isUserRegistrationDone &&
                isEntityEnabled &&
                isEntityOnboarded &&
                !isPhoneVerified &&
                !isEmulated() && (
                  <Route exact path={`/mfa-setup`} component={MFASetup} />
                )}

              <Auth>
                <Switch>
                  <PrivateRoute path="/app">
                    <ApplicationRoutes />
                  </PrivateRoute>
                </Switch>
              </Auth>

              <Route path="*" component={PageNotFound} />
            </Switch>
          </Suspense>
        </Layout>
      </ThemeProvider>
    </>
  );
};

export default withErrorBoundary(App);
