import React from 'react';
import { Wrapper, FixedContent } from './StaleContainerWeb.styles';

const StaleContainerWeb = ({ maxWidth = '100%', children }) => (
  <Wrapper>
    <FixedContent maxWidth={maxWidth}>{children}</FixedContent>
  </Wrapper>
);

export default StaleContainerWeb;
