import { StaleContainer } from 'components/StaleLayout/StaleLayout.styles';
import { FC } from 'react';
import Button from '../Button/Button';
import StaleBtnGroup from '../StaleBtnGroup/StaleBtnGroup';
import StalePopup from '../StalePopup/StalePopup';
import { Paragraph } from '../Typography/Typography';

interface OwnProps {
  onAccept: () => void;
  onReject: () => void;
  onClose: () => void;
}

const LinkContactToSourceContactPopup: FC<OwnProps> = ({
  onAccept,
  onReject,
  onClose,
}) => (
  <StalePopup
    title="Since you selected an existing contact"
    theme="small"
    width="450px"
    minHeight="auto"
    onClose={onClose}
  >
    <StaleContainer style={{ padding: 0 }}>
      <Paragraph>
        Do you want to link the Xero contact on this invoice to all future
        invoices with the selected Payee?
      </Paragraph>
      <StaleBtnGroup horizontal container={false}>
        <Button onClick={onAccept}>Yes please</Button>
        <Button onClick={onReject} variant="secondary">
          Not now
        </Button>
      </StaleBtnGroup>
    </StaleContainer>
  </StalePopup>
);

export default LinkContactToSourceContactPopup;
