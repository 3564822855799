import { SpacingCssProps } from '../Spacing/Spacing.styles';
import { StyledInlineLoader } from './InlineLoader.styles';

interface OwnProps extends SpacingCssProps {
  height?: string;
  width?: string;
}
const InlineLoader = ({
  height = '16px',
  width = '16px',
  ...spacingCssProps
}: OwnProps) => (
  <StyledInlineLoader width={width} height={height} {...spacingCssProps}>
    <use xlinkHref="#loader-ico" />
  </StyledInlineLoader>
);

export default InlineLoader;
