import styled, { css } from 'styled-components';
import { Row } from '../Row/Row';

export const CalendarWrapper = styled.div(
  ({ theme }) => css`
    background: ${theme.color.white};
    padding: 0 ${theme.spacing.m} ${theme.spacing.xxl};
    box-shadow: ${theme.shadow};
    border-radius: ${theme.borderRadius.m};
    position: relative;
    max-width: 407px;

    .react-calendar {
      width: 100%;
      border: none;
      padding: ${theme.spacing.m} 0 ${theme.spacing.xl};

      &__viewContainer {
        @media (min-width: ${theme.breakpoint.largeMin}px) {
          padding: 0 12px;
        }
        @media (max-width: ${theme.breakpoint.largeMax}px) {
          padding: 0 4px;
        }
      }

      &__navigation {
        display: flex;
        justify-content: space-between;
        margin: 0 0 16px;
        height: 38px;
        padding: 0 0 12px;
        border-bottom: 1px solid ${theme.color.greyLight_2};

        @media (min-width: ${theme.breakpoint.largeMin}px) {
          margin: 14px 12px 12px;
        }

        &__next2-button,
        &__prev2-button {
          display: none;
        }

        &__next2-button:active,
        &__prev2-button:active,
        &__next2-button:hover,
        &__prev2-button:hover,
        &__next2-button:focus,
        &__prev2-button:focus {
          display: none;
        }

        &__label__labelText {
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          color: ${theme.color.dark};
          margin: 0px 6px 4px;
          display: block;
        }

        &__arrow {
          &:disabled svg {
            fill: ${theme.color.grey};
          }
        }

        &__prev-button,
        &__next-button {
          &:focus svg {
            fill: ${theme.color.emeraldDark};
          }
        }
      }

      abbr {
        text-decoration: none;
      }

      &__tile {
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        color: ${theme.color.dark};
        padding: 0;
        margin: 4.5px 0;
        background: none;
        display: flex;
        align-items: center;
        justify-content: center;

        &:disabled {
          color: ${theme.color.grey};
          pointer-events: none;
        }

        &:focus {
          abbr {
            box-shadow: inset 0 0 0 1px ${theme.color.emeraldDark};
          }
        }

        abbr {
          border-radius: 24px;
          padding: 4px 7px 2px;
          display: block;
        }

        &.react-calendar__month-view__days__day {
          abbr {
            width: 30px;
          }
        }

        &.react-calendar__century-view__decades__decade,
        &.react-calendar__decade-view__years__year {
          margin-left: auto;
          margin-right: auto;

          &:disabled {
            display: none;
          }
        }

        &--active {
          abbr {
            background: ${theme.color.emeraldDark};
            color: ${theme.color.white};
          }
        }
      }

      &__month-view {
        &__weekdays {
          font-weight: 500;
          font-size: 14px;
          line-height: 150%;
          color: #8c9199;
          text-align: center;

          &__weekday {
            padding: 8px 0 12px;
          }
        }
      }

      &__month-view__days__day--neighboringMonth {
        color: #8c9199;
      }
    }
  `
);

export const CalendarButtonWrapper = styled(Row)(
  ({ theme }) => css`
    padding: 0 ${theme.spacing.m};
  `
);
