import { Col } from 'components';
import styled, { css } from 'styled-components';

export const IntegrationWrapper = styled(Col)(
  ({ theme }) => css`
    background: ${theme.color.white};
    width: 100%;

    @media (min-width: ${theme.breakpoint.mediumMin}px) {
      max-width: 956px;
      margin: 0 auto;
      border-radius: 6px;
      box-shadow: ${theme.shadow};
    }
  `
);
