import { IContact } from './recipients';
import { ISystemFields } from './systemFields';

export interface IInvoice extends ISystemFields {
  id: string;
  contactId?: string;
  contact?: IContact;
  invoiceNumber: string;
  type: 'Receivable' | 'Payable';
  status: INVOICE_STATUSES;

  reference?: string;
  currency: string;
  externalCurrencyRate?: number;
  date: string;
  dueDate: string;

  amountDue: number;
  amountPaid?: number;
  isDiscounted?: boolean;
  totalDiscountAmount?: number;
  subTotalAmount?: number;
  totalTaxAmount?: number;
  totalAmount: number;
  lineAmountTypes?: string;
  lineItems?: IInvoiceLineItem[];
  prebookFee?: number;
  prebookFeePercent?: number;
  fullyPaidOnDate?: string;
  payments?: IInvoicePayment[];

  externalRefs?: {
    xeroId?: string;
    xeroContactId?: string;
    xeroRepeatingInvoiceId?: string;
    codatId?: string;
    codatContactId?: string;
    orderWiseId?: string;
    orderWiseContactId?: string;
  };

  trackingId?: string;
  contractId?: string;
  contractAssignment?: boolean;
  transferId?: string;
  excludeFromRisk?: boolean;
  approval?: IInvoiceApproval;

  permissions?: {
    xero?: boolean;
  };

  hasAttachments?: boolean;
  attachments?: IAttachment[];

  lastSyncFromExternalSource?: ISourceSyncData;
  lastSyncToExternalSource?: ISourceSyncData;
}

export interface ISourceSyncData {
  timestamp: {
    seconds: number;
    nanoseconds: number;
  };
  success: boolean;
}

export interface IAttachment {
  fileName: string; // "sample.pdf"
  mimeType: string; // "application/pdf"
  url: string; // "https://api.xero.com/api.xro/2.0/Invoices/f853f034-ef27-4e0b-a5d0-12b2b156babb/Attachments/sample.pdf"
}

export interface IInvoiceApproval {
  status: 'submitted' | 'approved' | null;
  submittedBy: string;
  submittedAt: string;
  approvedBy?: string;
  approvedAt?: string;
}

// temporary structure for POC
export interface IInvoicePayment {
  id: string;
  recipientName: string;
  amount: number;
  date: string;
  currency: string;
}

export interface IInvoiceLineItem {
  itemCode: string;
  name: string;
  description: string;
  unitAmount: number;
  lineAmount: number;
  taxAmount?: number;
  quantity: number;
  discountRate?: number;
  discountAmount?: number;
  externalRefs?: {
    xeroId?: string;
    xeroItemId?: string;
  };
}

export enum INVOICE_STATUSES {
  draft = 'DRAFT',
  submitted = 'SUBMITTED',
  deleted = 'DELETED',
  authorised = 'AUTHORISED',
  paymentScheduled = 'PAYMENT SCHEDULED',
  partiallyPaid = 'PARTIALLY PAID',
  processingPayment = 'PROCESSING PAYMENT',
  paid = 'PAID',
  voided = 'VOIDED',
  purchaseOrder = 'PURCHASE ORDER', // TODO: Reconsider removing this status from interface
  salesOrder = 'SALES ORDER', // TODO: Reconsider removing this status from interface
}
