import styled from 'styled-components';
import { Row } from '../Row/Row';

export const ContactButton = styled(Row)`
  width: inherit;
  min-width: inherit;
  flex: 1;

  &:disabled {
    cursor: default;
    pointer-events: none;
  }
`;
