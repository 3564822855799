import { Col, Row } from 'components';
import styled from 'styled-components';

export const ControlsWrapper = styled(Row)`
  background: ${({ theme }) => theme.color.white};
  border-top-right-radius: ${({ theme }) => theme.borderRadius.m};
  padding: ${({ theme }) => theme.spacing.m};
  gap: ${({ theme }) => theme.spacing.xl};
  justify-content: flex-start;
  border-top: 1px solid ${({ theme }) => theme.color.greyLight_2};
`;

export const ProgressBarWrapper = styled(Col)`
  gap: ${({ theme }) => theme.spacing.xs};
  min-width: 310px;
  margin-left: auto;
`;
