import { ISelectItem } from 'components/shared/StaleInputSelect/StaleInputSelect';
import { HF_GURU_TASKS } from 'types/entities';
import { getSettingsPageLink } from 'utils/links';

export const SLIDER_DATA = [
  {
    id: 0,
    title: 'Prebook exchange',
    text:
      'Book your exchange in advance with a small prepayment and save time and money.',
    icon: 'prebook',
  },
  {
    id: 1,
    title: 'Follow currencies',
    text:
      'Add a currency pair to follow their rate changes. It may help you to pre-book a rate or make transfers when the rate is suitable.',
    icon: 'rates',
  },
  {
    id: 2,
    title: 'Transfer money',
    text: 'Pay securely in 38 currencies using current or prebooked rates.',
    icon: 'transfer',
  },
];

export const SLIDER_OPTIONS = {
  speed: 400,
  slidesToShow: 1,
  fade: true,
  arrows: false,
  dots: true,
  autoplay: true,
  autoplaySpeed: 3000,
};

// in minutes
export const DEFAULT_SESSION_TIMEOUT = 20;

export const RECIPIENT_ROUTING_TYPES = {
  aba: 'ABA',
  sortCode: 'Sort code',
  bsbCode: 'BSB code',
  institutionNo: 'Institution number',
  bankCode: 'Bank code',
  branchCode: 'Branch code',
  clabe: 'CLABE',
  cnaps: 'CNAPS',
};

export const VALIDATION_MESSAGES = [
  {
    id: 0,
    text: 'At least 8 characters',
    typeId: 'minLength',
    validate: (value: string) => value?.length >= 8,
  },
  {
    id: 1,
    text: 'At least one number',
    typeId: 'oneNumber',
    validate: (value: string) => /(?=.*\d)/.test(value),
  },
  {
    id: 2,
    text: 'At least one upper case character',
    typeId: 'oneUppercase',
    validate: (value: string) => /(?=.*[A-Z])/.test(value),
  },
  {
    id: 3,
    text: 'At least one lower case character',
    typeId: 'oneLowercase',
    validate: (value: string) => value && /(?=.*[a-z])/.test(value),
  },
  {
    id: 4,
    text: 'At least one special character',
    typeId: 'oneSpecial',
    validate: (value: string) => /(?=.*[-+_!@#$%^&*.,?])/.test(value),
  },
];

export const ERROR_MESSAGES = {
  amountTooLow: 'Amount is too low',
  aboveCreditLimit:
    'Amount is too high. To increase your limit, please contact support.',
  requiredField: 'This field is required',
  incorrectDate: 'Date is incorrect',
};

export const HELP_URL = 'https://help.hedgeflows.com';
export const SCAM_HELP_URL =
  'https://help.hedgeflows.com/how-to-avoid-fraudulent-payments';

export const DATE_FORMAT = 'DD MM YYYY';
export const DB_MONTH_FORMAT = 'YYYY-MM';
export const MONTH_DATE_FORMAT = 'MMM YYYY';
export const QUARTER_DATE_FORMAT = '[Q]Q YYYY';

export const RISK_APPETITE_SLIDER_DATA = [
  {
    id: 1,
    title: 'Risk tolerance',
    text:
      'We help you set up the risk tolerance metric and monitor your currency risks so that you don’t lose more that you can afford to',
    icon: 'past-performance',
  },
  {
    id: 2,
    title: 'Risk preferences',
    text:
      'There is more than one way to think about risks and you can adopt approach that suits your business',
    icon: 'transfer',
  },
  {
    id: 3,
    title: 'Risk planning',
    text:
      'If you care about your profit margins and cashflows, take time to understand how currencies affect them.',
    icon: 'prebook',
  },
];

export const COMMITTED_CURRENCY_QUESTIONS: ISelectItem[] = [
  {
    id: 'COMMITTED_1',
    value: 'WaitUntilFXRatesReachTarget',
    name: 'Wait until FX rates reach my targets',
  },
  {
    id: 'COMMITTED_2',
    value: 'SecureRatesWhenGood',
    name: 'Secure rates when they are good',
  },
  {
    id: 'COMMITTED_3',
    value: 'SecureRatesWhenExposureReachesLimit',
    name: 'Secure rates when exposure reaches our risk limit',
  },
  {
    id: 'COMMITTED_4',
    value: 'SecureRatesWhenGettingInvoices',
    name: 'Secure rates as soon as I get foreign invoices',
  },
];

export const FUTURE_CURRENCY_QUESTIONS: ISelectItem[] = [
  {
    id: 'FUTURE_1',
    value: 'NoVisibility',
    name: 'I have no visibility on future cashflows',
  },
  {
    id: 'FUTURE_2',
    value: 'DoNothing',
    name: 'Do nothing until invoices are accrued',
  },
  {
    id: 'FUTURE_3',
    value: 'SecureRatesWhenGood',
    name: 'Secure rates when they are good',
  },
  {
    id: 'FUTURE_4',
    value: 'SecureRatesToStayWithinRiskLimits',
    name: 'Secure rates to stay within my risk limit',
  },
];

export const GURU_CARDS = [
  {
    id: HF_GURU_TASKS.integrate,
    icon: 'track',
    title: 'Integrate accounting system',
    text: 'Integrate with your system to enable our automation and analytics',
    link: getSettingsPageLink({ tab: 'integrations' }),
    buttonText: 'Connect systems',
    order: 1,
  },
  {
    id: HF_GURU_TASKS.healthCheck,
    icon: 'hf-guru-past-performance',
    title: 'Review your past performance',
    text: 'Periodically check how well we manage our currency risks and costs',
    link: '/app/reports',
    buttonText: 'Run currency health-check',
    order: 2,
  },
  {
    id: HF_GURU_TASKS.register,
    icon: 'complete',
    title: 'Register your business',
    text: 'To get full access to HedgeFlows only takes a few minutes',
    link: '/app/company-registration',
    buttonText: 'Register your company ',
    order: 3,
  },
  {
    id: HF_GURU_TASKS.riskPreference,
    icon: 'rates',
    title: 'Assess your risk tolerance',
    text:
      'Tell us about your preferences for financial risks from foreign trade',
    link: '/app/risk-settings',
    buttonText: 'Short risk assessment',
    order: 4,
  },
  {
    id: HF_GURU_TASKS.manageRisk,
    icon: 'hf-guru-exposure',
    title: 'Manage your currency risks',
    text:
      'Transact to manage currency risks, and make or collect foreign payments',
    link: '/app/invoices',
    buttonText: 'Manage risks',
    order: 5,
  },
  {
    id: HF_GURU_TASKS.automateProcesses,
    icon: 'prebook',
    title: 'Automate processes',
    text: 'Plan in advance or bulk process foreign cashflows to save time',
    link: '/app/invoices',
    buttonText: 'Manage invoices ',
    order: 6,
  },
];
