import { FC } from 'react';
import CashflowAtRisk from './components/CashflowAtRisk/CashflowAtRisk';
import Networth from './components/Networth/Networth';
import { useTheme } from 'styled-components';
import MonthlyBreakdown from './components/MonthlyBreakdown/MonthlyBreakdown';
import DataTable from './components/DataTable/DataTable';
import { BottomContentWrapper, TopContentWrapper } from './Risks.styles';

const Risks: FC = () => {
  const theme = useTheme();

  return (
    <>
      <TopContentWrapper
        columnGap={theme.spacing.m}
        mb
        mbValue={theme.spacing.xxxl}
      >
        <CashflowAtRisk />
        <Networth />
        <MonthlyBreakdown />
      </TopContentWrapper>

      <BottomContentWrapper>
        <DataTable />
      </BottomContentWrapper>
    </>
  );
};

export default Risks;
