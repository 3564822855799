import styled, { css } from 'styled-components';

export const StaleWrapper = styled.thead<{ fontWeight: number }>(
  ({ theme, fontWeight }) => css`
    th {
      font-size: 12px;
      font-weight: ${fontWeight};
      line-height: 18px;
      color: ${theme.color.greyDark};
      padding: 12px 24px 15px;
      vertical-align: middle;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: ${theme.color.white};
        border-top: 1px solid ${theme.color.greyLight_2};
        box-shadow: inset 0 -4px 0 0 ${theme.color.greyLight_2};
      }

      * {
        position: relative;
      }

      button {
        display: flex;
        align-items: center;
        font-size: 14px;
        line-height: 21px;
        font-weight: 700;
        color: ${theme.color.grey};

        margin: 0 4px;

        svg {
          margin: 0 4px;
          transition: ${theme.transition};
          width: 16px;
          height: 16px;
          fill: ${theme.color.greyDark};
        }

        &.text {
          width: auto;
          white-space: nowrap;
        }
      }

      &:first-child {
        &:before {
          border-radius: 6px 0 0 6px;
          border-left: 1px solid ${theme.color.greyLight_2};
        }
      }

      &:last-child {
        text-align: right;

        p,
        .table-row {
          justify-content: flex-end;
        }

        &:before {
          border-radius: 0 6px 6px 0;
          border-right: 1px solid ${theme.color.greyLight_2};
        }
      }

      p {
        display: flex;
        align-items: center;
      }
    }
  `
);
