import styled from 'styled-components';

export const FilterButton = styled.button<{ active?: boolean }>`
  background: ${({ active, theme }) =>
    active ? theme.color.emeraldLight : theme.color.white};
  padding: ${({ theme }) => `${theme.spacing.xxs} ${theme.spacing.l}`};
  border-radius: ${({ theme }) => theme.borderRadius.xl};
  border: 1px solid ${({ theme }) => theme.color.greyLight_1};
  margin: 0 ${({ theme }) => theme.spacing.xxs};
`;
