// TODO: revisit types for selectedTransfer here, seems to be unclear because we have either IBulkPayment or ITransfer
import { FC, useMemo, useState, useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import {
  Title,
  TransferTable,
  Col,
  Row,
  Paragraph,
} from 'components';
import { useStoreState } from 'state';
import { useQuery } from 'hooks';
import { ITransfer, TRANSFER_TYPE } from 'types';
import Popup from 'components/shared/Popup/Popup';
import { useTheme } from 'styled-components';
import TransferDetails from '../../../pages/Dashboard/components/TransferDetails/TransferDetails';
import RecipientDetails from '../../../pages/Dashboard/components/RecipientDetails/RecipientDetails';
import BulkPaymentDetails from '../../../pages/Dashboard/components/BulkPaymentDetails/BulkPaymentDetails';
import PaymentCredentials from '../../../pages/Dashboard/components/PaymentCredentials/PaymentCredentials';
import { StepperItem } from 'components/shared/StaleStepsHorizontal/StaleStepsHorizontal.styles';
import MT103Details from '../../../pages/Dashboard/components/MT103Details/MT103Details';
import { getInvoicesByIdArray } from 'services/firebase/invoices';
import { parseIntoCurrencyString } from 'utils';
import TransferInvoicesTable from 'components/shared/TransferInvoicesTable/TransferInvoicesTable';
import {
  InnerDetailsWrapper,
  OverflowXWrapper,
} from '../InnerDetails/InnerDetails.styles';
import Button from '../Button/Button';

type ContentType = 'payment' | 'transfers' | 'invoices' | 'mt103' | null;

const TransferInner: FC = () => {
  const history = useHistory();
  const theme = useTheme();

  const { transferById } = useStoreState((state) => state.TransfersState);
  const { bulkTransferById } = useStoreState(
    (state) => state.BulkPaymentsState
  );
  const { transfersByBulkId } = useStoreState((state) => state.TransfersState);

  const URLQuery = useQuery();
  const transferId = URLQuery.get('transferId');
  const bulkId = URLQuery.get('bulkPaymentId');
  const { url } = useRouteMatch();
  const [relatedInvoices, setRelatedInvoices] = useState<any[]>([]);
  const [contentType, setContentType] = useState<ContentType>(null);

  const selectedTransfer = useMemo<ITransfer>(
    // @ts-ignore
    () => (bulkId ? bulkTransferById(bulkId) : transferById(transferId)),
    [transferById, bulkTransferById, transferId, bulkId]
  );

  const relatedTransfers = useMemo(
    () => (bulkId ? transfersByBulkId(bulkId) : []),
    [transfersByBulkId, bulkId]
  );

  const relatedInvoicesIds = useMemo(() => {
    const ids: string[] = [];

    relatedTransfers.forEach((transfer) => {
      if (!!transfer?.invoiceId) {
        ids.push(transfer.invoiceId);
      }
      if (!!transfer?.invoiceIds?.length) {
        ids.push(...transfer.invoiceIds);
      }
    });

    if (!!selectedTransfer?.invoiceId) {
      ids.push(selectedTransfer.invoiceId);
    }
    if (!!selectedTransfer?.invoiceIds?.length) {
      ids.push(...selectedTransfer.invoiceIds);
    }

    return ids;
  }, [relatedTransfers, selectedTransfer]);

  const recipient = selectedTransfer?.recipient;

  useEffect(() => {
    if (!!relatedInvoicesIds?.length) {
      const getRelatedInvoices = async () => {
        try {
          const result = await getInvoicesByIdArray(relatedInvoicesIds);

          setRelatedInvoices(result);
        } catch (error) {
          console.warn(error);
        }
      };

      getRelatedInvoices();
    }
  }, [relatedInvoicesIds]);

  const generateTabs = () => {
    const tabsArray: Array<{ id: ContentType; title: string }> = [];

    if (
      selectedTransfer.status === 'awaiting_payment' &&
      !selectedTransfer?.bulkPaymentId
    ) {
      tabsArray.push({
        id: 'payment',
        title: 'Payment',
      });
    }

    if (relatedTransfers?.length > 0) {
      tabsArray.push({
        id: 'transfers',
        title: 'Transfers',
      });
    }

    if (relatedInvoices?.length > 0) {
      tabsArray.push({
        id: 'invoices',
        title: 'Invoices',
      });
    }

    if (
      selectedTransfer?.transferType === TRANSFER_TYPE.priority &&
      selectedTransfer.externalRefs?.ccId
    ) {
      tabsArray.push({
        id: 'mt103',
        title: 'MT103',
      });
    }

    return tabsArray;
  };

  const tabs = generateTabs();

  useEffect(() => {
    if (!!tabs.length && !contentType) {
      setContentType(tabs[0].id);
    }
  }, [contentType, tabs]);

  return (
    <Popup
      HeaderContent={
        <Title variant="h3">{bulkId ? 'Bulk payment' : 'Transfer'}</Title>
      }
      FooterContent={<Button onClick={() => history.push(url)}>Close</Button>}
      width="900px"
      height="800px"
      onClose={history.goBack}
    >
      <Col rowGap={theme.spacing.xl}>
        {recipient && (
          <InnerDetailsWrapper>
            <TransferDetails transfer={selectedTransfer} />
            <RecipientDetails transfer={selectedTransfer} />
          </InnerDetailsWrapper>
        )}

        {bulkId && <BulkPaymentDetails transfer={selectedTransfer} />}

        <Row justifyContent="flex-start">
          {tabs.map(({ title, id }) => (
            <StepperItem
              onClick={() => setContentType(id)}
              key={id}
              current={id === contentType}
            >
              {title}
            </StepperItem>
          ))}
        </Row>

        {contentType === 'transfers' && (
          <Col>
            <Title mb variant="h5">
              Transfers <span>{relatedTransfers?.length}</span>
            </Title>
            <OverflowXWrapper>
              <TransferTable data={relatedTransfers} />
            </OverflowXWrapper>
          </Col>
        )}

        {contentType === 'invoices' && (
          <Col>
            <Title mb variant="h5">
              List of invoices covered by transfer
            </Title>
            <Paragraph mb>
              Your transfer amount of
              <strong>
                {` ${parseIntoCurrencyString(selectedTransfer?.buyAmount)} ${
                  selectedTransfer?.buyCurrency
                } `}
              </strong>
              covers the following invoices:
            </Paragraph>
            <OverflowXWrapper>
              <TransferInvoicesTable data={relatedInvoices} />
            </OverflowXWrapper>
          </Col>
        )}

        {contentType === 'payment' && (
          <PaymentCredentials transfer={selectedTransfer} />
        )}

        {contentType === 'mt103' && (
          <MT103Details transfer={selectedTransfer} />
        )}
      </Col>
    </Popup>
  );
};

export default TransferInner;
