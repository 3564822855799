import React, { FC, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AmountHeading, AmountWrapper, Wrapper } from './PopupTracking.styles';
import {
  StaleContainer,
  StaleBtnGroup,
  Paragraph,
  StaleTextHint,
  StalePopup,
  Row,
  InlineLoader,
} from 'components';
import InfoCard from '../../InfoCard/InfoCard';
import ReactSlider from 'react-slider';
import dayjs from 'dayjs';
import { Firebase } from 'services';
import { useStoreActions, useStoreState } from 'state';
import { parseIntoCurrencyString } from 'utils';
import { IInvoice } from 'types';
import useCurrencyRate from 'hooks/useCurrencyRate';
import {
  getInvoiceNumber,
  getInvoicePrebookLink,
  getInvoiceTransferLink,
} from 'utils/invoices';
import Button from 'components/shared/Button/Button';

interface OwnProps {
  invoice: IInvoice;
  onChangeTarget: (invoice: IInvoice) => void;
  onClose: () => void;
}

const PopupTracking: FC<OwnProps> = ({ onClose, onChangeTarget, invoice }) => {
  const history = useHistory();

  const [tracking, setTracking] = useState<any>(null);
  const { rate } = useCurrencyRate({
    buyCurrency: invoice.currency,
    sellCurrency: 'GBP',
  });
  const [bookingFeeRate, setBookingFeeRate] = useState<number>(0);
  const { currencyByCode } = useStoreState((state) => state.CurrenciesState);
  const { rateAnalyses } = useStoreState((state) => state.ReferenceDataState);
  const { getRateAnalyses } = useStoreActions(
    (actions) => actions.ReferenceDataState
  );
  const { getInvoiceTracking } = useStoreActions(
    (actions) => actions.InvoicesState
  );

  const currency = currencyByCode(invoice.currency);

  useEffect(() => {
    if (invoice.trackingId) {
      getInvoiceTracking({
        trackingId: invoice.trackingId,
      }).then((data) => {
        setTracking(data);
      });
    }
  }, [invoice.trackingId, getInvoiceTracking]);

  useEffect(() => {
    if (invoice && rate) {
      getRateAnalyses({
        buyCurrency: invoice.currency,
        sellCurrency: 'GBP',
        buyAmount: invoice.totalAmount,
        sellAmount: invoice.totalAmount / rate,
      });
    }
  }, [invoice, rate, getRateAnalyses]);

  useEffect(() => {
    if (invoice) {
      Firebase.getForwardRateAndFees({
        sellCurrency: 'GBP',
        buyCurrency: invoice.currency,
        date: dayjs(invoice.dueDate).format('YYYY-MM-DD'),
      })
        .then((response) => {
          if (response.data) {
            setBookingFeeRate(response.data.bookingFeeRate);
          }
        })
        .catch((error) => console.warn(error));
    }
  }, [invoice]);

  const activeRateAnalysesResult = useMemo(() => rateAnalyses?.data[2], [
    rateAnalyses,
  ]);

  const goToTransfer = () => {
    history.push(getInvoiceTransferLink(invoice));
  };

  const goToPrebook = () => {
    history.push(getInvoicePrebookLink(invoice));
  };

  return (
    <StalePopup
      title={`Invoice ${getInvoiceNumber(invoice)}`}
      theme="grey"
      width="439px"
      headContentAdditional={
        <Paragraph style={{ marginLeft: 'auto', marginRight: 12 }}>
          {`Due date ${dayjs(invoice.dueDate).format('D MMM, YYYY')}`}
        </Paragraph>
      }
      onClose={onClose}
    >
      <Wrapper>
        <StaleContainer>
          <Row
            style={{
              alignItems: 'flex-start',
            }}
          >
            <AmountWrapper>
              <AmountHeading>Amount due</AmountHeading>
              <Row>
                <svg width="24px" height="24px" style={{ marginRight: '8px' }}>
                  <use xlinkHref={`#${currency?.countryCode}`} />
                </svg>
                <Paragraph variant="bold">{`${
                  currency?.symbol
                }${parseIntoCurrencyString(
                  invoice.totalAmount,
                  currency?.precision
                )}`}</Paragraph>
              </Row>
            </AmountWrapper>

            <AmountWrapper>
              <AmountHeading>Current</AmountHeading>
              <Row>
                <svg width="24px" height="24px" style={{ marginRight: '8px' }}>
                  <use xlinkHref={`#gb`} />
                </svg>
                <Paragraph variant="bold">
                  {rate ? (
                    `£${parseIntoCurrencyString(invoice.totalAmount / rate)}`
                  ) : (
                    <InlineLoader />
                  )}
                </Paragraph>
              </Row>
            </AmountWrapper>

            <AmountWrapper>
              <Row>
                <div
                  style={{
                    height: 16,
                    border: '2px dotted #8C9199',
                    marginRight: 8,
                  }}
                />
                <AmountHeading>Target</AmountHeading>
              </Row>
              <Paragraph variant="bold">{`${
                currency?.symbol
              }${parseIntoCurrencyString(
                tracking?.targetAmount,
                currency?.precision
              )}`}</Paragraph>

              <Button
                onClick={() => {
                  onChangeTarget(invoice);
                  onClose();
                }}
                variant="link"
              >
                Change
              </Button>
            </AmountWrapper>
          </Row>

          <InfoCard skin="border">
            <div className="tracked-block">
              <div className="col">
                <span>Lowest</span>

                <p>
                  <span>{`£${parseIntoCurrencyString(
                    activeRateAnalysesResult?.lowest
                  )}`}</span>
                </p>
              </div>
              <div
                className="col"
                style={{
                  flex: '1 0 auto',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {rate ? (
                  <ReactSlider
                    value={[tracking?.targetAmount, invoice.totalAmount / rate]}
                    min={activeRateAnalysesResult?.lowest}
                    max={activeRateAnalysesResult?.highest}
                    className="cost-range"
                    thumbClassName="example-thumb"
                    trackClassName="example-track"
                    disabled
                    renderThumb={(props) => {
                      if (props.key === 'example-thumb-1') {
                        return (
                          <div {...props}>
                            <div className="tooltip">Current</div>
                          </div>
                        );
                      }

                      return (
                        <div
                          {...props}
                          style={{
                            ...props.style,
                            border: '2px dashed #8C9199',
                          }}
                        />
                      );
                    }}
                  />
                ) : (
                  <InlineLoader />
                )}
              </div>
              <div className="col">
                <span>Highest</span>

                <p>
                  <span>{`£${parseIntoCurrencyString(
                    activeRateAnalysesResult?.highest
                  )}`}</span>
                </p>
              </div>
            </div>

            <StaleTextHint>Last 90 days rate changes</StaleTextHint>
          </InfoCard>

          <InfoCard icon="lock-ico">
            <Paragraph variant="bold">
              Current rate: <span>£1.00 = {`${currency?.symbol}${rate}`}</span>
            </Paragraph>
            <Paragraph>
              {`Booking fee: £${
                bookingFeeRate && rate
                  ? parseIntoCurrencyString(
                      (invoice.totalAmount / rate) * bookingFeeRate
                    )
                  : ''
              } to secure this this rate until ${dayjs(invoice.dueDate).format(
                'D MMM, YYYY'
              )}`}
            </Paragraph>
          </InfoCard>
        </StaleContainer>

        <StaleBtnGroup container={false}>
          <Button onClick={goToPrebook}>Prebook</Button>

          <Button variant="link" onClick={goToTransfer}>
            Pay now
          </Button>
        </StaleBtnGroup>
      </Wrapper>
    </StalePopup>
  );
};

export default PopupTracking;
