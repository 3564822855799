import { FC, useEffect } from 'react';
import { StaleTitleH4 } from 'components';
import { Wrapper } from './StaleSessionTimeout.styles';
import { useStoreActions } from 'state';
import { Button } from '../StaleButton/StaleButton';

const StaleSessionTimeout: FC = () => {
  const { signOut } = useStoreActions((actions) => actions.UserState);
  const { setIsSessionExpired } = useStoreActions(
    (actions) => actions.SessionState
  );
  useEffect(() => {
    signOut();
  }, [signOut]);

  return (
    <Wrapper>
      <div className="popup">
        <StaleTitleH4>Session is expired due to inactivity.</StaleTitleH4>

        <Button onClick={() => setIsSessionExpired(false)}>Close</Button>
      </div>
    </Wrapper>
  );
};

export default StaleSessionTimeout;
