import styled from 'styled-components';
import { Row } from '../Row/Row';

export const AccountsRow = styled(Row)`
  flex: 1;
  padding: ${({ theme }) => `${theme.spacing.s} ${theme.spacing.m}`};
  background: ${({ theme }) => theme.color.white};
  border-radius: ${({ theme }) => theme.borderRadius.s};
  border: 1px solid ${({ theme }) => theme.color.greyLight_2};
  min-height: 56px;
`;
