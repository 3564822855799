import { Table } from 'components';
import useUrlValues from 'hooks/useUrlValues';
import { FC, useMemo } from 'react';
import { useStoreState } from 'state';
import { generateTableColumns } from './tableColumnsGenerator';
import { formatValuesPerMonth, formatValuesPerQuarter } from '../../utils';

const DataTable: FC = () => {
  const { currency, period } = useUrlValues('currency', 'period');
  const { cashflowsRisks } = useStoreState((state) => state.ReferenceDataState);

  const currencyRiskPerCurrency = currency
    ? cashflowsRisks?.currencyRisk[currency]
    : undefined;

  const columns = useMemo(
    () => (currency ? generateTableColumns({ currencyCode: currency }) : []),
    [currency]
  );

  const tableData = useMemo(() => {
    const monthlyData = currencyRiskPerCurrency
      ? formatValuesPerMonth(currencyRiskPerCurrency.perMonth)
      : [];

    if (period === 'quarterly') {
      return formatValuesPerQuarter(monthlyData);
    }

    return monthlyData;
  }, [currencyRiskPerCurrency, period]);

  return <Table data={tableData} columns={columns} isVirtualized />;
};

export default DataTable;
