import { Col, Icon } from 'components';
import Button from 'components/shared/Button/Button';
import { useHistory, useLocation } from 'react-router';

import { FC } from 'react';
import { getLink } from 'utils/links';
import {
  ErrorPlaceholderWrapper,
  ErrorPlaceholderTextsWrapper,
  HeadingTitle,
  TextTitle,
} from './ErrorPlaceholder.styles';

const ErrorPlaceholder: FC = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const isOnDashboard = pathname === '/app/dashboard';

  return (
    <ErrorPlaceholderWrapper>
      <ErrorPlaceholderTextsWrapper>
        <HeadingTitle mb variant="h1">
          We sure made a mess of this...
        </HeadingTitle>
        <TextTitle variant="h3">
          We are truly sorry that this happened. You can try again, but if this
          error persists then please don’t hesitate to contact us.
        </TextTitle>
        {!isOnDashboard && (
          <Button onClick={() => history.push(getLink('/app/dashboard'))}>
            Back to dashboard
          </Button>
        )}
      </ErrorPlaceholderTextsWrapper>
      <Col flex={1}>
        <Icon icon="error-page-image" width="100%" height="400px" />
      </Col>
    </ErrorPlaceholderWrapper>
  );
};

export default ErrorPlaceholder;
