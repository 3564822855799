import { FC, AllHTMLAttributes, ButtonHTMLAttributes } from 'react';
import { StyledButton } from './Button.styles';
import InlineLoader from '../InlineLoader/InlineLoader';
import { SpacingCssProps } from '../Spacing/Spacing.styles';
import Icon, { IconProps } from '../Icon/Icon';
import { MakeOptional } from 'types';

export interface ButtonProps
  extends Pick<
      AllHTMLAttributes<HTMLButtonElement>,
      'onClick' | 'disabled' | 'form'
    >,
    Pick<ButtonHTMLAttributes<HTMLButtonElement>, 'type'>,
    MakeOptional<Pick<IconProps, 'icon'>>,
    SpacingCssProps {
  variant?: 'primary' | 'secondary' | 'link';
  isLoading?: boolean;
}

const Button: FC<ButtonProps> = ({
  variant,
  disabled,
  children,
  onClick,
  isLoading,
  icon,
  form,
  ...spacingCssProps
}) => (
  <StyledButton
    variant={variant}
    disabled={disabled}
    onClick={onClick}
    form={form}
    {...spacingCssProps}
  >
    {children}

    {!!icon && <Icon icon={icon} height="16px" width="16px" ml />}
    {isLoading && <InlineLoader ml />}
  </StyledButton>
);

export default Button;
