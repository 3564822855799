import { Icon } from 'components';
import { FC } from 'react';
import { CellProps } from 'react-table';
import { useTheme } from 'styled-components';
import { IPaymentRunItemSummary } from 'types/paymentRuns';

const ConfirmationIconCell: FC<
  Pick<CellProps<IPaymentRunItemSummary>, 'row'>
> = ({ row }) => {
  const theme = useTheme();
  const { buyFx } = row.original;

  if (!buyFx) {
    return null;
  }

  return <Icon mr icon="lock-ico" fill={theme.color.black} />;
};

export default ConfirmationIconCell;
