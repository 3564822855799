import { useState, useEffect, useMemo } from 'react';

import { useStoreActions, useStoreState } from 'state';
import { ICurrency, IRateContract, Nullable } from 'types';

interface UseCurrenciesParams {
  predefinedBuyCurrency?: Nullable<string>;
  predefinedSellCurrency?: Nullable<string>;
  rateContract?: IRateContract;
}

export interface UseCurrenciesReturnValues {
  sellCurrency: ICurrency;
  buyCurrency: ICurrency;
  sellCurrencyCode: string;
  buyCurrencyCode: string;
  sellCurrencyCountryCode: string;
  buyCurrencyCountryCode: string;
  sellCurrencySymbol: string;
  sellCurrencyPrecision: number;
  buyCurrencySymbol: string;
  buyCurrencyPrecision: number;
  updateSellCurrency: (currency: ICurrency) => void;
  updateBuyCurrency: (currency: ICurrency) => void;
}

const useCurrencies = ({
  predefinedBuyCurrency,
  predefinedSellCurrency,
}: UseCurrenciesParams): UseCurrenciesReturnValues => {
  const { prebookableBuyCurrencies, prebookableSellCurrencies } = useStoreState(
    (state) => state.CurrenciesState
  );
  const { getNonTradingDays } = useStoreActions(
    (actions) => actions.ReferenceDataState
  );

  const [sellCurrency, setSellCurrency] = useState(
    prebookableSellCurrencies.find(
      (item) => item.code === (predefinedSellCurrency ?? 'GBP')
    ) || prebookableSellCurrencies[0]
  );

  const [buyCurrency, setBuyCurrency] = useState(
    prebookableBuyCurrencies.find(
      (item) => item.code === (predefinedBuyCurrency ?? 'USD')
    ) || prebookableBuyCurrencies[0]
  );

  useEffect(() => {
    getNonTradingDays({
      ccyPair: `${sellCurrency?.code}${buyCurrency?.code}`,
    });
  }, [getNonTradingDays, sellCurrency?.code, buyCurrency?.code]);

  const sellCurrencyDetails = useMemo(
    () => ({
      sellCurrencyCode: sellCurrency?.code || '',
      sellCurrencyCountryCode: sellCurrency?.countryCode || '',
      sellCurrencySymbol: sellCurrency?.symbol || '',
      sellCurrencyPrecision: sellCurrency?.precision || 2,
    }),
    [sellCurrency]
  );

  const buyCurrencyDetails = useMemo(
    () => ({
      buyCurrencyCode: buyCurrency?.code || '',
      buyCurrencyCountryCode: buyCurrency?.countryCode || '',
      buyCurrencySymbol: buyCurrency?.symbol || '',
      buyCurrencyPrecision: buyCurrency?.precision || 2,
    }),
    [buyCurrency]
  );

  return {
    sellCurrency,
    buyCurrency,
    updateSellCurrency: setSellCurrency,
    updateBuyCurrency: setBuyCurrency,
    ...sellCurrencyDetails,
    ...buyCurrencyDetails,
  };
};

export default useCurrencies;
