import { InlineLoader, Paragraph, Row } from 'components';
import Checkbox from 'components/shared/Checkbox/Checkbox';
import { Column } from 'react-table';
import { Nullable } from 'types';
import { IEntityUser } from 'state/user';
import { isEmulated } from 'utils';
import Button from 'components/shared/Button/Button';

interface OwnProps {
  userId: Nullable<string>;
  onEditEntityUser: (member: IEntityUser) => void;
  onSetEntityApprover: (id: string) => Promise<void>;
  isLoadingRoles: boolean;
  hasApprovalFlow?: boolean;
  isUpdatingEntityApprover: boolean;
  isAutomationPackageEnabled: boolean;
}

export const generateEntityUserTableColumns = ({
  userId,
  onEditEntityUser,
  onSetEntityApprover,
  isLoadingRoles,
  hasApprovalFlow,
  isUpdatingEntityApprover,
  isAutomationPackageEnabled,
}: OwnProps): Column<IEntityUser>[] => {
  const columns: Column<IEntityUser>[] = [
    {
      accessor: 'name',
      Header: 'Name',
      Cell: ({ row, value }) => (
        <Paragraph
          variant="bold"
          color={userId === row.original.id ? 'emeraldDark' : 'dark'}
        >
          {value}
        </Paragraph>
      ),
      width: 100,
    },
    {
      accessor: 'email',
      Header: 'Mail',
      Cell: ({ value }) => <Paragraph>{value}</Paragraph>,
      width: 100,
    },
    {
      accessor: 'roles',
      Header: 'Roles (coming soon)',
      disableSortBy: true,
      Cell: ({ value }) => <Paragraph>{value?.join(', ')}</Paragraph>,
      width: 100,
    },
    {
      accessor: 'isApprover',
      Header: 'Is Approver?',
      disableSortBy: true,
      Cell: ({ row, value }) =>
        isUpdatingEntityApprover ? (
          <InlineLoader />
        ) : (
          <Checkbox
            onClick={() => onSetEntityApprover(row.original.id)}
            disabled={value}
            checked={value}
          />
        ),
      width: 100,
    },
    {
      id: 'id',
      accessor: 'id',
      disableSortBy: true,
      Header: () => null,
      Cell: ({ row }) => (
        <Row flex={1} justifyContent="flex-end">
          {isLoadingRoles && <InlineLoader />}
          {!isLoadingRoles && (
            <Button
              onClick={() => onEditEntityUser(row.original)}
              variant="link"
              disabled={!isEmulated()}
              icon="edit-ico"
            />
          )}
        </Row>
      ),
      width: 60,
      minWidth: 55,
    },
  ];

  if (!isAutomationPackageEnabled || !hasApprovalFlow) {
    columns.splice(3, 1);
  }

  return columns;
};
