import styled, { css } from 'styled-components';
import { isMobile } from 'react-device-detect';

export const Wrapper = styled.div(
  ({ theme }) => css`
    z-index: 12;
    color: ${theme.color.white};
    min-height: 56px;
    display: flex;
    flex-direction: column;

    .back {
      position: absolute;
      right: 16px;
      top: 4px;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      height: 24px;

      svg {
        fill: ${theme.color.white};
      }
    }

    ${!isMobile
      ? css`
          flex: 0 0 110px;
          padding: 24px 0 10px 16px;

          @media (min-width: ${theme.breakpoint.largeMin}px) {
            padding: 48px 0 10px 48px;
            flex: 0 0 174px;
          }

          .row {
            flex-direction: column;
          }
        `
      : css`
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          padding: 6px 16px 10px;
          background: ${theme.color.dark};
          justify-content: center;
        `}
  `
);

interface OwnProps {
  passed?: boolean;
  disabled?: boolean;
  current?: boolean;
}

export const StepItem = styled.div<OwnProps>(
  ({ theme, passed, disabled, current }) => css`
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: ${theme.color.grey};
    position: relative;
    padding: ${isMobile ? '6px 24px 2px 0' : '0 32px 0 0'};
    min-height: ${!isMobile && '72px'};
    margin-bottom: ${!isMobile && '2px'};

    @media (min-width: ${theme.breakpoint.largeMin}px) {
      font-size: 16px;
    }

    &:last-child {
      padding-right: 0;
    }
    &:not(:last-of-type):after {
      content: '';
    }

    &:after {
      width: 0;
      height: 0;
      border-top: ${isMobile && '4.5px solid transparent'};
      border-left: ${isMobile && `6px solid ${theme.color.grey}`};
      border-bottom: ${isMobile && '4.5px solid transparent'};
      position: absolute;
      top: ${isMobile && '3px'};
      right: ${isMobile ? '8px' : '0'};
      bottom: 1px;
      margin: auto;
    }

    ${!isMobile &&
    css`
      span {
        position: absolute;
        right: 0;
        top: 0;
        border-right: 2px solid ${theme.color.grey};
        padding-right: 10px;
      }

      &:after {
        width: 1px;
        height: calc(100% - 24px);
        background: ${theme.color.greyLight_1};
      }
    `}

    ${passed &&
    css`
      color: ${isMobile ? theme.color.white : theme.color.dark};
      cursor: pointer;

      span {
        border-color: ${theme.color.dark};
      }

      &:hover {
        opacity: 0.8;
      }

      &:after {
        border-left-color: ${theme.color.white};
      }
    `}

    ${disabled &&
    css`
      pointer-events: none;
    `}

    ${current &&
    css`
      color: ${theme.color.emeraldDark};

      span {
        border-color: ${theme.color.emeraldDark};
      }
    `}
  `
);
