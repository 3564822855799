import IMask from 'imask';
import { roundToPrecision } from './common';

export const stringToCurrencyNumberString = (
  value: string,
  precision = 2
): string => {
  const pipe = IMask.createPipe({
    mask: Number,
    scale: precision,
    radix: '.',
    thousandsSeparator: ',',
    normalizeZeros: true,
    padFractionalZeros: precision > 0 ? true : false,
  });

  return pipe(value);
};

export const numberToCurrencyNumberString = (
  value?: number,
  precision = 2
): string => {
  if (typeof value === 'undefined') {
    return '';
  }

  const pipe = IMask.createPipe(
    {
      mask: Number,
      scale: precision,
      radix: '.',
      thousandsSeparator: ',',
      normalizeZeros: true,
      padFractionalZeros: precision > 0 ? true : false,
    },
    IMask.PIPE_TYPE.TYPED,
    IMask.PIPE_TYPE.MASKED
  );

  return pipe(roundToPrecision(value, precision));
};
