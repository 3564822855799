import { ICountry, IEntityDetails, Nullable, IContact } from 'types';

interface IsContactCountryInCountriesSendingMoneyToProps {
  entityKnownCountries?: IEntityDetails['countriesSendingMoneyTo'];
  recipientCountry?: ICountry;
  recipientForEdit: Nullable<IContact>;
}

export const isContactCountrySuspiciousCheck = ({
  entityKnownCountries,
  recipientCountry,
  recipientForEdit,
}: IsContactCountryInCountriesSendingMoneyToProps) => {
  if (!recipientCountry) {
    return false;
  }

  if (
    recipientForEdit &&
    (!recipientForEdit.recipientCountry ||
      recipientForEdit.recipientCountry === recipientCountry.alpha2)
  ) {
    return false;
  }

  return (
    entityKnownCountries &&
    !entityKnownCountries.includes(recipientCountry.alpha2)
  );
};

interface IsContactPaymentDetailsChangedProps {
  recipientForEdit: Nullable<IContact>;
  bicSwiftValue?: string;
  accountNumberValue?: string;
  routingNumberValue?: string;
  routingNumber2Value?: string;
}

export const isContactPaymentDetailsChanged = ({
  recipientForEdit,
  bicSwiftValue,
  accountNumberValue,
  routingNumberValue,
  routingNumber2Value,
}: IsContactPaymentDetailsChangedProps) => {
  if (!recipientForEdit) {
    return false;
  }

  const {
    bicSwift,
    accountNumber,
    routingNumber,
    routingNumber2,
  } = recipientForEdit;

  if (
    accountNumber !== accountNumberValue ||
    (bicSwift && bicSwift !== bicSwiftValue) ||
    (routingNumber && routingNumber !== routingNumberValue) ||
    (routingNumber2 && routingNumber2 !== routingNumber2Value)
  ) {
    return true;
  }

  return false;
};
