import styled, { css } from 'styled-components';
import { StaleTitleH4, Button, ButtonSecondary, StaleBtnGroup } from 'components';

export const StaleGetRateFailed = styled.div(
  ({ theme }) => css`
    position: fixed;
    background: rgb(18, 18, 19, 0.4);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999999;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;

    .popup {
      background: ${theme.color.white};
      padding: 16px;
      box-shadow: 0 8px 30px rgba(0, 0, 0, 0.12);
      border-radius: 16px;
      position: relative;
    }

    ${StaleBtnGroup} {
      padding-top: 16px;
    }

    ${StaleTitleH4} {
      margin-bottom: 6px;
    }

    ${Button},
    ${ButtonSecondary} {
      flex: 0 0 48%;
      max-width: 48%;
      margin: 0;
      padding: 0;

      &:not(:last-child) {
        margin-bottom: 0;
      }
    }

    .close {
      position: absolute;
      top: 16px;
      right: 16px;
    }
  `
);
