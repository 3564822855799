import React, { FC, useEffect, useState } from 'react';

import { InvoiceInfo } from './components';
import { Wrapper } from './InvoiceInner.styles';
import { isMobile } from 'react-device-detect';
import {
  Row,
  StaleContainer,
  StaleContainerWeb,
  StaleOverflowScroll,
  StaleOverflowWrap,
  StaleSidebar,
} from 'components';
import { RouteComponentProps, useParams } from 'react-router-dom';
import _get from 'lodash.get';
import { useStoreActions } from 'state';
import { useQuery } from 'hooks';
import { IInvoice } from 'types';

const InvoiceInner: FC<RouteComponentProps> = ({ history }) => {
  const params = useParams();
  const URLQuery = useQuery();
  const { subscribeToInvoice } = useStoreActions(
    (actions) => actions.InvoicesState
  );
  const [invoice, setInvoice] = useState<IInvoice>();
  const invoiceId = _get(params, 'invoiceId', null);
  const transferFlow = URLQuery.get('transferFlow');

  const OverflowScrollMob = isMobile ? StaleOverflowScroll : React.Fragment;

  useEffect(() => {
    if (invoiceId) {
      subscribeToInvoice({
        invoiceId,
        callback: (data) => setInvoice(data),
      });
    }
  }, [subscribeToInvoice, invoiceId]);

  return (
    <Wrapper>
      {isMobile ? (
        !!invoice && <InvoiceInfo invoice={invoice} />
      ) : (
        <>
          <StaleSidebar hideIconLabels />

          <StaleContainerWeb maxWidth={'954px'}>
            <StaleOverflowWrap>
              <Row>
                <button onClick={history.goBack} className="link-back">
                  <svg>
                    <use xlinkHref="#arrow-left" />
                  </svg>
                  Back to {transferFlow ? 'transfer' : 'invoices'}
                </button>
              </Row>
              <OverflowScrollMob>
                <div className="row content">
                  <StaleContainer>
                    {!!invoice && <InvoiceInfo invoice={invoice} />}
                  </StaleContainer>
                </div>
              </OverflowScrollMob>
            </StaleOverflowWrap>
          </StaleContainerWeb>
        </>
      )}
    </Wrapper>
  );
};

export default InvoiceInner;
