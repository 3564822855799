import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import CurrencyInfo from '../CurrencyInfo/CurrencyInfo';
import { Wrapper } from './CurrencyTiles.styles';
import { subscribeToPastPerformanceCurrencies } from 'services/firebase/analysis';
import { useStoreState } from 'state';
import { TReportsContentType } from 'pages/Reports/types';

interface OwnProps {
  entityId: string;
  activeCurrencyCode: string | undefined;
  setActiveCurrencyCode: Dispatch<SetStateAction<string>>;
  activeMonth: string;
  setActiveMonth: Dispatch<SetStateAction<string>>;
  contentType: TReportsContentType;
  setContentType: Dispatch<SetStateAction<TReportsContentType>>;
}

const CurrencyTiles: FC<OwnProps> = ({
  entityId,
  activeCurrencyCode,
  setActiveCurrencyCode,
  activeMonth,
  setActiveMonth,
  contentType,
  setContentType,
}) => {
  const [currencyCodes, setCurrencyCodes] = useState<string[]>([]);
  const { currencyByCode } = useStoreState((state) => state.CurrenciesState);

  useEffect(() => {
    let unsubscribe: (() => void) | undefined;
    if (entityId) {
      unsubscribe = subscribeToPastPerformanceCurrencies({
        entityId,
        callback: (data) =>
          setCurrencyCodes(
            data
              .filter(
                (pastPerformanceCurrency) =>
                  currencyByCode(pastPerformanceCurrency.id)?.tier1
              )
              .map((pastPerformanceCurrency) => pastPerformanceCurrency.id)
          ),
      });
    }

    return () => unsubscribe?.();
  }, [currencyByCode, entityId]);

  useEffect(() => {
    if (currencyCodes && !activeCurrencyCode) {
      // Use the following order of priority for the initial active currency:
      // USD, EUR, CAD, rest of the currencies in any order
      if (
        currencyCodes.findIndex((currencyCode) => currencyCode === 'USD') > -1
      ) {
        setActiveCurrencyCode('USD');
      } else if (
        currencyCodes.findIndex((currencyCode) => currencyCode === 'EUR') > -1
      ) {
        setActiveCurrencyCode('EUR');
      } else if (
        currencyCodes.findIndex((currencyCode) => currencyCode === 'CAD') > -1
      ) {
        setActiveCurrencyCode('CAD');
      } else {
        const defaultCurrencyCode = currencyCodes[0]?.toString();
        if (defaultCurrencyCode) {
          setActiveCurrencyCode(defaultCurrencyCode);
        }
      }
    }
  }, [activeCurrencyCode, currencyCodes, setActiveCurrencyCode]);

  return (
    <>
      {activeCurrencyCode && (
        <Wrapper>
          <CurrencyInfo
            activeMonth={activeMonth}
            setActiveMonth={setActiveMonth}
            contentType={contentType}
            setContentType={setContentType}
            currencyCodes={currencyCodes}
            setActiveCurrencyCode={setActiveCurrencyCode}
            activeCurrencyCode={activeCurrencyCode}
          />
        </Wrapper>
      )}
    </>
  );
};

export default CurrencyTiles;
