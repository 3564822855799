import styled, { css } from 'styled-components';
import { StaleParagraphMedium } from 'components';

export const PrebookedTotals = styled.div(
  () => css`
    padding: 16px;
    position: relative;

    .download {
      position: absolute;
      width: 24px;
      height: 24px;
      right: 16px;
      top: 16px;
    }

    .pie-wrap {
      width: 182px;
      height: 182px;
      margin: 12px auto;
    }

    .row {
      padding: 0 20px;
    }

    ${StaleParagraphMedium} {
      flex: 0 0 50%;
      max-width: 50%;
      padding: 7px 6px;

      svg {
        margin-right: 12px;
      }
    }
  `
);
