import { Col, Row } from 'components';
import styled from 'styled-components';

export const SidebarPageTemplateContent = styled(Col)`
  flex: 1;
  align-self: stretch;
  overflow-y: auto;
  background-color: ${({ theme }) => theme.color.greyLight_2};
  padding: ${({ theme }) => `${theme.spacing.m} ${theme.spacing.m}`};
  max-height: 100vh;
  @media (min-width: ${({ theme }) => theme.breakpoint.largeMin}px) {
    padding: ${({ theme }) => `${theme.spacing.xxl} ${theme.spacing.xxxl}`};
  }
`;

export const SidebarPageTemplateHeader = styled(Row)`
  margin-bottom: ${({ theme }) => theme.spacing.l};
  @media (min-width: ${({ theme }) => theme.breakpoint.largeMin}px) {
    margin-bottom: ${({ theme }) => theme.spacing.xxl};
  }
`;
