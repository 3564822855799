import { FC } from 'react';
import { Paragraph, StalePopup } from 'components';
import { useHistory } from 'react-router-dom';
import { useStoreState } from 'state';
import { Col } from '../Col/Col';
import { useTheme } from 'styled-components';
import Button from '../Button/Button';

interface OwnProps {
  onClose: () => void;
}

const StaleLimitedAccess: FC<OwnProps> = ({ onClose }) => {
  const history = useHistory();
  const theme = useTheme();
  const { isEntityEnabled, userEntity } = useStoreState(
    (state) => state.UserState
  );

  const isOnReview =
    !isEntityEnabled && userEntity?.status === 'onboardingStep4Completed';

  return (
    <StalePopup
      title="Limited access"
      theme="small"
      width="347px"
      minHeight="auto"
      onClose={onClose}
    >
      <>
        <Paragraph>
          {isOnReview
            ? 'Your company registration is being reviewed. We will notify you once your account has been enabled and you are able to perform this action.'
            : 'To complete this operation, you need to first finish the company registration.'}
        </Paragraph>
        <Col mt rowGap={theme.spacing.m}>
          {isOnReview ? (
            <Button onClick={() => history.push('/app/dashboard')}>
              Back to dashboard
            </Button>
          ) : (
            <>
              <Button
                variant="secondary"
                onClick={() => history.push('/app/company-registration')}
              >
                Register company now
              </Button>
              <Button onClick={onClose}>Cancel</Button>
            </>
          )}
        </Col>
      </>
    </StalePopup>
  );
};

export default StaleLimitedAccess;
