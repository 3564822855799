import styled, { css } from 'styled-components';
import { StaleTitleH5 } from 'components';

export const Transfer = styled.div(
  ({ theme }) => css`
    padding: 16px;
    position: relative;
    background: ${theme.color.white};

    &:not(:last-of-type) {
      box-shadow: inset 0 -1px 0 ${theme.color.greyLight_1};
    }

    .few & {
      &:first-of-type {
        border-radius: 6px 6px 0 0;
      }
      &:last-of-type {
        border-radius: 0 0 6px 6px;
      }
    }

    ${StaleTitleH5} {
      margin: 0 30px 7px 0;
      min-height: 24px;
    }

    svg {
      width: 24px;
      height: 24px;
    }

    &:nth-child(odd) {
      box-shadow: inset 0 -1px 0 ${theme.color.greyLight_1};
    }

    .row {
      justify-content: space-between;
      align-items: flex-end;
      margin-bottom: 7px;
    }
  `
);
