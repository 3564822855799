import { FC, useState, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Title, StaleSelectMenu, Paragraph } from 'components';
import { useStoreState } from 'state';
import { useQuery } from 'hooks';
import _orderBy from 'lodash.orderby';
import { ICurrencyInput } from 'types';
import { transformCurrencyToSelectOption } from 'utils';
import { useTheme } from 'styled-components';
import { platformNamesToIntegrationDocIds } from 'utils/integrations';
import { TPlatformNames } from 'types/integrations';
import Button from '../Button/Button';

interface Inputs {
  excludedCurrencies: ICurrencyInput[];
}

interface OwnProps {
  onSaveValues: (values: string[]) => void;
  platformName: TPlatformNames;
}

const StepCurrencies: FC<OwnProps> = ({ onSaveValues, platformName }) => {
  const theme = useTheme();
  const URLQuery = useQuery();
  const firstIntegration = URLQuery.get('firstIntegration') === 'true';

  const [currenciesSearchValue, setCurrenciesSearchValue] = useState('');
  const { currencies } = useStoreState((state) => state.CurrenciesState);
  const { integrationsSummary } = useStoreState(({ UserState }) => UserState);

  const integrationDocumentId = platformNamesToIntegrationDocIds[platformName];

  const savedExcludedCurrencies =
    integrationsSummary?.[integrationDocumentId]?.settings?.excludedCurrencies;

  const defaultCurrencies = useMemo(() => {
    if (firstIntegration) {
      const currency = currencies.find((curr) => curr.code === 'GBP');
      return currency ? [transformCurrencyToSelectOption(currency)] : [];
    }

    return currencies
      .filter(
        (currency) =>
          savedExcludedCurrencies?.find(
            (excluded) => excluded === currency.code
          ) && currency
      )
      .map(transformCurrencyToSelectOption);
  }, [currencies, savedExcludedCurrencies, firstIntegration]);

  const currenciesOptions = useMemo(
    () => _orderBy(currencies.map(transformCurrencyToSelectOption), 'label'),
    [currencies]
  );

  const { control, handleSubmit } = useForm<Inputs>({
    mode: 'all',
    defaultValues: {
      excludedCurrencies: defaultCurrencies,
    },
  });

  const onSubmit = async ({ excludedCurrencies }) => {
    const excludedCurrenciesToUse = excludedCurrencies?.map(
      (currency) => currency.value.code
    );
    onSaveValues(excludedCurrenciesToUse);
  };

  return (
    <>
      <Title mb mbValue={theme.spacing.xl}>
        What currencies should we ignore?
      </Title>

      <Paragraph mb mbValue={theme.spacing.xl}>
        Please select currencies that you don’t want to manage via HedgeFlows
        from the list:
      </Paragraph>

      <form id="excluded-currencies-form" onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name="excludedCurrencies"
          defaultValue={null}
          render={({ onChange, value, name }) => {
            return (
              <StaleSelectMenu
                id={name}
                name={name}
                label="Currencies to exclude:"
                isMulti
                data={currenciesOptions}
                value={value}
                onChange={(item) => {
                  if (!Array.isArray(item)) {
                    onChange(null);
                  } else {
                    onChange(item);
                  }
                }}
                inputValue={currenciesSearchValue}
                onInputChange={setCurrenciesSearchValue}
                menuPlacement="top"
                menuPosition="absolute"
                withBackdrop={false}
              />
            );
          }}
        />
      </form>

      <Button mt mtValue={theme.spacing.xl} form="excluded-currencies-form">
        Continue
      </Button>
    </>
  );
};

export default StepCurrencies;
