import ChartBar from 'components/shared/ChartBar/ChartBar';
import { FC } from 'react';
import { ICurrency, IRiskDashboardItemPerMonthWithDate } from 'types';
import { roundToPrecision } from 'utils';
import ChartLegendsLayer from './components/ChartLegendsLayer/ChartLegendsLayer';
import CustomChartBar from './components/CustomChartBar/CustomChartBar';
import CustomChartLabel from './components/CustomChartLabel/CustomChartLabel';

interface OwnProps {
  chartData: Array<
    IRiskDashboardItemPerMonthWithDate & { [key: string]: number | string }
  >;
  keys?: string[];
  currencySymbol?: ICurrency['symbol'];
}

const MonthlyBreakdownChartBar: FC<OwnProps> = ({
  chartData,
  keys = [
    'payables',
    'receivables',
    'prebookedAndHedged',
    'purchaseOrders',
    'saleOrders',
  ],
  currencySymbol,
}) => {
  const maxValue = chartData.reduce((acc, item) => {
    const itemValues = Object.values(item).filter(
      (val) => typeof val !== 'string'
    ) as number[];

    return Math.max(acc, ...itemValues);
  }, 0);

  const horizontalData = [
    0,
    roundToPrecision(maxValue / 2, 0),
    roundToPrecision(maxValue, 0),
  ];

  return (
    <ChartBar
      data={chartData}
      keys={keys}
      indexBy="date"
      margin={{
        top: 20,
        bottom: 70,
        left: `${maxValue}`.length * 7 + 18,
      }}
      padding={0.3}
      innerPadding={10}
      groupMode="grouped"
      axisTop={null}
      axisRight={null}
      enableGridX={false}
      maxValue={maxValue}
      gridYValues={horizontalData}
      axisLeft={{
        tickPadding: 12,
        tickSize: 0,
        tickValues: horizontalData,
        format: (t) => <CustomChartLabel data={t} variant="left" />,
      }}
      axisBottom={{
        tickPadding: 16,
        tickSize: 0,
        format: (t) => <CustomChartLabel data={t} variant="bottom" />,
      }}
      barComponent={(data) => (
        <CustomChartBar data={data} currencySymbol={currencySymbol} />
      )}
      layers={[
        'grid',
        'axes',
        'bars',
        (layerData) => <ChartLegendsLayer data={layerData} />,
        'markers',
        'legends',
        'annotations',
      ]}
    />
  );
};

export default MonthlyBreakdownChartBar;
