import styled, { css } from 'styled-components';
import { StaleTitleH3 } from 'components';

export const Header = styled.header(
  ({ theme }) => css`
    z-index: 12;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    height: 56px;
    display: flex;
    align-items: center;
    padding-top: 2px;

    @media (min-width: ${theme.breakpoint.largeMin}px) {
      height: 68px;
    }

    &.theme-grey {
      color: ${theme.color.dark};
      background-color: ${theme.color.greyLight_2};
    }

    &.theme-dark {
      color: ${theme.color.white};
      background-color: ${theme.color.dark};

      svg {
        fill: ${theme.color.white};
      }

      .burger span,
      .burger span:after,
      .burger span:before {
        background: ${theme.color.white};
      }
    }

    &.theme-white {
      flex: 0 0 80px;
      color: ${theme.color.dark};
      background-color: ${theme.color.white};
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.12);
      transition: ${theme.transition};
    }

    .head-row {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      padding: 0 16px;
      align-items: center;
      justify-content: space-between;
      position: relative;
      z-index: 1;
      margin-top: 0;

      @media (min-width: ${theme.breakpoint.largeMin}px) {
        max-width: 1320px;
        margin: auto;
        padding: 0 21px 0 16px;
      }

      ${StaleTitleH3} {
        max-width: calc(100% - 40px);
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        padding-left: 4px;
      }
    }

    .logo {
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: ${theme.color.emeraldDark};

      svg {
        margin: -2px 4px 0 -2px;
        width: 32px;
        height: 28px;

        @media (min-width: ${theme.breakpoint.largeMin}px) {
          width: 50px;
          height: 45px;
        }
      }

      &:focus,
      &:active {
        text-shadow: 0 0 8px ${theme.color.emeraldDark};
      }
    }

    .icons {
      display: flex;
      align-items: center;
      height: 24px;
      padding-left: 18px;

      a,
      button {
        max-height: 100%;
        margin: 0 0 0 14px;
      }

      svg {
        margin: 0;
        width: 24px;
        height: 24px;
      }
    }

    .burger {
      width: 24px;
      height: 24px;
      background: none;
      align-items: center;
      justify-content: center;
      display: flex;

      span {
        width: 18px;
        min-width: 18px;
        height: 2px;
        display: block;
        position: relative;
        border-radius: ${theme.borderRadius.s};
        background: rgb(0, 0, 0);
        transition: ${theme.transition};

        &:after,
        &:before {
          content: '';
          width: 100%;
          height: 2px;
          display: block;
          position: absolute;
          border-radius: ${theme.borderRadius.s};
          background: ${theme.color.dark};
          transition: ${theme.transition};
        }

        &:after {
          bottom: -5px;
        }
        &:before {
          top: -5px;
        }
      }

      &:focus {
        span {
          background: ${theme.color.emeraldDark};

          &:after,
          &:before {
            background: ${theme.color.emeraldDark};
          }
        }
      }
    }

    .mobile-menu {
      background: ${theme.color.dark};
      width: 100vw;
      height: calc(var(--vh, 1vh) * 100);
      position: fixed;
      right: -100vw;
      top: 0;
      bottom: 0;
      transition: ${theme.transition};
      padding-top: 56px;

      .column {
        display: flex;
        padding: 16px 0;
        height: 100%;
        flex-direction: column;
        align-items: flex-start;
        overflow: auto;
      }

      .link {
        color: ${theme.color.white};
        font-weight: 500;
        font-size: 14px;
        line-height: 150%;
        padding: 16px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        transition: ${theme.transition};

        &:focus,
        &:hover {
          color: ${theme.color.emeraldDark};

          svg {
            fill: ${theme.color.emeraldDark};
          }
        }
      }

      svg {
        margin-right: 12px;
        fill: ${theme.color.grey};
        width: 24px;
        height: 24px;
        transition: ${theme.transition};
      }

      .bot {
        width: 100%;
        margin-top: auto;
      }

      &.landing-menu {
        position: absolute;
        height: auto;
        width: auto;
        right: 20px;
        bottom: inherit;
        max-height: 0;
        overflow: hidden;
        background: ${theme.color.greyDark};
        padding-top: 0;
        top: 68px;
        border-radius: 4px;
        min-width: 244px;

        @media (min-width: ${theme.breakpoint.extraLargeMin}px) {
          right: calc(50vw - 639px);
        }

        .row {
          height: auto;
          padding: 8px 0;
        }

        .link {
          padding: 10px 16px;
        }

        svg {
          margin-right: 12px;
          fill: ${theme.color.white};
          margin-left: 0;
        }
      }

      &.landing-menu {
        position: absolute;
        height: auto;
        width: auto;
        right: 20px;
        bottom: inherit;
        max-height: 0;
        overflow: hidden;
        background: ${theme.color.greyDark};
        padding-top: 0;
        top: 68px;
        border-radius: 4px;
        min-width: 244px;

        @media (min-width: ${theme.breakpoint.extraLargeMin}px) {
          right: calc(50vw - 639px);
        }

        .row {
          height: auto;
          padding: 8px 0;
        }

        .link {
          padding: 10px 16px;
        }

        svg {
          margin-right: 12px;
          fill: ${theme.color.white};
          margin-left: 0;
        }
      }
    }

    &.menu-show {
      background-color: ${theme.color.transparent};
      box-shadow: 0 2px 6px ${theme.color.transparent};

      .burger {
        span {
          background: ${theme.color.transparent};

          &:after,
          &:before {
            top: 0;
            bottom: 0;
            border-radius: 0;
            background: ${theme.color.white};
          }

          &:after {
            transform: rotate(-45deg);
          }
          &:before {
            transform: rotate(45deg);
          }
        }
      }

      .mobile-menu {
        right: 0;

        &.landing-menu {
          max-height: 90vh;
          right: 20px;

          @media (min-width: ${theme.breakpoint.extraLargeMin}px) {
            right: calc(50vw - 639px);
          }
        }
      }

      &.theme-dark {
        background-color: ${theme.color.dark};
        box-shadow: 0 2px 6px ${theme.color.transparent};

        .burger {
          span {
            background: ${theme.color.transparent};
          }
        }
      }

      .icons {
        a:not(.burger),
        button:not(.burger) {
          display: none;
        }
      }
    }

    ${StaleTitleH3} {
      display: flex;
      align-items: center;
    }

    .back {
      padding: 4px 12px 0 0;

      svg {
        width: 24px;
        height: 24px;
      }
    }

    .menu {
      flex: 1 0 auto;
      max-width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-left: 12px;

      @media (min-width: ${theme.breakpoint.smallMin}px) {
        padding-left: 34px;
      }
      @media (min-width: ${theme.breakpoint.largeMin}px) {
        padding-left: 66px;
      }

      &-col {
        display: flex;
        align-items: center;
        white-space: nowrap;
      }

      &-item {
        margin: 0 18px;
        font-weight: 500;
        transition: ${theme.transition};

        @media (min-width: ${theme.breakpoint.mediumMin}px) {
          margin: 0 18px 0 8px;
        }
        @media (min-width: ${theme.breakpoint.largeMin}px) {
          margin: 0 38px 0 15px;
        }

        &:hover,
        &:focus {
          color: ${theme.color.emeraldDark};
        }
      }
    }
  `
);
