import dayjs from 'dayjs';
import groupBy from 'lodash.groupby';
import orderBy from 'lodash.orderby';
import { IRiskDashboardItem, IRiskDashboardItemPerMonth } from 'types';
import {
  DB_MONTH_FORMAT,
  MONTH_DATE_FORMAT,
  QUARTER_DATE_FORMAT,
} from 'variables';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import advancedFormat from 'dayjs/plugin/advancedFormat';

dayjs.extend(quarterOfYear);
dayjs.extend(advancedFormat);

export const formatValuesPerMonth = (
  rawValues: IRiskDashboardItem['perMonth']
) => {
  const rawValuesWithMonth = Object.entries(rawValues).reduce(
    (acc, [key, value]) => {
      return [
        ...acc,
        {
          ...value,
          date: dayjs(key, DB_MONTH_FORMAT).format(MONTH_DATE_FORMAT),
        },
      ];
    },
    []
  );

  return orderBy(rawValuesWithMonth, ({ date }) =>
    dayjs(date, MONTH_DATE_FORMAT)
  );
};

const summarizeMonthlyValues = (
  riskDashboardItemsPerMonth: IRiskDashboardItemPerMonth[]
) =>
  riskDashboardItemsPerMonth.reduce<IRiskDashboardItemPerMonth>(
    (acc, value) => {
      const {
        payables,
        netExposure,
        receivables,
        purchaseOrders,
        saleOrders,
        prebooked,
        externalHedges,
        netExposureInSellCurrency,
      } = acc;
      const {
        payables: valuePayables,
        netExposure: valueNetExposure,
        receivables: valueReceivables,
        purchaseOrders: valuePurchaseOrders,
        saleOrders: valueSaleOrders,
        prebooked: valuePrebooked,
        externalHedges: valueExternalHedges,
        netExposureInSellCurrency: valueNetExposureInSellCurrency,
      } = value;

      return {
        netExposure: netExposure + valueNetExposure,
        payables: payables + valuePayables,
        receivables: receivables + valueReceivables,
        purchaseOrders: purchaseOrders + valuePurchaseOrders,
        saleOrders: saleOrders + valueSaleOrders,
        prebooked: prebooked + valuePrebooked,
        externalHedges: externalHedges + valueExternalHedges,
        netExposureInSellCurrency:
          netExposureInSellCurrency + valueNetExposureInSellCurrency,
      };
    },
    {
      netExposure: 0,
      payables: 0,
      receivables: 0,
      purchaseOrders: 0,
      saleOrders: 0,
      prebooked: 0,
      externalHedges: 0,
      netExposureInSellCurrency: 0,
    }
  );

export const formatValuesPerQuarter = (
  monthlyValues: {
    date: string;
    netExposure: number;
    payables: number;
    receivables: number;
    prebooked: number;
    externalHedges: number;
    saleOrders: number;
    purchaseOrders: number;
    netExposureInSellCurrency: number;
  }[]
) => {
  const monthlyValuesByQuarter = groupBy(monthlyValues, ({ date }) =>
    dayjs(date, MONTH_DATE_FORMAT).format(QUARTER_DATE_FORMAT)
  );

  return Object.entries(monthlyValuesByQuarter).reduce((acc, [_, value]) => {
    const summarizedValues = summarizeMonthlyValues(value);

    return [
      ...acc,
      {
        ...summarizedValues,
        date: dayjs(value[0].date, MONTH_DATE_FORMAT).format(
          QUARTER_DATE_FORMAT
        ),
      },
    ];
  }, []);
};
