import { FC, useState, useRef, useEffect } from 'react';
import { Transition } from 'react-transition-group';
import { Paragraph } from '../Typography/Typography';

import { ListItem, ListItemIcon } from './StaleHexagonLine.styles';

const duration = 600;

const defaultStyle = {
  transition: `all ${duration}ms ease`,
  opacity: 1,
  height: 'auto',
  marginBottom: 8,
};

const transitionStyles = {
  entering: { opacity: 1, maxHeight: 40, marginBottom: 8 },
  entered: { opacity: 1, maxHeight: 40, marginBottom: 8 },
  exiting: { opacity: 0, maxHeight: 0, marginBottom: 0 },
  exited: { opacity: 0, maxHeight: 0, marginBottom: 0 },
};

interface OwnProps {
  text: string;
  isValid: boolean;
  hideWhenValid?: boolean;
}

const StaleHexagonLine: FC<OwnProps> = ({
  text,
  isValid,
  hideWhenValid = false,
}) => {
  const [isHidden, setIsHidden] = useState(true);

  useEffect(() => {
    if (hideWhenValid && isValid) {
      setTimeout(() => setIsHidden(true), 800);
    } else {
      setIsHidden(false);
    }
  }, [isValid, hideWhenValid]);

  const nodeRef = useRef<HTMLDivElement>(null);

  return (
    <Transition nodeRef={nodeRef} in={!isHidden} timeout={duration}>
      {(state) => (
        <ListItem
          ref={nodeRef}
          style={{
            ...defaultStyle,
            ...transitionStyles[state],
          }}
        >
          <ListItemIcon isValid={isValid}>
            <use xlinkHref="#star-ico" />
          </ListItemIcon>
          <Paragraph>{text}</Paragraph>
        </ListItem>
      )}
    </Transition>
  );
};

export default StaleHexagonLine;
