import {
  forwardRef,
  PropsWithChildren,
  ReactNode,
  Ref,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { VariableSizeList } from 'react-window';

import {
  TableFooter,
  TableRow,
  TableWrapper,
  TableHead,
  TableHeaderCell,
  SortIcon,
  TableBody,
} from './Table.styles';
import Checkbox from '../Checkbox/Checkbox';
import StaleTooltip from '../StaleTooltip/StaleTooltip';
import {
  IdType,
  Row,
  useExpanded,
  useRowSelect,
  useSortBy,
  useTable,
  useGlobalFilter,
  UseTableOptions,
  UseGlobalFiltersOptions,
  useFilters,
  UseFiltersOptions,
  UseRowSelectOptions,
  UseExpandedOptions,
  UseSortByOptions,
  TableInstance,
  useFlexLayout,
  useRowState,
} from 'react-table';
import ExpanderCell from './ExpanderCell';
import VirtualizedRow from './VirtualizedRow';
import NormalRow from './NormalRow';
import {
  CELL_DEFAULT_WIDTH,
  DEFAULT_COLUMN,
  DEFAULT_ROW_HEIGHT,
} from './consts';

export type ExposedUseTableProps<T extends object> = Pick<
  TableInstance<T>,
  'setFilter' | 'setGlobalFilter' | 'setAllFilters'
>;

export interface TableProps<T extends {}>
  extends UseTableOptions<T>,
    UseGlobalFiltersOptions<T>,
    UseFiltersOptions<T>,
    UseRowSelectOptions<T>,
    UseExpandedOptions<T>,
    UseSortByOptions<T> {
  expansionRender?: (record: T) => ReactNode;
  isExpandable?: (record: T) => boolean;
  isRowSelectable?: (props: {
    row: Row<T>;
    selectedFlatRows: Row<T>[];
  }) => boolean;
  isAllRowsSelectable?: boolean;
  onSelect?: (rowIds: Record<IdType<T>, boolean>, rows: Row<T>[]) => unknown;
  onRowClick?: (record: T) => unknown;
  isRowDisabled?: (record: T) => boolean;
  disabledCheckboxHint?: string;
  withHead?: boolean;
  withSelectAll?: boolean;
  selectable?: boolean;
  sortable?: boolean;
  renderFooterContent?: ReactNode;
  isVirtualized?: boolean;
  defaultRowHeight?: number;
  minVisibleRows?: number;
}

const Table = forwardRef(
  <T extends {}>(
    {
      columns,
      data,
      expansionRender,
      isExpandable,
      selectable = false,
      sortable = false,
      isRowDisabled,
      onRowClick,
      isRowSelectable = () => true,
      onSelect,
      disabledCheckboxHint = '',
      withHead = true,
      withSelectAll = false,
      globalFilter,
      manualGlobalFilter,
      disableGlobalFilter,
      renderFooterContent,
      autoResetExpanded,
      autoResetSelectedRows,
      autoResetSortBy,
      autoResetGlobalFilter,
      autoResetFilters,
      filterTypes,
      initialState,
      isVirtualized = false,
      defaultRowHeight = DEFAULT_ROW_HEIGHT,
      minVisibleRows = 5,
    }: PropsWithChildren<TableProps<T>>,
    ref: Ref<ExposedUseTableProps<T>>
  ) => {
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow,
      selectedFlatRows,
      setGlobalFilter,
      setFilter,
      setAllFilters,
      state: { selectedRowIds },
      totalColumnsWidth,
    } = useTable<T>(
      {
        initialState,
        columns,
        data,
        disableSortBy: !sortable,
        autoResetExpanded,
        autoResetSelectedRows,
        autoResetSortBy,
        autoResetGlobalFilter,
        autoResetFilters,
        globalFilter,
        manualGlobalFilter,
        disableGlobalFilter,
        filterTypes,
        defaultColumn: DEFAULT_COLUMN,
      },
      useRowState,
      useFlexLayout,
      useFilters,
      useGlobalFilter,
      useSortBy,
      useExpanded,
      useRowSelect,
      (hooks) => {
        if (selectable) {
          hooks.visibleColumns.push((columns) => [
            {
              id: 'selection',
              Header: (props) => {
                if (withSelectAll) {
                  const { toggleRowSelected } = props;
                  const selectableRows = rows.filter((row) =>
                    isRowSelectable({
                      row,
                      selectedFlatRows: props.selectedFlatRows,
                    })
                  );
                  const onChange = (
                    event: React.ChangeEvent<HTMLInputElement>
                  ) => {
                    selectableRows.forEach((row) => {
                      toggleRowSelected(row.id, event.currentTarget.checked);
                    });
                  };

                  return (
                    <Checkbox
                      onChange={onChange}
                      checked={
                        !!props.selectedFlatRows.length &&
                        props.selectedFlatRows.length === selectableRows.length
                      }
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                    />
                  );
                }

                return null;
              },
              Cell: (props) => {
                const isSelectable = isRowSelectable({
                  row: props.row,
                  selectedFlatRows: props.selectedFlatRows,
                });

                return (
                  <StaleTooltip
                    disabled={isSelectable}
                    text={disabledCheckboxHint}
                  >
                    <Checkbox
                      disabled={!isSelectable}
                      {...props.row.getToggleRowSelectedProps()}
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                    />
                  </StaleTooltip>
                );
              },
              width: CELL_DEFAULT_WIDTH,
              minWidth: CELL_DEFAULT_WIDTH,
              canResize: false,
            },
            ...columns,
          ]);
        }

        if (isExpandable) {
          hooks.visibleColumns.push((columns) => [
            {
              Header: () => null,
              id: 'expander',
              Cell: (cellProps) => {
                const { row } = cellProps;
                const isRowExpandable = isExpandable(row.original);

                return isRowExpandable ? <ExpanderCell {...cellProps} /> : null;
              },
              width: CELL_DEFAULT_WIDTH,
              minWidth: CELL_DEFAULT_WIDTH,
              canResize: false,
            },
            ...columns,
          ]);
        }
      }
    );

    const tableHeadRef = useRef<HTMLDivElement>(null);
    const tableBodyRef = useRef<HTMLDivElement>(null);
    const listRef = useRef<VariableSizeList>(null);

    const [tableHeadHeight, setTableHeadHeight] = useState(0);
    const [tableBodyHeight, setTableBodyHeight] = useState(0);
    const [expandedRowSizes, setExpandedRowSizes] = useState<
      Record<string, number>
    >({});

    const minBodyHeight =
      Math.min(minVisibleRows, rows.length) * defaultRowHeight;

    const getSizes = () => {
      if (tableBodyRef.current) {
        setTableBodyHeight(tableBodyRef.current.getBoundingClientRect().height);
      }

      if (tableHeadRef.current) {
        setTableHeadHeight(tableHeadRef.current.getBoundingClientRect().height);
      }
    };

    useEffect(() => {
      window.addEventListener('resize', getSizes);

      return () => {
        window.removeEventListener('resize', getSizes);
      };
    }, []);

    useLayoutEffect(() => {
      getSizes();
    }, []);

    /* 
      TODO: consider moving into useImperativeHandle ⬇
    */
    useEffect(() => {
      onSelect?.(selectedRowIds, selectedFlatRows);
    }, [onSelect, selectedFlatRows, selectedRowIds]);

    useImperativeHandle(
      ref,
      () => ({
        setFilter,
        setGlobalFilter,
        setAllFilters,
      }),
      [setAllFilters, setFilter, setGlobalFilter]
    );

    return (
      <>
        <TableWrapper
          role="table"
          isVirtualized={isVirtualized}
          {...getTableProps({
            style: {
              minHeight: minBodyHeight + tableHeadHeight,
            },
          })}
        >
          {withHead && (
            <TableHead role="rowgroup" ref={tableHeadRef}>
              {headerGroups.map((headerGroup) => {
                return (
                  <TableRow role="row" {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => {
                      const { style, ...rest } = column.getHeaderProps(
                        column.getSortByToggleProps()
                      );

                      return (
                        <TableHeaderCell
                          isSelectableCell={column.id === 'selection'}
                          role="columnheader"
                          {...rest}
                          style={style}
                        >
                          {column.canSort && (
                            <SortIcon active={column.isSorted}>
                              <use xlinkHref="#sort-table" />
                            </SortIcon>
                          )}
                          {column.render('Header')}
                        </TableHeaderCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableHead>
          )}

          <TableBody
            role="rowgroup"
            tableHeadHeight={tableHeadHeight}
            {...getTableBodyProps({
              style: {
                minHeight: minBodyHeight,
              },
            })}
            ref={tableBodyRef}
            isVirtualized={isVirtualized}
          >
            {isVirtualized && !!tableBodyHeight ? (
              <VariableSizeList
                ref={listRef}
                height={tableBodyHeight}
                itemCount={rows.length}
                itemSize={(index) => {
                  const row = rows[index];

                  if (row.isExpanded) {
                    const expansionHeight = expandedRowSizes[index];

                    if (expansionHeight) {
                      return defaultRowHeight + expansionHeight;
                    }
                  }

                  return defaultRowHeight;
                }}
                width="100%"
                style={{
                  maxHeight: '100%',
                  minWidth: totalColumnsWidth,
                  overflow: 'overlay',
                }}
              >
                {(data) => (
                  <VirtualizedRow
                    {...data}
                    rows={rows}
                    prepareRow={prepareRow}
                    isRowDisabled={isRowDisabled}
                    onRowClick={onRowClick}
                    expansionRender={expansionRender}
                    setExpandedRowSizes={setExpandedRowSizes}
                    expandedRowSizes={expandedRowSizes}
                    listRef={listRef}
                    rowHeight={defaultRowHeight}
                  />
                )}
              </VariableSizeList>
            ) : (
              rows.map((row) => (
                <NormalRow
                  key={row.id}
                  row={row}
                  prepareRow={prepareRow}
                  isRowDisabled={isRowDisabled}
                  onRowClick={onRowClick}
                  expansionRender={expansionRender}
                  rowHeight={defaultRowHeight}
                />
              ))
            )}
          </TableBody>
        </TableWrapper>

        {renderFooterContent && (
          <TableFooter>{renderFooterContent}</TableFooter>
        )}
      </>
    );
  }
);

export default Table as <T extends object>(
  props: TableProps<T> & { ref?: Ref<ExposedUseTableProps<T>> }
) => JSX.Element;
