import styled, { css } from 'styled-components';
import { InputSearch } from 'components';

export const Wrapper = styled.div<{
  focused: boolean;
  error: boolean;
}>(
  ({ theme, error, focused }) => css`
    .wrap {
      color: ${theme.color.dark};
      background: ${theme.color.greyLight_3};
      border-radius: 6px;
      position: relative;
      height: 52px;
      display: flex;
      align-items: center;
      box-shadow: ${error && `inset 0 0 0 1px ${theme.color.red}`};
      box-shadow: ${focused && `inset 0 0 0 1px ${theme.color.emeraldDark}`};
    }

    .input {
      padding: 0 98px 0 0;
      width: 100%;
      height: 100%;
      font-weight: 500;
      font-size: 18px;
      transition: ${theme.transition};
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      letter-spacing: 0.03em;

      &[type='number'],
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        -moz-appearance: textfield;
        margin: 0;
      }
    }

    .country {
      margin: 0 16px;
      min-width: 22px;
      width: 22px;
      height: 22px;
    }

    .select {
      width: 86px;
      height: 100%;
      position: absolute;
      right: 0;
      bottom: 0;
      background: #bbf0dd;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 16px;
      padding: 2px 12px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 0 6px 6px 0;
      pointer-events: fill;
      cursor: pointer;

      &:focus {
        box-shadow: inset 0 0 0 1px ${theme.color.emeraldDark};
      }

      &.read-only {
        justify-content: center;
      }

      .i-arrow {
        width: 24px;
        height: 24px;
        transition: ${theme.transition} linear;
      }

      &.open {
        .i-arrow {
          transform: rotate(180deg);
        }
      }
    }

    .error {
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      margin: 3px 0;
      color: ${theme.color.red};
    }

    ${InputSearch} {
      padding-bottom: 12px;
    }
  `
);
