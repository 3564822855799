import LogRocket from 'logrocket';
import LogrocketFuzzySanitizer from 'logrocket-fuzzy-search-sanitizer';

/**
 * Update this with any properties we don't want to be visible on LogRocket
 */
const sanitisedProperties = [
  'idToken',
  'password',
  'passwordHash',
  'pwd',
  'refreshToken',
];

/**
 * We can't just delete the existing key/value pair because that mutates the original action.
 * So we need to copy it to a new object that is set just for LogRocket.
 */
export const recursivelySanitiseAction = (obj, debug = false) => {
  Object.entries(obj).forEach(([key, val]) => {
    if (val && typeof val === 'object') {
      if (debug) {
        console.log('key:before', key, obj[key]);
      }
      obj[key] = recursivelySanitiseAction(val, debug);
      if (debug) {
        console.log('key:after', key, obj[key]);
      }
    } else if (sanitisedProperties.includes(key)) {
      if (debug) {
        console.log('found key to sanitise', key);
      }

      const newObject = {
        ...obj,
        [key]: 'SANITISED',
      };
      if (debug) {
        console.log('setting ', newObject);
      }
      obj = newObject;
    }
  });
  return obj;
};

interface INetworkRequestResponse {
  url: string;
  body: any;
  method: string;
  headers: object;
}
/**
 *
 * @param func request ot response sanitiser from LogrocketFuzzySanitizer
 * @returns sanitised request or response
 */
const customNetworkSanitizer = (func: Function) => (
  network: INetworkRequestResponse
) => {
  if (
    network.url.includes('googleapis') ||
    network.url.includes('google.firestore')
  ) {
    network.url = 'SANITISED';
  }

  if (network.headers['Authorization']) {
    network.headers['Authorization'] = 'SANITISED';
  }

  return func(network);
};

if (process.env.REACT_APP_LOGROCKET_APP_ID && process.env.NODE_ENV !== 'test') {
  const { requestSanitizer, responseSanitizer } = LogrocketFuzzySanitizer.setup(
    sanitisedProperties
  );

  LogRocket.init(process.env.REACT_APP_LOGROCKET_APP_ID, {
    network: {
      // @ts-ignore // required because of library type mismatches
      requestSanitizer: customNetworkSanitizer(requestSanitizer),
      // @ts-ignore // required because of library type mismatches
      responseSanitizer: customNetworkSanitizer(responseSanitizer),
    },
  });
}
