import { Paragraph } from 'components';
import Button from 'components/shared/Button/Button';
import { FC } from 'react';
import { useStoreState } from 'state';
import { parseIntoCurrencyStringWithSymbol, roundToPrecision } from 'utils';

interface OwnProps {
  value: number;
  valueInPerc?: number;
  currencyCode: string;
  setRecordForFxBreakdown: () => void;
  disableButton?: boolean;
}

const GainLossCell: FC<OwnProps> = ({
  value,
  valueInPerc,
  currencyCode,
  setRecordForFxBreakdown,
  disableButton,
}) => {
  const { currencyByCode } = useStoreState((state) => state.CurrenciesState);

  const currency = currencyByCode(currencyCode);

  const renderText = () => (
    <>
      <Paragraph>
        {parseIntoCurrencyStringWithSymbol(value, currency?.symbol)}
      </Paragraph>
      {typeof valueInPerc === 'number' && (
        <Paragraph ml color={valueInPerc >= 0 ? 'green' : 'red'}>
          {`(${roundToPrecision(Math.abs(valueInPerc * 100))}%)`}
        </Paragraph>
      )}
    </>
  );

  return disableButton ? (
    renderText()
  ) : (
    <Button variant="link" onClick={setRecordForFxBreakdown}>
      {renderText()}
    </Button>
  );
};

export default GainLossCell;
