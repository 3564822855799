import useCodatIntegration from './useCodatIntegration';

const useSage50 = () => {
  const {
    onImportData: onImportSage50Data,
    isLoading: isLoadingSage50Data,
    isIntegrated: isIntegratedWithSage50,
    onLogin: onSage50Login,
    isImporting: isImportingSage50Data,
    queueNewCodatSync: queueNewSage50Sync,
    syncStatus: sage50syncStatus,
  } = useCodatIntegration({ platformNames: ['Sage 50 (UK)'] });

  return {
    isImportingSage50Data,
    isIntegratedWithSage50,
    isLoadingSage50Data,
    onImportSage50Data,
    onSage50Login,
    queueNewSage50Sync,
    sage50syncStatus,
  };
};

export default useSage50;
