import { FC } from 'react';
import { Paragraph, Row, Icon } from 'components';
import { parseIntoCurrencyStringWithSymbol } from 'utils';
import { useStoreState } from 'state';
import { useTheme } from 'styled-components';

interface OwnProps {
  amount?: number;
  currencyCode: string;
  withFlag?: boolean;
}

const AmountCell: FC<OwnProps> = ({ amount = 0, currencyCode, withFlag }) => {
  const theme = useTheme();
  const { currencyByCode } = useStoreState((state) => state.CurrenciesState);
  const currency = currencyByCode(currencyCode);
  return (
    <Row gap={theme.spacing.xs}>
      {withFlag && currency && <Icon icon={currency.countryCode} />}
      <Paragraph>
        {parseIntoCurrencyStringWithSymbol(
          amount,
          currency?.symbol,
          currency?.precision
        )}
      </Paragraph>
    </Row>
  );
};

export default AmountCell;
