import styled, { css } from 'styled-components';

export const Wrapper = styled.div(
  ({ theme }) => css`
    padding: 16px;
    position: relative;
    background: ${theme.color.white};
    border-left: 4px solid ${theme.color.transparent};

    &:not(:last-of-type) {
      box-shadow: inset 0 -1px 0 ${theme.color.greyLight_1};
    }

    .few & {
      border: none;
      &:first-of-type {
        border-radius: 6px 6px 0 0;
      }
      &:last-of-type {
        border-radius: 0 0 6px 6px;
      }
    }

    .title-h1 {
      line-height: 28px;
      margin: 0 4px 0 8px;
      font-size: 28px;
      font-weight: 700;
      max-width: 100%;

      @media (min-width: 1025px) and (min-height: 568px) {
        font-size: 36px;
        line-height: 42.2px;
      }
    }
    .title-h4 {
      line-height: 24px;
      margin: 0 5px;
      font-size: 18px;
      font-weight: 700;
      max-width: 100%;

      @media (min-width: 1025px) and (min-height: 568px) {
        font-size: 20px;
        line-height: 36px;
      }
    }

    svg {
      width: 24px;
      height: 24px;
    }

    .line {
      display: flex;
      align-items: flex-end;

      svg {
        margin-bottom: 3px;
      }
    }

    .row {
      justify-content: space-between;
      align-items: flex-end;
    }

    .icons {
      position: absolute;
      right: 16px;
      top: 16px;

      button {
        width: 24px;
        height: 24px;
        margin-left: 12px;
      }

      svg {
        fill: ${theme.color.grey};
      }
    }
  `
);
