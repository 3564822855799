import styled from 'styled-components';
import { Col } from 'components/shared/Col/Col';
import { Row } from 'components/shared/Row/Row';

export const AccordionWrapper = styled(Col)`
  flex: 1;
  background: ${({ theme }) => theme.color.black};
  border-top-left-radius: ${({ theme }) => theme.borderRadius.m};
  border-top-right-radius: ${({ theme }) => theme.borderRadius.m};
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  @media (min-width: ${({ theme }) => theme.breakpoint.largeMin}px) {
    border-radius: ${({ theme }) => theme.borderRadius.m};
    position: relative;
  }
`;

export const AccordionHeader = styled(Row)`
  gap: ${({ theme }) => theme.spacing.xxl};
  padding: ${({ theme }) => theme.spacing.m};
  cursor: pointer;
`;

export const AccordionContent = styled(Col)`
  gap: ${({ theme }) => theme.spacing.xs};
  padding: ${({ theme }) => theme.spacing.m};
`;

export const ArrowWrapper = styled(Col)<{ isOpen?: boolean }>`
  align-items: center;
  transition: ${({ theme }) => theme.transition};
  transform: rotate(${({ isOpen }) => (isOpen ? '180deg' : '0deg')});
`;

export const List = styled.ul`
  padding-left: ${({ theme }) => theme.spacing.xs};
`;

export const ListLi = styled.li`
  position: relative;

  &:not(:last-child) {
    &:after {
      content: '';
      position: absolute;
      height: 100%;
      left: -1px;
      top: ${({ theme }) => theme.spacing.m};
      border-left: 1px dashed ${({ theme }) => theme.color.emeraldDark};
    }
  }

  svg {
    position: absolute;
    top: 3px;
    left: -7px;
  }
`;
