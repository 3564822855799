import styled from 'styled-components';

export const ReactSliderWrapper = styled.div`
  .time-range {
    height: 40px;
    border-bottom: 3px solid ${({ theme }) => theme.color.dark};
    margin: 36px 0 20px;

    .example-thumb {
      bottom: -20px;
      width: 40px;
      border: 2px solid ${({ theme }) => theme.color.dark};
      background: ${({ theme }) => theme.color.dark};
      box-shadow: inset 0 0 0 7px ${({ theme }) => theme.color.white};
    }

    .tooltip {
      color: ${({ theme }) => theme.color.white};
      background: ${({ theme }) => theme.color.dark};
    }
  }

  .example-thumb {
    height: 40px;
    border-radius: 40px;
    border: 2px solid ${({ theme }) => theme.color.dark};
  }

  .tooltip {
    position: absolute;
    bottom: calc(100% + 6px);
    padding: 6px 10px;

    border-radius: 6px;

    left: 50%;
    transform: translate(-50%, 0);
    display: flex;
    flex-direction: column;
    white-space: nowrap;
  }
`;
