import { useState } from 'react';
import { IContact, IInvoice } from 'types';

const useInvoicePopups = () => {
  const [
    changeTargetInvoice,
    setChangeTargetInvoice,
  ] = useState<IInvoice | null>(null);
  const [
    existingInvoiceTracking,
    setExistingInvoiceTracking,
  ] = useState<IInvoice | null>(null);
  const [invoiceDecide, setInvoiceDecide] = useState<IInvoice | null>(null);
  const [
    startTrackingInvoice,
    setStartTrackingInvoice,
  ] = useState<IInvoice | null>(null);
  const [showAllDecideFields, setShowAllDecideFields] = useState(false);
  const [
    cancelPrebookInvoice,
    setCancelPrebookInvoice,
  ] = useState<IInvoice | null>(null);
  const [
    existingPrebookInvoice,
    setExistingPrebookInvoice,
  ] = useState<IInvoice | null>(null);
  const [contactForEdit, setContactForEdit] = useState<IContact | null>(null);
  const [
    invoiceForAddContact,
    setInvoiceForAddContact,
  ] = useState<IInvoice | null>(null);
  const [showInvoiceDelete, setShowInvoiceDelete] = useState<IInvoice | null>(
    null
  );
  const [
    showPurchaseOrderPaymentsOnInvoice,
    setShowPurchaseOrderPaymentsOnInvoice,
  ] = useState<IInvoice | null>(null);
  const [
    showChoosePurchaseOrderPaymentsOnInvoice,
    setShowChoosePurchaseOrderPaymentsOnInvoice,
  ] = useState<IInvoice | null>(null);
  const [showBulkPrebook, setShowBulkPrebook] = useState(false);

  return {
    changeTargetInvoice,
    setChangeTargetInvoice,
    existingInvoiceTracking,
    setExistingInvoiceTracking,
    invoiceDecide,
    setInvoiceDecide,
    startTrackingInvoice,
    setStartTrackingInvoice,
    showAllDecideFields,
    setShowAllDecideFields,
    cancelPrebookInvoice,
    setCancelPrebookInvoice,
    existingPrebookInvoice,
    setExistingPrebookInvoice,
    contactForEdit,
    setContactForEdit,
    invoiceForAddContact,
    setInvoiceForAddContact,
    showInvoiceDelete,
    setShowInvoiceDelete,
    showPurchaseOrderPaymentsOnInvoice,
    setShowPurchaseOrderPaymentsOnInvoice,
    showChoosePurchaseOrderPaymentsOnInvoice,
    setShowChoosePurchaseOrderPaymentsOnInvoice,
    showBulkPrebook,
    setShowBulkPrebook,
  };
};

export default useInvoicePopups;
