import React, { FC, useState, useMemo, CSSProperties } from 'react';

import {
  StaleButtonsPopup,
  InputSearch,
  StaleRecipient,
  Row,
  StaleSubTitleMedium,
  Title,
  StaleRecipientDropdown,
  Paragraph,
  StaleLoader,
} from 'components';
import {
  Wrapper,
  AddRecipientButtonBig,
  ListWrapper,
} from './StaleRecipientList.styles';
import { RECIPIENT_STATUS, ICurrency, IRecipient, TRANSFER_TYPE } from 'types';
import { useStoreActions } from 'state';
import Icon from '../Icon/Icon';
import Button from '../Button/Button';
import { useTheme } from 'styled-components';

interface OwnProps {
  recipientItemType?: 'dropdown' | 'card';
  buyCurrency: ICurrency;
  disabled?: boolean;
  isInvoice?: boolean;
  onAddRecipient: () => void;
  onEditRecipient: (recipient: IRecipient) => void;
  recipient?: IRecipient;
  recipients: IRecipient[];
  sellCurrency: ICurrency;
  setRecipient: (recipient: IRecipient) => void;
  transferType?: TRANSFER_TYPE;
  checkForDisabledRecipients?: boolean;
  listWrapperMaxHeight?: CSSProperties['maxHeight'];
}

const StaleRecipientList: FC<OwnProps> = ({
  recipientItemType = 'card',
  buyCurrency,
  disabled = false,
  isInvoice = false,
  onAddRecipient,
  onEditRecipient,
  recipient,
  recipients,
  sellCurrency,
  setRecipient,
  transferType,
  checkForDisabledRecipients,
  listWrapperMaxHeight,
}) => {
  const theme = useTheme()
  const [searchValue, setSearchValue] = useState('');
  const [showDeleteRecipient, setShowDeleteRecipient] = useState(false);
  const [recipientIdForDelete, setRecipientIdForDelete] = useState('');
  const { deleteRecipient } = useStoreActions(
    (actions) => actions.RecipientsState
  );

  const filteredRecipients = useMemo(
    () =>
      recipients.filter(
        (item) =>
          item.currency === buyCurrency?.code &&
          item.status !== RECIPIENT_STATUS.deleted &&
          item.recipientName
            .toLowerCase()
            .trim()
            .includes(searchValue.toLowerCase().trim())
      ),
    [recipients, searchValue, buyCurrency]
  );

  const isList = useMemo(() => !!filteredRecipients.length, [
    filteredRecipients,
  ]);

  const onDeleteRecipient = async () => {
    await deleteRecipient(recipientIdForDelete);

    setRecipientIdForDelete('');
    setShowDeleteRecipient(false);
  };

  // TODO: rework, condition is redundant
  const isListWithSearch = (isList && !searchValue) || searchValue;

  return (
    <Wrapper>
      <Row justifyContent="space-between">
        <Title variant="h5">Select the recipient</Title>

        {isListWithSearch && (
          <Button
            variant="link"
            disabled={disabled}
            isLoading={disabled}
            onClick={() => onAddRecipient()}
            icon={disabled ? undefined : 'user-plus-ico'}
            ml
            mlValue={theme.spacing.xs}
          >
            {disabled ? 'Loading...' : 'Add recipient'}
          </Button>
        )}
      </Row>

      {isListWithSearch && (
        <InputSearch
          isGray={true}
          style={{ marginBottom: 16 }}
          type="text"
          placeholder="Search"
          value={searchValue}
          onChange={(e) => {
            setSearchValue(e.target.value);
          }}
        />
      )}

      <ListWrapper maxHeight={listWrapperMaxHeight}>
        {isListWithSearch && !isList && (
          <Paragraph style={{ textAlign: 'center' }} variant="bold">
            No results found
          </Paragraph>
        )}

        {!isListWithSearch && (
          <AddRecipientButtonBig type="button" onClick={onAddRecipient}>
            {disabled ? (
              <StaleLoader style={{ minHeight: 55 }} />
            ) : (
              <>
                <Icon icon="user-plus-ico" />
                <StaleSubTitleMedium>Add new recipient</StaleSubTitleMedium>
              </>
            )}
          </AddRecipientButtonBig>
        )}

        {isList &&
          filteredRecipients.map((item) =>
            recipientItemType === 'card' ? (
              <StaleRecipient
                key={item.id}
                isInvoice={isInvoice}
                selectedRecipient={recipient}
                transferType={transferType}
                recipient={item}
                sellCurrency={sellCurrency}
                setRecipient={setRecipient}
                onEditRecipient={onEditRecipient}
                setShowDeleteRecipient={setShowDeleteRecipient}
                setRecipientIdForDelete={setRecipientIdForDelete}
                checkDisabled={checkForDisabledRecipients}
              />
            ) : (
              <StaleRecipientDropdown
                key={item.id}
                isInvoice={isInvoice}
                selectedRecipient={recipient}
                transferType={transferType}
                recipient={item}
                sellCurrency={sellCurrency}
                setRecipient={setRecipient}
                onEditRecipient={onEditRecipient}
                checkDisabled={checkForDisabledRecipients}
              />
            )
          )}
      </ListWrapper>

      {showDeleteRecipient && (
        <StaleButtonsPopup
          title="Delete recipient?"
          text="Please confirm that you want to delete this recipient."
          mainButtonText="Yes, delete"
          secondaryButtonText="Cancel"
          width="347px"
          onCancel={() => {
            setShowDeleteRecipient(false);
            setRecipientIdForDelete('');
          }}
          onClose={() => {
            setShowDeleteRecipient(false);
            setRecipientIdForDelete('');
          }}
          onContinue={() => {
            onDeleteRecipient();
          }}
        />
      )}
    </Wrapper>
  );
};

export default StaleRecipientList;
