import { getLink } from 'utils/links';
import { HELP_URL } from 'variables';

export const SIDEDRAWER_ITEMS = [
  {
    title: 'Dashboard',
    slug: '/app/dashboard',
    icon: 'dashboard-ico',
    externalLink: '',
  },
  {
    title: 'Exchange',
    slug: getLink('/app/prebook'),
    icon: 'prebook-ico',
    externalLink: '',
  },
  {
    title: 'Transfer',
    slug: getLink('/app/transfers'),
    icon: 'transfer-ico',
    externalLink: '',
  },
  {
    title: 'Invoices',
    slug: '/app/invoices',
    icon: 'file-ico',
    externalLink: '',
  },
  {
    title: 'Contacts',
    slug: '/app/contacts',
    icon: 'users-group',
    externalLink: '',
  },
  {
    title: 'Settings',
    slug: '/app/settings',
    icon: 'settings-ico',
    externalLink: '',
  },
  {
    title: 'FAQ',
    slug: `${HELP_URL}`,
    icon: 'question-ico',
    externalLink: `${HELP_URL}`,
  },
];

export const DROPDOWNMENU_ITEMS = [
  {
    title: 'Dashboard',
    slug: '/app/dashboard',
    icon: 'dashboard-ico',
    externalLink: '',
  },
  {
    title: 'Exchange',
    slug: getLink('/app/prebook'),
    icon: 'prebook-filled',
    externalLink: '',
  },
  {
    title: 'Transfer',
    slug: getLink('/app/transfers'),
    icon: 'transfer-ico',
    externalLink: '',
  },
  {
    title: 'Invoices',
    slug: '/app/invoices',
    icon: 'file-ico',
    externalLink: '',
  },
  {
    title: 'Contacts',
    slug: '/app/contacts',
    icon: 'users-group',
    externalLink: '',
  },
  {
    title: 'Settings',
    slug: '/app/settings',
    icon: 'settings-ico',
    externalLink: '',
  },
];

export const SOLUTIONS_DROPDOWN_DATA = {
  id: 1,
  title: 'Solutions',
  links: [
    {
      title: 'Wholesale & Retail',
      slug:
        'https://land.hedgeflows.com/en-gb/currency-solutions-fore-retailers-wholesalers',
      target: '_blank',
    },
    {
      title: 'Freight Forwarders',
      slug:
        'https://land.hedgeflows.com/en-gb/currency-solutions-for-freight-forwarders',
      target: '_blank',
    },
    {
      title: 'Manufacturing',
      slug:
        'https://land.hedgeflows.com/en-gb/currency-solutions-for-manufacturers',
      target: '_blank',
    },
    {
      title: 'Start-ups',
      slug: 'https://land.hedgeflows.com/en-gb/currency-solutions-for-startups',
      target: '_blank',
    },
  ],
};

export const ABOUT_DROPDOWN_DATA = {
  id: 2,
  title: 'About',
  links: [
    {
      title: 'About us',
      slug: '/about-us',
      target: '',
    },
    {
      title: 'Our pricing',
      slug: 'https://land.hedgeflows.com/en-gb/pricing-guide',
      target: '_blank',
    },
    {
      title: 'Our blog',
      slug: 'https://hedgeflows-8698300.hs-sites.com/',
      target: '_blank',
    },
    {
      title: 'Careers',
      slug: 'https://apply.workable.com/hedgeflows',
      target: '_blank',
    },
  ],
};
