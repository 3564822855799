import { Row, Paragraph } from 'components';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div``;

export const ConfirmBox = styled(Row)(
  ({ theme }) => css`
    padding: 16px 17px 14px 19px;
    border-radius: 10px;
    align-items: flex-start;
    background: ${theme.color.greyLight_2};
    margin-top: 14px;

    ${Paragraph} {
      margin-left: 14px;
    }
  `
);
