import { AxiosPrivateFirebaseInstance } from 'settings';
import { IResponse } from 'types';
import { ICashflowsSummary } from 'types/cashflows';
import { openQuery } from 'utils';
import firestore from './firestore';

export interface SubscribeToCashflowSummariesParams {
  entityId: string;
  callback: (cashflowSummaries: ICashflowsSummary[]) => void;
}

export const subscribeToCashflowSummaries = ({
  entityId,
  callback,
}: SubscribeToCashflowSummariesParams) => {
  return firestore
    .collection('entities')
    .doc(entityId)
    .collection('cashflowsSummary')
    .onSnapshot(
      (query) => callback(openQuery(query)),
      (error) =>
        console.warn(
          'Failed to subscribe to cashflow summaries. Error: ',
          error
        )
    );
};

interface RefreshCashflowSummariesParams {
  entityId: string;
}

export const refreshCashflowSummaries = async ({
  entityId,
}: RefreshCashflowSummariesParams) => {
  return AxiosPrivateFirebaseInstance.post<IResponse>(
    `cashflows/refresh/${entityId}`
  );
};
