import styled, { css } from 'styled-components';

export const Wrapper = styled.div<{
  error: boolean;
}>(
  ({ theme, error }) => css`
    .wrap {
      color: ${theme.color.dark};
      background: ${theme.color.greyLight_3};
      border-radius: 6px;
      position: relative;
      height: 52px;
      display: flex;
      align-items: center;
      box-shadow: ${error && `0 0 0 1px ${theme.color.red}`};
    }

    .input {
      padding: 0 12px 0 12px;
      margin-left: 120px;
      position: absolute;
      left: 1px;
      right: 1px;
      height: 22px;
      top: 14px;
      width: calc(100% - 122px);
      transition: box-shadow 0s 0s;
      box-shadow: inset 0 30px 0 ${theme.color.transparent};
      background: ${theme.color.transparent};
      -webkit-text-fill-color: ${theme.color.dark};
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;

      &[type='number'],
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        -moz-appearance: textfield;
        margin: 0;
      }
    }

    .select {
      width: 120px;
      position: absolute;
      left: 1px;
      top: 1px;
      height: 50px;
      text-transform: uppercase;
      border-right: 1px solid ${theme.color.white};
      font-weight: 500;
      font-size: 14px;
      padding: 0 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 6px 0 0 6px;

      .i-arrow {
        width: 24px;
        height: 24px;
        transition: ${theme.transition} linear;
      }
    }

    .label {
      position: absolute;
      right: 0;
      left: 120px;
      padding: 0 16px 0 12px;
      top: 0;
      display: flex;
      align-items: center;
      height: 52px;
      cursor: text;
      transition: ${theme.transition};
      color: ${error && theme.color.red};
    }

    &.focused .wrap {
      box-shadow: 0 0 0 1px ${theme.color.emeraldDark};
      box-shadow: ${error && `0 0 0 1px ${theme.color.red}`};
    }

    &.filled {
      .label {
        top: -8px;
        font-size: 12px;
      }
      .input {
        top: 24px;
        box-shadow: inset 0 30px 0 ${theme.color.greyLight_3};
        transition: box-shadow ${theme.transition} ${theme.transition};
      }
    }

    .error {
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      margin: 3px 0;
      color: ${theme.color.red};
    }
  `
);
