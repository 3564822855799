import React, { FC } from 'react';
import {
  StaleTitleH4,
  StaleParagraphMedium,
  Button,
  ButtonSecondary,
  StaleBtnGroup,
} from 'components';
import { StaleGetRateFailed } from './StaleGetRateFailed.styles';

interface OwnProps {
  onCancel: () => void;
  onRetry: () => void;
  onClose: () => void;
}

const StaleGetRateFailedComponent: FC<OwnProps> = ({
  onCancel,
  onRetry,
  onClose,
}) => (
  <StaleGetRateFailed>
    <div className="popup">
      <button className="close" onClick={onClose}>
        <svg width="24" height="24">
          <use xlinkHref="#cross-ico" />
        </svg>
      </button>

      <StaleTitleH4>Rate is temporarily unavailable.</StaleTitleH4>

      <StaleParagraphMedium>
        Please try again or cancel in order to use previous currency.
      </StaleParagraphMedium>

      <StaleBtnGroup horizontal container={false}>
        <ButtonSecondary onClick={onCancel}>Cancel</ButtonSecondary>
        <Button onClick={onRetry}>Try again</Button>
      </StaleBtnGroup>
    </div>
  </StaleGetRateFailed>
);

export default StaleGetRateFailedComponent;
