import { TAccountingIntegration } from 'types';
import useQuickBooks from './useQuickBooks';
import useSage50 from './useSage50';
import useXero from './useXero';

const useFindAccountingIntegration = (): TAccountingIntegration => {
  const { isIntegrated: isIntegratedWithXero } = useXero();
  const { isIntegratedWithSage50 } = useSage50();
  const { isIntegratedWithQuickBooks } = useQuickBooks();

  if (isIntegratedWithXero) {
    return 'xero';
  }
  if (isIntegratedWithSage50) {
    return 'sage50';
  }
  if (isIntegratedWithQuickBooks) {
    return 'quickBooks';
  }

  return null;
};

export default useFindAccountingIntegration;
