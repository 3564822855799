import { FC, useMemo, useState } from 'react';
import { useStoreActions, useStoreState } from 'state';
import { useLocation } from 'react-router';
import { useTheme } from 'styled-components';
import Card from 'components/shared/Card/Card.styles';
import {
  Col,
  Paragraph,
  Row,
  StaleInputSelect,
  StaleProgressBar,
} from 'components';
import useUrlValues from 'hooks/useUrlValues';
import HexagonRating from 'components/shared/HexagonRating/HexagonRating';
import useRiskRating from 'hooks/useRiskRating';
import { getHexagonsStatus } from 'components/shared/HexagonRating/utils';
import { getRiskLevelRatingText } from 'utils/analysis';
import { parseIntoCurrencyStringWithSymbol } from 'utils';
import Button from 'components/shared/Button/Button';
import ReduceRiskPopup from './components/ReduceRiskPopup/ReduceRiskPopup';
import { ISelectItem } from 'components/shared/StaleInputSelect/StaleInputSelect';

const CashflowAtRisk: FC = () => {
  const theme = useTheme();
  const location = useLocation<{ detectedCurrencies: string[] }>();
  const { userEntity, entityId, entityDefaultCurrency } = useStoreState(
    (state) => state.UserState
  );
  const { currencyByCode, buyCurrencies } = useStoreState(
    (state) => state.CurrenciesState
  );
  const { cashflowsRisks, isRefreshingCashflowsRisksData } = useStoreState(
    (state) => state.ReferenceDataState
  );
  const { refreshCashflowsRisksData } = useStoreActions(
    (actions) => actions.ReferenceDataState
  );
  const { setUrlValue, currency } = useUrlValues('currency');
  const selectData = useMemo(
    () =>
      location.state.detectedCurrencies.reduce<ISelectItem[]>(
        (acc, currencyCode) => {
          if (buyCurrencies.find((curr) => curr.code === currencyCode)) {
            acc.push({
              id: currencyCode,
              name: currencyCode,
              value: currencyCode,
              icon: currencyByCode(currencyCode)?.countryCode,
            });
          }

          return acc;
        },
        []
      ),
    [buyCurrencies, currencyByCode, location.state.detectedCurrencies]
  );
  const currencyRiskPerCurrency = currency
    ? cashflowsRisks?.currencyRisk[currency]
    : undefined;
  const { riskRating } = useRiskRating(currencyRiskPerCurrency?.cashflowAtRisk);
  const [showPrebookAllPopup, setShowPrebookAllPopup] = useState(false);

  const cashflowRiskProgress = useMemo(() => {
    if (!currencyRiskPerCurrency || !userEntity.riskTolerance) {
      return 0;
    }

    const { cashflowAtRisk } = currencyRiskPerCurrency;

    const progress = cashflowAtRisk / (userEntity.riskTolerance / 100);

    if (progress < 0) {
      return progress * -1;
    }

    return progress;
  }, [currencyRiskPerCurrency, userEntity.riskTolerance]);

  const onRecalculate = async () => {
    if (!entityId || !entityDefaultCurrency) {
      return;
    }

    await refreshCashflowsRisksData({
      entityId,
      sellCurrency: entityDefaultCurrency,
    });
  };

  console.log('cashflowsRisks', cashflowsRisks);

  return (
    <>
      <Card alignSelf="stretch" justifyContent="space-between" flex={0.4}>
        <Row columnGap={theme.spacing.xxxl}>
          <StaleInputSelect
            selected={currency ?? location.state.detectedCurrencies[0]}
            data={selectData}
            onSelect={(item) => setUrlValue('currency', item.value)}
            id="invoices-currency"
            style={{ minWidth: '132px' }}
          />
          <HexagonRating
            rating={riskRating}
            status={getHexagonsStatus(true, 'asc', riskRating)}
            statusText={getRiskLevelRatingText(riskRating)}
          />
        </Row>

        <Col>
          <Row>
            <Paragraph variant="bold">Cashflow at risk</Paragraph>
            <Paragraph variant="bold">
              {currencyRiskPerCurrency &&
                parseIntoCurrencyStringWithSymbol(
                  Math.abs(currencyRiskPerCurrency.cashflowAtRisk),
                  currencyByCode(userEntity.entityCurrency)?.symbol
                )}
            </Paragraph>
          </Row>

          <StaleProgressBar
            progress={cashflowRiskProgress}
            color={theme.color.coral}
            fullWidth
          />
        </Col>

        <Row columnGap={theme.spacing.m}>
          <Button onClick={() => setShowPrebookAllPopup(true)}>
            Reduce risks
          </Button>
          <Button
            isLoading={isRefreshingCashflowsRisksData}
            onClick={onRecalculate}
            variant="link"
          >
            Refresh
          </Button>
        </Row>
      </Card>

      {showPrebookAllPopup && (
        <ReduceRiskPopup
          activeCurrency={currency}
          setShowPrebookAllPopup={setShowPrebookAllPopup}
        />
      )}
    </>
  );
};

export default CashflowAtRisk;
