import { FC } from 'react';

import { Paragraph, Col, Title } from 'components';
import { useTheme } from 'styled-components';
import { useHistory } from 'react-router';
import Button from 'components/shared/Button/Button';

const StepFour: FC = () => {
  const theme = useTheme();
  const history = useHistory();

  return (
    <>
      <Title mb mbValue={theme.spacing.xxl} variant="h1">
        Thanks! We've saved your preferences
      </Title>

      <Col justifyContent="center">
        <Paragraph mb mbValue={theme.spacing.xl}>
          We will use them to guide you on our platform. You can review or
          change your risk preferences in Settings.
        </Paragraph>
      </Col>

      <Col
        justifyContent="center"
        style={{ paddingTop: '120px', paddingBottom: '20px' }}
      >
        <Button onClick={() => history.push('/app/dashboard')}>
          Back to Dashboard
        </Button>
      </Col>
      <Col justifyContent="center">
        <Button
          variant="secondary"
          onClick={() => history.push('/app/settings')}
        >
          Settings
        </Button>
      </Col>
    </>
  );
};

export default StepFour;
