import styled from 'styled-components';
import { Row } from 'components/shared/Row/Row';
import { Paragraph } from 'components/shared/Typography/Typography';

export const IntegrationsWrapper = styled(Row)`
  flex-wrap: wrap;
`;

export const IntegrationsParagraph = styled(Paragraph)`
  margin-top: ${({ theme }) => theme.spacing.s};
`;
