import styled, { css } from 'styled-components';

import { Paragraph, Row } from 'components';

export const NotificationWrapper = styled.div<{
  position: string;
  bgColor: string;
}>(
  ({ theme, position = 'left', bgColor }) => css`
    width: 100%;
    padding: 16px;
    background: ${bgColor};
    border-radius: 6px;
    margin-top: ${position === 'right' && '15px'};

    ${Paragraph} {
      color: ${theme.color.black};
    }

    ${Row} {
      justify-content: ${position === 'left' ? 'space-between' : 'flex-end'};

      &:last-child {
        justify-content: ${position === 'left' ? 'flex-start' : 'flex-end'};
      }

      ${Paragraph} {
        &:first-child {
          margin-right: 12px;
        }
      }
    }
  `
);
