import { useStoreState } from 'state';

const useIsAwaitingRegistration = () => {
  const { isEntityEnabled, userEntity } = useStoreState(
    (state) => state.UserState
  );

  return !isEntityEnabled && userEntity?.status !== 'onboardingStep4Completed';
};

export default useIsAwaitingRegistration;
