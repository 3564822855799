import { TitleCount } from './Contacts.styles';
import {
  AddContact,
  StaleButtonsPopup,
  Paragraph,
  Title,
  TableSearchInput,
  Row,
} from 'components';
import { useStoreActions, useStoreState } from 'state';
import { filterTypes, generateTableColumns } from './utils';
import { FC, useMemo, useState } from 'react';
import { IContact, TRANSFER_TYPE } from 'types';
import { RouteComponentProps } from 'react-router-dom';
import Table from 'components/shared/Table/Table';
import useTableFiltering from 'hooks/useTableFiltering';
import Button from 'components/shared/Button/Button';

const Contacts: FC<RouteComponentProps> = ({ history }) => {
  const { recipients } = useStoreState((state) => state.RecipientsState);
  const { userEntity } = useStoreState((state) => state.UserState);
  const { countryByCode } = useStoreState((state) => state.ReferenceDataState);
  const { currencies, currencyByCode } = useStoreState(
    (state) => state.CurrenciesState
  );
  const { systemVariables } = useStoreState(
    (state) => state.ReferenceDataState
  );
  const { deleteRecipient } = useStoreActions(
    (actions) => actions.RecipientsState
  );

  const sellCurrency = currencyByCode(
    userEntity?.entityCurrency || systemVariables?.defaultSellCurrency
  );
  const [showDeleteRecipient, setShowDeleteRecipient] = useState(false);
  const [recipientIdForDelete, setRecipientIdForDelete] = useState('');
  const [recipientForEdit, setRecipientForEdit] = useState<IContact>();
  const [isAddRecipient, setIsAddRecipient] = useState(false);

  const onDeleteRecipient = async () => {
    await deleteRecipient(recipientIdForDelete);

    setRecipientIdForDelete('');
    setShowDeleteRecipient(false);
  };

  const { searchValue, setSearchValue, tableRef } = useTableFiltering<
    string,
    IContact
  >({
    filterTypeName: null,
  });

  const tableColumns = useMemo(
    () =>
      generateTableColumns({
        setShowDeleteRecipient,
        setRecipientIdForDelete,
        countryByCode,
        currencies,
        setRecipientForEdit,
        setIsAddRecipient,
      }),
    [
      setShowDeleteRecipient,
      setRecipientIdForDelete,
      countryByCode,
      currencies,
      setRecipientForEdit,
      setIsAddRecipient,
    ]
  );

  const initialCurrency = recipientForEdit
    ? currencyByCode(recipientForEdit.currency)
    : currencyByCode(systemVariables?.defaultBuyCurrency);

  return (
    <>
      <Row mb>
        <Row>
          <Title variant="h3" mr>
            Contacts <TitleCount>{recipients?.length}</TitleCount>
          </Title>
          <TableSearchInput
            style={{ marginRight: 26 }}
            placeholder="Search by contact name"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            width="254px"
          />
          <Button onClick={() => setIsAddRecipient(true)} variant="link">
            + Add new
          </Button>
        </Row>

        <Row>
          <Button
            onClick={() => {
              history.push('/app/bulk-contacts-sage');
            }}
            variant="link"
          >
            Upload Sage contacts
          </Button>

          <Button
            onClick={() => {
              history.push('/app/bulk-contacts');
            }}
            variant="link"
          >
            Upload contacts
          </Button>
        </Row>
      </Row>

      <Row>
        {recipients?.length > 0 ? (
          <Table<IContact>
            // @ts-ignore
            ref={tableRef}
            sortable
            filterTypes={filterTypes}
            globalFilter="text"
            columns={tableColumns}
            data={recipients}
            autoResetGlobalFilter={false}
          />
        ) : (
          <Paragraph>There is no contacts</Paragraph>
        )}
      </Row>

      {showDeleteRecipient && (
        <StaleButtonsPopup
          title="Delete recipient?"
          text="Please confirm that you want to delete this recipient."
          mainButtonText="Yes, delete"
          secondaryButtonText="Cancel"
          width="347px"
          onCancel={() => {
            setShowDeleteRecipient(false);
            setRecipientIdForDelete('');
          }}
          onClose={() => {
            setShowDeleteRecipient(false);
            setRecipientIdForDelete('');
          }}
          onContinue={() => {
            onDeleteRecipient();
          }}
        />
      )}

      {/* TODO: strange condition for add and edit, revisit */}
      {isAddRecipient && initialCurrency && sellCurrency && (
        <AddContact
          onClose={() => {
            setIsAddRecipient(false);
            setRecipientForEdit(undefined);
          }}
          withDraftWarning
          recipientForEdit={recipientForEdit}
          initialCurrency={initialCurrency}
          sellCurrency={sellCurrency}
          initialTransferType={
            recipientForEdit
              ? recipientForEdit.paymentType === 'swift'
                ? TRANSFER_TYPE.priority
                : TRANSFER_TYPE.regular
              : TRANSFER_TYPE.priority
          }
          onContinue={() => {
            setIsAddRecipient(false);
          }}
        />
      )}
    </>
  );
};

export default Contacts;
