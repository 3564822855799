import styled, { css } from 'styled-components';

export const StaleLoaderWrapper = styled.div<{
  size: 'small' | 'medium' | 'large';
}>(
  ({ size }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    svg {
      ${size === 'small' &&
      css`
        width: 24px;
        height: 24px;
      `}
      ${size === 'medium' &&
      css`
        width: 50px;
        height: 50px;
      `}
    ${size === 'large' &&
      css`
        width: 80px;
        height: 80px;
      `}

      animation: loading 1.2s linear infinite;
    }

    @keyframes loading {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
  `
);
