import { FC } from 'react';
import { useStoreState } from 'state';
import { Row, Icon, Paragraph } from 'components';
import { useTheme } from 'styled-components';

interface OwnProps {
  currencyCode: string;
}

const CurrencyCell: FC<OwnProps> = ({ currencyCode }) => {
  const theme = useTheme();
  const { currencyByCode } = useStoreState((state) => state.CurrenciesState);

  const currency = currencyByCode(currencyCode);

  if (!currency) {
    return null;
  }

  return (
    <Row columnGap={theme.spacing.s} alignItems="center">
      <Icon icon={currency.countryCode} />
      <Paragraph variant="bold">{currencyCode}</Paragraph>
    </Row>
  );
};

export default CurrencyCell;
