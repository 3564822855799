// TDOO: rework this component and make logic more uniform, too many similar props now
import {
  AddContact,
  StaleButtonsPopup,
  StaleCancellingBalance,
  StaleXeroReauthenticatePopup,
} from 'components';
import useXero from 'hooks/useXero';
import { InvoicesByCurrency } from 'components/shared/InvoicesTable/types';
import { Dispatch, FC, SetStateAction } from 'react';
import { useStoreState, useStoreActions } from 'state';
import { IContact, IInvoice, TRANSFER_TYPE } from 'types';
import { Notify } from 'utils';
import PopupTracking from './PopupTracking/PopupTracking';
import UseExistingPrebook from './UseExistingPrebook/UseExistingPrebook';
import DecidePopup from './Decide';
import GoodRateTrack from './GoodRateTrack';
import PrebookByIds from './PrebookByIds';
import PrePaymentsOnPurchaseOrder from './PrePaymentsOnPurchaseOrder/PrePaymentsOnPurchaseOrder';
import SelectExistingContact from './SelectExistingContact';

interface OwnProps {
  changeTargetInvoice: IInvoice | null;
  setChangeTargetInvoice: Dispatch<SetStateAction<IInvoice | null>>;
  existingInvoiceTracking: IInvoice | null;
  setExistingInvoiceTracking: Dispatch<SetStateAction<IInvoice | null>>;
  invoiceDecide: IInvoice | null;
  setInvoiceDecide: Dispatch<SetStateAction<IInvoice | null>>;
  startTrackingInvoice: IInvoice | null;
  setStartTrackingInvoice: Dispatch<SetStateAction<IInvoice | null>>;
  showAllDecideFields: boolean;
  setShowAllDecideFields: Dispatch<SetStateAction<boolean>>;
  cancelPrebookInvoice: IInvoice | null;
  setCancelPrebookInvoice: Dispatch<SetStateAction<IInvoice | null>>;
  existingPrebookInvoice: IInvoice | null;
  setExistingPrebookInvoice: Dispatch<SetStateAction<IInvoice | null>>;
  contactForEdit: IContact | null;
  setContactForEdit: Dispatch<SetStateAction<IContact | null>>;
  invoiceForAddContact: IInvoice | null;
  setInvoiceForAddContact: Dispatch<SetStateAction<IInvoice | null>>;
  showInvoiceDelete: IInvoice | null;
  setShowInvoiceDelete: Dispatch<SetStateAction<IInvoice | null>>;
  showPurchaseOrderPaymentsOnInvoice: IInvoice | null;
  setShowPurchaseOrderPaymentsOnInvoice: Dispatch<
    SetStateAction<IInvoice | null>
  >;
  showBulkPrebook: boolean;
  setShowBulkPrebook: Dispatch<SetStateAction<boolean>>;
  selectedInvoices?: IInvoice[];
  invoicesCurrency?: InvoicesByCurrency['currencyCode'];
  invoicesTotalAmount?: number;
  invoicesTotalBookingCost?: number;
}

const Popups: FC<OwnProps> = ({
  changeTargetInvoice,
  setChangeTargetInvoice,
  existingInvoiceTracking,
  setExistingInvoiceTracking,
  invoiceDecide,
  setInvoiceDecide,
  startTrackingInvoice,
  setStartTrackingInvoice,
  showAllDecideFields,
  setShowAllDecideFields,
  cancelPrebookInvoice,
  setCancelPrebookInvoice,
  existingPrebookInvoice,
  setExistingPrebookInvoice,
  contactForEdit,
  setContactForEdit,
  invoiceForAddContact,
  setInvoiceForAddContact,
  showInvoiceDelete,
  setShowInvoiceDelete,
  showPurchaseOrderPaymentsOnInvoice,
  setShowPurchaseOrderPaymentsOnInvoice,
  showBulkPrebook,
  setShowBulkPrebook,
  selectedInvoices,
  invoicesCurrency,
  invoicesTotalAmount,
  invoicesTotalBookingCost,
}) => {
  const { reauthenticate } = useXero();
  const { currencyByCode } = useStoreState((state) => state.CurrenciesState);
  const { systemVariables } = useStoreState(
    (state) => state.ReferenceDataState
  );
  const { deleteInvoice } = useStoreActions((state) => state.InvoicesState);

  const onDeleteInvoice = async () => {
    try {
      if (!showInvoiceDelete) {
        return;
      }

      const data = await deleteInvoice({
        invoiceId: showInvoiceDelete.id,
      });

      if (data && data.success) {
        Notify.success('Invoice deleted');
      } else {
        Notify.error(data?.message || 'Failed to delete Invoice');
      }
    } catch (error) {
      Notify.error('Failed to delete Invoice');
      console.warn(error);
    } finally {
      setShowInvoiceDelete(null);
    }
  };

  const initialCurrency = currencyByCode(contactForEdit?.currency);
  const sellCurrency = currencyByCode(systemVariables?.defaultSellCurrency);

  return (
    <>
      {!!contactForEdit && initialCurrency && sellCurrency && (
        <AddContact
          onClose={() => {
            setContactForEdit(null);
          }}
          withDraftWarning
          recipientForEdit={contactForEdit}
          initialCurrency={initialCurrency}
          sellCurrency={sellCurrency}
          initialTransferType={
            contactForEdit.paymentType === 'swift'
              ? TRANSFER_TYPE.priority
              : TRANSFER_TYPE.regular
          }
          onContinue={() => {
            setContactForEdit(null);
          }}
        />
      )}
      <StaleXeroReauthenticatePopup />
      {existingInvoiceTracking && !changeTargetInvoice && !reauthenticate && (
        <PopupTracking
          invoice={existingInvoiceTracking}
          onChangeTarget={setChangeTargetInvoice}
          onClose={() => setExistingInvoiceTracking(null)}
        />
      )}
      {changeTargetInvoice && !reauthenticate && (
        <DecidePopup
          invoice={changeTargetInvoice}
          isEdit
          onClose={() => setChangeTargetInvoice(null)}
        />
      )}
      {invoiceDecide && (
        <DecidePopup
          invoice={invoiceDecide}
          onClose={() => {
            setInvoiceDecide(null);
            setShowAllDecideFields(false);
          }}
          showAllFields={showAllDecideFields}
          setShowAllDecideFields={setShowAllDecideFields}
        />
      )}
      {startTrackingInvoice && (
        <GoodRateTrack
          invoice={startTrackingInvoice}
          onClose={() => setStartTrackingInvoice(null)}
        />
      )}
      {cancelPrebookInvoice && cancelPrebookInvoice.contractId && (
        <StaleCancellingBalance
          onClose={() => setCancelPrebookInvoice(null)}
          invoice={cancelPrebookInvoice}
        />
      )}
      {showPurchaseOrderPaymentsOnInvoice && (
        <PrePaymentsOnPurchaseOrder
          invoice={showPurchaseOrderPaymentsOnInvoice}
          onClose={() => setShowPurchaseOrderPaymentsOnInvoice(null)}
          setContactForEdit={setContactForEdit}
          setInvoiceForAddContact={setInvoiceForAddContact}
        />
      )}

      {!!existingPrebookInvoice && (
        <UseExistingPrebook
          invoice={existingPrebookInvoice}
          sellCurrency={'GBP'}
          onClose={() => setExistingPrebookInvoice(null)}
        />
      )}

      {!!invoiceForAddContact && (
        <SelectExistingContact
          invoice={invoiceForAddContact}
          onClose={() => setInvoiceForAddContact(null)}
        />
      )}

      {!!showInvoiceDelete && (
        <StaleButtonsPopup
          title="Delete invoice?"
          text="Please confirm that you want to delete this invoice."
          mainButtonText="Yes, delete"
          secondaryButtonText="Cancel"
          width="347px"
          onClose={() => {
            setShowInvoiceDelete(null);
          }}
          onContinue={() => {
            onDeleteInvoice();
          }}
        />
      )}

      {showBulkPrebook && (
        <PrebookByIds
          selectedInvoices={selectedInvoices ?? []}
          invoicesCurrency={invoicesCurrency}
          invoicesTotalAmount={invoicesTotalAmount}
          invoicesTotalBookingCost={invoicesTotalBookingCost}
          onClose={() => {
            setShowBulkPrebook(false);
          }}
        />
      )}
    </>
  );
};

export default Popups;
