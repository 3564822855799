import { Paragraph } from 'components';
import { FC, useMemo } from 'react';
import { useStoreState } from 'state';
import { parseIntoCurrencyStringWithSymbol } from 'utils';
import { getNumberOfDaysBetweenTwoDates } from 'utils/dates';

interface OwnProps {
  currencyCode: string;
  amount: number;
  creationDate: string;
  paidDate: string;
}

const RiskContributionCell: FC<OwnProps> = ({
  currencyCode,
  amount,
  creationDate,
  paidDate,
}) => {
  const { currencyByCode } = useStoreState((state) => state.CurrenciesState);
  const { riskContribution } = useStoreState(
    (state) => state.ReferenceDataState
  );

  const numberOfDays = getNumberOfDaysBetweenTwoDates(creationDate, paidDate);

  const currency = currencyByCode(currencyCode);
  const closestNumber = useMemo(
    () =>
      riskContribution[currencyCode]
        ? Object.keys(riskContribution[currencyCode])
            .map((item) => Number(item))
            .sort(
              (a, b) => Math.abs(numberOfDays - a) - Math.abs(numberOfDays - b)
            )[0]
        : 0,
    [currencyCode, numberOfDays, riskContribution]
  );
  const riskContributionPerc =
    riskContribution[currencyCode]?.[closestNumber.toString()] ?? 0;

  return (
    <Paragraph>
      {parseIntoCurrencyStringWithSymbol(
        amount * riskContributionPerc,
        currency?.symbol
      )}
    </Paragraph>
  );
};

export default RiskContributionCell;
