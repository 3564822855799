import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { importXeroData } from 'services/firebase/invoices';

import { getXeroStateToken, xeroDisconnect } from 'services/firebase/xero';
import { useStoreState } from 'state';
import { getXeroLogsPageLink, getXeroSettingsPageLink } from 'utils/links';

const DEFAULT_SCOPES =
  'openid profile email accounting.transactions accounting.reports.read accounting.contacts offline_access accounting.settings accounting.attachments';

/**
 * We need the string behind the function so window.location.href is always correct, and not dependent on when and where this module was first loaded.
 */
const getDefaultAuthUrl = () =>
  `https://login.xero.com/identity/connect/authorize?response_type=code&client_id=${process.env.REACT_APP_XERO_CLIENT_ID}&redirect_uri=${window.location.href}&scope=${DEFAULT_SCOPES}`;

interface IUseXero {
  retrieveStateToken?: boolean;
}
const useXero = ({ retrieveStateToken = false }: IUseXero = {}) => {
  const { integrationsSummary, entityId } = useStoreState(
    (state) => state.UserState
  );
  const { xero } = useStoreState(
    ({ UserState }) => UserState.integrationsSummary
  );
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [isDisconnecting, setIsDisconnecting] = useState(false);
  const [isImporting, setIsImporting] = useState(false);

  const [oAuthUrl, setOAuthUrl] = useState(getDefaultAuthUrl());
  const [stateToken, setXeroStateToken] = useState<string>('');

  const isIntegrated = integrationsSummary.xero?.connected;
  const reauthenticate = !!integrationsSummary.xero?.reauthenticate;
  const syncMessage = integrationsSummary.xero?.syncMessage;
  const deepLinkKey = integrationsSummary.xero?.deepLinkKey;
  const connectionName = integrationsSummary.xero?.connectionName;

  useEffect(() => {
    const retrieveXeroStateToken = async () => {
      try {
        setIsLoading(true);
        const data = await getXeroStateToken(entityId);
        setXeroStateToken(data?.state ?? '');
      } catch (error) {
        // show some error
        console.error('Error retrieving status token', error.message);
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    };

    if (retrieveStateToken) {
      retrieveXeroStateToken();
    }
  }, [retrieveStateToken, entityId]);

  useEffect(() => {
    if (stateToken) {
      setOAuthUrl(
        `https://login.xero.com/identity/connect/authorize?response_type=code&client_id=${process.env.REACT_APP_XERO_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_XERO_REDIRECT_URI}&scope=${DEFAULT_SCOPES}&state=${stateToken}`
      );
    }
  }, [stateToken]);

  const onImportData = async () => {
    try {
      setIsImporting(true);
      await importXeroData();
    } catch (error) {
      setIsImporting(false);
      throw error;
    }
  };

  // set importing status
  useEffect(() => {
    setIsImporting(
      xero?.syncStatus === 'queued' || xero?.syncStatus === 'inProgress'
    );
  }, [xero?.syncStatus]);

  const onLogin = async () => {
    if (!window || !window?.top) {
      return;
    }

    const width = 400;
    const height = 500;

    const top = window.top.outerHeight / 2 + window.top.screenY - height / 2;
    const left = window.top.outerWidth / 2 + window.top.screenX - width / 2;

    window.open(
      oAuthUrl,
      'Connect to Xero',
      `resizable, scrollbars, status, width=${width}px, height=${height}px, left=${left}, top=${top}`
    );
  };

  const onSignUp = useCallback(() => {
    window.location.href = oAuthUrl;
  }, [oAuthUrl]);

  const onDisconnect = async () => {
    try {
      setIsDisconnecting(true);
      await xeroDisconnect();
      setIsDisconnecting(false);
    } catch (error) {
      setIsDisconnecting(false);
      throw error;
    }
  };

  const onManageSettings = async () => {
    // if user has gone through settings once, this will be true
    const url =
      !xero?.settings && !!xero?.connected
        ? getXeroSettingsPageLink({
            step: '1',
            firstIntegration: true,
          })
        : getXeroSettingsPageLink({
            step: '1',
          });
    history.push(url);
  };

  const onManageLogs = () => history.push(getXeroLogsPageLink());

  return {
    connectionName,
    deepLinkKey,
    isDisconnecting,
    isImporting,
    isIntegrated,
    isLoading,
    oAuthUrl,
    onDisconnect,
    onImportData,
    onLogin,
    onManageLogs,
    onManageSettings,
    onSignUp,
    reauthenticate,
    syncMessage,
  };
};

export default useXero;
