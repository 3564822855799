import { FC, useEffect, useMemo, useState } from 'react';
import { Col, Table, Title } from 'components';
import { TPastPerformanceItem } from 'types/analyses';
import { subscribeToPastPerformancePerRecord } from 'services/firebase/analysis';
import { useStoreState } from 'state';
import { filterTypes, generateReportsTableColumns } from './utils';
import TableControls from './components/TableControls/TableControls';
import ReportCalculationStatus from './components/ReportCalculationStatus/ReportCalculationStatus';
import useTableFiltering from 'hooks/useTableFiltering';
import CurrencyTiles from './components/CurrencyTiles/CurrencyTiles';
import FxBreakdownPopup from './components/FxBreakdownPopup/FxBreakdownPopup';
import { HF_GURU_TASKS } from 'types/entities';
import useHfGuru from 'hooks/useHfGuru';
import Placeholder from 'components/shared/Placeholder/Placeholder';
import { useTheme } from 'styled-components';
import { TReportsContentType, TReportsTableFilterValue } from './types';
import { INVOICE_STATUSES } from 'types';

const Reports: FC = () => {
  const theme = useTheme();
  const [contentType, setContentType] = useState<TReportsContentType>(
    'realised'
  );
  const { entityId } = useStoreState((state) => state.UserState);
  const [activeCurrencyCode, setActiveCurrencyCode] = useState<string>();
  const [data, setData] = useState<TPastPerformanceItem[]>([]);
  const [
    recordForFxBreakdown,
    setRecordForFxBreakdown,
  ] = useState<TPastPerformanceItem>();
  const [activeMonth, setActiveMonth] = useState('');

  const { hfGuruData, updateHfGuruStatus } = useHfGuru();

  const columns = useMemo(
    () =>
      generateReportsTableColumns({
        setRecordForFxBreakdown,
        contentType,
        activeMonth,
      }),
    [activeMonth, contentType]
  );

  const { filter, setFilter, tableRef } = useTableFiltering<
    TReportsTableFilterValue,
    TPastPerformanceItem
  >({
    filterTypeName: 'direction',
  });

  // we want to show transfers in to GBP, so for now lets add the entity currency to supported currencies
  useEffect(() => {
    if (
      hfGuruData &&
      !hfGuruData.completed &&
      !hfGuruData.statuses?.healthCheck?.completed
    ) {
      updateHfGuruStatus(HF_GURU_TASKS.healthCheck, false);
    }
  }, [hfGuruData, updateHfGuruStatus]);

  useEffect(() => {
    let unsubscribe: (() => void) | undefined;

    if (entityId && activeCurrencyCode) {
      unsubscribe = subscribeToPastPerformancePerRecord({
        entityId,
        currency: activeCurrencyCode,
        callback: (pastPerformanceData) => {
          const filteredPastPerformanceData = pastPerformanceData.filter(
            (pastPerformanceItem) => {
              if (
                contentType === 'realised' &&
                pastPerformanceItem.status === INVOICE_STATUSES.authorised
              ) {
                return false;
              }

              if (pastPerformanceItem.recordType === 'bankTransfer') {
                return pastPerformanceItem.paidDateMonth === activeMonth;
              }

              if (
                contentType === 'marketImpact' &&
                pastPerformanceItem.monthlyImpactContributions
              ) {
                return Object.keys(
                  pastPerformanceItem.monthlyImpactContributions
                ).some(
                  (monthlyContributionKey) =>
                    monthlyContributionKey === activeMonth
                );
              }

              return pastPerformanceItem.paidDateMonth === activeMonth;
            }
          );

          setData(filteredPastPerformanceData);
        },
      });
    }

    return () => unsubscribe?.();
  }, [activeCurrencyCode, activeMonth, entityId, contentType]);

  return (
    <>
      {entityId && (
        <CurrencyTiles
          contentType={contentType}
          setContentType={setContentType}
          entityId={entityId}
          activeCurrencyCode={activeCurrencyCode}
          setActiveCurrencyCode={setActiveCurrencyCode}
          activeMonth={activeMonth}
          setActiveMonth={setActiveMonth}
        />
      )}

      <ReportCalculationStatus />

      {activeMonth ? (
        <>
          <TableControls
            invoiceCount={data.length}
            filter={filter}
            setFilter={setFilter}
          />

          <Table
            ref={tableRef}
            isVirtualized
            data={data}
            columns={columns}
            sortable
            filterTypes={filterTypes}
            globalFilter="text"
          />
        </>
      ) : (
        <Col>
          <Title mb mbValue={theme.spacing.l} variant="h5">
            Invoices
          </Title>

          <Placeholder
            fullWidth
            description="Choose a period on the graph to see the breakdown"
          />
        </Col>
      )}

      {!!recordForFxBreakdown && (
        <FxBreakdownPopup
          data={recordForFxBreakdown}
          onClose={() => setRecordForFxBreakdown(undefined)}
        />
      )}
    </>
  );
};

export default Reports;
