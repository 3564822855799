import {
  EXTERNAL_PAYMENT_TYPE,
  IExternalPayment,
} from 'types/externalPayments';
import { openQuery } from 'utils';
import db from 'services/firestore';

export interface SubscribeToExternalPaymentsParams {
  entityId: string;
  callback: (invoices: IExternalPayment[]) => void;
}

export const subscribeToExternalPayments = ({
  entityId,
  callback,
}: SubscribeToExternalPaymentsParams) => {
  return db
    .collection('externalPayments')
    .where('_owner', '==', entityId)
    .where('type', '==', EXTERNAL_PAYMENT_TYPE.PAYMENT_ON_ACCOUNT)
    .orderBy('date')
    .onSnapshot(
      (query) => {
        callback(openQuery(query));
      },
      (error) =>
        console.log('Failed to subscribe to externalPayments. Error: ', error)
    );
};
