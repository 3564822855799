import { FC } from 'react';
import Integrations from 'pages/Settings/components/Integrations/Integrations';
import {
  IntegrationsWrapper,
  IntegrationsParagraph,
} from './StaleIntegrationSettings.styles';
import { useTheme } from 'styled-components';
import { Subtitle } from '../Typography/Typography';
import { SettingsContent } from 'pages/Settings/Settings.styles';

const StaleIntegrationSettings: FC = () => {
  const theme = useTheme();
  return (
    <SettingsContent>
      <Subtitle variant="bold">Accounting system integrations</Subtitle>

      <IntegrationsWrapper mt>
        <Integrations variant="accounting" />
      </IntegrationsWrapper>

      <Subtitle mt mtValue={theme.spacing.xxl} variant="bold">
        Business systems integrations
      </Subtitle>
      <IntegrationsParagraph>
        Integrate your business systems to manage your upcoming currency needs:
      </IntegrationsParagraph>

      <IntegrationsWrapper mt>
        <Integrations variant="business" />
      </IntegrationsWrapper>
    </SettingsContent>
  );
};

export default StaleIntegrationSettings;
