import { FC } from 'react';
import {
  FullSizeStepsRightCol,
  FullSizeStepsLeftCol,
  FullSizeStepsWrapper,
  FullSizeContentContainer,
  FullSizeStepsCrossButton,
} from './FullSizeStepsTemplate.styles';
import Icon from '../../shared/Icon/Icon';

interface OwnProps {
  onClose: () => void;
  leftColumn?: React.ReactNode;
  rightColumn?: React.ReactNode;
}

const FullSizeStepsTemplate: FC<OwnProps> = ({
  onClose,
  leftColumn,
  rightColumn,
}) => (
  <FullSizeStepsWrapper>
    <FullSizeStepsLeftCol>
      <FullSizeContentContainer>{leftColumn}</FullSizeContentContainer>
    </FullSizeStepsLeftCol>

    <FullSizeStepsRightCol>
      <FullSizeContentContainer>
        <FullSizeStepsCrossButton variant="link" onClick={onClose}>
          <Icon icon="cross-ico" />
        </FullSizeStepsCrossButton>
        {rightColumn}
      </FullSizeContentContainer>
    </FullSizeStepsRightCol>
  </FullSizeStepsWrapper>
);
export default FullSizeStepsTemplate;
