import styled, { css } from 'styled-components';

export const DropdownWrapper = styled.div(
  ({ theme }) => css`
    .arrow {
      width: 15px;
      height: 15px;
      transition: ${theme.transition};
      margin-left: 10px;
    }

    &.dropdown-block {
      position: relative;

      .dropdown {
        background: ${theme.color.greyDark};
        position: absolute;
        top: 65px;
        left: 15px;
        max-height: 0;
        height: auto;
        width: auto;
        overflow: hidden;
        border-radius: 4px;
        min-width: 244px;
        transition: ${theme.transition};
      }

      .menu-item {
        display: flex;
        align-items: center;
        padding: 20px 0;
      }

      .row {
        padding: 8px 0;
        height: 100%;
        flex-direction: column;
        align-items: flex-start;
      }

      .link {
        color: ${theme.color.white};
        font-weight: 500;
        font-size: 14px;
        line-height: 150%;
        padding: 10px 16px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        transition: ${theme.transition};

        &:focus,
        &:hover {
          color: ${theme.color.emeraldDark};
        }
      }
    }

    &.accordion-block {
      width: 100%;

      .dropdown {
        max-height: 0;
        height: auto;
        width: 100%;
        overflow: hidden;
        transition: ${theme.transition} all ease;
      }

      .menu-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 16px;
        margin: 0;
        font-size: 14px;
      }

      .row {
        height: 100%;
        flex-direction: column;
        align-items: flex-start;
        border-top: 1px solid ${theme.color.emeraldDark};
        border-bottom: 1px solid ${theme.color.emeraldDark};
      }

      .link {
        font-size: 18px;
        padding: 10px 16px 10px 42px;
      }
    }

    &.open {
      .dropdown {
        max-height: 90vh;
      }
      .menu-item {
        color: ${theme.color.emeraldDark};
      }
      .arrow {
        transform: rotate(-180deg);
        fill: ${theme.color.emeraldDark};
      }
    }
  `
);
