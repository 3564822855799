import { FC, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import {
  StaleParagraphMedium,
  StaleButtonCopy,
  TransferTable,
  Title,
  Row,
  Col,
  Subtitle,
  Paragraph,
  Icon,
} from 'components';
import orderBy from 'lodash.orderby';
import {
  parseIntoCurrencyString,
  getCountryCodeByCurrency,
  parseRateWithPrecision,
  getPrebookStatusDetails,
  canUseRateContract,
  Notify,
} from 'utils';
import dayjs from 'dayjs';
import { useStoreState } from 'state';
import { useQuery } from 'hooks';
import { CONTRACT_STATUS, IRateContract } from 'types';
import Popup from 'components/shared/Popup/Popup';
import { useTheme } from 'styled-components';
import { RowInfo } from 'components/shared/RowInfo/RowInfo.styles';
import { getPrebookingsPageLink, getTransfersPageLink } from 'utils/links';
import { DATE_FORMAT } from 'variables';
import {
  InnerDetailsWrapper,
  OverflowXWrapper,
} from '../InnerDetails/InnerDetails.styles';
import Button from '../Button/Button';

const PrebookInner: FC = () => {
  const history = useHistory();
  const theme = useTheme();
  const URLQuery = useQuery();
  const contractId = URLQuery.get('contractId');

  const { userEntity } = useStoreState((state) => state.UserState);
  const { transfersByRateContract } = useStoreState(
    (state) => state.TransfersState
  );
  const { rateContractById } = useStoreState(
    (state) => state.RateContractsState
  );
  const { currencies, currencyByCode } = useStoreState(
    (state) => state.CurrenciesState
  );
  const { fundingAccountByCurrency } = useStoreState(
    (state) => state.ReferenceDataState
  );

  const selectedRateContract = useMemo(() => rateContractById(contractId), [
    rateContractById,
    contractId,
  ]);

  const relatedTransfers = useMemo(
    () => transfersByRateContract(selectedRateContract?.id),
    [transfersByRateContract, selectedRateContract]
  );
  const fundingAccount = useMemo(
    () => fundingAccountByCurrency(selectedRateContract?.sellCurrency),
    [selectedRateContract, fundingAccountByCurrency]
  );

  const isAwaitingPrepayment =
    selectedRateContract?.status === CONTRACT_STATUS.awaitingPrepayment;
  const statusInfo = getPrebookStatusDetails(selectedRateContract);

  const getDaysLeft = (contract: IRateContract) =>
    dayjs(contract?.expiryDate).isBefore(dayjs())
      ? '0 days'
      : dayjs().to(dayjs(contract?.expiryDate), true);

  const onCancelContract = (contract: IRateContract) => {
    history.push(
      getPrebookingsPageLink({
        currency: contract.buyCurrency,
        cancelContractId: contract.id,
      })
    );
  };

  const onDeleteContract = (contract: IRateContract) => {
    history.push(
      getPrebookingsPageLink({
        currency: contract.buyCurrency,
        deleteContractId: contract.id,
      })
    );
  };

  return (
    <Popup
      HeaderContent={<Title variant="h3">Prebooked Exchange</Title>}
      FooterContent={
        <Row gap={theme.spacing.m} justifyContent="flex-start" flexWrap="wrap">
          <Button onClick={history.goBack}>Close</Button>
          {isAwaitingPrepayment && selectedRateContract && (
            <Button
              variant="secondary"
              onClick={() => onDeleteContract(selectedRateContract)}
            >
              Cancel
            </Button>
          )}
          {selectedRateContract && canUseRateContract(selectedRateContract) && (
            <>
              <Button
                onClick={(e) => {
                  e.stopPropagation();

                  const { remainingBuyAmount, id } = selectedRateContract;

                  history.push(
                    getTransfersPageLink({
                      predefinedBuyAmount: remainingBuyAmount.toString(),
                      predefinedRateContractId: id,
                    })
                  );
                }}
              >
                Transfer Using This Rate
              </Button>

              <Button
                variant="secondary"
                onClick={() => onCancelContract(selectedRateContract)}
              >
                Cancel Remaining Amount
              </Button>
            </>
          )}
        </Row>
      }
      width="900px"
      height="800px"
      onClose={history.goBack}
    >
      <Col rowGap={theme.spacing.xl}>
        <InnerDetailsWrapper>
          <Col flex={1} alignSelf="stretch">
            <Row mbValue={theme.spacing.xs} mb>
              <Subtitle variant="bold">Prebook Details</Subtitle>

              <Paragraph style={{ fontStyle: 'italic' }}>
                {selectedRateContract?.bookingName
                  ? `${selectedRateContract?.bookingName}(${selectedRateContract?._shortId})`
                  : selectedRateContract?._shortId}
              </Paragraph>
            </Row>

            <RowInfo>
              <Paragraph>Currency rate</Paragraph>

              <Row>
                <Icon
                  width="16px"
                  height="16px"
                  icon={getCountryCodeByCurrency(
                    selectedRateContract?.buyCurrency,
                    currencies
                  )}
                />

                <Paragraph mlValue={theme.spacing.s} ml>
                  {`1 ${
                    selectedRateContract?.sellCurrency
                  } = ${parseRateWithPrecision(selectedRateContract?.rate)} ${
                    selectedRateContract?.buyCurrency
                  }`}
                </Paragraph>
              </Row>
            </RowInfo>

            <RowInfo alignItems="flex-start">
              <Paragraph>Prebooked amount</Paragraph>

              <Col style={{ textAlign: 'right' }}>
                <Paragraph>
                  Booked{' '}
                  {`${parseIntoCurrencyString(
                    selectedRateContract?.buyAmount,
                    currencyByCode(selectedRateContract?.buyCurrency)?.precision
                  )} ${selectedRateContract?.buyCurrency}`}
                </Paragraph>

                <Paragraph>
                  Remaining{' '}
                  {`${parseIntoCurrencyString(
                    selectedRateContract?.remainingBuyAmount,
                    currencyByCode(selectedRateContract?.buyCurrency)?.precision
                  )} ${selectedRateContract?.buyCurrency}`}
                </Paragraph>
              </Col>
            </RowInfo>

            <RowInfo>
              <Paragraph>Created on</Paragraph>
              <Paragraph>
                {dayjs(selectedRateContract?._created).format(DATE_FORMAT)}
              </Paragraph>
            </RowInfo>

            <RowInfo>
              <Paragraph>Use by</Paragraph>
              <Paragraph>
                {dayjs(selectedRateContract?.expiryDate).format(DATE_FORMAT)}
              </Paragraph>
            </RowInfo>
          </Col>

          {selectedRateContract && (
            <Col flex={1} alignSelf="stretch">
              <Subtitle variant="bold" mb>
                Additional Information
              </Subtitle>

              <RowInfo>
                <Paragraph>Fee amount</Paragraph>
                <Paragraph>{`${parseIntoCurrencyString(
                  selectedRateContract?.feeAmount,
                  currencyByCode(selectedRateContract?.sellCurrency)?.precision
                )} ${selectedRateContract?.sellCurrency}`}</Paragraph>
              </RowInfo>

              <RowInfo>
                <Paragraph variant="bold">Prepayment amount</Paragraph>
                <Paragraph variant="bold">{`${parseIntoCurrencyString(
                  selectedRateContract?.prepaymentAmount ?? 0,
                  currencyByCode(selectedRateContract?.sellCurrency)?.precision
                )} ${selectedRateContract?.sellCurrency}`}</Paragraph>
              </RowInfo>

              <RowInfo alignItems="flex-start">
                <Paragraph variant="bold">Status</Paragraph>

                <Col style={{ textAlign: 'right' }}>
                  <Paragraph
                    style={{
                      color: statusInfo?.color,
                    }}
                  >
                    {` ${statusInfo?.text}`}
                  </Paragraph>

                  {isAwaitingPrepayment && (
                    <Paragraph>{`(Pay by ${dayjs(
                      selectedRateContract?.prepaymentPayByDate
                    ).format(DATE_FORMAT)})`}</Paragraph>
                  )}

                  {!isAwaitingPrepayment && (
                    <Paragraph>{`Use until ${dayjs(
                      selectedRateContract?.expiryDate
                    ).format(DATE_FORMAT)} (${getDaysLeft(
                      selectedRateContract
                    )} left)`}</Paragraph>
                  )}
                </Col>
              </RowInfo>
            </Col>
          )}
        </InnerDetailsWrapper>

        {isAwaitingPrepayment && (
          <Col mt>
            <Subtitle variant="bold" mb>
              {`Please pay the prepayment of ${
                currencyByCode(selectedRateContract?.sellCurrency)?.symbol
              } ${parseIntoCurrencyString(
                selectedRateContract?.prepaymentAmount,
                currencyByCode(selectedRateContract?.sellCurrency)?.precision
              )} to`}
            </Subtitle>

            <RowInfo>
              <Paragraph>Account name</Paragraph>
              <Paragraph variant="bold">The Currency Cloud Limited</Paragraph>
            </RowInfo>
            {selectedRateContract?.sellCurrency !== 'GBP' ? (
              <>
                <RowInfo>
                  <StaleParagraphMedium>SWIFT / BIC</StaleParagraphMedium>
                  <StaleButtonCopy
                    showIcon
                    onClick={() => {
                      navigator.clipboard.writeText(
                        fundingAccount?.bicSwift ?? ''
                      );
                      Notify.success('Copied SWIFT / BIC to clipboard!');
                    }}
                  >
                    <Paragraph variant="bold">
                      {fundingAccount?.bicSwift}
                    </Paragraph>
                  </StaleButtonCopy>
                </RowInfo>
                <RowInfo>
                  <Paragraph>IBAN</Paragraph>
                  <StaleButtonCopy
                    showIcon
                    onClick={() => {
                      navigator.clipboard.writeText(fundingAccount?.iban ?? '');
                      Notify.success('Copied IBAN to clipboard!');
                    }}
                  >
                    <Paragraph variant="bold">{fundingAccount?.iban}</Paragraph>
                  </StaleButtonCopy>
                </RowInfo>
              </>
            ) : (
              <>
                <RowInfo>
                  <Paragraph>Account Number</Paragraph>
                  <StaleButtonCopy
                    showIcon
                    onClick={() => {
                      navigator.clipboard.writeText(
                        fundingAccount?.accountNumber ?? ''
                      );
                      Notify.success('Copied account number to clipboard!');
                    }}
                  >
                    <Paragraph variant="bold">
                      {fundingAccount?.accountNumber}
                    </Paragraph>
                  </StaleButtonCopy>
                </RowInfo>
                <RowInfo>
                  <Paragraph>Sort Code</Paragraph>
                  <StaleButtonCopy
                    showIcon
                    onClick={() => {
                      navigator.clipboard.writeText(
                        fundingAccount?.sortCode ?? ''
                      );
                      Notify.success('Copied sort code to clipboard!');
                    }}
                  >
                    <Paragraph variant="bold">
                      {fundingAccount?.sortCode}
                    </Paragraph>
                  </StaleButtonCopy>
                </RowInfo>
              </>
            )}

            <RowInfo>
              <Paragraph>Reference</Paragraph>
              <StaleButtonCopy
                showIcon
                onClick={() => {
                  navigator.clipboard.writeText(
                    userEntity.externalRefs.ccShortReference
                  );
                  Notify.success('Copied reference to clipboard!');
                }}
              >
                <Paragraph variant="bold">
                  {userEntity.externalRefs.ccShortReference}
                </Paragraph>
              </StaleButtonCopy>
            </RowInfo>
            <RowInfo justifyContent="flex-end">
              <Paragraph error>
                Please ensure the reference is
                <br />
                added to your transfer details
              </Paragraph>
            </RowInfo>

            <RowInfo>
              <Paragraph>Bank name</Paragraph>
              <Paragraph>Barclays Bank plc</Paragraph>
            </RowInfo>
            <RowInfo>
              <Paragraph>Bank address</Paragraph>
              <Paragraph>1 Churchill Place, London, E14 546</Paragraph>
            </RowInfo>

            {selectedRateContract?.sellCurrency === 'GBP' && (
              <>
                <RowInfo>
                  <Paragraph>SWIFT / BIC</Paragraph>
                  <Paragraph>{fundingAccount?.bicSwift}</Paragraph>
                </RowInfo>
                <RowInfo>
                  <Paragraph>IBAN</Paragraph>
                  <Paragraph>{fundingAccount?.iban}</Paragraph>
                </RowInfo>
              </>
            )}
          </Col>
        )}

        {!isAwaitingPrepayment && (
          <Col mt>
            <Subtitle variant="bold" mb>
              Transfers{' '}
              <span style={{ color: theme.color.grey }}>
                {relatedTransfers.length}
              </span>
            </Subtitle>

            {!!relatedTransfers.length ? (
              <OverflowXWrapper>
                <TransferTable
                  data={orderBy(relatedTransfers, '_created', 'desc')}
                />
              </OverflowXWrapper>
            ) : null}
          </Col>
        )}
      </Col>
    </Popup>
  );
};

export default PrebookInner;
