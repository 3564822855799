import { useEffect } from 'react';
import {
  subscribeToInvoicesPaidViaHf,
  subscribeToInvoicesUnpaid,
} from 'services/firebase/invoices';
import { useStoreActions, useStoreState } from 'state';
import { IInvoice } from 'types';

const useSubscribeToInvoices = () => {
  const { entityId, integrationsSummary } = useStoreState(
    (state) => state.UserState
  );
  const { sortAndSetInvoices } = useStoreActions(
    (actions) => actions.InvoicesState
  );

  useEffect(() => {
    let unsubscribeFromInvoicesUnpaid;
    let unsubscribeFromInvoicesPaidViaHf;
    let retrievedInvoicesUnpaid: IInvoice[] = [];
    let retrievedInvoicesPaidViaHf: IInvoice[] = [];

    if (entityId) {
      const excludedCurrencies =
        integrationsSummary.xero?.settings?.excludedCurrencies;

      unsubscribeFromInvoicesUnpaid = subscribeToInvoicesUnpaid({
        entityId,
        callback: (invoices) => {
          retrievedInvoicesUnpaid = excludedCurrencies
            ? invoices.filter(
                (invoice) => !excludedCurrencies.includes(invoice.currency)
              )
            : invoices;

          sortAndSetInvoices([
            ...retrievedInvoicesUnpaid,
            ...retrievedInvoicesPaidViaHf,
          ]);
        },
      });
      unsubscribeFromInvoicesPaidViaHf = subscribeToInvoicesPaidViaHf({
        entityId,
        callback: (invoices) => {
          retrievedInvoicesPaidViaHf = excludedCurrencies
            ? invoices.filter(
                (invoice) => !excludedCurrencies.includes(invoice.currency)
              )
            : invoices;

          sortAndSetInvoices([
            ...retrievedInvoicesUnpaid,
            ...retrievedInvoicesPaidViaHf,
          ]);
        },
      });
    }

    return () => {
      unsubscribeFromInvoicesUnpaid?.();
      unsubscribeFromInvoicesPaidViaHf?.();
    };
  }, [
    entityId,
    integrationsSummary.xero?.settings?.excludedCurrencies,
    sortAndSetInvoices,
  ]);
};

export default useSubscribeToInvoices;
