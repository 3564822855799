import { AxiosPrivateFirebaseInstance } from 'settings';
import { IBalance } from 'types/balances';
import { openQuery } from 'utils';
import firestore from './firestore';

export interface SubscribeToBalancesParams {
  entityId: string;
  callback: (balances: IBalance[]) => void;
}

export const subscribeToBalances = ({
  entityId,
  callback,
}: SubscribeToBalancesParams) => {
  return firestore
    .collection('entities')
    .doc(entityId)
    .collection('balances')
    .onSnapshot(
      (query) => callback(openQuery(query)),
      (error) =>
        console.warn('Failed to subscribe to entity balances. Error: ', error)
    );
};

export const refreshBalances = async () =>
  AxiosPrivateFirebaseInstance.post('/balances/refresh');
