import React from 'react';
import { components } from 'react-select';

const MultiValueLabel = (props) => (
  <components.MultiValueLabel {...props}>
    {props.data.icon && (
      <svg width="24px" height="24px">
        <use xlinkHref={`#${props.data.icon}`} />
      </svg>
    )}
    {props.data.label}
  </components.MultiValueLabel>
);

export default MultiValueLabel;
