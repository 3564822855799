import React from 'react';
import { useHistory } from 'react-router-dom';

import { StaleNotification, ButtonStyleLink } from 'components';
import useIsAwaitingRegistration from 'hooks/useIsAwaitingRegistration';
import { Paragraph } from '../Typography/Typography';

type Props = {
  title?: string;
  bgColor?: string;
  style?: object;
};

const StaleRegisterCompanyNotification: React.FC<Props> = ({
  title = 'Full access required to continue',
  bgColor = '#121213',
  style,
}) => {
  const history = useHistory();
  const isAwaitingRegistration = useIsAwaitingRegistration();

  return isAwaitingRegistration ? (
    <StaleNotification
      icon="look-smile"
      bgColor={bgColor}
      title={title}
      cross={false}
      style={style}
    >
      <Paragraph color="white">
        To get full access to HedgeFlows please{' '}
        <ButtonStyleLink
          onClick={() => history.push('/app/company-registration')}
        >
          register your company
        </ButtonStyleLink>
      </Paragraph>
    </StaleNotification>
  ) : null;
};

export default StaleRegisterCompanyNotification;
