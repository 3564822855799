import styled, { css } from 'styled-components';

import { StaleTitleH5, StaleParagraphMedium } from 'components';

export const Wrapper = styled.div(
  ({ theme }) => css`
    position: fixed;
    background: rgb(18, 18, 19, 0.4);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;

    .popup {
      background: ${theme.color.white};
      padding: 16px 16px 27px;
      border-radius: 8px;
      position: relative;

      @media (min-width: ${theme.breakpoint.mediumMin}px) {
        padding: 24px 48px;
        max-width: 439px;
      }
    }

    ${StaleParagraphMedium} {
      margin-bottom: 14px;

      &.resend {
        color: ${theme.color.greyDark};
        margin-top: 10px;
        margin-bottom: 35px;

        button {
          display: inline;
        }
      }
    }

    ${StaleTitleH5} {
      margin-bottom: 16px;
    }

    .field {
      margin-bottom: 0 !important;
    }
  `
);
