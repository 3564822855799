import styled, { css } from 'styled-components';

export const Wrapper = styled.div<{
  icon?: boolean;
  locked?: boolean;
  error: boolean;
  currencyPrefix: boolean;
  filled: boolean;
  focused: boolean;
}>(
  ({
    theme,
    icon = false,
    locked = false,
    error,
    currencyPrefix = false,
    filled,
    focused,
  }) => css`
    color: ${theme.color.dark};
    position: relative;

    .wrap {
      height: 52px;
      position: absolute;
      width: 100%;
      top: 0;
      display: flex;
      align-items: flex-end;
      padding: ${icon ? '0 52px 0 16px' : '0 16px'};
      padding: ${locked && '0'};
      transition: padding ${theme.transition};
    }

    &.moving {
      &:before {
        content: '';
        width: 100%;
        display: block;
        border-radius: 6px;
        height: 52px;
        border: none;
        background: ${locked
          ? theme.color.transparent
          : theme.color.greyLight_3};
        color: ${theme.color.dark};
        transition: ${theme.transition};
        box-shadow: ${error && `inset 0 0 0 1px ${theme.color.red}`};
      }

      .label {
        position: absolute;
        left: 1px;
        right: 1px;
        padding: ${icon ? '0 52px 0 16px' : '0 16px'};
        padding: ${locked && '0'};
        top: 0;
        display: flex;
        align-items: center;
        height: 52px;
        cursor: text;
        font-size: 14px;
        transition: ${theme.transition};
        font-weight: ${locked && '700'};
        color: ${locked && theme.color.greyDark};
        color: ${error && theme.color.red};
      }

      .input {
        flex: 0 1 auto;
        position: relative;
        left: 1px;
        right: 1px;
        bottom: 0;
        height: 22px;
        width: calc(100% - 2px);
        box-shadow: inset 0 30px 0 ${theme.color.transparent};
        background: ${theme.color.transparent};
        -webkit-text-fill-color: ${theme.color.dark};
        transition: padding ${theme.transition}, box-shadow 0s 0s;
        padding-left: ${currencyPrefix && filled && '4px'};
      }

      ${focused &&
      css`
        &:before {
          box-shadow: 0 0 0 1px ${theme.color.emeraldDark};
          box-shadow: ${error && `inset 0 0 0 1px ${theme.color.red}`};
        }
      `}
      ${(filled || focused) &&
      css`
        .label {
          top: ${locked ? '-12px' : '-8px'};
          font-size: ${locked ? '14px' : '12px'};
        }

        .input {
          bottom: ${locked ? '3px' : '5px'};
          box-shadow: inset 0 30px 0
            ${locked ? 'none' : theme.color.greyLight_3};
          transition: padding ${theme.transition}, box-shadow 0s 0s;
        }
      `}

      .icon {
        position: absolute;
        right: 16px;
        top: 14px;
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0 6px 6px 0;

        &:hover {
          svg {
            opacity: 0.6;
          }
        }

        svg {
          transition: ${theme.transition};
        }
      }
    }

    &.static {
      .input {
        padding: 0 16px;
        width: 100%;
        height: 52px;
        border: 1px solid ${theme.color.transparent};
        background: ${theme.color.greyLight_3};
        border-radius: 6px;
        transition: ${theme.transition};
      }

      .label {
        font-size: 16px;
        line-height: 24px;
        font-weight: 700;
        max-width: 100%;
        display: block;
        margin-bottom: 4px;

        @media (min-width: 1025px) and (min-height: 568px) {
          font-size: 18px;
          line-height: 24px;
        }
      }

      &.focused .input {
        box-shadow: 0 0 0 1px ${theme.color.emeraldDark};
      }
    }

    .input {
      font-weight: 500;
      font-size: 14px;
      box-shadow: inset 0 56px 0 ${theme.color.greyLight_3};
    }

    .hexagon-list {
      margin-top: 8px;
    }

    .error {
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      margin: 3px 0;
      color: ${theme.color.red};
    }

    .prefix {
      opacity: ${filled ? 1 : 0};
      max-width: ${!filled ? '0' : '100vw'};
      transition: opacity ${filled && theme.transition} 0s,
        max-width ${theme.transition} 0s;
      position: relative;
      height: 22px;
      bottom: ${locked ? '4px' : '6px'};
    }
  `
);
