import { AxiosPrivateFirebaseInstance } from 'settings';
import { IResponse } from 'types';
import { HF_GURU_TASKS, IHfGuru } from 'types/entities';

interface RegistrationSetting {
  entityCurrency: string;
  objectiveGlobalBusinessAccount: boolean;
  objectivePaymentAutomation: boolean;
  objectiveFxRiskAndCostManagement: boolean;
  accountingSystem?: string;
  howDidYouFindUs?: string;
}

export const saveRegistrationSettings = async (
  payload: RegistrationSetting
) => {
  return AxiosPrivateFirebaseInstance.put<IResponse<RegistrationSetting>>(
    '/entities/settings',
    payload
  );
};

export const getHFGuruStatuses = async () => {
  return AxiosPrivateFirebaseInstance.get<IResponse<IHfGuru>>('/entities/guru');
};

export interface UpdateHFGuruStatusesParams {
  task: HF_GURU_TASKS;
  close: boolean;
}

/**
 * Represents updateHFGuruStatuses.
 * @constructor
 * @param {string} task - for healthCheck close === false => healthCheck.completed = true
 * @param {boolean} close - if close === true => task.deferUntil + 1 month
 */
export const updateHFGuruStatuses = async ({
  task,
  close,
}: UpdateHFGuruStatusesParams) =>
  AxiosPrivateFirebaseInstance.post<IResponse<IHfGuru>>(
    `/entities/guru/${task}/${close}`
  );

export interface DeferSuggestedFollowedCurrencyParams {
  entityId: string;
  currencyCode: string;
}

export const deferSuggestedFollowedCurrency = async ({
  entityId,
  currencyCode,
}: DeferSuggestedFollowedCurrencyParams) =>
  AxiosPrivateFirebaseInstance.post<IResponse>(
    `/entities/${entityId}/deferSuggestedFollowedCurrency/${currencyCode}`
  );
