import { PropsWithChildren } from 'react';
import { CellProps } from 'react-table';
import { TableExpandButton } from './Table.styles';

const ExpanderCell = <T extends object>({
  row,
}: PropsWithChildren<CellProps<T>>) => {
  //@ts-ignore
  const { onClick, ...rest } = row.getToggleRowExpandedProps();

  return (
    <TableExpandButton
      {...rest}
      onClick={(e) => {
        e.stopPropagation();
        onClick();
      }}
    >
      <svg width="24px" height="24px">
        <use xlinkHref={row.isExpanded ? '#show-less-ico' : '#show-more-ico'} />
      </svg>
    </TableExpandButton>
  );
};

export default ExpanderCell;
