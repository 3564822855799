import { ExtendPropertyType, ICurrency } from 'types';

export type TAccountingIntegration = 'xero' | 'quickBooks' | 'sage50' | null;

export type IAvailableIntegrationIds =
  | 'csv'
  | 'xero'
  | 'codat'
  | 'sage50'
  | 'dynamics'
  | 'orderWise';

export type IAvailableIntegrationNames =
  | 'Xero'
  | 'Sage 50'
  | 'QuickBooks Sandbox'
  | 'QuickBooks'
  | 'Dynamics 365 Business Central'
  | 'CSV'
  | 'OrderWise';

export const codatPlatformNames = [
  'Sage 50 (UK)',
  'QuickBooks Online Sandbox',
  'QuickBooks Online',
  'Dynamics 365 Business Central',
] as const;

export type TCodatPlatformNames = typeof codatPlatformNames[number];

export type TPlatformNames = TCodatPlatformNames | 'Xero';

export const platformNamesToLogSourceNames: Record<
  TPlatformNames,
  'xero' | 'codat'
> = {
  'Sage 50 (UK)': 'codat',
  'QuickBooks Online Sandbox': 'codat',
  'QuickBooks Online': 'codat',
  'Dynamics 365 Business Central': 'codat',
  Xero: 'xero',
};

export interface IAvailableIntegration {
  id: IAvailableIntegrationIds;
  name: IAvailableIntegrationNames;
  img?: string;
  type: INTEGRATION_TYPE;
  platformNames?: TCodatPlatformNames[];
  available: boolean;
  codatShortCode?: string; // used by codat connect to jump to specific integration in popup
}

export interface IIntegration {
  id?: string;
  xero?: IXeroStatus;
  sage50?: IIntegrationStatus;
  orderWise?: IIntegrationStatus;
  codat?: ICodatStatus;
}

export enum SYNC_STATUS {
  queued = 'queued',
  inProgress = 'inProgress',
  complete = 'complete',
  error = 'error',
}

export interface IIntegrationLog {
  entity: string;
  entityId: string;
  link: string;
  operation: string;
  timestamp: number;
  _source: 'xero' | 'codat';
}

export interface IIntegrationStatus {
  connected: boolean;
  connectionName?: string;
  permissions?: Record<string, boolean>;
  reauthenticate?: boolean;
  syncMessage?: string;
  syncStatus?: SYNC_STATUS;
  deepLinkKey?: string;
  platformName?: TCodatPlatformNames; // used by codat to differentiate platform
  settings?: Record<string, unknown>;
}

export interface IXeroStatus
  extends Omit<IIntegrationStatus, 'settings' | 'permissions'> {
  settings?: IXeroSettings;
  permissions?: IIntegrationPermissions;
}

export interface ICodatStatus
  extends Omit<IIntegrationStatus, 'settings' | 'permissions'> {
  settings?: ICodatSettings;
  permissions?: IIntegrationPermissions;
}

export interface IXeroSettings {
  settingsType: 'Xero';
  monthsInThePastToQueryFor?: number;
  excludedCurrencies?: ICurrency['code'][];
  bankFeesAccount?: IXeroAccount;
  /** @deprecated Use {@link accounts} instead */
  mainSellCurrencyAccount?: IXeroAccount;
  newAccountCurrencies?: string[];
  accounts?: Record<string, IXeroAccount>; // maps foreign currencies to accounts on Xero
}

export type ICodatSettings = {
  settingsType: 'Codat';
  monthsInThePastToQueryFor?: number;
  excludedCurrencies?: ICurrency['code'][];
  bankFeesAccount?: ICodatAccount;
  /** @deprecated Use {@link accounts} instead */
  mainSellCurrencyAccount?: ICodatAccount;
  newAccountCurrencies?: string[];
  accounts?: Record<string, ICodatAccount>; // maps foreign currencies to accounts on Xero
};

export type TXeroSettingsInput = ExtendPropertyType<
  IXeroSettings,
  'bankFeesAccount' | 'mainSellCurrencyAccount',
  null
>;
export type TCodatSettingsInput = ExtendPropertyType<
  ICodatSettings,
  'bankFeesAccount' | 'mainSellCurrencyAccount',
  null
>;

export interface ICodatAccount {
  id: string;
  currency: string;
  isBankAccount: boolean;
  modifiedDate: string;
  name: string;
  status: string;
  type: TCodatAccountTypes;
}

export interface IXeroAccount {
  accountID: string;
  name: string;
  type: string;
  code: string;
  _class: string;
  currencyCode?: string;
}

export type TCodatAccountTypes =
  | 'Asset'
  | 'Expense'
  | 'Equity'
  | 'Liability'
  | 'Income'
  | 'Unknown';

export interface IIntegrationPermissions {
  global: boolean;
  invoices?: boolean;
  payments?: boolean;
  purchaseOrders?: boolean;
  bills?: boolean;
  accounts?: boolean;
  bankFees?: boolean;
}

export interface IAccountData {
  id: string;
  value: IXeroAccount | ICodatAccount | null;
  name: string;
}

export type TIntegrationDocIds = 'xero' | 'codat';

export enum INTEGRATION_TYPE {
  accounting = 'accounting',
  business = 'business',
}

export const isCodatAccount = (
  account: IXeroAccount | ICodatAccount
): account is ICodatAccount => (account as ICodatAccount).id !== undefined;

export const isXeroAccount = (
  account: IXeroAccount | ICodatAccount
): account is IXeroAccount => (account as IXeroAccount).accountID !== undefined;

export const isXeroPlatform = (
  platformName: TPlatformNames
): platformName is 'Xero' => platformName === 'Xero';

export const isCodatPlatform = (
  platformName: TPlatformNames
): platformName is TCodatPlatformNames =>
  codatPlatformNames.includes(platformName as TCodatPlatformNames);

export const isXeroSettings = (
  settings: TXeroSettingsInput | TCodatSettingsInput
): settings is TXeroSettingsInput =>
  (settings as TXeroSettingsInput).settingsType === 'Xero';

export const isCodatSettings = (
  settings: TXeroSettingsInput | TCodatSettingsInput
): settings is TCodatSettingsInput =>
  (settings as TCodatSettingsInput).settingsType === 'Codat';
