import styled, { css } from 'styled-components';
import { isMobile } from 'react-device-detect';

import { StaleOverflowScroll } from 'components';

export const Wrapper = styled.div(
  ({ theme }) => css`
    width: 100%;
    height: calc(var(--vh, 1vh) * 100);

    display: ${!isMobile && 'flex'};

    .link-back {
      font-size: 28px;
      line-height: 36px;
      font-weight: 700;
      margin: 0 0 28px -32px;
      display: flex;
      align-items: center;

      svg {
        width: 40px;
        height: 40px;
        margin-right: 12px;
      }
    }

    ${!isMobile
      ? css`
          ${StaleOverflowScroll} {
            height: 100%;
            box-shadow: 0 4px 9px -4px rgba(0, 0, 0, 0.1);
            border-radius: 8px;
          }

          .col {
            flex: 0 0 50%;
            max-width: 50%;
            display: flex;
            flex-direction: column;

            &:first-of-type {
              padding-right: 16px;

              @media (min-width: ${theme.breakpoint.largeMin}px) {
                padding-right: ${isMobile ? '31px' : '16px'};
              }
            }

            &:not(:first-of-type) {
              padding-left: 8px;

              @media (min-width: ${theme.breakpoint.largeMin}px) {
                padding-left: 16px;
              }
            }
          }
        `
      : css`
          padding-top: 56px;

          @media (min-width: ${theme.breakpoint.largeMin}px) {
            padding-top: 68px;
          }
        `}
  `
);
