import { FC } from 'react';
import { StaleButtonCopy, Paragraph, Row, StaleTextHint } from 'components';
import { Notify } from 'utils';

interface OwnProps {
  title: string;
  text: string;
  notification?: string;
  variant?: 'small' | 'medium' | 'copy';
}

const StaleFinalStepInfoRow: FC<OwnProps> = ({
  title,
  text,
  notification,
  variant = 'medium',
}) => {
  switch (variant) {
    case 'medium':
      return (
        <Row>
          <Paragraph variant="thin">{title}</Paragraph>
          <Paragraph variant="bold">{text}</Paragraph>
        </Row>
      );
    case 'small':
      return (
        <Row>
          <StaleTextHint>{title}</StaleTextHint>
          <StaleTextHint>{text}</StaleTextHint>
        </Row>
      );
    case 'copy':
      return (
        <Row>
          <Paragraph variant="thin">{title}</Paragraph>
          <div>
            <StaleButtonCopy
              showIcon
              onClick={() => {
                navigator.clipboard.writeText(text);
                Notify.success(notification ?? '');
              }}
            >
              <Paragraph variant="bold">{text}</Paragraph>
            </StaleButtonCopy>
          </div>
        </Row>
      );
  }
};

export default StaleFinalStepInfoRow;
