import styled from 'styled-components';
import { Row } from '../Row/Row';
import { spacingCss, SpacingCssProps } from '../Spacing/Spacing.styles';

const Field = styled(Row).attrs<SpacingCssProps>(({ theme }) => ({
  mbValue: theme.spacing.m,
  mtValue: theme.spacing.m,
  mrValue: theme.spacing.m,
  mlValue: theme.spacing.m,
}))<{ fullWidth?: boolean; fluid?: boolean }>`
  max-width: ${({ fluid }) => (fluid ? '100%' : '342px')};
  flex: ${({ flex }) => (flex ? flex : 1)};
  width: ${({ fullWidth }) => (fullWidth ? '342px' : 'unset')};
  align-items: flex-start;
  align-self: stretch;

  ${spacingCss}
`;

export default Field;
