import styled, { css } from 'styled-components';

interface HorizontalStepsItemProps {
  passed?: boolean;
  disabled?: boolean;
  current?: boolean;
}

export const HorizontalStepsItem = styled.div<HorizontalStepsItemProps>`
  font-size: ${({ theme }) => theme.fontSize.m};
  color: ${({ current, theme }) =>
    current ? theme.color.emeraldDark : theme.color.grey};
  position: relative;
  padding: 0 ${({ theme }) => theme.spacing.s};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};

  &:last-child {
    padding-right: 0;
  }

  ${({ passed, theme }) =>
    passed &&
    css`
      color: ${theme.color.white};

      &:hover {
        opacity: 0.8;
      }
    `}
`;
