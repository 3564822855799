import styled, { css } from 'styled-components';

export const Wrapper = styled.div<{
  icon: boolean;
  locked: boolean;
  error: boolean;
  isFocused: boolean;
  isPrefix: boolean;
  isDisabled?: boolean;
}>(
  ({ theme, icon, locked, error, isFocused, isPrefix, isDisabled }) => css`
    color: ${theme.color.dark};
    position: relative;
    width: 100%;

    .error {
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      margin: 3px 0;
      color: ${theme.color.red};
    }

    .wrap {
      height: 52px;
      position: absolute;
      width: 100%;
      top: 0;
      display: flex;
      align-items: flex-end;
      padding: ${icon ? '0 52px 0 16px' : '0 16px'};
      padding: ${locked && '0'};
      transition: padding ${theme.transition};
    }

    &.moving {
      &:before {
        content: '';
        width: 100%;
        display: block;
        border-radius: 6px;
        height: 52px;
        border: none;
        background: ${locked
          ? theme.color.transparent
          : theme.color.greyLight_3};
        color: ${theme.color.dark};
        transition: ${theme.transition};
        box-shadow: ${error && `inset 0 0 0 1px ${theme.color.red}`};
      }

      .label {
        position: absolute;
        left: 1px;
        right: 1px;
        padding: ${icon ? '0 52px 0 16px' : '0 16px'};
        padding: ${locked && '0'};
        top: 0;
        display: flex;
        align-items: center;
        height: 52px;
        cursor: text;
        font-size: 14px;
        transition: ${theme.transition};
        font-weight: ${locked && '700'};
        color: ${locked && theme.color.greyDark};
        color: ${error && theme.color.red};
      }

      .input {
        position: absolute;
        left: 1px;
        right: 1px;
        height: 22px;
        top: 14px;
        width: calc(100% - 2px);
        box-shadow: inset 0 30px 0 ${theme.color.transparent};
        background: ${theme.color.transparent};
        -webkit-text-fill-color: ${theme.color.dark};
        transition: padding ${theme.transition}, box-shadow 0s 0s;
        padding: ${icon ? '0 52px 0 16px' : '0 16px'};
        padding: ${locked && '0'};
        padding-left: ${isPrefix && isFocused && '40px'};
        text-align: left;
      }

      &.focused:before {
        box-shadow: inset 0 0 0 1px ${theme.color.emeraldDark};
        box-shadow: ${error && `inset 0 0 0 1px ${theme.color.red}`};
      }

      &.filled,
      &.focused {
        .label {
          top: ${locked ? '-12px' : '-8px'};
          font-size: ${locked ? '14px' : '12px'};
        }
        .input {
          top: ${locked ? '26px' : '24px'};
          box-shadow: inset 0 30px 0
            ${locked ? 'none' : theme.color.greyLight_3};
          transition: padding ${theme.transition}, box-shadow 0.15s 0.15s;
        }
      }

      .icons-wrapper {
        position: absolute;
        right: 16px;
        top: 14px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0 6px 6px 0;

        svg {
          transition: ${theme.transition};
        }

        button {
          max-height: 100%;

          &:hover,
          &:focus {
            svg {
              fill: ${theme.color.emeraldDark};
            }
          }
        }

        .icon {
          margin-left: 8px;
        }
      }
    }

    &.static {
      .wrap {
        position: relative;
        padding: 0;
      }

      .input {
        padding: 0 16px;
        width: 100%;
        height: 52px;
        border: 1px solid ${theme.color.transparent};
        background: ${theme.color.greyLight_3};
        border-radius: 6px;
        transition: ${theme.transition};
        box-shadow: inset 0 0 0 1px ${theme.color.greyLight_3};
        text-align: left;
      }

      .label {
        font-size: 16px;
        line-height: 24px;
        font-weight: 700;
        max-width: 100%;
        display: block;
        margin-bottom: 4px;

        @media (min-width: 1025px) and (min-height: 568px) {
          font-size: 18px;
          line-height: 24px;
        }
      }

      &.focused .input {
        box-shadow: inset 0 0 0 1px ${theme.color.emeraldDark};
      }
    }

    .input {
      font-weight: 500;
      font-size: 14px;
      box-shadow: inset 0 56px 0 ${theme.color.greyLight_3};
      cursor: ${isDisabled && 'default'};
    }

    .label {
      color: ${isDisabled && theme.color.grey};
      cursor: ${isDisabled && 'default'};
    }

    .hexagon-list {
      margin-top: 8px;
    }

    .loader {
      animation: loading 1.2s linear infinite;
    }

    @keyframes loading {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }

    .prefix {
      opacity: ${isFocused ? 1 : 0};
      max-width: ${!isFocused ? '0' : '100vw'};
      transition: opacity ${isFocused && theme.transition} 0s,
        max-width ${theme.transition} 0s;
      position: relative;
      height: 22px;
      bottom: ${locked ? '4px' : '6px'};
      z-index: 1;
    }
  `
);
