import styled from 'styled-components';

const ChartPoint = styled.circle<{ active?: boolean }>`
  opacity: ${({ active }) => (active ? 1 : 0.8)};
  cursor: pointer;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 1;
  }
`;

export default ChartPoint;
