import { FC, useMemo, useEffect, useState, useCallback } from 'react';
import { IInvoice } from 'types';
import { useStoreState, useStoreActions } from 'state';
import { appendRateAndProfitAndLossToInvoice } from 'utils/invoices';
import InvoicesTableShared from 'components/shared/InvoicesTable/InvoicesTable';
import { IInvoiceForTable } from 'components/shared/InvoicesTable/types';
import { Row, StaleLoader } from 'components';
import { detectCurrenciesFromInvoices } from 'pages/Invoices/utils';
import {
  deletePaymentRunInvoice,
  getDraftPaymentRun,
} from 'services/paymentRuns';
import { getInvoicesByIdArray } from 'services/firebase/invoices';
import Button from 'components/shared/Button/Button';
import { errorHandler } from 'utils/errors';
import { ActionButton } from 'components/shared/ActionButton/ActionButton.styles';
import { useHistory } from 'react-router';
import { getPaymentRunPageLink } from 'utils/links';
import useRates from 'hooks/useRates';
import useUrlValues from 'hooks/useUrlValues';

const PaymentRunInvoicesTable: FC = () => {
  const history = useHistory();
  const { entityDefaultCurrency, entityId } = useStoreState(
    ({ UserState }) => UserState
  );
  const { isUpdatingPaymentRun } = useStoreState(
    (state) => state.PaymentRunsState
  );
  const { setState } = useStoreActions((state) => state.PaymentRunsState);

  const [paymentRunId, setPaymentRunId] = useState('');
  const [invoices, setInvoices] = useState<IInvoice[]>([]);
  const [isLoadingInvoices, setIsLoadingInvoices] = useState(true);
  const detectedCurrencies = useMemo(
    () => detectCurrenciesFromInvoices(invoices, entityDefaultCurrency),
    [entityDefaultCurrency, invoices]
  );
  const { ratesByCurrency } = useRates({ currencies: detectedCurrencies });
  const { currency } = useUrlValues('currency');

  useEffect(() => {
    if (entityId) {
      const getPaymentRunInvoices = async () => {
        try {
          const { data: response } = await getDraftPaymentRun({ entityId });

          if (!response.data) {
            return;
          }

          const paymentRunInvoices = await getInvoicesByIdArray(
            response.data.instructions.invoiceIds
          );

          setPaymentRunId(response.data.id);
          setInvoices(paymentRunInvoices);
        } catch (error) {
          console.error('Failed to get payment run invoices: ', error);
        } finally {
          setIsLoadingInvoices(false);
        }
      };

      getPaymentRunInvoices();
    }
  }, [entityId]);

  const onRemoveInvoice = useCallback(
    async (paymentRunInvoiceId: string) => {
      try {
        setState(['isUpdatingPaymentRun', true]);

        const { data: response } = await deletePaymentRunInvoice({
          paymentRunId,
          paymentRunInvoiceId,
        });

        if (response.data) {
          const paymentRunInvoices = await getInvoicesByIdArray(
            response.data.instructions.invoiceIds
          );
          setInvoices(paymentRunInvoices);
        }
      } catch (error) {
        errorHandler(error);
      } finally {
        setState(['isUpdatingPaymentRun', false]);
      }
    },
    [paymentRunId, setState]
  );

  // TODO: consider moving into BE query
  const invoicesForTable = useMemo(
    () =>
      invoices.reduce<IInvoiceForTable[]>((acc, invoice) => {
        const invoiceWithRateAndProfitAndLoss = appendRateAndProfitAndLossToInvoice(
          invoice,
          ratesByCurrency?.[invoice.currency]
        );

        if (currency && currency !== 'all' && currency !== invoice.currency) {
          return acc;
        }

        acc.push(invoiceWithRateAndProfitAndLoss);

        return acc;
      }, []),
    [currency, invoices, ratesByCurrency]
  );

  if (isLoadingInvoices) {
    return <StaleLoader size="large" />;
  }

  return (
    <InvoicesTableShared
      isVirtualized
      data={invoicesForTable}
      showFooter={!!invoices.length}
      selectable={false}
      renderActionCell={({ id }) => (
        <ActionButton
          disabled={isUpdatingPaymentRun}
          onClick={() => onRemoveInvoice(id)}
        >
          Remove
        </ActionButton>
      )}
      renderFooterContent={
        invoices.length ? (
          <Row>
            <Button
              disabled={isUpdatingPaymentRun}
              onClick={() =>
                history.push(
                  getPaymentRunPageLink({
                    step: '1',
                  })
                )
              }
            >
              Continue
            </Button>
          </Row>
        ) : null
      }
    />
  );
};

export default PaymentRunInvoicesTable;
