import { FC, useState, useEffect, ChangeEvent } from 'react';
import { CalendarProps } from 'react-calendar';
import { createPortal } from 'react-dom';
import { UseControllerOptions, useController } from 'react-hook-form';
import { IMaskInput } from 'react-imask';
import IMask from 'imask';
import { Wrapper } from './StaleInputDate.styles';
import dayjs from 'dayjs';
import { PopperProps, usePopper } from 'react-popper';
import OutsideClickHandler from 'react-outside-click-handler';
import Icon from '../Icon/Icon';
import Calendar from '../Calendar/Calendar';
import { DATE_FORMAT } from 'variables';
import { useTheme } from 'styled-components';

interface OwnProps
  extends UseControllerOptions,
    Pick<PopperProps<unknown>, 'modifiers' | 'placement' | 'strategy'> {
  id: string;
  type?: HTMLInputElement['type'];
  label?: string;
  view?: 'static' | 'moving';
  icon?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => any;
  locked?: boolean;
  placeholder?: string;
  error?: string;
  onChangeCallback?: (value: Date) => any;
  calendarProps?: CalendarProps;
  disabledDates?: string[];
  withCalendar?: boolean;
  isRequired?: boolean;
  defaultValue?: string;
}

const StaleInputDate: FC<OwnProps> = ({
  id,
  name = '',
  label = '',
  view = 'static',
  type = 'text',
  defaultValue = '',
  icon = 'calendar-ico',
  locked = false,
  control,
  error = '',
  placeholder = '',
  onChangeCallback,
  placement = 'auto',
  strategy = 'absolute',
  modifiers = [],
  disabledDates = [],
  calendarProps = {
    minDetail: 'year',
  },
  withCalendar = true,
  isRequired = true,
}) => {
  const theme = useTheme();

  const {
    field: { ref, value, onChange },
  } = useController({
    name,
    control,
    rules: {
      validate: (valueToValidate: string) => {
        if (!isRequired) {
          return true;
        }

        if (!valueToValidate) {
          return 'Date is required.';
        }

        if (!dayjs(valueToValidate, DATE_FORMAT).isValid()) {
          return 'Invalid date.';
        }

        if (
          disabledDates.includes(
            dayjs(valueToValidate, DATE_FORMAT).format('YYYY-MM-DD')
          )
        ) {
          return 'Date cannot be selected. Select another one please.';
        }

        const { minDate, maxDate } = calendarProps;

        if (
          minDate &&
          dayjs(valueToValidate, DATE_FORMAT).isBefore(dayjs(minDate), 'day')
        ) {
          return 'Date is before the minimum date.';
        }

        if (
          maxDate &&
          dayjs(valueToValidate, DATE_FORMAT).isAfter(maxDate, 'day')
        ) {
          return 'Date is after the maximum date.';
        }

        return true;
      },
    },
    defaultValue,
  });

  const [actionClasses, setActionClasses] = useState('');
  const [show, setShow] = useState(false);

  const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 8],
        },
      },
      ...modifiers,
    ],
    placement,
    strategy,
  });

  const onSave = (valueToSave: Date | Date[]) => {
    if (!Array.isArray(valueToSave)) {
      onChange(dayjs(valueToSave).format(DATE_FORMAT));
    }

    setShow(false);
  };

  const onClose = () => {
    setShow(false);
  };

  const renderContent = () => {
    const {
      defaultActiveStartDate,
      minDate,
      maxDate,
      minDetail,
    } = calendarProps;

    const content = (
      <OutsideClickHandler onOutsideClick={onClose}>
        <div
          ref={setPopperElement}
          style={{ ...styles.popper, zIndex: theme.zIndex.calendar }}
          {...attributes.popper}
        >
          <Calendar
            formatShortWeekday={(_, val) => dayjs(val).format('dd')}
            defaultActiveStartDate={defaultActiveStartDate}
            minDate={minDate}
            maxDate={maxDate}
            minDetail={minDetail}
            nextLabel={<Icon icon="calendar-arrow-next" />}
            prevLabel={<Icon icon="calendar-arrow-prev" />}
            tileDisabled={(value) =>
              disabledDates.includes(dayjs(value.date).format('YYYY-MM-DD'))
            }
            defaultValue={dayjs(value, DATE_FORMAT).toDate()}
            onSave={onSave}
            onClose={onClose}
          />
        </div>
      </OutsideClickHandler>
    );

    return createPortal(content, document.body);
  };

  useEffect(() => {
    if (value) {
      setActionClasses('filled');
    }
  }, [value]);

  return (
    <Wrapper
      className={`${actionClasses} ${view}`}
      icon={withCalendar}
      locked={locked}
      error={!!error}
      ref={setReferenceElement}
    >
      {label && (
        <label className="label" htmlFor={id}>
          {label}
        </label>
      )}

      <IMaskInput
        mask={Date}
        inputMode="decimal"
        className="input"
        value={value}
        format={(date) => dayjs(date).format(DATE_FORMAT)}
        parse={(str) => dayjs(str, DATE_FORMAT).toDate()}
        onAccept={(value) => {
          onChange(value);
          // @ts-ignore
          onChangeCallback?.(value);
        }}
        lazy={!actionClasses}
        autofix
        pattern="d `m `Y"
        blocks={{
          d: {
            mask: IMask.MaskedRange,
            from: 1,
            to: 31,
            maxLength: 2,
            placeholderChar: 'D',
          },
          m: {
            mask: IMask.MaskedRange,
            from: 1,
            to: 12,
            maxLength: 2,
            placeholderChar: 'M',
          },
          Y: {
            mask: IMask.MaskedRange,
            from: 1900,
            to: 9999,
            placeholderChar: 'Y',
          },
        }}
        ref={ref}
        id={id}
        type={type}
        placeholder={view === 'static' ? label : placeholder}
        autoComplete="new-password"
        disabled={locked}
        onFocus={() => setActionClasses(label ? 'focused filled' : 'focused')}
        onBlur={(e) =>
          e.target.value.length && label
            ? setActionClasses('filled')
            : setActionClasses('')
        }
      />

      {error && <span className="error">{error}</span>}

      {withCalendar && (
        <button type="button" className="icon" onClick={() => setShow(true)}>
          <Icon icon={icon} />
        </button>
      )}

      {show && renderContent()}
    </Wrapper>
  );
};

export default StaleInputDate;
