import { useStoreState } from 'state';
import useCodat from './useCodat';
import useQuickBooks from './useQuickBooks';
import useSage50 from './useSage50';
import useXero from './useXero';

const useIsIntegratedWithAnything = () => {
  const { isIntegratedWithSage50 } = useSage50();
  const { isIntegratedWithQuickBooks } = useQuickBooks();
  const { isIntegrated: isIntegratedWithXero } = useXero();
  const { isIntegratedViaCodat } = useCodat();
  const { integrationsSummary } = useStoreState((state) => state.UserState);

  return (
    isIntegratedWithSage50 ||
    isIntegratedWithQuickBooks ||
    isIntegratedWithXero ||
    isIntegratedViaCodat ||
    Object.values(integrationsSummary).find((item) => item.connected)
  );
};

export default useIsIntegratedWithAnything;
