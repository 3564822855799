import { FC, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { IInvoice } from 'types';
import { generateInvoiceTableColumns } from './utils';
import Table, { TableProps } from '../Table/Table';
import useXero from 'hooks/useXero';

export interface OwnProps
  extends Pick<TableProps<IInvoice>, 'isVirtualized' | 'data'> {
  data: IInvoice[];
}

const TransferInvoicesTable: FC<OwnProps> = ({ data, isVirtualized }) => {
  const history = useHistory();
  const { deepLinkKey } = useXero();

  const goToInvoice = useCallback(
    (record) => history.push(`/app/invoices/${record.id}?transferFlow=true`),
    [history]
  );

  const invoiceTableColumns = useMemo(
    () => generateInvoiceTableColumns(goToInvoice, deepLinkKey ?? ''),
    [goToInvoice, deepLinkKey]
  );

  return (
    <Table<IInvoice>
      onRowClick={goToInvoice}
      data={data}
      columns={invoiceTableColumns}
      sortable
      isVirtualized={isVirtualized}
      defaultRowHeight={48}
    />
  );
};
export default TransferInvoicesTable;
