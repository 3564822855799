import { FC, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { isMobile } from 'react-device-detect';

import {
  StaleTitleH1,
  StaleTitleH5,
  StaleParagraphMedium,
  StaleBtnGroup,
  Button,
  ButtonStyleLink,
  StaleInput,
  StaleCarousel,
  StaleOverflowScroll,
} from 'components';
import { Firebase } from 'services';
import { useStoreActions, useStoreState } from 'state';
import { SignUpWrap, SignUpNav, SignUpContent } from '..';
import { Notify, getSubstractedEmail } from 'utils';
import { useHistory } from 'react-router-dom';
import { ERROR_MESSAGES } from 'variables';

type Inputs = {
  verificationCode: string;
};

interface OwnProps {
  email: string;
  password: string;
  onContinueHandler: () => void;
  onCloseHandler: () => void;
  onNavItemClick: (stepNumber?: number) => void;
}

const StepTwo: FC<OwnProps> = ({
  email,
  password,
  onContinueHandler,
  onCloseHandler,
  onNavItemClick,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const { userId, user } = useStoreState((state) => state.UserState);
  const { createAuthUser, signOut } = useStoreActions(
    (actions) => actions.UserState
  );

  const {
    control,
    formState: { isValid },
    handleSubmit,
  } = useForm<Inputs>({
    mode: 'onChange',
  });

  useEffect(() => {
    if (!userId) {
      createAuthUser({
        email,
        password,
      });
    }
  }, [createAuthUser, email, password, userId]);

  useEffect(() => {
    if (userId) {
      Firebase.generateVerificationCode();
    }
  }, [userId]);

  const onSubmit = async (values) => {
    setIsLoading(true);

    const verificationResponse = await Firebase.verifyVerificationCode({
      code: values.verificationCode.trim(),
    });

    if (!verificationResponse?.success) {
      Notify.error(
        verificationResponse?.message || 'Verification code is not valid.'
      );
      setIsLoading(false);
      return;
    }

    setIsLoading(false);

    onContinueHandler();
  };

  const onError = (errors) => {
    // TODO: discuss error handling
  };

  return (
    <SignUpWrap>
      <SignUpNav>
        {isMobile ? (
          <>
            <StaleTitleH5>Sign up</StaleTitleH5>

            <ButtonStyleLink className="back previous" onClick={onCloseHandler}>
              <svg width="24px" height="24px">
                <use xlinkHref="#cross-ico" />
              </svg>
            </ButtonStyleLink>

            <div className="row">
              <div className="item passed" onClick={() => onNavItemClick()}>
                Create account
              </div>
              <div className="item current">Confirm email</div>
              <div className="item">Basic details</div>
            </div>
          </>
        ) : (
          <>
            <StaleTitleH5>Let’s sign you up to HedgeFlows</StaleTitleH5>

            <StaleParagraphMedium>
              You are about 10 minutes away from having access to our best
              features:{' '}
            </StaleParagraphMedium>

            <StaleCarousel />
          </>
        )}
      </SignUpNav>

      <SignUpContent className="rounded">
        <StaleOverflowScroll>
          <div className="block">
            {isMobile ? (
              <StaleTitleH5>Confirm your email</StaleTitleH5>
            ) : (
              <>
                <ButtonStyleLink className="cross" onClick={onCloseHandler}>
                  <svg width="24" height="24">
                    <use xlinkHref="#cross-ico" />
                  </svg>
                </ButtonStyleLink>

                <ButtonStyleLink
                  className="back"
                  onClick={() => {
                    signOut();
                    history.push('/');
                  }}
                >
                  <svg width="24" height="24" style={{ fill: '#040405' }}>
                    <use xlinkHref="#arrow-left" />
                  </svg>
                  Change account
                </ButtonStyleLink>

                <StaleTitleH1>Please confirm your email</StaleTitleH1>
              </>
            )}

            <form id="verification-code-form">
              <div className="field">
                <StaleParagraphMedium>
                  {`We’ve just sent you an email with verification code to
                  ${getSubstractedEmail(email ? email : user?.email)} `}
                </StaleParagraphMedium>

                <StaleInput
                  id="code"
                  label="Verification code"
                  view="moving"
                  type="text"
                  name="verificationCode"
                  control={control}
                  rules={{
                    required: ERROR_MESSAGES.requiredField,
                  }}
                  autoFocus
                />

                <StaleParagraphMedium className="resend">
                  Haven’t received the code?{' '}
                  <ButtonStyleLink
                    type="button"
                    onClick={async (event) => {
                      event.preventDefault();

                      if (email || user?.email) {
                        await Firebase.generateVerificationCode();

                        Notify.info('Verification code has been resent.');
                      }
                    }}
                  >
                    Resend
                  </ButtonStyleLink>
                </StaleParagraphMedium>
              </div>
            </form>
            {!isMobile && (
              <StaleBtnGroup>
                <Button
                  form="verification-code-form"
                  disabled={!isValid || isLoading}
                  onClick={handleSubmit(onSubmit, onError)}
                >
                  Continue
                  {isLoading && (
                    <svg width="16px" height="16px" className="loader">
                      <use xlinkHref="#loader-ico" />
                    </svg>
                  )}
                </Button>
              </StaleBtnGroup>
            )}
          </div>
        </StaleOverflowScroll>
      </SignUpContent>

      {isMobile && (
        <StaleBtnGroup>
          <Button
            form="verification-code-form"
            disabled={!isValid || isLoading}
            onClick={handleSubmit(onSubmit, onError)}
          >
            Continue
            {isLoading && (
              <svg width="16px" height="16px" className="loader">
                <use xlinkHref="#loader-ico" />
              </svg>
            )}
          </Button>
        </StaleBtnGroup>
      )}
    </SignUpWrap>
  );
};

export default StepTwo;
