import {
  TRANSFER_TYPE,
  RECIPIENT_STATUS,
  PAYMENT_TYPE,
  IRecipient,
} from 'types';

export const isRecipientSwift = (recipient: IRecipient) =>
  recipient?.paymentType === PAYMENT_TYPE.swift;

export const isRecipientDisabled = (
  transferType: TRANSFER_TYPE,
  recipient: IRecipient,
  isSwift: boolean,
  checkDisabled: boolean
) =>
  (checkDisabled &&
    (transferType === TRANSFER_TYPE.regular ? isSwift : !isSwift)) ||
  recipient.status === RECIPIENT_STATUS.draft;
