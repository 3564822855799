import { Component, ComponentType } from 'react';
import { log } from 'utils';
import ErrorPlaceholder from './components/ErrorPlaceholder/ErrorPlaceholder';

export const withErrorBoundary = <P extends object>(
  WrappedComponent: ComponentType<P>
) =>
  class ErrorBoundary extends Component<P, { hasError: boolean }> {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }

    componentDidCatch(error, info) {
      // Display fallback UI
      this.setState({ hasError: true });
      // Send info about error to log service from here
      log({
        type: 'error',
        triggeredBy: 'ErrorBoundary',
        message: error.message,
        uiComponentStack: info.componentStack,
        url: window.location.href,
      });
    }

    render() {
      if (this.state.hasError) {
        return <ErrorPlaceholder />;
      }

      return <WrappedComponent {...this.props} />;
    }
  };
