import styled from 'styled-components';

import { Col } from 'components/shared/Col/Col';
import { Row } from 'components/shared/Row/Row';
import { Title } from 'components/shared/Typography/Typography';

export const AccountDetailsWrapper = styled(Col)`
  margin-bottom: 24px;
  width: 546px;
`;

export const AccountDetailsHeadingWrapper = styled(Row)`
  justify-content: flex-start;
  margin-bottom: 4px;
`;

export const AccountDetailsTitle = styled(Title)`
  margin-left: 12px;
  margin-right: auto;
`;

export const AccountDetailWrapper = styled(Row)`
  padding-left: 28px;
  margin: 4px 0;
`;

// TODO: consider moving into shared component (e.g PopupFooter)
export const AccountDetailsFooter = styled(Row)`
  padding: 24px 48px;
  border-radius: 0 8px 8px 0;
  justify-content: flex-start;
`;
