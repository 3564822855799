import { Col, Paragraph, Subtitle } from 'components';
import { RowInfo } from 'components/shared/RowInfo/RowInfo.styles';
import dayjs from 'dayjs';
import { FC, useEffect, useState } from 'react';
import { checkRecipientBankDetails } from 'services/firebase';
import { ITransfer } from 'types';

import { getTransferStatusDetails } from 'utils';
import { RECIPIENT_ROUTING_TYPES } from 'variables';

interface OwnProps {
  // TODO: sometimes it is IBUlkPayment, fix types
  transfer: ITransfer;
}

const RecipientDetails: FC<OwnProps> = ({ transfer }) => {
  const statusInfo = getTransferStatusDetails(transfer);
  const [bankDetails, setBankDetails] = useState<any>(null);

  const recipient = transfer?.recipient;
  const isIban = recipient?.paymentType === 'iban';
  const isSwift = recipient?.paymentType === 'swift';
  const isLocal = recipient?.paymentType === 'local';

  // look up for bank name if user has no bank name inside transfer details
  useEffect(() => {
    if (recipient && !recipient.bankName) {
      checkRecipientBankDetails({
        type: isSwift ? 'bic_swift' : 'iban',
        value: isSwift ? recipient.bicSwift : recipient.accountNumber,
      })
        .then(({ data }) => setBankDetails(data))
        .catch((error) =>
          console.log('checkRecipientBankDetails error: ', error)
        );
    }
  }, [recipient, isSwift]);

  if (!recipient) {
    return null;
  }

  return (
    <Col flex={1} alignSelf="stretch">
      <Subtitle variant="bold" mb>
        Recipient
      </Subtitle>

      {isIban && (
        <>
          <RowInfo>
            <Paragraph>Account holder</Paragraph>

            <Paragraph>{recipient.recipientName}</Paragraph>
          </RowInfo>

          <RowInfo>
            <Paragraph>IBAN</Paragraph>
            <Paragraph>{recipient.accountNumber}</Paragraph>
          </RowInfo>

          <RowInfo>
            <Paragraph>Bank name</Paragraph>
            <Paragraph>{recipient.bankName || bankDetails?.bankName}</Paragraph>
          </RowInfo>
          <RowInfo alignItems="flex-start">
            <Paragraph variant="bold">Status</Paragraph>
            <Col style={{ textAlign: 'right' }}>
              {statusInfo && <Paragraph>{statusInfo.text}</Paragraph>}
              {transfer.status === 'awaiting_payment' && (
                <Paragraph>
                  {`(Pay by ${transfer.payByTimeGMT} ${dayjs(
                    transfer.payByDate
                  ).format('on D MMM YYYY')})`}
                </Paragraph>
              )}
            </Col>
          </RowInfo>
        </>
      )}

      {isSwift && (
        <>
          <RowInfo>
            <Paragraph>Recipient</Paragraph>
            <Paragraph>{recipient.recipientName}</Paragraph>
          </RowInfo>

          <RowInfo>
            <Paragraph>SWIFT BIC</Paragraph>
            <Paragraph>{recipient.bicSwift}</Paragraph>
          </RowInfo>

          <RowInfo>
            <Paragraph>Account number</Paragraph>
            <Paragraph>{recipient.accountNumber}</Paragraph>
          </RowInfo>

          <RowInfo>
            <Paragraph>Bank name</Paragraph>
            <Paragraph>{recipient.bankName || bankDetails?.bankName}</Paragraph>
          </RowInfo>
          <RowInfo alignItems="flex-start">
            <Paragraph variant="bold">Status</Paragraph>
            <Col style={{ textAlign: 'right' }}>
              {statusInfo && <Paragraph>{statusInfo.text}</Paragraph>}
              {transfer.status === 'awaiting_payment' && (
                <Paragraph>
                  {`(Pay by ${transfer.payByTimeGMT} ${dayjs(
                    transfer.payByDate
                  ).format('on D MMM YYYY')})`}
                </Paragraph>
              )}
            </Col>
          </RowInfo>
        </>
      )}

      {isLocal && (
        <>
          <RowInfo>
            <Paragraph>Recipient</Paragraph>

            <Paragraph>{recipient.recipientName}</Paragraph>
          </RowInfo>

          <RowInfo>
            <Paragraph>Account number</Paragraph>
            <Paragraph>{recipient.accountNumber}</Paragraph>
          </RowInfo>

          <RowInfo>
            <Paragraph>
              {recipient ? RECIPIENT_ROUTING_TYPES[recipient.routingType] : ''}
            </Paragraph>
            <Paragraph>{recipient.routingNumber}</Paragraph>
          </RowInfo>
          <RowInfo alignItems="flex-start">
            <Paragraph variant="bold">Status</Paragraph>
            <Col style={{ textAlign: 'right' }}>
              {statusInfo && <Paragraph>{statusInfo.text}</Paragraph>}
              {transfer.status === 'awaiting_payment' && (
                <Paragraph>
                  {`(Pay by ${transfer.payByTimeGMT} ${dayjs(
                    transfer.payByDate
                  ).format('on D MMM YYYY')})`}
                </Paragraph>
              )}
            </Col>
          </RowInfo>
        </>
      )}
    </Col>
  );
};

export default RecipientDetails;
