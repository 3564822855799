import styled, { css } from 'styled-components';

export const StaleButtonNew = styled.button<{
  variant?: 'primary' | 'secondary' | 'link';
}>(
  ({ variant = 'primary', theme }) => css`
    cursor: pointer;

    .loader {
      min-width: 16px;
      animation: loading 1.2s linear infinite;
    }

    @keyframes loading {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }

    ${variant !== 'link' &&
    css`
      outline: none;
      border: 1px solid ${theme.color.transparent};
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-weight: 700;
      cursor: pointer;
      transition: ${theme.transition};
      min-height: 48px;
      padding: 10px 40px;
      line-height: 21px;
      border-radius: 32px;
      width: 100%;

      @media (max-width: ${theme.breakpoint.largeMax}px) {
        font-size: 14px;
        padding: 10px 32px;
        cursor: default;
      }

      svg {
        margin: -1px 0 0 11px;
        transition: fill 0.4s;
        height: 24px;
        width: 24px;
      }

      &:focus,
      &:active {
        border-color: ${theme.color.emeraldDark};
        color: ${theme.color.emeraldDark};

        svg {
          fill: ${theme.color.emeraldDark};
        }
      }

      &.disabled,
      &[disabled] {
        cursor: default;
        pointer-events: none;
      }
    `}

    ${variant === 'primary' &&
    css`
      color: ${theme.color.white};
      background: ${theme.color.dark};

      .theme-dark & {
        border: 1px solid ${theme.color.white};
      }

      svg {
        fill: ${theme.color.white};
      }

      &:hover {
        background: ${theme.color.black};
      }

      &.disabled,
      &[disabled] {
        background: ${theme.color.grey};
      }
    `}

    ${variant === 'secondary'
      ? css`
          color: ${theme.color.dark};
          background: ${theme.color.transparent};
          border: 1px solid ${theme.color.dark};

          svg {
            fill: ${theme.color.dark};
          }

          &:hover {
            color: ${theme.color.greyDark};
            border-color: ${theme.color.greyDark};
            background: ${theme.color.greyLight_2};

            svg {
              fill: ${theme.color.greyDark};
            }
          }

          &.disabled,
          &[disabled] {
            color: ${theme.color.grey};
            border-color: ${theme.color.grey};

            svg {
              fill: ${theme.color.grey};
            }
          }
        `
      : ''}

    ${variant === 'link'
      ? css`
          font-weight: 500;
          display: flex;
          align-items: center;
          color: ${theme.color.dark};
          transition: ${theme.transition};
          text-decoration: underline;
          text-underline-offset: 2px;

          svg {
            height: 24px;
            width: 24px;
            margin: 0 4px;
            transition: fill 0.4s;
            fill: ${theme.color.dark};
          }

          &:hover {
            color: ${theme.color.greyDark};

            svg {
              fill: ${theme.color.greyDark};
            }
          }

          &:focus,
          &:active {
            color: ${theme.color.emeraldDark};
            border-color: ${theme.color.emeraldDark};

            svg {
              fill: ${theme.color.emeraldDark};
            }
          }

          &.disabled,
          &[disabled] {
            color: ${theme.color.grey};

            svg {
              fill: ${theme.color.grey};
            }
          }

          .btns-group & {
            justify-content: center;
          }
        `
      : ''}
  `
);
