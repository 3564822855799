import { FC } from 'react';
import { StaleInput, Row, Paragraph, StaleSwitch } from 'components';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useStoreState } from 'state';
import auth from 'services/auth';
import {
  SettingsContent,
  SettingsField,
  SettingsFooter,
} from 'pages/Settings/Settings.styles';
import { Firebase } from 'services';
import { Notify } from 'utils';
import Button from 'components/shared/Button/Button';

interface OwnProps {
  setShowChangePassword: (value: boolean) => void;
}

const UserSettings: FC<OwnProps> = ({ setShowChangePassword }) => {
  const { control } = useForm();
  const { user } = useStoreState((state) => state.UserState);
  const {
    entityId,
    hasApprovalFlow,
    isAutomationPackageEnabled,
  } = useStoreState(({ UserState }) => UserState);
  const { photoURL } = auth.currentUser?.providerData[0] ?? {};
  const [isLoading, setIsLoading] = useState(false);

  const onEnableApprovalFlowForEntity = async (status: boolean) => {
    if (!entityId) {
      return;
    }

    try {
      setIsLoading(true);
      const response = await Firebase.enableApprovalFlowForEntity({
        entityId,
      });

      if (response?.data?.success) {
        Notify.success(`Approval flow ${status ? 'disabled' : 'enabled'}`);
      } else {
        Notify.error(response?.data?.message ?? '');
      }
    } catch (error) {
      Notify.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <form>
        <SettingsContent>
          <SettingsField>
            <StaleInput
              id="email"
              name="email"
              label="Email"
              view="moving"
              type="email"
              defaultValue={user?.email}
              locked={true}
              control={control}
            />
          </SettingsField>
          {!photoURL ? (
            <StaleInput
              id="password"
              name="password"
              label="Password"
              view="moving"
              type="password"
              defaultValue="its-password-variable"
              renderIcon={false}
              locked
              control={control}
            />
          ) : (
            <SettingsField>
              <StaleInput
                id="textWithGoogle"
                name="textWithGoogle"
                view="moving"
                type="text"
                defaultValue="Authenticated using Google credentials"
                locked={true}
                control={control}
              />
            </SettingsField>
          )}

          {isAutomationPackageEnabled && (
            <Row mt justifyContent="flex-start">
              <Paragraph mr>Approval flow</Paragraph>
              <StaleSwitch
                id="approval-flow"
                isOn={hasApprovalFlow}
                handleToggle={() =>
                  onEnableApprovalFlowForEntity(hasApprovalFlow)
                }
                disabled={isLoading}
              />
            </Row>
          )}
        </SettingsContent>

        {!photoURL && (
          <SettingsFooter>
            <Button
              variant="link"
              type="button"
              onClick={() => setShowChangePassword(true)}
            >
              Change password
            </Button>
          </SettingsFooter>
        )}
      </form>
    </>
  );
};

export default UserSettings;
