import styled, { css } from 'styled-components';
import { StaleBtnGroup } from 'components';

export const Wrapper = styled.div(
  ({ theme }) => css`
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 24px 12px 0;

    div${StaleBtnGroup} {
      padding: 46px 20px;
      margin-top: auto;

      button {
        flex: 0 1 auto;
        max-width: 100%;
        padding: 0 32px;
        white-space: nowrap;

        &:first-child {
          margin-bottom: 16px;
        }
      }
    }
  `
);
