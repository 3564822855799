import { FC, Dispatch, SetStateAction } from 'react';
import { Row } from 'components';
import { FilterButton } from 'components/shared/FilterButton/FilterButton.styles';
import CounterTitle from 'components/shared/CounterTitle/CounterTitle';
import { TReportsTableFilterValue } from '../../types';
import { useTheme } from 'styled-components';

interface IProps {
  invoiceCount: number;
  filter?: TReportsTableFilterValue;
  setFilter: Dispatch<SetStateAction<TReportsTableFilterValue>>;
}

const TableControls: FC<IProps> = ({ invoiceCount, filter, setFilter }) => {
  const theme = useTheme();
  return (
    <Row mr mb justifyContent="flex-start" gap={theme.spacing.m}>
      <CounterTitle title="Contributors" count={invoiceCount} />

      <FilterButton
        onClick={() =>
          setFilter(filter === 'payables' ? 'default' : 'payables')
        }
        active={filter === 'payables'}
      >
        Payables
      </FilterButton>

      <FilterButton
        onClick={() =>
          setFilter(filter === 'receivables' ? 'default' : 'receivables')
        }
        active={filter === 'receivables'}
      >
        Receivables
      </FilterButton>

      <FilterButton
        onClick={() =>
          setFilter(filter === 'transfers' ? 'default' : 'transfers')
        }
        active={filter === 'transfers'}
      >
        Transfers
      </FilterButton>
    </Row>
  );
};

export default TableControls;
