import { MouseEvent as ReactMouseEvent, useCallback, useRef } from 'react';

const useChartTooltip = () => {
  const tooltipRef = useRef<HTMLDivElement>(null);

  const onMouseEnter = useCallback(
    () => tooltipRef.current?.style.setProperty('display', 'block'),
    []
  );

  const onMouseMove = useCallback(
    (event: ReactMouseEvent<SVGElement, MouseEvent>) => {
      const { pageX, pageY } = event;

      if (tooltipRef?.current) {
        const rect = tooltipRef.current.getBoundingClientRect();
        tooltipRef.current?.style.setProperty(
          'left',
          `${pageX - rect.width / 2}px`
        );
        tooltipRef.current?.style.setProperty(
          'top',
          `${pageY - rect.height - 8}px`
        );
      }
    },
    []
  );

  const onMouseLeave = useCallback(
    () => tooltipRef.current?.style.setProperty('display', 'none'),
    []
  );

  return {
    tooltipRef,
    onMouseEnter,
    onMouseMove,
    onMouseLeave,
  };
};

export default useChartTooltip;
