import { useState, useEffect } from 'react';
import {
  ReportCalculationStatus,
  subscribeToPastPerformanceStatus,
} from 'services/firebase/analysis';
import { useStoreState } from 'state';

const usePastPerformanceCalculationStatus = (): ReportCalculationStatus => {
  const { entityId } = useStoreState((state) => state.UserState);
  const [
    reportCalculationStatus,
    setReportCalculationStatus,
  ] = useState<ReportCalculationStatus>('complete');

  // subscribe to status so we can show a spinner if new values are being calculated
  useEffect(() => {
    let unsubscribe: (() => void) | undefined;
    if (entityId) {
      unsubscribe = subscribeToPastPerformanceStatus({
        entityId,
        callback: setReportCalculationStatus,
      });
    }

    return () => unsubscribe?.();
  }, [entityId]);

  return reportCalculationStatus;
};

export default usePastPerformanceCalculationStatus;
