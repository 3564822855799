import { Paragraph } from 'components';
import { FC } from 'react';
import { useStoreState } from 'state';
import { parseIntoCurrencyStringWithSymbol } from 'utils';

interface OwnProps {
  value: number;
  currencyCode: string;
}

const AmountCell: FC<OwnProps> = ({ value, currencyCode }) => {
  const { currencyByCode } = useStoreState((state) => state.CurrenciesState);
  const currency = currencyByCode(currencyCode);

  return (
    <Paragraph>
      {parseIntoCurrencyStringWithSymbol(value, currency?.symbol)}
    </Paragraph>
  );
};

export default AmountCell;
