import { FC } from 'react';
import { Paragraph, Row } from 'components';
import { parseIntoCurrencyStringWithSymbol } from 'utils';

interface OwnProps {
  value: number;
  currencySymbol?: string;
}

const InfoValue: FC<OwnProps> = ({ value, currencySymbol }) => (
  <Row flex={1} justifyContent="flex-end">
    <Paragraph color={value < 0 ? 'red' : 'dark'} variant="bold">
      {parseIntoCurrencyStringWithSymbol(value, currencySymbol)}
    </Paragraph>
  </Row>
);

export default InfoValue;
