import { useState } from 'react';
import { useHistory } from 'react-router';
import { codatPlatformNamesToSettingsPaths } from 'routes/IntegrationsRouteUtil';

import {
  getCodatRedirectUri,
  queueNewCodatSync,
} from 'services/firebase/codat';
import { useStoreState } from 'state';
import { TCodatPlatformNames } from 'types/integrations';
import { getCodatLogsPageLink, getCodatSettingsLink } from 'utils/links';

interface IUseCodat {
  codatShortCode?: string;
  platformNames: TCodatPlatformNames[];
}

export const isSage50Checker = (platformNames: TCodatPlatformNames[]) =>
  platformNames.includes('Sage 50 (UK)');

export const isQuickBooksChecker = (platformNames: TCodatPlatformNames[]) =>
  platformNames.includes('QuickBooks Online');

export const isQuickBooksSandboxChecker = (
  platformNames: TCodatPlatformNames[]
) => platformNames.includes('QuickBooks Online Sandbox');

export const isDynamics365Checker = (platformNames: TCodatPlatformNames[]) =>
  platformNames.includes('Dynamics 365 Business Central');

const isCodatAccountingIntegrationCheckers = [
  isSage50Checker,
  isQuickBooksChecker,
  isDynamics365Checker,
  isQuickBooksSandboxChecker,
];
export const isCodatAccountingIntegration = (
  platformNames: TCodatPlatformNames[]
) =>
  isCodatAccountingIntegrationCheckers.some((checker) =>
    checker(platformNames)
  );

const useCodatIntegration = ({ codatShortCode, platformNames }: IUseCodat) => {
  const history = useHistory();
  const { userEntity, integrationsSummary, user } = useStoreState(
    (state) => state.UserState
  );

  const [isLoading, setIsLoading] = useState(false);
  const [isImporting, setIsImporting] = useState(false);
  const [codatWindowUrl, setCodatWindowUrl] = useState<string>();
  const platformName = integrationsSummary?.codat?.platformName;
  const isIntegrated =
    !!integrationsSummary?.codat?.connected &&
    !!platformName &&
    platformNames?.includes(platformName);

  const isSage50 = isSage50Checker(platformNames);
  const isQuickBooks = isQuickBooksChecker(platformNames);
  const isDynamics365 = isDynamics365Checker(platformNames);

  const retrieveCodatRedirectUri = async () => {
    try {
      setIsLoading(true);
      const redirect = await getCodatRedirectUri(user?.id ?? '');

      if (redirect) {
        setCodatWindowUrl(redirect);
        return redirect;
      }
    } catch (error) {
      // show some error
      console.error('Error retrieving status token', error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const openPopup = (url: string) => {
    if (!window || !window.top) {
      return;
    }

    const width = 700;
    const height = 1000;

    const top = window.top.outerHeight / 2 + window.top.screenY - height / 2;
    const left = window.top.outerWidth / 2 + window.top.screenX - width / 2;

    // creates direct link to specific integration via Codat
    const urlWithShortCode = !!codatShortCode
      ? `${url.replace('link', 'links')}/consent/${codatShortCode}`
      : url;

    window.open(
      urlWithShortCode,
      'Connect to Codat',
      `resizable, scrollbars, status, width=${width}px, height=${height}px, left=${left}, top=${top}`
    );
  };

  const onLogin = async () => {
    // We retrieve the company url here because
    // we don't want to unnecessarily create a codat company
    // in lower environments, using up limits
    const url = codatWindowUrl ?? (await retrieveCodatRedirectUri());
    if (url) {
      openPopup(url);
    }
  };

  const onImportData = async () => {
    try {
      setIsImporting(true);
      await queueNewCodatSync();
      setTimeout(() => {
        setIsImporting(false);
      }, 15000);
    } catch (error) {
      setIsImporting(false);
      throw error;
    }
  };

  const onManageSettings = () => {
    if (platformName) {
      const path = codatPlatformNamesToSettingsPaths[platformName];
      const link = getCodatSettingsLink(path, { step: '1' });
      history.push(link);
    } else {
      console.error('Tried to call onManageSettings with no platformName');
    }
  };

  const onManageLogs = () => {
    if (platformName) {
      history.push(getCodatLogsPageLink(platformName));
    } else {
      console.error('Tried to call onManageLogs with no platformName');
    }
  };

  return {
    onLogin,
    onImportData,
    isImporting,
    isLoading,
    isIntegrated,
    platformName,
    queueNewCodatSync,
    syncStatus: userEntity?.integrations?.codat?.syncDetails?.status,
    onManageSettings,
    onManageLogs,
    isSage50,
    isQuickBooks,
    isDynamics365,
  };
};

export default useCodatIntegration;
