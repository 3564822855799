import styled, { css } from 'styled-components';
import { ButtonStyleLink } from 'components';

export const LoaderWrapper = styled.div`
  width: 100%;
  height: 53px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Wrapper = styled.div(
  ({ theme }) => css`
    position: relative;

    > .head {
      padding: 0 48px;
      color: ${theme.color.white};
      background: ${theme.color.dark};
      border-radius: 8px 8px 0 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 72px;

      .status {
        padding-right: 16px;
        position: relative;

        &:before {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          margin: auto;
          right: 0;
          width: 8px;
          height: 8px;
          background: ${theme.color.grey};
          border-radius: ${theme.borderRadius.round};
        }
      }
    }

    .content {
      padding: 24px 48px 40px;
      background: ${theme.color.white};
      box-shadow: 0 0 9px rgba(0, 0, 0, 0.1);
      border-radius: 8px;

      .row-info {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        white-space: break-spaces;

        &:not(:last-of-type) {
          margin-bottom: 16px;
        }

        p:last-of-type {
          display: flex;
          align-items: center;
        }
      }

      .col {
        &:first-child {
          flex: 0 0 63%;
          max-width: 63%;
          padding-right: 0;
        }

        &:not(:first-child) {
          flex: 0 0 37%;
          max-width: 37%;
          text-align: right;
        }

        .col {
          padding: 0;

          &:first-child {
            flex: 0 0 59%;
            max-width: 59%;
            padding-right: 16px;
          }

          &:not(:first-child) {
            flex: 0 0 41%;
            max-width: 41%;
          }
        }
      }

      .field {
        margin-bottom: 14px;
      }
    }

    .invoice-table {
      width: 100%;
      margin-top: 8px;

      th {
        color: ${theme.color.white};
        background: ${theme.color.greyDark};
        padding: 12px 4px;
        text-align: left;

        &:first-child {
          padding-left: 32px;
          border-radius: 6px 0 0 6px;
        }

        &:last-child {
          padding-right: 17px;
          border-radius: 0 6px 6px 0;
          text-align: right;
        }
        &:nth-last-child(2) {
          text-align: right;
        }
      }

      td {
        padding: 4px 0 4px 4px;
        height: 40px;
        vertical-align: middle;

        p {
          @media (max-width: ${theme.breakpoint.largeMax}px) {
            font-size: 12px;
            line-height: 18px;
          }
        }

        .field-bg {
          min-width: 48px;
          flex: 1 0 auto;
          background: ${theme.color.greyLight_3};
          border-radius: 6px;
          padding: 10px 8px;
          color: ${theme.color.grey};
          height: 100%;

          @media (min-width: ${theme.breakpoint.mediumMin}px) {
            padding: 10px;
          }
          @media (min-width: ${theme.breakpoint.largeMin}px) {
            padding: 16px;
          }

          p {
            @media (min-width: ${theme.breakpoint.largeMin}px) {
              max-width: 270px;
            }
          }
        }

        &:first-child {
          position: relative;
        }

        &:last-child {
          text-align: right;

          @media (min-width: ${theme.breakpoint.largeMin}px) {
            width: 106px;
          }
          @media (max-width: ${theme.breakpoint.largeMax}px) {
            padding-left: 12px;
          }
        }
        &:nth-last-child(2) {
          text-align: right;
        }

        .remove {
          width: 30px;
          height: 36px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          left: 0;

          @media (min-width: ${theme.breakpoint.largeMin}px) {
            height: 52px;
          }

          svg {
            fill: ${theme.color.grey};
          }
        }
      }

      tr:first-child {
        td {
          padding-top: 16px;
        }
      }
      tr:last-of-type {
        td {
          &:last-of-type {
            p {
              display: flex;
              align-items: center;
              justify-content: flex-end;
              margin-bottom: 8px;

              &:last-of-type {
                margin-bottom: 0;
              }
            }
          }
        }
      }

      &.summary {
        tr:last-of-type {
          td {
            padding-top: 16px;
            border-top: 1px solid ${theme.color.greyLight_2};
          }
        }

        td {
          &:last-child {
            width: 0;
          }

          button {
            margin-left: auto;
            font-size: 14px;
            line-height: 24px;
            text-decoration: underline;
            text-underline-offset: 1px;
          }
        }
      }
    }

    .update-btn {
      &:hover {
        svg {
          transform: rotate(-360deg);
          transition: transform 0.5s ease-out;
        }
      }

      svg {
        transform: rotate(0);
        transition: fill ${theme.transition};
      }
    }

    ${ButtonStyleLink} {
      display: flex;
      align-items: center;
      transition: ${theme.transition};

      &:hover {
        color: ${theme.color.emeraldDark};

        svg {
          fill: ${theme.color.emeraldDark};
        }
      }

      svg {
        width: 20px;
        height: 20px;
        margin-right: 8px;
      }
    }

    .bottom {
      display: flex;
      align-items: center;
      position: absolute;
      bottom: 40px;

      ${ButtonStyleLink} {
        margin-right: 32px;
        text-decoration: underline;
        text-underline-offset: 2px;
      }
    }

    .wrap-popup {
      min-height: 300px !important;
    }
  `
);
