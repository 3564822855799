import styled from 'styled-components';
import { StaleContainer } from '../../StaleLayout/StaleLayout.styles';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  ${StaleContainer} {
    flex: 0 1 100%;
    overflow: auto;
    padding: 20px 24px 0;
  }
`;
