import { FC, RefObject } from 'react';
import { createPortal } from 'react-dom';
import { ChartTooltipWrapper } from './ChartTooltip.styles';

interface OwnProps {
  wrapperRef: RefObject<HTMLDivElement>;
}

const ChartTooltip: FC<OwnProps> = ({ children, wrapperRef }) => {
  return createPortal(
    <ChartTooltipWrapper ref={wrapperRef}>{children}</ChartTooltipWrapper>,
    document.body
  );
};

export default ChartTooltip;
