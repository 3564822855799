import dayjs from 'dayjs';
import { useState, useEffect, useCallback } from 'react';
import { getHFGuruStatuses, updateHFGuruStatuses } from 'services/entities';
import { Nullable } from 'types';
import { HF_GURU_TASKS, IHfGuru, IHfGuruStatuses } from 'types/entities';
import { GURU_CARDS } from 'variables';

const getHfGuruActiveTasks = (hfGuruStatuses: IHfGuruStatuses) => {
  const hfGuruActiveTasks: IGuruCard[] = [];
  let shouldShowRemainingTasksLink = false;

  Object.keys(hfGuruStatuses).forEach((statusName: HF_GURU_TASKS) => {
    const hfGuruStatus = hfGuruStatuses[statusName];

    const isDeferredUntil =
      hfGuruStatus?.deferUntil && dayjs().isBefore(hfGuruStatus.deferUntil);
    const isCompleted = hfGuruStatus.completed;

    if (!isCompleted && !isDeferredUntil) {
      const taskCard = GURU_CARDS.find((el) => el.id === statusName);

      if (taskCard) {
        hfGuruActiveTasks.push(taskCard);
      }
    }

    if (!isCompleted && isDeferredUntil && !shouldShowRemainingTasksLink) {
      shouldShowRemainingTasksLink = true;
    }
  });

  return {
    tasks: hfGuruActiveTasks.sort((a, b) => a.order - b.order),
    shouldShowRemainingTasksLink,
  };
};

interface IGuruCard {
  id: HF_GURU_TASKS;
  icon: string;
  title: string;
  text: string;
  link: string;
  buttonText: string;
  order: number;
}

const useHfGuru = () => {
  const [isHfGuruLoading, setIsHfGuruLoading] = useState(true);
  const [hfGuruData, setHfGuruData] = useState<Nullable<IHfGuru>>(null);
  const [hfGuruActiveTasks, setHfGuruActiveTasks] = useState<
    Nullable<IGuruCard[]>
  >(null);
  const [showRemainingTasksLink, setShowRemainingTasksLink] = useState(false);

  useEffect(() => {
    const getStatuses = async () => {
      try {
        setIsHfGuruLoading(true);
        const { data } = await getHFGuruStatuses();

        if (data && data.success) {
          setHfGuruData(data.data);
        } else {
          console.warn(data.message);
        }
      } catch (error) {
        console.warn(error.message);
      } finally {
        setIsHfGuruLoading(false);
      }
    };

    getStatuses();
  }, []);

  useEffect(() => {
    if (hfGuruData?.statuses) {
      const { tasks, shouldShowRemainingTasksLink } = getHfGuruActiveTasks(
        hfGuruData.statuses
      );

      setHfGuruActiveTasks(tasks);
      setShowRemainingTasksLink(shouldShowRemainingTasksLink);
    }
  }, [hfGuruData]);

  const updateHfGuruStatus = useCallback(
    async (id: HF_GURU_TASKS, close = true) => {
      try {
        const { data } = await updateHFGuruStatuses({
          task: id,
          close,
        });

        if (data && data.success) {
          setHfGuruData(data.data);
        } else {
          console.warn(data.message);
        }
      } catch (error) {
        console.warn(error.message);
      }
    },
    []
  );

  return {
    isHfGuruLoading,
    hfGuruData,
    updateHfGuruStatus,
    showRemainingTasksLink,
    setShowRemainingTasksLink,
    hfGuruActiveTasks,
  };
};

export default useHfGuru;
