import { StaleSwitch, Icon, Paragraph, Row, Col } from 'components';
import { FC } from 'react';
import { useTheme } from 'styled-components';
import { InfoCardWrapper } from './InfoCard.styles';

interface OwnProps {
  title: string;
  description: string;
  id: string;
  isOn: boolean;
  icon: string;
  onChange: () => void;
}

const InfoCard: FC<OwnProps> = ({
  id,
  title,
  description,
  isOn,
  icon,
  onChange,
}) => {
  const theme = useTheme();
  return (
    <InfoCardWrapper>
      <Icon icon={icon} width="60px" height="60px" />
      <Col flex={1}>
        <Row mb mbValue={theme.spacing.xs}>
          <Paragraph variant="bold">{title}</Paragraph>
          <StaleSwitch isOn={isOn} handleToggle={onChange} id={id} />
        </Row>
        <Paragraph color="greyDark">{description}</Paragraph>
      </Col>
    </InfoCardWrapper>
  );
};

export default InfoCard;
