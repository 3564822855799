import { Paragraph, Row, StaleInputSelect, Col } from 'components';
import { Dispatch, FC, SetStateAction, useState } from 'react';

import {
  CurrencyInfoTableWrapper,
  CurrencyInfoWrapper,
} from './CurrencyInfo.styles';

import { useStoreState } from 'state';
import usePastPerformanceDataPerMonth from 'pages/Reports/hooks/usePastPerformanceDataPerMonth';

import { useTheme } from 'styled-components';
import ChartNetOutstanding from '../ChartNetOutstanding/ChartNetOutstanding';
import ChartRealised from '../ChartRealised/ChartRealised';
import { StepperItem } from 'components/shared/StaleStepsHorizontal/StaleStepsHorizontal.styles';
import dayjs from 'dayjs';
import { DB_MONTH_FORMAT } from 'variables';
import ChartMarketImpact from '../ChartMarketImpact/ChartMarketImpact';
import { TReportsContentType } from 'pages/Reports/types';
import { AccountsRow } from 'components/shared/AccountsRow/AccountsRow.styles';
import Field from 'components/shared/Field/Field.styles';
import InfoValue from '../InfoValue/InfoValue';
import InfoTitle from '../InfoTitle/InfoTitle';

const TABS = [
  {
    id: 'volumes',
    title: 'Volumes',
  },
  {
    id: 'realised',
    title: 'Realised',
  },
  {
    id: 'marketImpact',
    title: 'Market Impact',
  },
] as const;

type NumberOFMonthsType = 3 | 6 | 9 | 12 | 18 | 24;

interface OwnProps {
  contentType: TReportsContentType;
  setContentType: Dispatch<SetStateAction<TReportsContentType>>;
  activeMonth: string;
  setActiveMonth: Dispatch<SetStateAction<string>>;
  currencyCodes?: string[];
  setActiveCurrencyCode: (code: string) => void;
  activeCurrencyCode: string;
}

const CurrencyInfo: FC<OwnProps> = ({
  setContentType,
  contentType,
  activeMonth,
  setActiveMonth,
  currencyCodes,
  setActiveCurrencyCode,
  activeCurrencyCode,
}) => {
  const theme = useTheme();
  const { entityDefaultCurrency } = useStoreState((state) => state.UserState);
  const { currencyByCode } = useStoreState((state) => state.CurrenciesState);

  const currency = currencyByCode(entityDefaultCurrency);
  const [months, setMonths] = useState<NumberOFMonthsType>(6);

  const {
    averageMonthlyRealisedImpact,
    averageMonthlyUnrealisedImpact,
    totalTurnover,
    averageTurnover,
    totalRealisedImpact,
    totalUnrealisedImpact,
    data,
    totalGainLoss,
    totalTransactionCost,
    averageGainLoss,
    averageTransactionCost,
  } = usePastPerformanceDataPerMonth({
    currencyCode: activeCurrencyCode,
    from: dayjs()
      .subtract(months - 1, 'months')
      .format(DB_MONTH_FORMAT),
  });

  return (
    <Row flex={1} columnGap={theme.spacing.s}>
      <CurrencyInfoWrapper>
        <AccountsRow columnGap={theme.spacing.xxl}>
          <Field fluid flex={1}>
            <StaleInputSelect
              selected={activeCurrencyCode}
              data={currencyCodes?.map((currencyCode) => ({
                id: currencyCode,
                name: currencyCode,
                value: currencyCode,
                icon: currencyByCode(currencyCode)?.countryCode,
              }))}
              onSelect={(item) => setActiveCurrencyCode(item.value)}
              id="backtester-currency"
              style={{ width: '100%' }}
            />
          </Field>
          <Field fluid flex={1}>
            <StaleInputSelect
              selected={months}
              data={[
                {
                  id: 3,
                  name: 'Last 3 months',
                  value: 3,
                },
                {
                  id: 6,
                  name: 'Last 6 months',
                  value: 6,
                },
                {
                  id: 9,
                  name: 'Last 9 months',
                  value: 9,
                },
                {
                  id: 12,
                  name: 'Last 12 months',
                  value: 12,
                },
                {
                  id: 18,
                  name: 'Last 18 months',
                  value: 18,
                },
                {
                  id: 24,
                  name: 'Last 24 months',
                  value: 24,
                },
              ]}
              onSelect={(item) => setMonths(item.value)}
              id="backtester-data-duration"
              style={{ width: '100%' }}
            />
          </Field>
        </AccountsRow>

        <Col>
          <AccountsRow flex={1}>
            <Row flex={1.2} justifyContent="flex-start">
              <Paragraph variant="thin">Metrics</Paragraph>
            </Row>
            <Row flex={1} justifyContent="flex-end">
              <Paragraph variant="thin">Total</Paragraph>
            </Row>
            <Row flex={1} justifyContent="flex-end">
              <Paragraph variant="thin">Monthly</Paragraph>
            </Row>
          </AccountsRow>
          <AccountsRow flex={1}>
            <InfoTitle
              title="FX Turnover"
              info="Total turnover (in your main currency) of foreign currency
                  cashflows paid or received over the period."
            />
            <InfoValue
              value={totalTurnover}
              currencySymbol={currency?.symbol}
            />
            <InfoValue
              value={averageTurnover}
              currencySymbol={currency?.symbol}
            />
          </AccountsRow>

          <AccountsRow flex={1}>
            <InfoTitle
              title="Realised impact"
              info="Changes in the value of foreign currency cashflows due to
                  exchange rate fluctuations between cashflow date and payment
                  date for each cashflow."
            />
            <InfoValue
              value={totalRealisedImpact}
              currencySymbol={currency?.symbol}
            />
            <InfoValue
              value={averageMonthlyRealisedImpact}
              currencySymbol={currency?.symbol}
            />
          </AccountsRow>
          <AccountsRow flex={1}>
            <InfoTitle
              title="Transaction cost"
              info="Estimated costs of exchanging currencies based on the
                  difference between the official FX rates (Bank of England
                  data) on each payment date and actual rates used for each
                  cashflow payment."
            />
            <InfoValue
              value={totalTransactionCost}
              currencySymbol={currency?.symbol}
            />
            <InfoValue
              value={averageTransactionCost}
              currencySymbol={currency?.symbol}
            />
          </AccountsRow>
          <AccountsRow flex={1}>
            <InfoTitle
              title="Total gains/losses"
              info="Sum of market impact and transaction costs for a given period."
            />
            <InfoValue
              value={totalGainLoss}
              currencySymbol={currency?.symbol}
            />
            <InfoValue
              value={averageGainLoss}
              currencySymbol={currency?.symbol}
            />
          </AccountsRow>
          <AccountsRow flex={1}>
            <InfoTitle
              title="Unrealised Impact"
              info="Changes in the value of foreign currency cashflows due to
                  exchange rate fluctuations between cashflow date and last
                  available rate."
            />
            <InfoValue
              value={totalUnrealisedImpact}
              currencySymbol={currency?.symbol}
            />
            <InfoValue
              value={averageMonthlyUnrealisedImpact}
              currencySymbol={currency?.symbol}
            />
          </AccountsRow>
        </Col>
      </CurrencyInfoWrapper>
      <CurrencyInfoTableWrapper>
        <Row justifyContent="flex-start">
          {TABS.map(({ title, id }) => (
            <StepperItem
              onClick={() => setContentType(id)}
              key={id}
              current={id === contentType}
            >
              {title}
            </StepperItem>
          ))}
        </Row>

        <Row alignItems="stretch" flex={1}>
          {contentType === 'realised' && (
            <ChartRealised
              data={data}
              onChartBarClick={setActiveMonth}
              activeMonth={activeMonth}
            />
          )}
          {contentType === 'volumes' && (
            <ChartNetOutstanding
              data={data}
              onLinePointClick={setActiveMonth}
              activeMonth={activeMonth}
            />
          )}
          {contentType === 'marketImpact' && (
            <ChartMarketImpact
              data={data}
              onChartBarClick={setActiveMonth}
              activeMonth={activeMonth}
            />
          )}
        </Row>
      </CurrencyInfoTableWrapper>
    </Row>
  );
};

export default CurrencyInfo;
