import _groupBy from 'lodash.groupby';
import { ICurrency, IInvoice, Nullable } from 'types';
import { InvoicesByCurrency } from '../../../components/shared/InvoicesTable/types';

export const groupInvoices = (
  invoices: IInvoice[],
  currencyByCode: (currencyCode: string) => Nullable<ICurrency>
) => {
  const result: InvoicesByCurrency[] = Object.entries(
    _groupBy(invoices, 'currency')
  ).map(([key, value]) => {
    const currency = currencyByCode(key);
    return {
      currencyCode: key,
      currencyCountryCode: currency?.countryCode ?? '',
      currencySymbol: currency?.symbol ?? '',
      totalAmount: value.reduce(
        (total, val) => total + (val.totalAmount ?? 0),
        0
      ),
      invoices: value,
    };
  });

  const sortedResult = result.sort((a, b) => {
    if (
      a.currencyCode === 'USD' ||
      (a.currencyCode === 'EUR' && b.currencyCode !== 'USD')
    ) {
      return -1;
    }

    if (b.currencyCode === 'GBP') {
      return -1;
    }

    return a.currencyCode > b.currencyCode ? -1 : 1;
  });

  return sortedResult;
};

export const detectCurrenciesFromInvoices = (
  invoices: IInvoice[],
  entityDefaultCurrency?: string | null
) => {
  const currencies = new Set<string>(
    new Set(invoices.map((invoice) => invoice.currency))
  );

  if (entityDefaultCurrency && currencies.has(entityDefaultCurrency)) {
    currencies.delete(entityDefaultCurrency);
  }

  return Array.from(currencies);
};
