import { FC } from 'react';

import { Wrapper } from './StaleProgressBar.styles';

interface OwnProps {
  fullWidth?: boolean;
  progress: number;
  color?: string;
}

const StaleProgressBar: FC<OwnProps> = ({ progress, color, fullWidth }) => {
  return (
    <Wrapper fullWidth={fullWidth} color={color}>
      <div className="progress" style={{ width: `${progress}%` }} />
    </Wrapper>
  );
};

export default StaleProgressBar;
