import { Row } from 'components';
import styled from 'styled-components';

export const TabsWrapper = styled(Row)`
  background: ${({ theme }) => theme.color.white};
  border-top-right-radius: ${({ theme }) => theme.borderRadius.m};
  border-top-left-radius: ${({ theme }) => theme.borderRadius.m};
  padding: ${({ theme }) =>
    `${theme.spacing.m} ${theme.spacing.m} 0 ${theme.spacing.m}`};
  gap: ${({ theme }) => theme.spacing.xl};
  align-items: center;
`;
