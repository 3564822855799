import { FC, useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';

import {
  Paragraph,
  Col,
  Row,
  Title,
  StaleLoader,
} from 'components';
import { Notify, parseIntoCurrencyStringWithSymbol } from 'utils';
import ReactSlider from 'react-slider';
import { useTheme } from 'styled-components';
import { updateEntitySettings } from 'services/firebase';
import { useStoreState } from 'state';
import { ReactSliderWrapper } from './StepOne.styles';
import { useHistory, useRouteMatch } from 'react-router';
import { useQuery } from 'hooks';
import {
  getPastPerformanceDataForEntity,
  PastPerformanceDataForEntityResponse,
} from 'services/firebase/analysis';
import { Nullable } from 'types';
import Button from 'components/shared/Button/Button';

const StepOne: FC = () => {
  const theme = useTheme();
  const history = useHistory();
  const urlQuery = useQuery();
  const { url } = useRouteMatch();
  const sliderRef = useRef<HTMLDivElement>(null);
  const thumbRef = useRef<HTMLDivElement>(null);
  const sliderWidth = sliderRef.current
    ? sliderRef.current.getBoundingClientRect().width
    : 0;

  const { userEntity, entityId } = useStoreState((state) => state.UserState);
  const { entityDefaultCurrency } = useStoreState((state) => state.UserState);
  const { currencyByCode } = useStoreState((state) => state.CurrenciesState);

  const [isLoading, setIsLoading] = useState(true);
  const [rateLimit, setRateLimit] = useState(10000);
  const [pastPerformanceData, setPastPerformanceData] = useState<
    Nullable<PastPerformanceDataForEntityResponse>
  >(null);

  const currency = currencyByCode(entityDefaultCurrency);

  const getPastData = async (id: string) => {
    try {
      setIsLoading(true);
      const { data } = await getPastPerformanceDataForEntity({
        entityId: id,
      });

      if (data && data.success) {
        setPastPerformanceData(data?.data);
      }
    } catch (error) {
      console.warn(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const onContinue = async () => {
    try {
      setIsLoading(true);

      const response = await updateEntitySettings({
        riskTolerance: rateLimit,
      });

      if (response?.data?.success) {
        urlQuery.set('step', '2');
        history.push(`${url}?${urlQuery.toString()}`);
      } else {
        Notify.error(response?.data?.message ?? '');
        setIsLoading(false);
      }
    } catch (error) {
      Notify.error(error.response?.data?.error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (userEntity.riskTolerance) {
      setRateLimit(userEntity.riskTolerance);
    }
  }, [userEntity]);

  useEffect(() => {
    if (entityId) {
      getPastData(entityId);
    }
  }, [entityId]);

  return isLoading ? (
    <StaleLoader size="large" />
  ) : (
    <>
      <Title mb mbValue={theme.spacing.xxl} variant="h1">
        Risk tolerance
      </Title>

      <Col justifyContent="center">
        <Paragraph mb>
          {`Based on historical accounts your business was exposed to potential losses of up to ${parseIntoCurrencyStringWithSymbol(
            Math.abs(pastPerformanceData?.worstPotentialLoss || 0),
            currency?.symbol
          )} in a month and`}
          <strong>
            {` lost ${parseIntoCurrencyStringWithSymbol(
              Math.abs(pastPerformanceData?.worstCumulativeImpact || 0),
              currency?.symbol
            )} in the worst month `}
          </strong>
          due to currency swings.
        </Paragraph>
        <Paragraph mb mbValue={theme.spacing.xl}>
          Booking guaranteed exchange rates in advance can help limit such
          losses.
        </Paragraph>

        <Title variant="h3">Set a limit for future currency losses:</Title>

        <ReactSliderWrapper ref={sliderRef}>
          <ReactSlider
            value={rateLimit}
            min={0}
            max={50000}
            step={500}
            className="time-range"
            thumbClassName="example-thumb"
            trackClassName="example-track"
            onChange={(value) => {
              if (Array.isArray(value)) {
                setRateLimit(value[0]);
              } else {
                setRateLimit(value);
              }
            }}
            renderThumb={(props) => {
              let translateX = 0;
              let thumbWidth = 0;
              if (thumbRef.current) {
                thumbWidth = thumbRef.current.getBoundingClientRect().width;

                const styleRight = parseFloat(
                  props.style?.right?.toString().replace('px', '') ?? '0'
                );

                const newPositionLeft =
                  sliderWidth - styleRight + (isMobile ? -10 : 10);

                const newPositionRight = styleRight - thumbWidth / 2 + 36;

                if (
                  sliderWidth - styleRight + 16 < thumbWidth / 2 &&
                  translateX !== newPositionLeft
                ) {
                  translateX = newPositionLeft;
                } else if (styleRight + 16 < thumbWidth / 2) {
                  translateX = newPositionRight;
                } else {
                  translateX = 0;
                }
              }

              return (
                <div {...props}>
                  <div
                    className="tooltip"
                    ref={thumbRef}
                    style={
                      translateX
                        ? {
                            transform: `translate(calc(-50% + ${translateX}px))`,
                          }
                        : {}
                    }
                  >
                    <Paragraph variant="bold" color="white">
                      {parseIntoCurrencyStringWithSymbol(
                        rateLimit,
                        currency?.symbol,
                        0
                      )}
                    </Paragraph>
                  </div>
                </div>
              );
            }}
          />
        </ReactSliderWrapper>

        <Row mb mbValue={theme.spacing.xxl}>
          <Paragraph variant="bold">
            {parseIntoCurrencyStringWithSymbol(0, currency?.symbol, 0)}
          </Paragraph>
          <Paragraph variant="bold">
            {parseIntoCurrencyStringWithSymbol(50000, currency?.symbol, 0)}
          </Paragraph>
        </Row>

        <Button
          disabled={isLoading}
          isLoading={isLoading}
          onClick={onContinue}
        >
          Continue
        </Button>
      </Col>
    </>
  );
};

export default StepOne;
