import styled, { css } from 'styled-components';
import { StaleParagraphMedium, ButtonStyleLink } from 'components';

export const Wrapper = styled.div(
  ({ theme }) => css`
    background: ${theme.color.white};
    box-shadow: ${theme.shadow};
    border-radius: 8px;
    padding: 16px 14px 20px 12px;
    margin: 0 0 12px;
    display: flex;
    width: 100%;
    cursor: pointer;
    position: relative;

    @media (min-width: ${theme.breakpoint.largeMin}px) {
      align-items: center;
    }

    .col {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      @media (min-width: ${theme.breakpoint.mediumMin}px) {
        max-width: 300px;
      }
      @media (min-width: ${theme.breakpoint.largeMin}px) {
        max-width: 495px;
      }

      ${ButtonStyleLink} {
        margin-left: 0;
      }
    }

    .icon {
      width: 88px;
      min-width: 88px;
      height: 88px;
      margin-right: 12px;
      fill: ${theme.color.dark};
    }

    ${StaleParagraphMedium} {
      color: ${theme.color.greyDark};
      margin: 2px 0 8px;
    }

    ${ButtonStyleLink} {
      @media (min-width: ${theme.breakpoint.mediumMin}px) {
        font-weight: 700;
        margin-left: auto;
      }
    }
  `
);
