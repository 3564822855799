import { FC, useMemo, useState } from 'react';
import { Wrapper } from './InvoiceInfo.styles';
import {
  StaleTitleH3,
  StaleTitleH5,
  StaleSubTitleMedium,
  StaleParagraphMedium,
  StaleInput,
  Row,
  StalePrebookRateNotification,
  StaleLimitedAccess,
  Col,
  Paragraph,
  InlineLoader,
} from 'components';
import { useForm } from 'react-hook-form';
import { useStoreActions, useStoreState } from 'state';
import dayjs from 'dayjs';
import { firstCharacterToUppercase, parseIntoCurrencyString } from 'utils';
import { useHistory } from 'react-router-dom';
import InvoiceDecide from 'components/shared/InvoicesTable/components/Popups/Decide';
import { CONTRACT_STATUS, IInvoice, INVOICE_STATUSES } from 'types';
import _orderBy from 'lodash.orderby';
import UseExistingPrebook from 'components/shared/InvoicesTable/components/Popups/UseExistingPrebook/UseExistingPrebook';
import useIsAwaitingRegistration from 'hooks/useIsAwaitingRegistration';
import useCurrencyRate from 'hooks/useCurrencyRate';
import InvoiceRate from './components/InvoiceRate/InvoiceRate';
import Attachments from './components/Attachments/Attachments';
import InvoiceLastSyncDetails from './components/InvoiceLastSyncDetails/InvoiceLastSyncDetails';
import useInvoiceRecord from 'pages/Invoices/hooks/useInvoiceRecord';
import {
  getInvoiceNumber,
  getInvoicePrebookLink,
  getInvoiceTransferLink,
  isInvoiceTrackable,
  isPayableInvoice,
} from 'utils/invoices';
import { useTheme } from 'styled-components';
import { getLink } from 'utils/links';
import useInvoicesApprovalStatus from 'hooks/useInvoiceApprovalStatus';
import Button from 'components/shared/Button/Button';

interface OwnProps {
  invoice: IInvoice;
}

const InvoiceInfo: FC<OwnProps> = ({ invoice }) => {
  const history = useHistory();
  const {
    isSameCurrency,
    isCanBePaid,
    isPayable,
    submittableForReview,
    isApprovable,
  } = useInvoiceRecord({
    record: invoice,
  });
  const { deleteInvoiceTracking } = useStoreActions(
    (actions) => actions.InvoicesState
  );
  const { hasApprovalFlow } = useStoreState(({ UserState }) => UserState);
  const { control } = useForm();
  const { rate } = useCurrencyRate({
    predicate: invoice.currency !== 'GBP',
    buyCurrency: isPayable ? invoice.currency : 'GBP',
    sellCurrency: isPayable ? 'GBP' : invoice.currency,
  });
  const { currencyByCode } = useStoreState((state) => state.CurrenciesState);
  const { rateContractById, rateContractsByCurrencyPair } = useStoreState(
    (state) => state.RateContractsState
  );
  const { transferById } = useStoreState((state) => state.TransfersState);
  const [showInvoiceDecide, setShowInvoiceDecide] = useState(false);
  const [existingPrebookCurrency, setExisingPrebookCurrency] = useState<
    string | null
  >(null);
  const invoiceContact = invoice?.contact;
  const isTrackable = isInvoiceTrackable(invoice);
  const currency = currencyByCode(invoice.currency);
  const prebookedRateContract = rateContractById(invoice.contractId);
  const invoiceTransfer = transferById(invoice.transferId);
  const daysLeft = useMemo(() => dayjs(invoice.dueDate).diff(dayjs(), 'days'), [
    invoice,
  ]);
  const [openRegisterCompanyPopup, setOpenRegisterCompanyPopup] = useState(
    false
  );
  const isAwaitingRegistration = useIsAwaitingRegistration();
  const {
    isUpdatingInvoicesApprovalStatus: isUpdatingInvoiceApprovalStatus,
    updateInvoicesApprovalStatus,
  } = useInvoicesApprovalStatus();

  const availablePrebookedRate = useMemo(
    () =>
      _orderBy(
        rateContractsByCurrencyPair('GBP', invoice.currency).filter(
          (item) =>
            item.remainingBuyAmount >= (invoice.amountDue ?? 0) &&
            item.status === CONTRACT_STATUS.readyToUse &&
            dayjs(item.expiryDate).isAfter(dayjs().subtract(1, 'day'))
        ),
        'rate',
        'desc'
      )[0],
    [invoice, rateContractsByCurrencyPair]
  );

  const goToTransfer = () => {
    if (isAwaitingRegistration) {
      setOpenRegisterCompanyPopup(true);
      return;
    }

    history.push(getInvoiceTransferLink(invoice));
  };

  const goToCurrencyExchange = async () => {
    const link = getLink('/app/currency-exchange?step=1', {
      predefinedSellAmount: invoice.amountDue.toString(),
      predefinedSellCurrency: invoice.currency,
      predefinedRateContractId: invoice.contractId,
      step: '2',
      invoiceId: invoice.id,
    });

    history.push(link);
  };

  const goToPrebook = () => {
    if (isAwaitingRegistration) {
      setOpenRegisterCompanyPopup(true);
      return;
    }

    history.push(getInvoicePrebookLink(invoice));
  };

  const getInvoiceContactBillingAddress = () => {
    let address = '';
    if (invoiceContact?.recipientAddress) {
      address += invoiceContact?.recipientAddress;
    }
    if (invoiceContact?.recipientPostcode) {
      address += ` ${invoiceContact?.recipientPostcode}`;
    }
    if (invoiceContact?.recipientCity) {
      address += ` ${invoiceContact?.recipientCity}`;
    }

    return `${address.trim()} `;
  };

  const theme = useTheme();

  const renderInvoiceSellAmount = () => {
    let rateToUse: number | undefined;

    if (invoiceTransfer?.rate) {
      rateToUse = invoiceTransfer?.rate;
    } else if (prebookedRateContract?.rate) {
      rateToUse = prebookedRateContract.rate;
    } else if (rate) {
      rateToUse = rate;
    }

    if (rateToUse !== undefined) {
      if (invoice.type === 'Receivable') {
        return `£${parseIntoCurrencyString(invoice.totalAmount * rateToUse)}`;
      }

      return `£${parseIntoCurrencyString(invoice.totalAmount / rateToUse)}`;
    }

    return <InlineLoader />;
  };

  const renderActionButtons = () => {
    if (isPayable) {
      if (daysLeft >= 7) {
        return (
          <>
            {!invoice.contractId && (
              <Button mr onClick={goToPrebook}>
                Prebook the rate
              </Button>
            )}

            {isCanBePaid && (
              <Button mr onClick={goToTransfer}>
                Pay
              </Button>
            )}

            {submittableForReview && (
              <Button
                mr
                disabled={isUpdatingInvoiceApprovalStatus}
                onClick={() =>
                  updateInvoicesApprovalStatus({
                    invoiceIds: [invoice.id],
                    approvalStatus: 'submitted',
                  })
                }
              >
                Submit
              </Button>
            )}

            {isApprovable && (
              <Button
                mr
                disabled={isUpdatingInvoiceApprovalStatus}
                onClick={() =>
                  updateInvoicesApprovalStatus({
                    invoiceIds: [invoice.id],
                    approvalStatus: 'approved',
                  })
                }
              >
                Approve
              </Button>
            )}

            {isTrackable && !invoice.contractId && (
              <Button
                mr
                variant="link"
                onClick={() => {
                  if (isAwaitingRegistration) {
                    setOpenRegisterCompanyPopup(true);
                    return;
                  }
                  if (invoice.trackingId) {
                    deleteInvoiceTracking({
                      trackingId: invoice.trackingId,
                    });
                  } else {
                    setShowInvoiceDecide(true);
                  }
                }}
                icon="eye-table-ico"
              >
                {invoice.trackingId ? 'Stop Tracking' : 'Track the rate'}
              </Button>
            )}
          </>
        );
      }

      return (
        <>
          {isCanBePaid && (
            <Button mr onClick={goToTransfer}>
              Pay
            </Button>
          )}

          {submittableForReview && (
            <Button
              mr
              disabled={isUpdatingInvoiceApprovalStatus}
              onClick={() =>
                updateInvoicesApprovalStatus({
                  invoiceIds: [invoice.id],
                  approvalStatus: 'submitted',
                })
              }
            >
              Submit
            </Button>
          )}

          {isApprovable && (
            <Button
              mr
              disabled={isUpdatingInvoiceApprovalStatus}
              onClick={() =>
                updateInvoicesApprovalStatus({
                  invoiceIds: [invoice.id],
                  approvalStatus: 'approved',
                })
              }
            >
              Approve
            </Button>
          )}

          {!invoice.contractId && (
            <Button mr variant="link" onClick={goToPrebook}>
              Prebook the rate
            </Button>
          )}
        </>
      );
    }

    return invoice.contractId ? (
      <Button mr onClick={goToCurrencyExchange}>
        Collect
      </Button>
    ) : (
      <Button mr onClick={goToPrebook}>
        Prebook the rate
      </Button>
    );
  };

  return (
    <>
      <Wrapper>
        <div className="head">
          <StaleTitleH3>Invoice {getInvoiceNumber(invoice)}</StaleTitleH3>

          <StaleSubTitleMedium className="status">
            {hasApprovalFlow &&
            invoice.status !== 'PAID' &&
            isPayableInvoice(invoice)
              ? invoice.approval?.status
                ? firstCharacterToUppercase(invoice.approval?.status)
                : 'Needs approval'
              : invoice.excludeFromRisk
              ? 'EXCLUDED'
              : invoice.status}
          </StaleSubTitleMedium>
        </div>

        <div className="content">
          <InvoiceLastSyncDetails invoice={invoice} />
          <Paragraph mb variant="bold">
            Bill to
          </Paragraph>

          <Row columnGap={theme.spacing.m}>
            <Col
              alignSelf="stretch"
              style={{
                width: 320,
              }}
            >
              <div className="field">
                <StaleInput
                  id="company"
                  name="company"
                  label="Company"
                  view="moving"
                  disabled
                  defaultValue={invoiceContact?.recipientName}
                  control={control}
                />
              </div>

              <div className="field">
                <StaleInput
                  id="email"
                  name="email"
                  label="Email"
                  view="moving"
                  disabled
                  defaultValue={
                    invoiceContact?.recipientEmail || 'Not specified'
                  }
                  control={control}
                />
              </div>

              <div className="field">
                <StaleInput
                  id="billingAddress"
                  name="billingAddress"
                  label="Billing address"
                  view="moving"
                  disabled
                  defaultValue={getInvoiceContactBillingAddress()}
                  control={control}
                />
              </div>
            </Col>

            <Col flex={1}>
              <Row
                style={{
                  flex: 1,
                  alignItems: 'flex-start',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: 204,
                  }}
                >
                  <div className="field">
                    <StaleInput
                      id="invoiceDate"
                      name="invoiceDate"
                      label="Invoice Date"
                      view="moving"
                      disabled
                      defaultValue={dayjs(invoice.date).format('D MMM YYYY')}
                      control={control}
                    />
                  </div>
                  <div className="field">
                    <StaleInput
                      id="dueDate"
                      name="dueDate"
                      label="Due Date"
                      view="moving"
                      disabled
                      defaultValue={dayjs(invoice.dueDate).format('D MMM YYYY')}
                      control={control}
                    />
                  </div>
                </div>

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                  }}
                >
                  <div className="row-info">
                    <StaleParagraphMedium>
                      {`Invoice currency: `}
                      <svg
                        width="24px"
                        height="24px"
                        style={{ marginRight: '8px' }}
                      >
                        <use xlinkHref={`#${currency?.countryCode}`} />
                      </svg>
                      {invoice.currency}
                    </StaleParagraphMedium>
                  </div>

                  <InvoiceRate
                    prebookedRateContract={prebookedRateContract}
                    currency={invoice.currency}
                    invoiceType={invoice.type}
                    transfer={invoiceTransfer}
                    rate={rate}
                  />

                  {invoice?.status === INVOICE_STATUSES.partiallyPaid && (
                    <div className="row-info">
                      <StaleParagraphMedium>Due amount:</StaleParagraphMedium>
                      <StaleTitleH5>{` ${
                        currency?.symbol
                      }${parseIntoCurrencyString(
                        invoice.amountDue
                      )}`}</StaleTitleH5>
                    </div>
                  )}

                  <div className="row-info">
                    <StaleParagraphMedium>Total:</StaleParagraphMedium>
                    <StaleTitleH5>{` ${
                      currency?.symbol
                    }${parseIntoCurrencyString(
                      invoice.totalAmount
                    )}`}</StaleTitleH5>
                  </div>
                </div>
              </Row>

              {!invoice.contractId &&
                !!availablePrebookedRate &&
                isPayable &&
                isCanBePaid && (
                  <StalePrebookRateNotification
                    position="left"
                    amount={invoice.amountDue ?? 0}
                    buyCurrency={currency}
                    sellCurrency={{ code: 'GBP', symbol: '£' }}
                    onSubmit={() => setExisingPrebookCurrency(invoice.currency)}
                  />
                )}
            </Col>
          </Row>

          <table className="invoice-table">
            <thead>
              <tr>
                <th>
                  <StaleParagraphMedium>#</StaleParagraphMedium>
                </th>
                <th>
                  <StaleParagraphMedium>Description</StaleParagraphMedium>
                </th>
                <th>
                  <StaleParagraphMedium>Quantity</StaleParagraphMedium>
                </th>
                <th>
                  <StaleParagraphMedium>Rate</StaleParagraphMedium>
                </th>
                <th>
                  <StaleParagraphMedium>Amount</StaleParagraphMedium>
                </th>
              </tr>
            </thead>

            <tbody>
              {invoice.lineItems?.map((item, index) => {
                return (
                  <tr key={item.itemCode}>
                    <td>
                      <div className="field-bg">
                        <StaleParagraphMedium>{index + 1}</StaleParagraphMedium>
                      </div>
                    </td>
                    <td>
                      <div className="field-bg">
                        <StaleParagraphMedium>
                          {item.description || item.name}
                        </StaleParagraphMedium>
                      </div>
                    </td>
                    <td>
                      <div className="field-bg">
                        <StaleParagraphMedium>
                          {item.quantity}
                        </StaleParagraphMedium>
                      </div>
                    </td>
                    <td>
                      <div className="field-bg">
                        <StaleParagraphMedium>
                          {parseIntoCurrencyString(
                            item.unitAmount,
                            currency?.precision
                          )}
                        </StaleParagraphMedium>
                      </div>
                    </td>
                    <td>
                      <StaleParagraphMedium>{`${
                        currency?.symbol
                      }${parseIntoCurrencyString(
                        item.lineAmount,
                        currency?.precision
                      )}`}</StaleParagraphMedium>
                    </td>
                  </tr>
                );
              })}

              <tr>
                <td colSpan={1} rowSpan={2}>
                  <Attachments invoice={invoice} />
                </td>
                <td colSpan={3} style={{ paddingRight: '24px' }}>
                  <StaleParagraphMedium>Subtotal:</StaleParagraphMedium>
                </td>
                <td>
                  <StaleParagraphMedium>{`${
                    currency?.symbol
                  }${parseIntoCurrencyString(
                    invoice.subTotalAmount ?? invoice.totalAmount
                  )}`}</StaleParagraphMedium>
                </td>
              </tr>

              <tr>
                <td colSpan={3} style={{ paddingRight: '24px' }}>
                  <StaleParagraphMedium>Taxes:</StaleParagraphMedium>
                </td>
                <td>
                  <StaleParagraphMedium>{`${
                    currency?.symbol
                  }${parseIntoCurrencyString(
                    invoice.totalTaxAmount ?? 0
                  )}`}</StaleParagraphMedium>
                </td>
              </tr>
            </tbody>
          </table>

          <Row
            style={{
              justifyContent: 'flex-start',
              borderTop: '1px solid #F2F2F2',
              paddingTop: 16,
            }}
          >
            {renderActionButtons()}

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginLeft: 'auto',
              }}
            >
              <Row
                style={{
                  alignItems: 'flex-start',
                  whiteSpace: 'break-spaces',
                }}
              >
                <StaleParagraphMedium
                  style={{
                    lineHeight: '24px',
                    marginRight: 4,
                  }}
                >
                  {`Total: `}
                </StaleParagraphMedium>

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  {!isSameCurrency && (
                    <Row
                      style={{
                        marginBottom: 8,
                      }}
                    >
                      <StaleTitleH5>
                        {`${currency?.symbol}${parseIntoCurrencyString(
                          invoice.totalAmount
                        )}`}
                      </StaleTitleH5>
                      <svg
                        width="24px"
                        height="24px"
                        style={{ marginLeft: '8px' }}
                      >
                        <use xlinkHref={`#${currency?.countryCode}`} />
                      </svg>
                    </Row>
                  )}
                  <Row>
                    <StaleSubTitleMedium>
                      {renderInvoiceSellAmount()}
                    </StaleSubTitleMedium>
                    <svg
                      width="24px"
                      height="24px"
                      style={{ marginLeft: '8px' }}
                    >
                      <use xlinkHref="#gb" />
                    </svg>
                  </Row>
                </div>
              </Row>
            </div>
          </Row>
        </div>
      </Wrapper>

      {showInvoiceDecide && (
        <InvoiceDecide
          invoice={invoice}
          onClose={() => setShowInvoiceDecide(false)}
          showAllFields={true}
        />
      )}

      {!!existingPrebookCurrency && (
        <UseExistingPrebook
          invoice={invoice}
          sellCurrency={'GBP'}
          onClose={() => setExisingPrebookCurrency(null)}
        />
      )}
      {openRegisterCompanyPopup && (
        <StaleLimitedAccess
          onClose={() => setOpenRegisterCompanyPopup(false)}
        />
      )}
    </>
  );
};
export default InvoiceInfo;
