import { Datum } from '@nivo/line';
import { Col, Paragraph } from 'components';
import useChartTooltip from 'pages/Reports/hooks/useChartTooltip';
import { FC } from 'react';
import { useTheme } from 'styled-components';
import { parseIntoCurrencyStringWithSymbol } from 'utils';
import ChartPoint from '../ChartPoint/ChartPoint.styles';
import ChartTooltip from '../ChartTooltip/ChartTooltip';

interface OwnProps {
  data: Datum;
  activeMonth: string;
  cx: number;
  cy: number;
  onClick: (month: string) => void;
}

const ChartNetOutstandingBalancePoint: FC<OwnProps> = ({
  data,
  activeMonth,
  onClick,
  cx,
  cy,
}) => {
  const { color } = useTheme();
  const { x, y } = data;
  const { tooltipRef, ...mouseEvents } = useChartTooltip();

  return (
    <g
      onClick={() => {
        if (typeof x === 'string') {
          onClick(x);
        }
      }}
      {...mouseEvents}
    >
      <ChartPoint
        active={activeMonth === x}
        fill={color.blueLight}
        r={6}
        cx={cx}
        cy={cy}
      />

      {typeof y === 'number' && (
        <ChartTooltip wrapperRef={tooltipRef}>
          <Col>
            <Paragraph color="white">{`Outstanding: ${parseIntoCurrencyStringWithSymbol(
              y,
              '£',
              0
            )}`}</Paragraph>
          </Col>
        </ChartTooltip>
      )}
    </g>
  );
};

export default ChartNetOutstandingBalancePoint;
