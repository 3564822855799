import { Row } from 'components';
import styled from 'styled-components';

export const InfoCardWrapper = styled(Row)`
  border: ${({ theme }) => `1px solid ${theme.color.greyLight_2}`};
  padding: ${({ theme }) => `${theme.spacing.s} ${theme.spacing.m}`};
  border-radius: ${({ theme }) => theme.borderRadius.m};
  background: ${({ theme }) => theme.color.greyLight_4};
  gap: ${({ theme }) => theme.spacing.m};
`;
