import { FC, useEffect } from 'react';
import { Title, Paragraph, StaleBtnGroup } from 'components';
import { Wrapper } from './StalePopupDeleteConfirmation.styles';
import OutsideClickHandler from 'react-outside-click-handler';
import Button from '../Button/Button';

interface OwnProps {
  type: 'cancel' | 'delete';
  title: string;
  text: string;
  onCancel: () => void;
  onContinue: () => void;
  onClose: () => void;
  isLoading?: boolean;
}

const StalePopupDeleteConfirmation: FC<OwnProps> = ({
  type,
  title,
  text,
  onCancel,
  onContinue,
  onClose,
  isLoading,
}) => {
  useEffect(() => {
    const handler = (e) => {
      if (e.key === 'Escape' || e.key === 'Esc' || e.keyCode === 27) {
        onClose();
      }
    };
    document.addEventListener('keydown', handler);
    return () => {
      document.removeEventListener('keydown', handler);
    };
  }, [onClose]);

  return (
    <Wrapper>
      <OutsideClickHandler onOutsideClick={onClose}>
        <div className="popup">
          <button className="close" onClick={onClose}>
            <svg width="24" height="24">
              <use xlinkHref="#cross-ico" />
            </svg>
          </button>

          <Title variant="h4">{title}</Title>

          <Paragraph>{text}</Paragraph>
          <StaleBtnGroup horizontal container={false}>
            <Button variant="secondary" onClick={onCancel}>
              {type === 'cancel' ? 'No' : 'Cancel'}
            </Button>
            <Button
              isLoading={isLoading}
              variant="primary"
              onClick={onContinue}
            >
              {type === 'cancel' ? 'Yes, cancel' : 'Yes, delete'}
            </Button>
          </StaleBtnGroup>
        </div>
      </OutsideClickHandler>
    </Wrapper>
  );
};

export default StalePopupDeleteConfirmation;
