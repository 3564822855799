import { IInvoicePayment } from 'types';
import { IExternalPayment } from 'types/externalPayments';

export const transformExternalPaymentToPaymentOnInvoice = (
  payment: IExternalPayment
): IInvoicePayment => ({
  id: payment.externalRefs.codatId,
  recipientName: payment.supplierRef.supplierName ?? '',
  amount: Math.abs(payment.totalAmount), // Using math.abs to match positive values on PAs uploaded via CSV, while from Codat these are negative values
  date: payment.date,
  currency: payment.currency ?? '',
});
