import { FC, useCallback } from 'react';
import { PrebookTable } from 'components';
import useUrlValues from 'hooks/useUrlValues';
import { isExternalHedge } from 'components/shared/PrebookTable/utils';

const PrebooksOverview: FC = () => {
  const { setUrlValue, currency } = useUrlValues('currency');

  const onPrebookTableRowClick = useCallback(
    (record) => {
      setUrlValue(
        isExternalHedge(record) ? 'hedgeId' : 'contractId',
        record.id
      );
    },
    [setUrlValue]
  );

  return (
    <>
      <PrebookTable
        withTitle
        isVirtualized
        currencyCode={currency ?? undefined}
        onRowClick={onPrebookTableRowClick}
      />
    </>
  );
};

export default PrebooksOverview;
