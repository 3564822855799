import { FC, useMemo } from 'react';
import { useStoreState } from 'state';
import {
  appendRateAndProfitAndLossToInvoice,
  isPaidInvoice,
  isOutstandingInvoice,
  isApprovedInvoice,
  isSubmittedInvoice,
} from 'utils/invoices';
import InvoicesTableShared from 'components/shared/InvoicesTable/InvoicesTable';
import { IInvoiceForTable } from 'components/shared/InvoicesTable/types';
import useUrlValues from 'hooks/useUrlValues';
import { StaleLoader } from 'components';
import { detectCurrenciesFromInvoices } from 'pages/Invoices/utils';
import useRates from 'hooks/useRates';

const InvoicesTable: FC = () => {
  const { entityDefaultCurrency } = useStoreState(({ UserState }) => UserState);
  const { invoices, isLoadingInvoices } = useStoreState(
    (state) => state.InvoicesState
  );
  const { currency, tab } = useUrlValues('currency', 'tab');
  const detectedCurrencies = useMemo(
    () => detectCurrenciesFromInvoices(invoices, entityDefaultCurrency),
    [entityDefaultCurrency, invoices]
  );
  const { ratesByCurrency } = useRates({ currencies: detectedCurrencies });

  // TODO: consider moving into BE query
  const invoicesForTable = useMemo(
    () =>
      invoices.reduce<IInvoiceForTable[]>((acc, invoice) => {
        const invoiceWithRateAndProfitAndLoss = appendRateAndProfitAndLossToInvoice(
          invoice,
          ratesByCurrency?.[invoice.currency]
        );

        if (currency && currency !== 'all' && currency !== invoice.currency) {
          return acc;
        }

        if (tab === 'paid' && !isPaidInvoice(invoice)) {
          return acc;
        }

        if (tab === 'outstanding' && !isOutstandingInvoice(invoice)) {
          return acc;
        }

        if (tab === 'approved' && !isApprovedInvoice(invoice)) {
          return acc;
        }

        if (tab === 'submitted' && !isSubmittedInvoice(invoice)) {
          return acc;
        }

        acc.push(invoiceWithRateAndProfitAndLoss);

        return acc;
      }, []),
    [currency, invoices, ratesByCurrency, tab]
  );

  if (isLoadingInvoices) {
    return <StaleLoader size="large" />;
  }

  return (
    <InvoicesTableShared
      isVirtualized
      data={invoicesForTable}
      autoResetSelectedRows
    />
  );
};

export default InvoicesTable;
