import { Col, Icon, Paragraph, Row, Subtitle } from 'components';
import { InnerDetailsWrapper } from 'components/shared/InnerDetails/InnerDetails.styles';
import { RowInfo } from 'components/shared/RowInfo/RowInfo.styles';
import dayjs from 'dayjs';
import { FC } from 'react';
import { useStoreState } from 'state';
import { ITransfer } from 'types';

import {
  getTransferStatusDetails,
  parseIntoCurrencyString,
  parseRateWithPrecision,
} from 'utils';

interface OwnProps {
  // TODO: sometimes it is IBUlkPayment, fix types
  transfer: ITransfer;
}

const BulkPaymentDetails: FC<OwnProps> = ({ transfer }) => {
  const { currencyByCode } = useStoreState(
    ({ CurrenciesState }) => CurrenciesState
  );
  const statusInfo = getTransferStatusDetails(transfer);
  const buyCurrency = currencyByCode(transfer.buyCurrency);

  return (
    <InnerDetailsWrapper>
      <Col flex={1} alignSelf="stretch">
        <Subtitle variant="bold" mb>
          Details
        </Subtitle>

        <RowInfo>
          <Paragraph>Currency rate</Paragraph>
          <Row>
            <Icon
              icon={buyCurrency?.countryCode ?? ''}
              width="20px"
              height="20px"
            />
            <Paragraph ml>
              {`1 ${transfer.sellCurrency} = ${parseRateWithPrecision(
                transfer.rate
              )} ${transfer.buyCurrency}`}
            </Paragraph>
          </Row>
        </RowInfo>
        <RowInfo>
          <Paragraph>Created on</Paragraph>
          <Paragraph>
            {dayjs(transfer._created).format('DD MMM, YYYY')}
          </Paragraph>
        </RowInfo>
      </Col>

      <Col flex={1} alignSelf="stretch">
        <Subtitle variant="bold" mb>
          Amount
        </Subtitle>

        <RowInfo>
          <Paragraph>Transfer amount total</Paragraph>
          <Paragraph>
            {parseIntoCurrencyString(transfer.sellAmount)}{' '}
            {transfer.sellCurrency}
          </Paragraph>
        </RowInfo>
        <RowInfo>
          <Paragraph>Fee amount total</Paragraph>
          <Paragraph>
            {/* @ts-ignore */}
            {transfer.paymentFee} {transfer.sellCurrency}
          </Paragraph>
        </RowInfo>
        <RowInfo>
          <Paragraph variant="bold">Payment amount total</Paragraph>
          <Paragraph variant="bold">
            {parseIntoCurrencyString(transfer.payAmount)}{' '}
            {transfer.sellCurrency}
          </Paragraph>
        </RowInfo>
        <RowInfo>
          <Paragraph variant="bold">Status</Paragraph>
          <div style={{ textAlign: 'right' }} className="col">
            {statusInfo && <Paragraph>{statusInfo.text}</Paragraph>}
            {transfer.status === 'awaiting_payment' && (
              <Paragraph>
                {`(Pay by ${transfer.payByTimeGMT} ${dayjs(
                  transfer.payByDate
                ).format('on D MMM YYYY')})`}
              </Paragraph>
            )}
          </div>
        </RowInfo>
      </Col>
    </InnerDetailsWrapper>
  );
};

export default BulkPaymentDetails;
