import styled, { css } from 'styled-components';

const style = () => css`
  padding: 18px 16px;
  border-radius: 8px;
  margin: 12px 0;
  position: relative;

  a {
    text-decoration: underline;
    font-size: 14px;
    line-height: 150%;
  }
`;
const Icon = ({ theme }) => css`
  padding-left: 52px;

  svg {
    position: absolute;
    left: 16px;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 24px;
    height: 24px;
    fill: ${theme.color.dark};
  }
`;

const Success = ({ theme }) => css`
  background: ${theme.color.emeraldLight};
`;
const Warning = ({ theme }) => css`
  background: ${theme.color.greyLight_2};
`;

const StaleTransferNotification = styled.div<{
  icon?: boolean;
  type?: 'success' | 'warning';
}>(
  (props) => css`
    ${style};

    ${props.type === 'success' && Success}
    ${props.type === 'warning' && Warning}
  
  ${props.icon && Icon}
  `
);

export default StaleTransferNotification;
