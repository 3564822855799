import { Bar, BarDatum, BarSvgProps } from '@nivo/bar';

import AutoSizer from 'react-virtualized-auto-sizer';

const ChartBar = <T extends BarDatum>(
  props: Omit<BarSvgProps<T>, 'height' | 'width'>
) => {
  return (
    <AutoSizer>
      {({ height, width }) => <Bar height={height} width={width} {...props} />}
    </AutoSizer>
  );
};

export default ChartBar;
