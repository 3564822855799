import { Col, StaleLoader, Table } from 'components';
import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from 'react';
import {
  deletePaymentRunInvoice,
  deletePaymentRunTransfer,
  updatePaymentRunTransfer,
} from 'services/paymentRuns';
import {
  IPaymentRun,
  IPaymentRunBreakdownByRecipient,
  IPaymentRunBreakdownByRecipientInvoice,
} from 'types/paymentRuns';
import { errorHandler } from 'utils/errors';
import {
  generatePaymentRunInvoicesTableColumns,
  generatePaymentRunTransfersTableColumns,
} from '../../tableColumnsGenerator';
import ReviewStepForm from './components/ReviewStepForm/ReviewStepForm';

interface OwnProps {
  paymentRunId: string;
  isCombineSameContacts: boolean;
  data: IPaymentRunBreakdownByRecipient[];
  localCurrencyCode: string;
  setPaymentRun: Dispatch<SetStateAction<IPaymentRun>>;
  onContinue: () => void;
}

const ReviewStep: FC<OwnProps> = ({
  paymentRunId,
  data,
  localCurrencyCode,
  isCombineSameContacts,
  setPaymentRun,
  onContinue,
}) => {
  const [isUpdatingPaymentRun, setIsUpdatingPaymentRun] = useState(false);

  const onEditReference = useCallback(
    async (recordId: string, updatedData: IPaymentRunBreakdownByRecipient) => {
      try {
        const { data: response } = await updatePaymentRunTransfer({
          paymentRunId,
          paymentRunTransferId: recordId,
          reference: updatedData.reference,
        });

        if (response.data) {
          setPaymentRun(response.data);
        }
      } catch (error) {
        errorHandler(error);
      }
    },
    [paymentRunId, setPaymentRun]
  );

  const onRemoveTransfer = useCallback(
    async (paymentRunTransferId: string) => {
      try {
        const { data: response } = await deletePaymentRunTransfer({
          paymentRunId,
          paymentRunTransferId,
        });

        if (response.data) {
          setPaymentRun(response.data);
        }
      } catch (error) {
        errorHandler(error);
      }
    },
    [paymentRunId, setPaymentRun]
  );

  const onRemoveInvoice = useCallback(
    async (paymentRunInvoiceId: string) => {
      try {
        const { data: response } = await deletePaymentRunInvoice({
          paymentRunId,
          paymentRunInvoiceId,
        });

        if (response.data) {
          setPaymentRun(response.data);
        }
      } catch (error) {
        errorHandler(error);
      }
    },
    [paymentRunId, setPaymentRun]
  );

  const paymentRunTransfersTableColumns = useMemo(
    () =>
      generatePaymentRunTransfersTableColumns({
        onEditReference,
        onRemoveTransfer,
      }),
    [onEditReference, onRemoveTransfer]
  );
  const paymentRunInvoicesTableColumnsExpansion = useMemo(
    () =>
      generatePaymentRunInvoicesTableColumns({
        onRemoveInvoice,
      }),
    [onRemoveInvoice]
  );

  return (
    <Col style={{ position: 'relative' }}>
      {isUpdatingPaymentRun && (
        <StaleLoader
          withBackdrop
          size="large"
          style={{ position: 'absolute', inset: 0, zIndex: 3 }}
        />
      )}
      <Table<IPaymentRunBreakdownByRecipient>
        data={data}
        columns={paymentRunTransfersTableColumns}
        isExpandable={(record) => !!record}
        isRowDisabled={(record) => !record.valid}
        autoResetExpanded={false}
        expansionRender={(record) => (
          <Table<IPaymentRunBreakdownByRecipientInvoice>
            data={record.invoices}
            defaultRowHeight={50}
            isExpandable={() => false}
            isRowDisabled={(record) => !record.valid}
            columns={paymentRunInvoicesTableColumnsExpansion}
            autoResetExpanded={false}
            withHead={false}
          />
        )}
        renderFooterContent={
          <ReviewStepForm
            paymentRunId={paymentRunId}
            isCombineSameContacts={isCombineSameContacts}
            estimatedCost={data.reduce((acc, item) => acc + item.localValue, 0)}
            localCurrencyCode={localCurrencyCode}
            setPaymentRun={setPaymentRun}
            setIsUpdatingPaymentRun={setIsUpdatingPaymentRun}
            onSubmit={onContinue}
          />
        }
        sortable
      />
    </Col>
  );
};

export default ReviewStep;
