import useUrlValues from 'hooks/useUrlValues';
import { useMemo, useState, useEffect } from 'react';
import { getInvoicesByIdArray } from 'services/firebase/invoices';
import { getDraftPaymentRun } from 'services/paymentRuns';
import { useStoreState } from 'state';
import {
  isPayableInvoice,
  isOutstandingInvoice,
  isPaidInvoice,
  isApprovedInvoice,
  isSubmittedInvoice,
  isInvoiceOverdue,
  isInvoiceDueIn14Days,
} from 'utils/invoices';
import { isExpired, isAvailable, isUsed } from 'utils/rateContracts';

export interface ITabsCounts {
  available: number;
  expired: number;
  used: number;
  prebookedFx: number;
  all: number;
  paid: number;
  approved: number;
  submitted: number;
  payables: number;
  overdue: number;
  dueIn14Days: number;
  paymentRun: number;
}

export interface ICurrenciesByTab {
  outstanding: Set<string>;
  submitted: Set<string>;
  approved: Set<string>;
  paymentRun: Set<string>;
  paid: Set<string>;
  prebookings: Set<string>;
}

const useInvoicesPageTabsCountsAndCurrencies = () => {
  const { currency, tab } = useUrlValues('tab', 'currency');
  const { entityId } = useStoreState((state) => state.UserState);
  const { invoices } = useStoreState((state) => state.InvoicesState);
  const { isUpdatingPaymentRun } = useStoreState(
    (state) => state.PaymentRunsState
  );
  const { rateContracts } = useStoreState((state) => state.RateContractsState);
  const { externalHedges } = useStoreState(
    (state) => state.ExternalHedgesState
  );
  const { currencyByCode } = useStoreState(
    ({ CurrenciesState }) => CurrenciesState
  );
  const [paymentRunInvoiceIds, setPaymentRunInvoiceIds] = useState<string[]>(
    []
  );
  const [currenciesByTab, setCurrenciesByTab] = useState<ICurrenciesByTab>({
    outstanding: new Set<string>(),
    submitted: new Set<string>(),
    approved: new Set<string>(),
    paymentRun: new Set<string>(),
    paid: new Set<string>(),
    prebookings: new Set<string>(),
  });

  useEffect(() => {
    if (entityId && !isUpdatingPaymentRun) {
      const getPaymentRunData = async () => {
        try {
          const { data: response } = await getDraftPaymentRun({ entityId });
          if (!response.data) {
            return;
          }

          setPaymentRunInvoiceIds(response.data.instructions.invoiceIds);
        } catch (error) {
          console.error('Failed to get payment run invoices: ', error);
        }
      };

      getPaymentRunData();
    }
  }, [entityId, isUpdatingPaymentRun, currencyByCode]);

  useEffect(() => {
    if (tab === 'paymentRun') {
      const getPaymentRunData = async () => {
        try {
          const paymentRunInvoices = await getInvoicesByIdArray(
            paymentRunInvoiceIds
          );

          paymentRunInvoices.forEach((invoice) => {
            if (!!currencyByCode(invoice.currency)?.enabled) {
              setCurrenciesByTab((prevState) => ({
                ...prevState,
                paymentRun: prevState.paymentRun.add(invoice.currency),
              }));
            }
          });
        } catch (error) {
          console.error('Failed to get payment run invoices: ', error);
        }
      };

      getPaymentRunData();
    }
  }, [paymentRunInvoiceIds, tab, currencyByCode]);

  const invoicesCounts = useMemo(
    () =>
      invoices.reduce<{
        all: number;
        paid: number;
        approved: number;
        submitted: number;
        payables: number;
        overdue: number;
        dueIn14Days: number;
      }>(
        (acc, invoice) => {
          if (currency && currency !== 'all' && currency !== invoice.currency) {
            return acc;
          }

          const isEnabledCurrency = !!currencyByCode(invoice.currency)?.enabled;

          if (isOutstandingInvoice(invoice)) {
            acc.all += 1;

            if (isEnabledCurrency) {
              setCurrenciesByTab((prevState) => ({
                ...prevState,
                outstanding: prevState.outstanding.add(invoice.currency),
              }));
            }
          }

          if (isPaidInvoice(invoice)) {
            acc.paid += 1;

            if (isEnabledCurrency) {
              setCurrenciesByTab((prevState) => ({
                ...prevState,
                paid: prevState.paid.add(invoice.currency),
              }));
            }
          }

          if (isApprovedInvoice(invoice)) {
            acc.approved += 1;

            if (isEnabledCurrency) {
              setCurrenciesByTab((prevState) => ({
                ...prevState,
                approved: prevState.approved.add(invoice.currency),
              }));
            }
          }

          if (isSubmittedInvoice(invoice)) {
            acc.submitted += 1;

            if (isEnabledCurrency) {
              setCurrenciesByTab((prevState) => ({
                ...prevState,
                submitted: prevState.submitted.add(invoice.currency),
              }));
            }
          }

          if (isPayableInvoice(invoice)) {
            acc.payables += 1;
          }

          if (isInvoiceOverdue(invoice)) {
            acc.overdue += 1;
          }

          if (isInvoiceDueIn14Days(invoice)) {
            acc.dueIn14Days += 1;
          }

          return acc;
        },
        {
          all: 0,
          paid: 0,
          approved: 0,
          submitted: 0,
          payables: 0,
          overdue: 0,
          dueIn14Days: 0,
        }
      ),
    [currency, invoices, currencyByCode]
  );

  const prebookingsAndExternalHedgesCounts = useMemo(
    () =>
      [...rateContracts, ...externalHedges].reduce<{
        available: number;
        expired: number;
        used: number;
        prebookedFx: number;
      }>(
        (acc, item) => {
          if (currency && currency !== 'all' && currency !== item.buyCurrency) {
            return acc;
          }

          acc.prebookedFx += 1;

          if (!!currencyByCode(item.buyCurrency)?.enabled) {
            setCurrenciesByTab((prevState) => ({
              ...prevState,
              prebookings: prevState.prebookings.add(item.buyCurrency),
            }));
          }

          if (isAvailable(item)) {
            acc.available += 1;
          }

          if (isExpired(item)) {
            acc.expired += 1;
          }

          if (isUsed(item)) {
            acc.used += 1;
          }

          return acc;
        },
        {
          available: 0,
          expired: 0,
          used: 0,
          prebookedFx: 0,
        }
      ),
    [currency, rateContracts, externalHedges, currencyByCode]
  );

  return {
    counts: {
      ...invoicesCounts,
      ...prebookingsAndExternalHedgesCounts,
      paymentRun: paymentRunInvoiceIds.length,
    } as ITabsCounts,
    currenciesByTab,
  };
};

export default useInvoicesPageTabsCountsAndCurrencies;
