import { Column, Row } from 'react-table';
import { IInvoice } from 'types';
import {
  PaymentNumberCell,
  RecipientNameCell,
  PaymentAmountCell,
} from 'components/shared/InvoicesTable/components/Cells/Cells';
import React, { useMemo, useRef } from 'react';
import { Table } from 'components';
import { IExternalPayment } from 'types/externalPayments';
import DateCell from 'components/shared/DateCell/DateCell';
import { ExposedUseTableProps } from 'components/shared/Table/Table';

const generateLinkPaymentTableColumns = (): Column<IExternalPayment>[] => {
  return [
    {
      accessor: 'externalRefs',
      Header: 'Payment',
      disableSortBy: true,
      Cell: ({ value }) => <PaymentNumberCell value={value.codatId} />,
      width: 80,
    },
    {
      accessor: 'supplierRef',
      Header: 'Name',
      Cell: ({ value }) => (
        <RecipientNameCell recipientName={value.supplierName} />
      ),
      width: 120,
    },
    {
      accessor: 'date',
      Header: 'Paym. date',
      Cell: ({ value }) => <DateCell value={value} />,
      width: 100,
    },
    {
      id: 'CCY',
      Header: 'Amount',
      disableSortBy: true,
      Cell: ({ row }) => (
        <PaymentAmountCell
          payment={{
            currency: row.original.currency,
            amount: Math.abs(row.original.totalAmount),
          }}
        />
      ),
      width: 100,
    },
  ];
};

interface Props {
  payments: IExternalPayment[];
  invoice: IInvoice;
  onSelectExternalPayment: (
    _: any,
    payments: Array<IExternalPayment | any>
  ) => void;
  isRowSelectable: (props: {
    row: Row<IExternalPayment>;
    selectedFlatRows: Row<IExternalPayment>[];
  }) => boolean;
}
const LinkPaymentsTable: React.FC<Props> = ({
  invoice,
  isRowSelectable,
  onSelectExternalPayment,
  payments,
}) => {
  const columns = useMemo(generateLinkPaymentTableColumns, []);
  const ref = useRef<ExposedUseTableProps<IExternalPayment>>(null);

  const invoicePaymentId = invoice.payments?.[0]?.id;
  const indexOfSelectedPayment = payments.findIndex(
    (payment) => payment.externalRefs.codatId === invoicePaymentId
  );

  return (
    <Table<IExternalPayment>
      selectable
      isRowSelectable={isRowSelectable}
      columns={columns}
      minVisibleRows={payments.length}
      data={payments}
      onSelect={onSelectExternalPayment}
      ref={ref}
      initialState={{ selectedRowIds: { [indexOfSelectedPayment]: true } }}
      disabledCheckboxHint="Can only select one payment"
    />
  );
};

export default LinkPaymentsTable;
