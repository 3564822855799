import { FC } from 'react';

import {
  StaleInputConvert,
  StaleGetRateFailed,
  Subtitle,
  Paragraph,
  Row,
} from 'components';
import PrebookedRateBlock from 'components/shared/PrebookedRateBlock/PrebookedRateBlock';
import { parseIntoCurrencyString } from 'utils';
import {
  FlowContentLeft,
  FlowContentRight,
  FlowStepContent,
  FlowStepFooter,
  FlowStepWrapper,
} from 'components/shared/FlowStep/FlowStep.styles';
import { useHistory } from 'react-router-dom';
import RateSelect from 'components/shared/RateSelect/RateSelect';
import { RATE_TYPE } from 'types';
import { useTheme } from 'styled-components';
import { UseExchangeDetailsReturnValues } from 'pages/CurrencyExchange/hooks/useExchangeDetails';
import { getLink } from 'utils/links';
import Button from 'components/shared/Button/Button';

interface OwnProps extends UseExchangeDetailsReturnValues {
  onContinue: () => void;
}

const ExchangeDetails: FC<OwnProps> = ({
  onContinue,
  rateContracts,
  selectedRateContract,
  rate,
  rateType,
  isLoading,
  buyAmountAsNumber,
  sellAmountAsNumber,
  buyCurrency,
  sellCurrency,
  sellCurrencies,
  setRateType,
  setSelectedRateContract,
  updateBuyAmount,
  updateSellAmount,
  updateSellCurrency,
  setFixedSide,
  updateBuyCurrency,
  isMarketRate,
  buyCurrencies,
  activeBalance,
  rateToUse,
  canContinue,
  prebookAndMarketRateDifference,
  isError,
  getRate,
  buyCurrencyCode,
  sellCurrencyCode,
  invoiceId,
}) => {
  const theme = useTheme();
  const history = useHistory();

  return (
    <>
      <FlowStepWrapper>
        <FlowStepContent>
          <FlowContentLeft>
            <RateSelect
              rateContracts={rateContracts}
              selectedRateContract={selectedRateContract}
              rate={rate}
              rateType={rateType}
              isRateLoading={isLoading}
              buyAmount={buyAmountAsNumber}
              sellCurrency={sellCurrency}
              buyCurrency={buyCurrency}
              setSelectedRateContract={setSelectedRateContract}
              setRateType={setRateType}
              invoiceId={invoiceId}
            />

            <Subtitle variant="bold" mt mb mbValue={theme.spacing.xs}>
              Convert from
            </Subtitle>

            <StaleInputConvert
              id="sellAmount"
              value={sellAmountAsNumber}
              onChange={updateSellAmount}
              currencies={sellCurrencies}
              selectedValue={sellCurrency}
              onSelect={(value) => {
                updateSellCurrency(value);
                setRateType(RATE_TYPE.market); //TODO: Oleksii to check if this is correct
              }}
              onFocus={() => setFixedSide('sell')}
              error={
                (activeBalance?.amount || 0) < sellAmountAsNumber
                  ? `Amount is too high. You do not have enough balance in your ${sellCurrency.code} account.`
                  : ''
              }
            />

            {isMarketRate ? (
              <>
                <Subtitle variant="bold" mt mb mbValue={theme.spacing.xs}>
                  To
                </Subtitle>

                <StaleInputConvert
                  id="buyAmount"
                  value={buyAmountAsNumber}
                  onChange={updateBuyAmount}
                  currencies={buyCurrencies}
                  selectedValue={buyCurrency}
                  onSelect={updateBuyCurrency}
                  onFocus={() => setFixedSide('buy')}
                />
              </>
            ) : (
              <Row mt>
                <Paragraph variant="bold">
                  To
                  {` ${buyCurrency?.symbol}${parseIntoCurrencyString(
                    buyAmountAsNumber,
                    buyCurrency.precision
                  )} `}
                </Paragraph>
              </Row>
            )}
          </FlowContentLeft>

          <FlowContentRight>
            <PrebookedRateBlock
              isMarketRate={isMarketRate}
              buyCurrency={buyCurrency}
              sellCurrency={sellCurrency}
              sellAmountAsNumber={sellAmountAsNumber}
              buyAmountAsNumber={buyAmountAsNumber}
              rate={rate}
              selectedRateContract={selectedRateContract}
              prebookAndMarketRateDifference={prebookAndMarketRateDifference}
              setRateType={setRateType}
              setSelectedRateContract={setSelectedRateContract}
            />
          </FlowContentRight>
        </FlowStepContent>
        <FlowStepFooter>
          <Button
            onClick={() => {
              if (!rateToUse) {
                return;
              }

              onContinue();
            }}
            disabled={!canContinue}
          >
            Exchange now
          </Button>

          <Button
            variant="secondary"
            onClick={() =>
              history.push(
                getLink('/app/prebook', {
                  predefinedBuyCurrency: buyCurrencyCode,
                  predefinedSellCurrency: sellCurrencyCode,
                  predefinedBuyAmount: buyAmountAsNumber?.toString(),
                })
              )
            }
            ml
          >
            Book rate to use later
          </Button>
        </FlowStepFooter>
      </FlowStepWrapper>

      {isError && (
        <StaleGetRateFailed
          onCancel={() => history.push('/app/dashboard')}
          onRetry={() => getRate(sellCurrency?.code, buyCurrency?.code)}
          onClose={() => history.push('/app/dashboard')}
        />
      )}
    </>
  );
};

export default ExchangeDetails;
