import styled, { css } from 'styled-components';
import { isMobile } from 'react-device-detect';
import { StaleOverflowScroll, InputSearch } from 'components';

export const Wrapper = styled.div(
  ({ theme }) => css`
    background: ${theme.color.white};

    @media (max-width: ${theme.breakpoint.largeMax}px) {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 56px 0 0;
      z-index: 12;
      display: flex;
      flex-direction: column;
    }

    @media (min-width: ${theme.breakpoint.largeMin}px) {
      position: absolute;
      width: 100%;
      border-radius: 6px;
      z-index: 2;
      right: 0;
      left: 0;
      top: 100%;
      box-shadow: 0 0 9px rgba(0, 0, 0, 0.1);
    }

    &.theme-dark {
      color: ${theme.color.white};
      background: ${theme.color.dark};

      @media (min-width: ${theme.breakpoint.largeMin}px) {
        border: 1px solid ${theme.color.emeraldDark};
      }

      .item {
        @media (min-width: ${theme.breakpoint.largeMin}px) {
          &:hover {
            background: rgba(69, 77, 88, 0.2);
          }
        }

        &.selected {
          background: rgba(69, 77, 88, 0.2);
        }

        &.disabled {
          opacity: 0.6;
          pointer-events: none;
          cursor: none;
        }
      }
    }

    ${InputSearch} {
      margin: ${isMobile ? '16px 16px 8px' : '4px 0'};
      padding-bottom: ${!isMobile && '15px'};
      width: ${isMobile ? 'auto' : '100%'};
      max-width: 100%;

      &:focus {
        @media (min-width: ${theme.breakpoint.largeMin}px) {
          box-shadow: 0 0 0 1px ${theme.color.emeraldDark};
        }
      }

      @media (min-width: ${theme.breakpoint.largeMin}px) {
        margin-bottom: 4px;
      }
    }

    .list-wrap {
      @media (min-width: ${theme.breakpoint.largeMin}px) {
        padding: 4px 0;
      }
      @media (max-width: ${theme.breakpoint.largeMax}px) {
        padding: 8px 0;
      }
    }

    ${StaleOverflowScroll} {
      padding: 0;
      box-shadow: none;

      @media (min-width: ${theme.breakpoint.largeMin}px) {
        max-height: 216px;
      }
    }

    .item {
      display: flex;
      align-items: center;
      padding: 10px 16px;
      font-size: 14px;
      min-height: 44px;
      line-height: 18px;
      position: relative;
      cursor: pointer;
      transition: ${theme.transition} linear;

      &.disabled {
        opacity: 0.6;
        pointer-events: none;
        cursor: none;
      }

      @media (min-width: ${theme.breakpoint.largeMin}px) {
        margin-bottom: 4px;

        &:hover {
          background: ${theme.color.greyLight_3};
        }
      }

      &.selected {
        background: ${theme.color.greyLight_3};
      }

      .country {
        width: 24px;
        height: 24px;
        margin-left: 0;
        margin-right: 8px;
      }
    }

    .tick {
      position: absolute;
      right: 16px;
      top: 0;
      bottom: 0;
      margin: auto;
      fill: ${theme.color.emeraldDark};
    }
  `
);

export const InputSelectHead = styled.div(
  ({ theme }) => css`
    z-index: 12;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    height: 56px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2px 16px 0px;

    @media (min-width: ${theme.breakpoint.largeMin}px) {
      display: none;
    }

    svg {
      margin: 0px;
    }

    &.theme-grey {
      color: ${theme.color.dark};
      background-color: ${theme.color.greyLight_2};
    }

    &.theme-dark {
      color: ${theme.color.white};
      background-color: ${theme.color.dark};

      svg {
        fill: ${theme.color.white};
      }
    }

    &.theme-white {
      color: ${theme.color.dark};
      background-color: ${theme.color.white};
    }
  `
);
