import React, { CSSProperties, FC } from 'react';

import { StaleSubTitleBold } from 'components';
import { Wrapper, Top, Bot } from './StaleNotification.styles';

interface OwnProps {
  title?: string;
  icon?: string;
  cross?: boolean;
  bgColor?: string;
  setShow?: (...args: any) => any;
  style?: CSSProperties;
}

const StaleNotification: FC<OwnProps> = ({
  title = '',
  icon = '',
  cross = true,
  bgColor = '#121213',
  setShow = () => {},
  children,
  style = {},
}) => (
  <Wrapper style={style} bgColor={bgColor}>
    <Top cross={cross}>
      {icon && (
        <svg>
          <use xlinkHref={`#${icon}`} />
        </svg>
      )}

      {title && <StaleSubTitleBold>{title}</StaleSubTitleBold>}
    </Top>
    {children}
    <Bot></Bot>

    {cross && (
      <button className="close" onClick={() => setShow(false)}>
        <svg>
          <use xlinkHref="#cross-ico" />
        </svg>
      </button>
    )}
  </Wrapper>
);

export default StaleNotification;
