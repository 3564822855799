import { FC } from 'react';
import { InlineLoader, Paragraph } from 'components';
import { parseIntoCurrencyStringWithSymbol } from 'utils';
import useInvoiceRecord from 'pages/Invoices/hooks/useInvoiceRecord';
import { IInvoiceForTable } from '../InvoicesTable/types';

interface OwnProps {
  record: IInvoiceForTable;
  profitAndLoss: number | null;
}

const ProfitAndLossCell: FC<OwnProps> = ({ record, profitAndLoss }) => {
  const { isSameCurrency } = useInvoiceRecord({ record });

  if (isSameCurrency) {
    return null;
  }

  if (!record.invoiceRate) {
    return <InlineLoader />;
  }

  return (
    <>
      {profitAndLoss && (
        <Paragraph ml color={profitAndLoss >= 0 ? 'green' : 'red'}>
          {`${parseIntoCurrencyStringWithSymbol(profitAndLoss, '£', 2)}`}
        </Paragraph>
      )}
    </>
  );
};

export default ProfitAndLossCell;
