import styled, { css } from 'styled-components';
import { Row, Col } from 'components';

export const ConvertInfoWrapper = styled.div(
  ({ theme }) => css`
    text-align: left;
    width: 100%;
    margin: 16px 0;
    display: flex;

    ${Col} {
      flex: 0 1 auto;
      max-width: 100%;
      min-width: 36%;

      > p:first-of-type {
        color: ${theme.color.greyDark};
      }
    }

    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }

    ${Row} {
      padding: 8px 33px 0 0;
      align-items: center;
      flex-wrap: nowrap;

      svg {
        width: 24px;
        height: 24px;
        margin-right: 8px;
      }
    }
  `
);
