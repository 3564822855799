import React from 'react';

const DropdownIndicator = () => {
  return (
    <svg className="i-arrow">
      <use xlinkHref="#arrow-down" />
    </svg>
  );
};

export default DropdownIndicator;
