import { AxiosPrivateFirebaseInstance } from 'settings';
import {
  IFollowedCurrencyPair,
  IResponse,
  UpdateFollowedCurrencyParams,
} from 'types';
import { openDoc, openQuery, openQueryWithTimestamp } from 'utils';
import db from 'services/firestore';
import { ICurrencyRate } from 'state/currencyRates';

export const getCurrencies = async () => {
  try {
    const data = await db
      .collection('referenceData')
      .doc('collections')
      .collection('currencies')
      .where('enabled', '==', true)
      .get();

    if (data) {
      return openQuery(data);
    }
  } catch (error) {
    console.warn(error.message);
  }
};

export interface SubscribeToFollowedCurrenciesParams {
  uid: string;
  callback: (transfers: IFollowedCurrencyPair[]) => void;
}

export const subscribeToFollowedCurrencies = ({
  uid,
  callback,
}: SubscribeToFollowedCurrenciesParams) => {
  try {
    return db
      .collection('users')
      .doc(uid)
      .collection('followedCurrencyPairs')
      .onSnapshot((query) => callback(openQueryWithTimestamp(query)));
  } catch (error) {
    console.warn(error);
  }
};

export const addFollowedCurrencyPair = async (
  params: Omit<IFollowedCurrencyPair, 'id'>
) => {
  try {
    const { data } = await AxiosPrivateFirebaseInstance.post<IResponse>(
      '/followed_currencies',
      params
    );

    return data;
  } catch (error) {
    console.warn(error);
  }
};

export const deleteFollowedCurrencyPair = async (
  followedCurrencyId: IFollowedCurrencyPair['id']
) => {
  try {
    const { data } = await AxiosPrivateFirebaseInstance.delete<IResponse>(
      `/followed_currencies/${followedCurrencyId}`
    );

    return data;
  } catch (error) {
    console.warn(error);
  }
};

export const updateFollowedCurrencyPair = async ({
  followedCurrencyData,
  followedCurrencyId,
}: {
  followedCurrencyId: IFollowedCurrencyPair['id'];
  followedCurrencyData: UpdateFollowedCurrencyParams;
}) => {
  try {
    const { data } = await AxiosPrivateFirebaseInstance.put<IResponse>(
      `/followed_currencies/${followedCurrencyId}`,
      followedCurrencyData
    );

    return data;
  } catch (error) {
    console.warn(error);
  }
};

export const subscribeToCurrencyRates = ({
  callback,
}: {
  callback: (idx: Record<string, ICurrencyRate>) => void;
}) => {
  const subscriber = db
    .collection('currencyRates')
    .doc('spotRates')
    .onSnapshot((query) => {
      const data = openDoc<Record<string, ICurrencyRate>>(query);
      if (data) {
        const mappedCurrencyRates = Object.entries(data).reduce(
          (accumulator, [currencyPair, rate]) => {
            accumulator[currencyPair] = rate;
            return accumulator;
          },
          {}
        );
        callback(mappedCurrencyRates);
      }
    });

  return subscriber;
};
