import styled, { css } from 'styled-components';
import { ButtonStyleLink } from '../shared';

export const Wrapper = styled.div<{ isIconsOnly: boolean }>(
  ({ theme, isIconsOnly }) => css`
    height: 100%;
    padding: 32px 20px 36px;
    background: ${theme.color.dark};
    transition: ${theme.transition};
    display: flex;
    align-items: end;
    flex-direction: column;
    min-width: ${isIconsOnly ? '64px' : '154px'};
    width: ${isIconsOnly && '64px'};
    position: relative;
    transition: ${theme.transition};

    .logo {
      font-weight: 700;
      font-size: ${isIconsOnly ? '0' : '16px'};
      line-height: 24px;
      display: flex;
      align-items: center;
      flex-direction: column;
      color: ${theme.color.emeraldDark};
      margin: ${isIconsOnly ? '0 -7px 33px 0' : '0 10px 24px'};

      img,
      svg {
        width: 40px;
        height: 35px;
        margin: ${isIconsOnly ? '-2px 0 0' : '-2px 0 9px'};
      }

      &:focus {
        &:not(:hover) {
          img,
          svg {
            box-shadow: 0 0 1px 1px ${theme.color.emeraldDark};
          }
        }
      }
    }

    .menu {
      &:last-child {
        .link svg {
          fill: ${theme.color.grey};
        }
      }

      &-wrap {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1 0 auto;
      }

      ${ButtonStyleLink},
      .link {
        display: flex;
        align-items: center;
        padding: 12px 0 20px;
        color: ${theme.color.white};
        font-weight: 500;
        font-size: ${isIconsOnly ? '0' : '14px'};
        line-height: 21px;
        transition: ${theme.transition};

        &:hover,
        &.active {
          color: ${theme.color.emeraldDark};
          svg {
            fill: ${theme.color.emeraldDark};
          }
        }

        svg {
          margin: ${isIconsOnly ? '0' : '0 12px 0 0'};
          fill: ${theme.color.white};
          width: 24px;
          height: 24px;
          transition: ${theme.transition};
        }

        &:focus {
          svg {
            fill: ${theme.color.emeraldDark};
          }
          &.active {
            svg {
              box-shadow: 0 0 1px 1px ${theme.color.emeraldDark};
            }
          }
        }
      }
    }
  `
);
