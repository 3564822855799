import { AxiosPrivateFirebaseInstance } from 'settings';
import { IResponse } from 'types';
import { IIntegrationLog } from 'types/integrations';

export interface IntegrateWithOrderWiseParams {
  username: string;
  password: string;
}

export const integrateWithOrderWise = async (
  params: IntegrateWithOrderWiseParams
) => AxiosPrivateFirebaseInstance.post('/orderwise', params);

export const fetchIntegrationLogs = async (source: 'xero' | 'codat') =>
  AxiosPrivateFirebaseInstance.get<IResponse<IIntegrationLog[]>>(
    `/integration/${source}/logs`
  );
