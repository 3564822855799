import { useStoreState } from 'state';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

/**
 * Redirect user to xero settings page flow if user has just integrated with Xero
 */
const useOnXeroConnection = () => {
  const history = useHistory();
  const location = useLocation();
  const { xero } = useStoreState(
    ({ UserState }) => UserState.integrationsSummary
  );

  useEffect(() => {
    if (
      !xero?.settings && // if user has gone through settings once, this will be true
      !!xero?.connected &&
      location.pathname !== '/signup/xero' &&
      location.pathname !== '/app/new-client/xero' &&
      location.pathname !== '/app/settings/xero'
    ) {
      history.push('/app/settings/xero?step=1&firstIntegration=true');
    }
  }, [history, xero?.connected, xero?.settings, location.pathname]);
};

export default useOnXeroConnection;
