import { useStoreState } from 'state';
import { useHistory, useRouteMatch } from 'react-router';
import { viewTransactionDetails } from 'utils/transactions';
import { Col, Row } from 'components';
import Table from 'components/shared/Table/Table';
import CounterTitle from 'components/shared/CounterTitle/CounterTitle';
import {
  generatePaymentRunTableTileColumns,
  paymentRunsTableTileFilterTypes,
} from './tableColumnsGenerator';
import { IBulkPayment } from 'types/bulkPayments';
import { useTheme } from 'styled-components';
import { FilterButton } from 'components/shared/FilterButton/FilterButton.styles';
import useTableFiltering from 'hooks/useTableFiltering';
import { getPaymentRunsPageLink } from 'utils/links';
import { FC } from 'react';
import useUrlValues from 'hooks/useUrlValues';
import TransferInner from '../TransferInner/TransferInner';
import Button from '../Button/Button';

type TFilters = 'recent' | 'scheduled' | 'failed' | '';

interface OwnProps {
  showLimit?: number;
  // TODO: consider removing that, now we have duplicate popups in a dashboard, so we need that as a workaround
  withPopups?: boolean;
  showMore?: boolean;
}

const PaymentsRunTableTile: FC<OwnProps> = ({
  showLimit,
  showMore,
  withPopups,
}) => {
  const theme = useTheme();
  const history = useHistory();
  const { url } = useRouteMatch();
  const { bulkPayments } = useStoreState((state) => state.BulkPaymentsState);
  const { transferId, bulkPaymentId } = useUrlValues(
    'transferId',
    'bulkPaymentId'
  );

  const paymentRunTransfersTableColumns = generatePaymentRunTableTileColumns();

  const onRowClick = (bulkPayment: IBulkPayment) =>
    viewTransactionDetails(url, history, {
      id: bulkPayment.id,
      type: 'bulkPayment',
    });

  const { filter, setFilter, tableRef } = useTableFiltering<
    TFilters,
    IBulkPayment
  >({
    filterTypeName: 'buyCurrency',
    initialFilterValue: '',
  });

  const onFilterClick = (filterName: TFilters) => {
    setFilter(filterName === filter ? '' : filterName);
  };

  return (
    <>
      <Col>
        <Row mb mbValue={theme.spacing.l}>
          <CounterTitle
            title={
              showLimit
                ? `Last ${bulkPayments.slice(0, showLimit).length} payment runs`
                : 'Payment runs'
            }
            count={showLimit ? undefined : bulkPayments.length}
          />
          <Row>
            <FilterButton
              onClick={() => onFilterClick('recent')}
              active={filter === 'recent'}
            >
              Recent
            </FilterButton>
            <FilterButton
              onClick={() => onFilterClick('scheduled')}
              active={filter === 'scheduled'}
            >
              Scheduled
            </FilterButton>
            <FilterButton
              onClick={() => onFilterClick('failed')}
              active={filter === 'failed'}
            >
              Failed
            </FilterButton>

            {showMore && (
              <Button
                ml
                mr
                mrValue={theme.spacing.xxs}
                mlValue={theme.spacing.xxs}
                variant="link"
                onClick={() => history.push(getPaymentRunsPageLink())}
              >
                Show more
              </Button>
            )}
          </Row>
        </Row>
        <Col style={{ position: 'relative' }}>
          <Table<IBulkPayment>
            ref={tableRef}
            data={showLimit ? bulkPayments.slice(0, showLimit) : bulkPayments}
            columns={paymentRunTransfersTableColumns}
            filterTypes={paymentRunsTableTileFilterTypes}
            autoResetExpanded={false}
            sortable
            onRowClick={onRowClick}
          />
        </Col>
      </Col>
      {withPopups && (transferId || bulkPaymentId) && <TransferInner />}
    </>
  );
};

export default PaymentsRunTableTile;
