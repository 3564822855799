import {
  IAccountData,
  ICodatAccount,
  TIntegrationDocIds,
  TPlatformNames,
  IXeroAccount,
} from 'types/integrations';

export const generateCodatBankFeesAccountData = (
  account: ICodatAccount
): IAccountData => {
  const { name, id } = account;
  const nameValue = `${name} (${id})`;
  return {
    id: nameValue,
    value: account,
    name: nameValue,
  };
};

export const generateXeroBankFeesAccountData = (
  account: IXeroAccount
): IAccountData => {
  const { name, code } = account;
  const nameValue = `${name} (${code})`;
  return {
    id: nameValue,
    value: account,
    name: nameValue,
  };
};

export const platformNamesToIntegrationDocIds: Record<
  TPlatformNames,
  TIntegrationDocIds
> = {
  Xero: 'xero',
  'Sage 50 (UK)': 'codat',
  'QuickBooks Online': 'codat',
  'QuickBooks Online Sandbox': 'codat',
  'Dynamics 365 Business Central': 'codat',
};
