import * as React from 'react';
import { IInvoice, TAccountingIntegration } from 'types';
import { convertTimestampSecondsToDDMMYYY } from 'utils/dates';
import { useInvoiceLastSyncDetails } from './useInvoiceLastSyncDetails';
import { Icon, Row, StaleTextHint } from 'components';

interface IProps {
  invoice: IInvoice;
}

const mapAccountingIntegrationToIcon: Record<
  NonNullable<TAccountingIntegration>,
  string
> = {
  xero: 'xero-ico',
  sage50: 'sage50Xml-ico',
  quickBooks: '#quick-books-ico',
};

const InvoiceLastSyncDetails: React.FC<IProps> = ({ invoice }) => {
  const {
    accountingIntegration,
    lastSyncFromExternalSource,
    lastSyncToExternalSource,
  } = useInvoiceLastSyncDetails(invoice);

  return (
    <Row mb justifyContent="flex-start">
      {!!accountingIntegration && (
        <Icon
          icon={mapAccountingIntegrationToIcon[accountingIntegration]}
          width="16"
          height="16"
        />
      )}

      {!!lastSyncFromExternalSource && (
        <StaleTextHint color="grey" ml>
          Imported:{' '}
          {convertTimestampSecondsToDDMMYYY(
            lastSyncFromExternalSource.timestamp.seconds
          )}
        </StaleTextHint>
      )}

      {!!lastSyncToExternalSource && (
        <StaleTextHint color="grey" ml>
          Updated:{' '}
          {convertTimestampSecondsToDDMMYYY(
            lastSyncToExternalSource.timestamp.seconds
          )}
        </StaleTextHint>
      )}
    </Row>
  );
};

export default InvoiceLastSyncDetails;
