import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';

dayjs.extend(weekday);

export const getFirstValidDay = (
  date = new Date(),
  daysBuffer = 0,
  nonTradingDays: string[] = [],
  disabledPastDates = false
) => {
  let isDayCorrect = false;
  let addDays = daysBuffer;

  let dateToUse = date;

  if (disabledPastDates) {
    dateToUse = dayjs(date).isBefore(new Date(), 'day') ? new Date() : date;
  }

  while (!isDayCorrect) {
    const currentDay = dayjs(dateToUse).add(addDays, 'days');

    const holidayDate = nonTradingDays.find(
      (item) => item === currentDay.format('YYYY-MM-DD')
    );

    const isWeekend = currentDay.weekday() === 0 || currentDay.weekday() === 6;

    if (!holidayDate && !isWeekend) {
      isDayCorrect = true;
    } else {
      addDays++;
    }
  }

  return dayjs(dateToUse).add(addDays, 'days').toDate();
};

export const getNumberOfDaysBetweenTwoDates = (
  startDate: dayjs.ConfigType,
  endDate: dayjs.ConfigType,
  dateFormat: dayjs.OptionType = 'YYYY-MM-DD'
) =>
  Math.abs(
    dayjs(startDate, dateFormat).diff(dayjs(endDate, dateFormat), 'days')
  );

export const isDateOverdue = (dateString: string) =>
  dayjs(dateString).endOf('day').isBefore(dayjs());

export const convertTimestampSecondsToDDMMYYY = (timestampSeconds: number) =>
  dayjs.unix(timestampSeconds).format('DD.MM.YYYY');
