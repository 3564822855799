import styled, { css } from 'styled-components';
import { spacingCss, SpacingCssProps } from '../Spacing/Spacing.styles';

export const StyledButton = styled.button.attrs<SpacingCssProps>(
  ({ theme, mbValue, mlValue, mtValue, mrValue }) => ({
    mbValue: mbValue || theme.spacing.m,
    mtValue: mtValue || theme.spacing.m,
    mrValue: mrValue || theme.spacing.m,
    mlValue: mlValue || theme.spacing.m,
  })
)<{
  variant?: 'primary' | 'secondary' | 'link';
}>(
  ({ variant = 'primary', theme }) => css`
    cursor: pointer;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: 700;
    transition: ${theme.transition};
    min-height: 48px;
    padding: 12px 32px;
    border-radius: 32px;
    line-height: 21px;
    border: 1px solid ${theme.color.transparent};

    :focus-visible {
      color: ${theme.color.emeraldDark};
      border: 1px solid ${theme.color.emeraldDark};
    }

    @media (max-width: ${theme.breakpoint.largeMax}px) {
      font-size: 14px;
      padding: 10px 32px;
      cursor: default;
    }

    &[disabled] {
      cursor: default;
      pointer-events: none;
    }

    ${variant === 'primary' &&
    css`
      color: ${theme.color.white};
      background: ${theme.color.dark};

      svg {
        fill: ${theme.color.white};
      }

      &:hover {
        background: ${theme.color.black};
      }

      &[disabled] {
        background: ${theme.color.grey};
      }
    `}

    ${variant === 'secondary' &&
    css`
      color: ${theme.color.dark};
      background: ${theme.color.transparent};
      border: 1px solid ${theme.color.dark};

      svg {
        fill: ${theme.color.dark};
      }

      &:hover {
        color: ${theme.color.greyDark};
        border-color: ${theme.color.greyDark};
        background: ${theme.color.greyLight_2};

        svg {
          fill: ${theme.color.greyDark};
        }
      }

      &[disabled] {
        color: ${theme.color.grey};
        border-color: ${theme.color.grey};

        svg {
          fill: ${theme.color.grey};
        }
      }
    `}

    ${variant === 'link' &&
    css`
      font-weight: 500;
      color: ${theme.color.dark};
      text-decoration: underline;
      text-underline-offset: 2px;
      padding: unset;
      min-height: unset;

      svg {
        height: 24px;
        width: 24px;
        margin: 0 4px;
        transition: fill 0.4s;
        fill: ${theme.color.dark};
      }

      &:hover {
        color: ${theme.color.greyDark};

        svg {
          fill: ${theme.color.greyDark};
        }
      }

      &:focus,
      &:active {
        color: ${theme.color.emeraldDark};
        border-color: ${theme.color.emeraldDark};

        svg {
          fill: ${theme.color.emeraldDark};
        }
      }

      &.disabled,
      &[disabled] {
        color: ${theme.color.grey};

        svg {
          fill: ${theme.color.grey};
        }
      }
    `}

    ${spacingCss}
  `
);
