import { FC } from 'react';
import { Icon, Paragraph, Row } from 'components';
import { useTheme } from 'styled-components';
import { IContact } from 'types';

interface OwnProps {
  record: IContact;
}

const EmailCell: FC<OwnProps> = ({ record }) => {
  const theme = useTheme();

  return (
    <Row>
      <Paragraph>{record.contactEmail || '-'}</Paragraph>
      {record.shouldSendRemittance && (
        <Icon ml fill={theme.color.emeraldDark} icon="tick-ico" />
      )}
    </Row>
  );
};

export default EmailCell;
