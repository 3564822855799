import { Col, Paragraph } from 'components';
import ChartTooltip from 'pages/Reports/components/ChartTooltip/ChartTooltip';
import useChartTooltip from 'pages/Reports/hooks/useChartTooltip';
import { useTheme } from 'styled-components';
import { parseIntoCurrencyStringWithSymbol } from 'utils';

const CustomChartBar = ({ data, currencySymbol }) => {
  const theme = useTheme();
  const { tooltipRef, ...mouseEvents } = useChartTooltip();
  const { bar } = data;
  const { prebooked, externalHedge, prebookedAndHedged } = bar?.data?.data;
  const isPrebookedAndHedged = bar?.data?.id === 'prebookedAndHedged';
  const colors = {
    payables: theme.color.red,
    receivables: theme.color.emeraldDark,
    prebookedAndHedged: theme.color.blueLight,
    purchaseOrders: theme.color.redDark,
    saleOrders: theme.color.green,
  };

  return (
    <g {...mouseEvents} style={{ cursor: 'pointer' }}>
      <rect
        fill={colors[bar?.data?.id]}
        x={bar?.x}
        y={bar?.y}
        style={{
          width: bar?.width,
          height: bar?.height,
        }}
      />

      <ChartTooltip wrapperRef={tooltipRef}>
        {isPrebookedAndHedged ? (
          <Col>
            {prebooked && (
              <Paragraph color="white">
                Prebooked:{' '}
                {parseIntoCurrencyStringWithSymbol(
                  prebooked,
                  currencySymbol,
                  0
                )}
              </Paragraph>
            )}
            {externalHedge && (
              <Paragraph color="white">
                Hedged:{' '}
                {parseIntoCurrencyStringWithSymbol(
                  externalHedge,
                  currencySymbol,
                  0
                )}
              </Paragraph>
            )}
            {prebooked && externalHedge && (
              <Paragraph color="white">
                Total:{' '}
                {parseIntoCurrencyStringWithSymbol(
                  prebookedAndHedged,
                  currencySymbol,
                  0
                )}
              </Paragraph>
            )}
          </Col>
        ) : (
          <Paragraph color="white">
            {parseIntoCurrencyStringWithSymbol(
              Number(data?.label),
              currencySymbol,
              0
            )}
          </Paragraph>
        )}
      </ChartTooltip>
    </g>
  );
};

export default CustomChartBar;
