import { BarCustomLayerProps } from '@nivo/bar';
import { FC } from 'react';
import { useTheme } from 'styled-components';
import { IPnlDataPerMonth } from 'types/analyses';

interface OwnProps {
  data: BarCustomLayerProps<IPnlDataPerMonth>;
}

const ChartMarketImpactLegendsLayer: FC<OwnProps> = ({ data }) => {
  const { color } = useTheme();

  return (
    <g>
      <rect
        fill={color.grey}
        x={data.width / 2 - 150}
        y={data.height - 35}
        style={{
          width: 15,
          height: 5,
        }}
      />

      <line
        stroke={color.black}
        x1={data.width / 2 - 130}
        x2={data.width / 2 - 130}
        y1={data.height - 38}
        y2={data.height - 28}
      />

      <rect
        fill={color.purple}
        x={data.width / 2 - 125}
        y={data.height - 35}
        style={{
          width: 15,
          height: 5,
        }}
      />

      <text
        y={data.height - 29}
        x={data.width / 2 - 105}
        style={{
          fontSize: 12,
        }}
      >
        Paid/Outstanding
      </text>
    </g>
  );
};

export default ChartMarketImpactLegendsLayer;
