import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const OptionsWrapper = styled.ul`
  position: absolute;
  top: 52px;
  left: 0;
  right: 0;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  max-height: 300px;
  overflow-y: auto;
  z-index: 1;
`;

export const OptionWrapper = styled.li`
  padding: 24px;
  background-color: ${({ theme }) => theme.color.white};
`;
