import styled, { css } from 'styled-components';
import { StaleContainer, StaleBtnGroup } from 'components';

export const Wrapper = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    height: 100%;

    ${StaleContainer} {
      flex: 0 1 100%;
      overflow: auto;
      padding: 20px 24px 0;
    }

    .field {
      margin-bottom: 14px;
    }

    .row {
      justify-content: space-between;
      align-items: flex-start;

      p {
        padding: 0;
        min-height: 18px;

        &:not(:last-child) {
          margin-bottom: 4px !important;
        }
        &:last-child {
          margin-bottom: 0 !important;
          margin-right: auto;
        }
      }
    }

    .tracked-table {
      display: flex;
      width: 100%;
      background: ${theme.color.greyLight_3};

      .col {
        padding: 15px 8px 16px;
        flex: 0 1 auto;
        max-width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;

        &:first-of-type {
          padding-left: 16px;
        }
        &:last-of-type {
          align-items: flex-end;
          padding-right: 16px;
        }

        .cost-range {
          width: 100%;
        }

        > span {
          color: ${theme.color.dark};
          font-size: 14px;
          margin-bottom: 9px;
        }
      }

      p {
        font-weight: 500;
        color: ${theme.color.greyDark};
      }
    }

    ${StaleBtnGroup} {
      button {
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
      }

      a {
        text-decoration: underline;
        text-underline-offset: 2px;
      }
    }
  `
);
