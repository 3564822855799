import { IResponse, TRANSFER_TYPE, IRecipient } from 'types';
import { AxiosPrivateFirebaseInstance } from 'settings';
import { openQueryWithTimestamp } from 'utils';
import storage from 'services/storage';
import db from 'services/firestore';

export interface IBulkTransferExecuteInput {
  buyAmountTotal: number;
  sellAmountTotal: number;
  rate: number;
  paymentFeesTotal: number;
  paymentTotal: number;
  transfers: IBulkTransferInput[];
  isConversionRequired: boolean;
}

interface IBulkTransferInput {
  sellCurrency: string;
  buyAmount: number;
  buyCurrency: string;
  transferType: TRANSFER_TYPE;
  recipient: IRecipient;
}

export interface ReadInvoicesFileParams {
  file: File;
}
export const readBulkTransfersFile = async (payload: FormData) => {
  return AxiosPrivateFirebaseInstance.post<IResponse>(
    `/bulk_payments/upload`,
    payload
  );
};

export const bulkTransfersUpload = async (
  payload: IBulkTransferExecuteInput
) => {
  return AxiosPrivateFirebaseInstance.post<IResponse>(
    `/bulk_payments`,
    payload
  );
};

export const bulkInvoicesUpload = async (payload: {
  invoiceIds: string[];
  combineSameContacts: boolean;
  isConversionRequired: boolean;
}) => {
  return AxiosPrivateFirebaseInstance.post<IResponse>(
    `/bulk_payments/upload/invoices`,
    payload
  );
};

export interface ExampleFiles {
  files: string[];
}

export const getExampleFile = async (payload: string) => {
  try {
    return await storage.ref(payload).getDownloadURL();
  } catch (error) {
    console.log(error);
  }
};

export interface SubscribeToBulkPayments {
  entityId: string;
  // TO DO CORRECT TYPES
  callback: (bulkPayments: any) => void;
}

export const subscribeToBulkPayments = ({
  entityId,
  callback,
}: SubscribeToBulkPayments) => {
  try {
    return db
      .collection('bulkPayments')
      .where('_owner', '==', entityId)
      .onSnapshot((query) => callback(openQueryWithTimestamp(query)));
  } catch (error) {
    console.warn(error);
  }
};
