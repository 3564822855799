import AmountCell from 'components/shared/AmountCell/AmountCell';
import AmountSellCurrencyCell from 'components/shared/AmountSellCurrencyCell/AmountSellCurrencyCell';
import ProfitAndLossCell from '../ProfitAndLossCell/ProfitAndLossCell';
import ContactCell from 'components/shared/ContactCell/ContactCell';
import DateCell from 'components/shared/DateCell/DateCell';
import DirectionCell from 'components/shared/DirectionCell/DirectionCell';
import InvoiceNumberCell from 'components/shared/InvoiceNumberCell/InvoiceNumberCell';
import { Dispatch, ReactNode, SetStateAction } from 'react';
import { Column, FilterTypes } from 'react-table';
import { Nullable, IRecipient, IInvoice, IContact } from 'types';
import {
  isReceivableInvoice,
  getInvoiceRemainingAmount,
  isPayableInvoice,
  isInvoiceOverdue,
  isInvoiceDueIn14Days,
} from 'utils/invoices';
import { parseRateWithPrecision } from 'utils';
import { FlagCell, InvoiceStatusCell } from './components/Cells/Cells';
import StatusIconCell from './components/StatusIconCell/StatusIconCell';
import InvoiceActionsMenu from './components/InvoiceActionsMenu/InvoiceActionsMenu';
import DoActionCell from './components/DoActionCell/DoActionCell';
import { Paragraph } from 'components';
import { IInvoiceForTable } from './types';

export const filterTypes: FilterTypes<IInvoiceForTable> = {
  direction: (rows, _, filterValue) => {
    if (filterValue === 'default') {
      return rows;
    }

    if (filterValue === 'overdue') {
      return rows.filter(({ original }) => isInvoiceOverdue(original));
    }

    if (filterValue === 'payables') {
      return rows.filter(({ original }) => isPayableInvoice(original));
    }

    if (filterValue === 'dueIn14Days') {
      return rows.filter(({ original }) => isInvoiceDueIn14Days(original));
    }

    return rows;
  },
  text: (rows, _, filterValue) => {
    if (!filterValue) {
      return rows;
    }

    return rows.filter(({ original }) => {
      if (original.invoiceNumber?.includes(filterValue)) {
        return true;
      }

      if (
        original.contact &&
        original.contact.recipientName
          ?.toLowerCase()
          .includes(filterValue.toLowerCase())
      ) {
        return true;
      }

      return false;
    });
  },
};

export const generateTableColumns = ({
  recipientById,
  setCancelPrebookInvoice,
  setContactForEdit,
  setExistingInvoiceTracking,
  setExistingPrebookInvoice,
  setInvoiceDecide,
  setInvoiceForAddContact,
  setShowAllDecideFields,
  setShowInvoiceDelete,
  setShowPurchaseOrderPaymentsOnInvoice,
  renderActionCell,
}: {
  recipientById: (id: Nullable<string>) => IRecipient | undefined;
  setCancelPrebookInvoice: Dispatch<SetStateAction<IInvoice>>;
  setContactForEdit: Dispatch<SetStateAction<IContact>>;
  setExistingInvoiceTracking: Dispatch<SetStateAction<IInvoice>>;
  setExistingPrebookInvoice: Dispatch<SetStateAction<IInvoice>>;
  setInvoiceDecide: Dispatch<SetStateAction<IInvoice>>;
  setInvoiceForAddContact: Dispatch<SetStateAction<IInvoice>>;
  setShowAllDecideFields: Dispatch<SetStateAction<boolean>>;
  setShowInvoiceDelete: Dispatch<SetStateAction<IInvoice>>;
  setShowPurchaseOrderPaymentsOnInvoice: Dispatch<SetStateAction<IInvoice>>;
  renderActionCell?: (record: IInvoiceForTable) => ReactNode;
}): Column<IInvoiceForTable>[] => {
  return [
    {
      accessor: 'invoiceNumber',
      Header: 'Ref',
      disableSortBy: true,
      Cell: ({ row }) => <InvoiceNumberCell record={row.original} />,
      width: 110,
    },
    {
      accessor: 'contact',
      Header: 'Name',
      sortType: (rowA, rowB) => {
        const existingRecipientA = recipientById(rowA.original.contactId);
        const recipientToUseA = existingRecipientA ?? rowA.original.contact;
        const existingRecipientB = recipientById(rowB.original.contactId);
        const recipientToUseB = existingRecipientB ?? rowB.original.contact;

        const a = recipientToUseA?.recipientName || '';
        const b = recipientToUseB?.recipientName || '';

        if (a > b) {
          return 1;
        }
        if (b > a) {
          return -1;
        }

        return 0;
      },
      Cell: ({ row, value }) => {
        const existingRecipient = recipientById(row.original.contactId);
        const recipientToUse = existingRecipient ?? value;

        return (
          <ContactCell
            invoice={row.original}
            recipientToUse={recipientToUse}
            setContactForEdit={setContactForEdit}
            setInvoiceForAddContact={setInvoiceForAddContact}
          />
        );
      },
      width: 160,
      minWidth: 130,
    },
    {
      accessor: 'date',
      Header: 'Issued',
      Cell: ({ value }) => <DateCell value={value} />,
      width: 100,
      minWidth: 80,
    },
    {
      accessor: 'dueDate',
      Header: 'Due',
      Cell: ({ value }) => <DateCell withOverdueMarker value={value} />,
      width: 100,
      minWidth: 80,
    },
    {
      accessor: 'currency',
      Cell: ({ value }) => <FlagCell currencyCode={value} />,
      width: 50,
      minWidth: 50,
      disableSortBy: true,
    },
    {
      accessor: () => null,
      id: 'direction',
      Header: 'Amount Due',
      disableSortBy: true,
      Cell: ({ row }) => (
        <>
          <DirectionCell
            withTitle={false}
            isReceivable={isReceivableInvoice(row.original)}
            currencyCode={row.original.currency}
          />
          <AmountCell
            amount={getInvoiceRemainingAmount(row.original)}
            currencyCode={row.original.currency}
          />
        </>
      ),
      filter: 'direction',
      width: 120,
      minWidth: 100,
    },
    {
      id: 'GBP',
      Header: 'GBP Value',
      disableSortBy: true,
      Cell: ({ row }) => <AmountSellCurrencyCell record={row.original} />,
      width: 120,
      minWidth: 100,
    },
    {
      id: 'invoiceRate',
      accessor: 'invoiceRate',
      Header: 'Invoice Rate',
      sortType: (rowA, rowB) => {
        const a = rowA.original.invoiceRate || 0;
        const b = rowB.original.invoiceRate || 0;

        if (a > b) {
          return 1;
        }
        if (b > a) {
          return -1;
        }

        return 0;
      },
      Cell: ({ row }) => (
        <Paragraph>
          {parseRateWithPrecision(row.original.externalCurrencyRate)}
        </Paragraph>
      ),
      width: 120,
      minWidth: 100,
    },
    {
      id: 'unrealisedPnl',
      accessor: 'profitAndLoss',
      Header: 'Unrealised P&L',
      sortType: (rowA, rowB) => {
        const a = rowA.original.profitAndLoss || 0;
        const b = rowB.original.profitAndLoss || 0;

        if (a > b) {
          return 1;
        }
        if (b > a) {
          return -1;
        }

        return 0;
      },
      Cell: ({ row }) => (
        <ProfitAndLossCell
          record={row.original}
          profitAndLoss={row.original.profitAndLoss}
        />
      ),
      width: 120,
      minWidth: 100,
    },
    {
      accessor: 'status',
      Header: 'Status',
      Cell: ({ row }) => <InvoiceStatusCell invoice={row.original} />,
      width: 110,
      minWidth: 90,
    },
    {
      id: 'toDo',
      disableSortBy: true,
      Cell: ({ row }) =>
        renderActionCell ? (
          renderActionCell(row.original)
        ) : (
          <DoActionCell record={row.original} />
        ),
      width: 80,
      minWidth: 60,
    },
    {
      id: 'icon',
      disableSortBy: true,
      Cell: ({ row }) => <StatusIconCell record={row.original} />,
      width: 48,
      minWidth: 48,
    },
    {
      id: 'dots',
      Header: () => null,
      disableSortBy: true,
      Cell: ({ row }) => (
        <InvoiceActionsMenu
          record={row.original}
          setInvoiceDecide={setInvoiceDecide}
          setCancelPrebookInvoice={setCancelPrebookInvoice}
          setExistingInvoiceTracking={setExistingInvoiceTracking}
          setExistingPrebookInvoice={setExistingPrebookInvoice}
          setShowAllDecideFields={setShowAllDecideFields}
          setShowInvoiceDelete={setShowInvoiceDelete}
          setShowPurchaseOrderPaymentsOnInvoice={
            setShowPurchaseOrderPaymentsOnInvoice
          }
        />
      ),
      width: 60,
      minWidth: 55,
    },
  ];
};
