import { FC } from 'react';
import { StaleTextHint, Col, Paragraph } from 'components';
import { parseIntoCurrencyString, parseRateWithPrecision } from 'utils';

interface OwnProps {
  amount: number;
  rate: number;
  currencyCode: string;
}

const AuthoriseGBPCostCell: FC<OwnProps> = ({ amount, rate, currencyCode }) => (
  <Col>
    <Paragraph variant="bold">
      {parseIntoCurrencyString(amount)} {currencyCode}
    </Paragraph>
    <StaleTextHint>{`${parseRateWithPrecision(rate)} rate`}</StaleTextHint>
  </Col>
);

export default AuthoriseGBPCostCell;
