import { FC } from 'react';
import { Col, StaleSwitch, Subtitle, Paragraph, Row, Title } from 'components';
import { useTheme } from 'styled-components';
import { TPlatformNames } from 'types/integrations';
import Button from '../Button/Button';

const IMPORT_DATA = [
  {
    id: 'invoices',
    isOn: true,
    title: 'Invoices',
  },
  {
    id: 'bills',
    isOn: true,
    title: 'Bills',
  },
  {
    id: 'payments',
    isOn: true,
    title: 'Payments',
  },
  {
    id: 'contactDetails',
    isOn: true,
    title: 'Contact details',
  },
  {
    id: 'purchaseAndSalesOrders',
    isOn: false,
    title: 'Purchase and sales orders (coming soon)',
  },
  {
    id: 'bankBalances',
    isOn: false,
    title: 'Bank balances (coming soon)',
  },
];

interface OwnProps {
  onContinue: () => void;
  platformName?: TPlatformNames;
}

const StepImport: FC<OwnProps> = ({ onContinue, platformName }) => {
  const theme = useTheme();

  return (
    <>
      <Title mb mbValue={theme.spacing.xl}>
        What shall we import from {platformName}?
      </Title>

      <Paragraph mb mbValue={theme.spacing.xl}>
        We will need to import the following data from {platformName} to help
        manage your currency needs and streamline you cross-border payments:
      </Paragraph>

      <Col gap={theme.spacing.xl} mb mbValue={theme.spacing.xxl}>
        {IMPORT_DATA.map(({ id, isOn, title }) => (
          <Row key={id} justifyContent="flex-start">
            <StaleSwitch id={id} isOn={isOn} disabled />
            <Subtitle ml>{title}</Subtitle>
          </Row>
        ))}
      </Col>

      <Button onClick={onContinue}>Continue</Button>
    </>
  );
};

export default StepImport;
