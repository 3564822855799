import dayjs from 'dayjs';
import { FC } from 'react';
import { IPnlDataPerMonth } from 'types/analyses';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import ChartBar from 'components/shared/ChartBar/ChartBar';
import ChartTick from '../ChartTick/ChartTick';
import ChartMarketImpactBarsLayer from '../ChartMarketImpactBarsLayer/ChartMarketImpactBarsLayer';
import ChartMarketImpactLegendsLayer from '../ChartMarketImpactLegendsLayer/ChartMarketImpactLegendsLayer';

dayjs.extend(customParseFormat);

const VALUE_BUFFER = 1.15;

interface OwnProps {
  data: IPnlDataPerMonth[];
  activeMonth: string;
  onChartBarClick: (barMonth: string) => void;
}

const ChartMarketImpact: FC<OwnProps> = ({
  data,
  activeMonth,
  onChartBarClick,
}) => {
  const minValue = data.reduce(
    (total, { fxImpactForPaid, fxImpactForUnpaid }) =>
      Math.min(
        total,
        fxImpactForPaid,
        fxImpactForUnpaid,
        fxImpactForUnpaid + fxImpactForPaid
      ),
    0
  );
  const maxValue = data.reduce(
    (total, { fxImpactForPaid, fxImpactForUnpaid }) =>
      Math.max(
        total,
        fxImpactForPaid,
        fxImpactForUnpaid,
        fxImpactForUnpaid + fxImpactForPaid
      ),
    0
  );
  const largestAbsoluteValue = Math.floor(
    Math.max(Math.abs(minValue), maxValue)
  );

  return (
    <ChartBar<IPnlDataPerMonth>
      keys={['fxImpactForPaid', 'fxImpactForUnpaid']}
      data={data}
      indexBy="id"
      margin={{
        top: 20,
        bottom: 70,
        left: `${largestAbsoluteValue}`.length * 6 + 18,
      }}
      enableLabel={false}
      minValue={minValue * VALUE_BUFFER}
      maxValue={maxValue * VALUE_BUFFER}
      axisTop={null}
      axisRight={null}
      enableGridX={false}
      axisBottom={{
        tickPadding: 16,
        tickSize: 0,
        // @ts-ignore
        renderTick: ChartTick,
      }}
      onClick={(datum) => {
        if (typeof datum.indexValue === 'string') {
          onChartBarClick(datum.indexValue);
        }
      }}
      layers={[
        'grid',
        'axes',
        (layerData) => (
          <ChartMarketImpactBarsLayer
            data={layerData}
            activeMonth={activeMonth}
            onClick={onChartBarClick}
          />
        ),
        'markers',
        (layerData) => <ChartMarketImpactLegendsLayer data={layerData} />,
        'annotations',
      ]}
    />
  );
};

export default ChartMarketImpact;
