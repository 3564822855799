import styled, { css } from 'styled-components';

export const Trigger = styled.button<{
  show?: boolean;
}>(
  ({ theme, show }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;

    svg {
      width: 24px;
      height: 24px;
      fill: ${show ? theme.color.dark : theme.color.grey};
      transition: ${theme.transition};

      @media (min-width: ${theme.breakpoint.largeMin}px) {
        &:hover {
          fill: ${theme.color.dark};
        }
      }
    }
  `
);

export const Arrow = styled.div`
  visibility: hidden;
  position: absolute;
  width: 8px;
  height: 8px;
  background: ${({ theme }) => theme.color.white};

  :before {
    position: absolute;
    width: 8px;
    height: 8px;
    background: ${({ theme }) => theme.color.white};
    visibility: visible;
    content: '';
    transform: rotate(45deg);
  }
`;

export const Menu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 0 4px;
  box-shadow: ${({ theme }) => theme.shadow};
  border-radius: 6px;
  min-width: 190px;
  background-color: ${({ theme }) => theme.color.white};
  z-index: ${({ theme }) => theme.zIndex.calendar};

  &[data-popper-placement^='top'] > ${Arrow} {
    bottom: -4px;
  }

  &[data-popper-placement^='bottom'] > ${Arrow} {
    top: -4px;
  }

  &[data-popper-placement^='left'] > ${Arrow} {
    right: -4px;
  }

  &[data-popper-placement^='right'] > ${Arrow} {
    left: -4px;
  }
`;

export const ThreeDotsButton = styled.button`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px 16px;
  margin-bottom: 4px;
  position: relative;
  z-index: 1;
  font-weight: 500;
  color: ${({ theme }) => theme.color.dark};
  transition: ${({ theme }) => theme.transition};
  font-size: 14px;
  line-height: 21px;

  &:hover {
    color: ${({ theme }) => theme.color.greyDark};

    svg {
      fill: ${({ theme }) => theme.color.greyDark};
    }
  }
`;
