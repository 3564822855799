import { action, Action, computed, Computed, thunk, Thunk } from 'easy-peasy';
import { Firebase } from 'services';
import { updateInvoice } from 'services/firebase/invoices';
import { ICurrency, IInvoice } from 'types';

import { Notify } from 'utils';

export interface InvoicesStateModel {
  invoices: IInvoice[];
  isLoadingInvoices: boolean;
  setState: Action<InvoicesStateModel, [string, any]>;
  getInvoices: Thunk<InvoicesStateModel>;
  getInvoice: Thunk<InvoicesStateModel, Firebase.Invoices.GetInvoiceParams>;
  sortAndSetInvoices: Thunk<InvoicesStateModel, IInvoice[]>;
  subscribeToInvoice: Thunk<
    InvoicesStateModel,
    Firebase.Invoices.SubscribeToInvoiceParams
  >;
  bindContractRateToInvoice: Thunk<
    InvoicesStateModel,
    Firebase.Invoices.BindContractRateToInvoiceParams
  >;
  unbindContractRateToInvoice: Thunk<
    InvoicesStateModel,
    Firebase.Invoices.BindContractRateToInvoiceParams
  >;
  cancelInvoiceContractRate: Thunk<
    InvoicesStateModel,
    Firebase.Invoices.CancelInvoiceContractRateParams
  >;
  createInvoiceTracking: Thunk<
    InvoicesStateModel,
    Firebase.Invoices.CreateInvoiceTrackingParams
  >;
  updateInvoiceTracking: Thunk<
    InvoicesStateModel,
    Firebase.Invoices.UpdateInvoiceTrackingParams
  >;
  deleteInvoiceTracking: Thunk<
    InvoicesStateModel,
    Firebase.Invoices.DeleteInvoiceTrackingParams
  >;
  getInvoiceTracking: Thunk<
    InvoicesStateModel,
    Firebase.Invoices.GetInvoiceTrackingParams
  >;
  updateInvoice: Thunk<
    InvoicesStateModel,
    {
      invoiceId: string;
      data: Partial<IInvoice>;
    }
  >;
  getInvoiceById: Computed<
    InvoicesStateModel,
    (invoiceId: string) => IInvoice | undefined
  >;
  deleteInvoice: Thunk<
    InvoicesStateModel,
    Firebase.Invoices.DeleteInvoiceParams
  >;
  invoicesByCurrency: Computed<
    InvoicesStateModel,
    (buyCurrencyCode: ICurrency['code']) => IInvoice[]
  >;
}

export const InvoicesState: InvoicesStateModel = {
  invoices: [],
  isLoadingInvoices: true,
  setState: action((state, payload) => {
    const [prop, to] = payload;
    state[prop] = to;
  }),
  getInvoices: thunk(async (actions) => {
    try {
      const { data } = await Firebase.Invoices.getInvoices();

      if (data.success) {
        //   @ts-ignore
        actions.setState(['invoices', data.invoices]);
      } else {
        Notify.error(data.message ?? '');
      }
    } catch (error) {
      console.warn(error);
    }
  }),
  sortAndSetInvoices: thunk((actions, payload) => {
    const sortedInvoices = payload.sort((a, b) => {
      if (a._created < b._created) {
        return -1;
      } else if (a._created > b._created) {
        return 1;
      }
      return 0;
    });
    actions.setState(['invoices', sortedInvoices]);
    actions.setState(['isLoadingInvoices', false]);
  }),
  subscribeToInvoice: thunk((actions, payload) => {
    try {
      const unsubscribe = Firebase.Invoices.subscribeToInvoice(payload);

      return unsubscribe;
    } catch (error) {
      console.warn(error);
    }
  }),
  getInvoice: thunk(async (actions, payload) => {
    try {
      const invoice = await Firebase.Invoices.getInvoice(payload);

      return invoice;
    } catch (error) {
      console.warn(error);
    }
  }),
  bindContractRateToInvoice: thunk(async (actions, payload) => {
    try {
      const invoice = await Firebase.Invoices.bindContractRateToInvoice(
        payload
      );

      return invoice;
    } catch (error) {
      Notify.error(
        'Could not add this prebook rate to the invoice at the moment'
      );
    }
  }),
  unbindContractRateToInvoice: thunk(async (actions, payload) => {
    try {
      const invoice = await Firebase.Invoices.unbindContractRateToInvoice(
        payload
      );

      return invoice;
    } catch (error) {
      Notify.error(
        'Could not remove this prebook rate from the invoice at the moment'
      );
    }
  }),
  cancelInvoiceContractRate: thunk(async (actions, payload) => {
    try {
      const response = await Firebase.Invoices.cancelInvoiceContractRate(
        payload
      );

      return response;
    } catch (error) {
      console.warn(error);
    }
  }),
  createInvoiceTracking: thunk(async (actions, payload) => {
    try {
      await Firebase.Invoices.createInvoiceTracking(payload);
    } catch (error) {
      console.warn(error);
    }
  }),
  updateInvoiceTracking: thunk(async (actions, payload) => {
    try {
      const response = await Firebase.Invoices.updateInvoiceTracking(payload);

      return response;
    } catch (error) {
      console.warn(error);
    }
  }),
  deleteInvoiceTracking: thunk(async (actions, payload) => {
    try {
      await Firebase.Invoices.deleteInvoiceTracking(payload);
    } catch (error) {
      console.warn(error);
    }
  }),
  getInvoiceTracking: thunk(async (actions, payload) => {
    try {
      const tracking = await Firebase.Invoices.getInvoiceTracking(payload);

      return tracking;
    } catch (error) {
      console.warn(error);
    }
  }),
  getInvoiceById: computed(
    [(state) => state?.invoices],
    (invoices) => (invoiceId) =>
      invoices?.find((invoice) => invoice.id === invoiceId)
  ),
  updateInvoice: thunk(async (_, payload) => {
    try {
      await updateInvoice(payload);
    } catch (error) {
      console.warn(error);
    }
  }),
  deleteInvoice: thunk(async (actions, payload) => {
    try {
      const res = await Firebase.Invoices.deleteInvoice(payload);

      return res.data;
    } catch (error) {
      console.warn(error);
    }
  }),
  invoicesByCurrency: computed(
    [(state) => state?.invoices],
    (invoices) => (buyCurrencyCode) =>
      invoices.filter((invoice) => invoice.currency === buyCurrencyCode)
  ),
};
