import { FC, InputHTMLAttributes } from 'react';

import { Wrapper } from './StaleCheckbox.styles';

interface OwnProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'type'> {
  label?: string;
}

const StaleCheckbox: FC<OwnProps> = ({ id, label, hidden, ...rest }) => {
  return (
    <Wrapper hidden={hidden}>
      <input id={id} type="checkbox" hidden={hidden} {...rest} />
      {label && <label htmlFor={id}>{label}</label>}
    </Wrapper>
  );
};

export default StaleCheckbox;
