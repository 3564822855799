import dayjs from 'dayjs';
import { FC } from 'react';
import { IPnlDataPerMonth } from 'types/analyses';
import ChartBar from 'components/shared/ChartBar/ChartBar';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import ChartReleasedLegendsLayer from '../ChartReleasedLegendsLayer/ChartReleasedLegendsLayer';
import ChartTick from '../ChartTick/ChartTick';
import ChartRealisedBarsLayer from '../ChartRealisedBarsLayer/ChartRealisedBarsLayer';

dayjs.extend(customParseFormat);

const VALUE_BUFFER = 1.15;

const getMinAndMaxValues = (data: IPnlDataPerMonth[]) =>
  data.reduce(
    ({ minValue, maxValue }, { totalFxImpactForPaid, transactionCost }) => ({
      minValue: Math.min(
        minValue,
        Math.min(0, totalFxImpactForPaid) + Math.min(0, transactionCost)
      ),
      maxValue: Math.max(
        maxValue,
        Math.max(0, totalFxImpactForPaid) + Math.max(0, transactionCost)
      ),
    }),
    {
      minValue: 0,
      maxValue: 0,
    }
  );

interface OwnProps {
  data: IPnlDataPerMonth[];
  activeMonth: string;
  onChartBarClick: (barMonth: string) => void;
}

const ChartRealised: FC<OwnProps> = ({
  data,
  activeMonth,
  onChartBarClick,
}) => {
  const { minValue, maxValue } = getMinAndMaxValues(data);

  const largestAbsoluteValue = Math.floor(
    Math.max(Math.abs(minValue), maxValue)
  );

  return (
    <ChartBar<IPnlDataPerMonth>
      keys={['totalFxImpactForPaid', 'transactionCost']}
      data={data}
      indexBy="id"
      margin={{
        top: 20,
        bottom: 70,
        left: `${largestAbsoluteValue}`.length * 6 + 18,
      }}
      axisTop={null}
      axisRight={null}
      enableGridX={false}
      isInteractive
      minValue={minValue * VALUE_BUFFER}
      maxValue={maxValue * VALUE_BUFFER}
      axisBottom={{
        tickPadding: 16,
        tickSize: 0,
        // @ts-ignore
        renderTick: ChartTick,
      }}
      layers={[
        'grid',
        'axes',
        (layerData) => (
          <ChartRealisedBarsLayer
            data={layerData}
            activeMonth={activeMonth}
            onClick={onChartBarClick}
          />
        ),
        (layerData) => (
          <ChartReleasedLegendsLayer
            data={layerData}
            activeMonth={activeMonth}
            onClick={onChartBarClick}
          />
        ),
        'markers',
        'legends',
        'annotations',
      ]}
      onClick={(datum) => {
        if (typeof datum.indexValue === 'string') {
          onChartBarClick(datum.indexValue);
        }
      }}
    />
  );
};

export default ChartRealised;
