import { useState } from 'react';
import {
  Title,
  Icon,
  Paragraph,
  Col,
  Row,
} from 'components';
import { useStoreState } from 'state';
import { ICurrencyInput, MakeOptional } from 'types';
import {
  IAccountData,
  isCodatPlatform,
  isXeroPlatform,
  isXeroSettings,
  TCodatSettingsInput,
  TPlatformNames,
  TXeroSettingsInput,
} from 'types/integrations';
import { Notify } from 'utils';
import TitleLoader from '../TitleLoader/TitleLoader';
import {
  saveXeroPermissions,
  createXeroAccount,
  saveXeroSettings,
  saveCodatPermissions,
  saveCodatSettings,
} from 'services/firebase';
import { useTheme } from 'styled-components';
import { XeroNotification } from 'components/shared/XeroNotification/XeroNotification.styles';
import useIntegrationAccounts from 'hooks/useIntegrationAccounts';
import UnorderedList from '../UnorderedList/UnorderedList';
import Pill from '../Pill/Pill';
import Button from '../Button/Button';

interface Inputs {
  paymentsAndConversions: boolean;
  invoicesAndBills: boolean;
  withBankFees: boolean;
  withExistingAccount: boolean;
  mainSellCurrencyAccount: IAccountData | null;
  createNewAccount: boolean;
  newAccountCurrencies: ICurrencyInput[];
}

interface ISavedValues extends MakeOptional<Inputs> {
  bankFeesAccount?: IAccountData | null;
}

interface OwnProps {
  savedValues?: ISavedValues;
  onContinue: () => void;
  platformName: TPlatformNames;
}

const StepAccountsSecond = ({
  savedValues,
  onContinue,
  platformName,
}: OwnProps) => {
  const theme = useTheme();
  const { xero, codat } = useStoreState(
    ({ UserState }) => UserState.integrationsSummary
  );
  const {
    haveGBPAccount,
    isLoadingAccounts,
    accounts,
  } = useIntegrationAccounts(platformName);
  const [isLoading, setIsLoading] = useState(false);

  const isXeroIntegration = isXeroPlatform(platformName);
  const isCodatIntegration = isCodatPlatform(platformName);

  const savedXeroSettings = xero?.settings;
  const savedCodatSettings = codat?.settings;

  const onSubmit = async () => {
    try {
      if (!isCodatIntegration && !isXeroIntegration) {
        console.error('attempting to save accounts for unsupported platform');
        Notify.error(
          'Attempting to save accounts for unsupported platform. Please contact support'
        );
        return;
      }

      setIsLoading(true);

      const permissions = {
        global: false,
        invoices: savedValues?.invoicesAndBills,
        payments: savedValues?.paymentsAndConversions,
        purchaseOrders: savedValues?.invoicesAndBills,
        bills: savedValues?.invoicesAndBills,
        bankFees: !!savedValues?.bankFeesAccount,
        accounts: savedValues?.paymentsAndConversions,
      };

      const settings = {
        ...(isCodatIntegration ? savedCodatSettings : savedXeroSettings),
        settingsType: isCodatIntegration ? 'Codat' : 'Xero',
        bankFeesAccount: savedValues?.bankFeesAccount?.value,
      } as TXeroSettingsInput | TCodatSettingsInput;

      const [permissionsResponse, settingsResponse] = isXeroSettings(settings)
        ? await Promise.all([
            saveXeroPermissions({ permissions }),
            saveXeroSettings({ settings }),
          ])
        : await Promise.all([
            saveCodatPermissions({ permissions }),
            saveCodatSettings({ settings }),
          ]);

      // creating accounts is currently a xero only feature
      if (!haveGBPAccount && isXeroIntegration) {
        // TODO hardcoding sell currency for now. Need to update BE + FE to
        // handle xero default currency along with account mapping
        await createXeroAccount({ currency: 'GBP' });
      }

      if (permissionsResponse.data?.success && settingsResponse.data?.success) {
        onContinue();
      } else {
        Notify.error(
          permissionsResponse?.data?.message ||
            settingsResponse?.data?.message ||
            ''
        );
        setIsLoading(false);
      }
    } catch (error) {
      Notify.error(error.response?.data?.error);
      setIsLoading(false);
    }
  };

  if (isLoadingAccounts) {
    return <TitleLoader title={`Getting ${platformName} accounts.`} />;
  }

  return (
    <>
      <Title mb mbValue={theme.spacing.xl}>
        Required HedgeFlows accounts in {platformName}
      </Title>

      <Col mb gap={theme.spacing.xs}>
        <Paragraph>
          We automatically create accounts in {platformName} to record your
          payments and collections:
        </Paragraph>

        <UnorderedList
          data={[
            'Invoices in foreign currencies paid using Pounds Sterling funds are processed via “HedgeFlows GBP”',
            'Invoices issued and paid using funds in the same currency are process via a relevant HedgeFlows currency account.',
          ]}
        />
      </Col>

      {accounts.length > 0 && (
        <Col mb gap={theme.spacing.xs}>
          <Paragraph>
            The following accounts already exist in {platformName}:{' '}
          </Paragraph>
          <Row
            flexWrap="wrap"
            gap={theme.spacing.s}
            justifyContent="flex-start"
          >
            {accounts.map(({ name }) => (
              <Pill text={name} />
            ))}
          </Row>
        </Col>
      )}

      <XeroNotification>
        <Icon icon="alert-ico" />
        <Paragraph ml>
          By pressing Confirm you confirm your permission for HedgeFlows to
          update {platformName} on your behalf. We will automatically record in{' '}
          {platformName}
          transactions that you process via HedgeFlows.
        </Paragraph>
      </XeroNotification>

      <Button
        isLoading={isLoading}
        disabled={isLoading}
        mt
        mtValue={theme.spacing.xl}
        onClick={onSubmit}
      >
        Confirm
      </Button>
    </>
  );
};

export default StepAccountsSecond;
