import { useCallback, useMemo } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router';
import useQuery from './useQuery';

const useUrlValues = <T extends string>(...valueNames: T[]) => {
  const urLQuery = useQuery();
  const { url } = useRouteMatch();
  const history = useHistory();
  const location = useLocation();

  const setUrlValue = useCallback(
    (name: string, value: string) => {
      urLQuery.set(name, value);

      history.replace(`${url}?${urLQuery.toString()}`, location.state);
    },
    [history, location.state, urLQuery, url]
  );

  const values = useMemo(
    () =>
      valueNames.reduce((acc, item) => {
        const value = urLQuery.get(item);

        acc[item] = value;

        return acc;
      }, {} as Record<typeof valueNames[number], string | null>),
    [urLQuery, valueNames]
  );

  return {
    ...values,
    setUrlValue,
  };
};

export default useUrlValues;
