import get from 'lodash.get';
import { CashflowsRisksData, ICurrency, IRiskDashboardItem } from 'types';

// TODO: take this info from the backend when we have it
export const getRiskLevelRatingText = (riskLevel?: number) => {
  if (typeof riskLevel === 'undefined') {
    return '';
  }

  if (riskLevel <= 1) {
    return 'VERY LOW';
  } else if (riskLevel === 2) {
    return 'LOW';
  } else if (riskLevel === 3) {
    return 'AVERAGE';
  } else if (riskLevel === 4) {
    return 'HIGH';
  } else {
    return 'VERY HIGH';
  }
};

export const getCashflowsPerCurrencyValues = (
  cashflowsPerCurrencyValues?: IRiskDashboardItem
) => ({
  netExposure: get(cashflowsPerCurrencyValues, 'netExposure', 0),
  netExposureInSellCurrency: get(
    cashflowsPerCurrencyValues,
    'netExposureInSellCurrency',
    0
  ),
  payables: get(cashflowsPerCurrencyValues, 'payables', 0),
  receivables: get(cashflowsPerCurrencyValues, 'receivables', 0),
  prebooked: get(cashflowsPerCurrencyValues, 'prebooked', 0),
  balances: get(cashflowsPerCurrencyValues, 'balance', 0),
  externalBalances: get(cashflowsPerCurrencyValues, 'externalBalance', 0),
  externalHedges: get(cashflowsPerCurrencyValues, 'externalHedges', 0),
  saleOrders: get(cashflowsPerCurrencyValues, 'saleOrders', 0),
  purchaseOrders: get(cashflowsPerCurrencyValues, 'purchaseOrders', 0),
});

interface IDetectCurrenciesFromCashflowsParams {
  currencies: ICurrency[];
  cashflowsRisks: CashflowsRisksData | null;
  entityDefaultCurrency?: string | null;
}

export const detectCurrenciesFromCashflows = ({
  currencies,
  cashflowsRisks,
  entityDefaultCurrency,
}: IDetectCurrenciesFromCashflowsParams) =>
  cashflowsRisks
    ? Object.keys(cashflowsRisks.currencyRisk)
        .sort()
        .filter(
          (currencyCode) =>
            currencyCode !== entityDefaultCurrency &&
            currencies.some(
              (currency) => currency.code === currencyCode && currency.enabled
            )
        )
    : [];
