// TODO: create uncontrolled Chekbox component
import React, { FC } from 'react';
import {
  FieldValues,
  useController,
  UseControllerOptions,
} from 'react-hook-form';
import { CSSProperties } from 'styled-components';

import { Wrapper } from './StaleCheckboxControlled.styles';

interface OwnProps extends UseControllerOptions<FieldValues> {
  id: string;
  Label?: React.ReactElement | string;
  onChangeCallback?: (value: boolean) => any;
  disabled?: boolean;
  style?: CSSProperties;
}

const StaleCheckboxControlled: FC<OwnProps> = ({
  id,
  Label,
  name,
  control,
  rules,
  defaultValue,
  onChangeCallback,
  disabled = false,
  style,
}) => {
  const {
    field: { ref, value, onChange },
  } = useController({
    name,
    control,
    rules,
    defaultValue,
  });

  return (
    <Wrapper style={style}>
      <input
        type="checkbox"
        ref={ref}
        id={id}
        name={name}
        disabled={disabled}
        checked={value}
        onKeyPress={(event) => {
          if (event.key === 'Enter') {
            onChange(!value);
            onChangeCallback && onChangeCallback(!value);
          }
        }}
        onChange={(e) => {
          onChange(e.target.checked);
          onChangeCallback && onChangeCallback(e.target.checked);
        }}
      />
      {Label && <label htmlFor={id}>{Label}</label>}
    </Wrapper>
  );
};

export default StaleCheckboxControlled;
