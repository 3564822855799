import { IContact } from 'types';
import { ContactReviewStatusWrapper } from './ContactReviewStatus.styles';

const ContactReviewStatus = ({ contact }: { contact: IContact }) => {
  if (!contact.enabled) {
    return (
      <ContactReviewStatusWrapper color="red">
        Incomplete
      </ContactReviewStatusWrapper>
    );
  }

  if (contact.reviewalStatus === 'updated') {
    return (
      <ContactReviewStatusWrapper color="coral">
        Updated
      </ContactReviewStatusWrapper>
    );
  }

  if (contact.reviewalStatus === 'new') {
    return (
      <ContactReviewStatusWrapper color="blue">New</ContactReviewStatusWrapper>
    );
  }

  return null;
};

export default ContactReviewStatus;
