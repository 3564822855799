import { FC } from 'react';
import { Span } from '../Typography/Typography';
import { StyledPill } from './Pill.styles';

interface OwnProps {
  text: string;
}

const Pill: FC<OwnProps> = ({ text }) => {
  return (
    <StyledPill>
      <Span>{text}</Span>
    </StyledPill>
  );
};

export default Pill;
