import React, { FC, useMemo } from 'react';
import RelativeTime from 'dayjs/plugin/relativeTime';
import dayjs from 'dayjs';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { Wrapper } from './StalePrebookPreview.styles';
import { StaleParagraphMedium } from 'components';
import { IRateContract } from 'types';
import {
  canUseRateContract,
  getCountryCodeByCurrency,
  getPrebookStatusDetails,
  parseIntoCurrencyString,
  parseRateWithPrecision,
} from 'utils';
import { useStoreState } from 'state';
import { getTransfersPageLink } from 'utils/links';
import { useQuery } from 'hooks';

dayjs.extend(RelativeTime);

interface OwnProps {
  data: IRateContract;
}

const StalePrebookPreview: FC<OwnProps> = ({ data }) => {
  const history = useHistory();
  const URLQuery = useQuery();
  const { url } = useRouteMatch();
  const { currencies } = useStoreState((state) => state.CurrenciesState);

  const buyCurrency = useMemo(
    () => currencies.find((item) => item.code === data.buyCurrency),
    [currencies, data]
  );

  const statusInfo = useMemo(() => getPrebookStatusDetails(data), [data]);

  const goToPrebookInner = () => {
    URLQuery.append('contractId', data.id);
    history.push(`${url}?${URLQuery.toString()}`);
  };

  return (
    <Wrapper
      onClick={goToPrebookInner}
      style={{ borderColor: statusInfo ? statusInfo.color : 'transparent' }}
    >
      <StaleParagraphMedium className="line" style={{ marginBottom: '14px' }}>
        1 {` ${data.sellCurrency} `} =
        <span className="title-h4">{parseRateWithPrecision(data.rate)}</span>
        {data.buyCurrency}
      </StaleParagraphMedium>

      <div className="row" style={{ marginBottom: '20px' }}>
        <StaleParagraphMedium className="line">
          <svg>
            <use
              xlinkHref={`#${getCountryCodeByCurrency(
                data.buyCurrency,
                currencies
              )}`}
            />
          </svg>
          <span className="title-h1">
            {parseIntoCurrencyString(
              data.remainingBuyAmount,
              buyCurrency?.precision
            )}
          </span>{' '}
          {data.buyCurrency}
        </StaleParagraphMedium>

        <StaleParagraphMedium>Available</StaleParagraphMedium>
      </div>

      <div className="row" style={{ marginBottom: '7px' }}>
        <StaleParagraphMedium>Use by</StaleParagraphMedium>
        <StaleParagraphMedium>{`${dayjs().to(
          dayjs(data.expiryDate),
          true
        )} left | ${dayjs(data.expiryDate).format(
          'DD.MM.YYYY'
        )}`}</StaleParagraphMedium>
      </div>

      <div className="row">
        {statusInfo && (
          <>
            <StaleParagraphMedium>Status</StaleParagraphMedium>
            <StaleParagraphMedium
              className="status"
              style={{ color: statusInfo.color }}
            >
              {statusInfo.text}
            </StaleParagraphMedium>
          </>
        )}
      </div>

      <div className="icons">
        {canUseRateContract(data) && (
          <button
            onClick={(e) => {
              e.stopPropagation();

              const { remainingBuyAmount, id } = data;

              history.push(
                getTransfersPageLink({
                  predefinedBuyAmount: remainingBuyAmount.toString(),
                  predefinedRateContractId: id,
                })
              );
            }}
          >
            <svg>
              <use xlinkHref="#transfer-ico" fill="#1CBD85" />
            </svg>
          </button>
        )}

        <button>
          <svg>
            <use xlinkHref="#info-ico" fill="#000" />
          </svg>
        </button>
      </div>
    </Wrapper>
  );
};

export default StalePrebookPreview;
