import { FC, useEffect, useState, useMemo } from 'react';
import { StaleCheckboxControlled, StaleInput, Row } from 'components';
import { useForm } from 'react-hook-form';
import { EntityInputField } from './NotificationSettings.styles';
import { useStoreState } from 'state';
import { Notify } from 'utils';
import { updateEntitySettings } from 'services/firebase';
import { IEntitySettings } from 'state/user';
import { useTheme } from 'styled-components';

import {
  SettingsContent,
  SettingsField,
  SettingsFooter,
} from 'pages/Settings/Settings.styles';
import Button from 'components/shared/Button/Button';

const NotificationSettings: FC = () => {
  const theme = useTheme();
  const { userEntity } = useStoreState((state) => state.UserState);
  const [isLoading, setIsLoading] = useState(false);

  const defaultValues = useMemo(() => {
    return {
      ...userEntity,
    };
  }, [userEntity]);

  const { control, handleSubmit, reset } = useForm<
    Omit<
      IEntitySettings,
      'riskSettingCommittedCashFlows' | 'riskSettingExpectedCashFlows'
    >
  >({
    mode: 'all',
    defaultValues,
  });

  useEffect(() => {
    if (userEntity) {
      reset(defaultValues);
    }
  }, [userEntity, reset, defaultValues]);

  const onSubmit = async (values) => {
    try {
      setIsLoading(true);

      const response = await updateEntitySettings(values);

      if (response?.data?.success) {
        reset(values);
        Notify.success('Notification settings saved successfully!');
      }
    } catch (error) {
      Notify.error(error.response?.data?.error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form id="notification-form" onSubmit={handleSubmit(onSubmit)}>
      <SettingsContent>
        <EntityInputField>
          <StaleInput
            id="alternative-email"
            name="email"
            view="moving"
            label="Notifications sent to"
            control={control}
            disabled={isLoading}
          />
        </EntityInputField>
        <SettingsField>
          <StaleCheckboxControlled
            id="shouldSendTransactionConfirmations"
            name="shouldSendTransactionConfirmations"
            control={control}
            Label="Email me transaction confirmations"
            disabled={isLoading}
          />
        </SettingsField>
        <StaleCheckboxControlled
          id="shouldSendRemittanceCopiesToSelf"
          name="shouldSendRemittanceCopiesToSelf"
          control={control}
          Label="Email me copies of Remittances"
          disabled={isLoading}
        />

        <StaleCheckboxControlled
          id="shouldSendApprovalAlerts"
          name="shouldSendApprovalAlerts"
          control={control}
          Label="Send approval alerts "
          disabled={isLoading}
        />
      </SettingsContent>
      <SettingsFooter>
        <Row columnGap={theme.spacing.m} justifyContent="flex-start">
          <Button
            variant="secondary"
            form="notification-form"
            disabled={isLoading}
            isLoading={isLoading}
          >
            Save
          </Button>
        </Row>
      </SettingsFooter>
    </form>
  );
};

export default NotificationSettings;
