import styled from 'styled-components';

export const BankInfoWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
  margin: 3px 0 16px;
  padding-left: 18px;
`;
