// @ts-nocheck
import { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { DropdownWrapper } from './StaleHeaderDropdown.styles';
import cx from 'classnames';
import { isMobile } from 'react-device-detect';

interface OwnProps {
  data: {
    id: number;
    title: string;
    links: {
      title: string;
      slug: string;
      target: string;
    }[];
  };
  component: 'dropdown' | 'accordion';
}

const StaleHeaderDropdown: FC<OwnProps> = ({ data, component }) => {
  const [showNavDropdown, setShowNavDropdown] = useState(false);

  return (
    <>
      <DropdownWrapper
        onClick={isMobile ? () => setShowNavDropdown(!showNavDropdown) : null}
        onMouseOver={!isMobile ? () => setShowNavDropdown(true) : null}
        onMouseLeave={!isMobile ? () => setShowNavDropdown(false) : null}
        className={cx(
          showNavDropdown && 'open',
          component === 'dropdown' && 'dropdown-block',
          component === 'accordion' && 'accordion-block'
        )}
      >
        <button className="menu-item">
          {data.title}
          <svg className="arrow">
            <use xlinkHref="#arrow-down" />
          </svg>
        </button>

        <div className="dropdown">
          <div className="row">
            {data.links.map(({ title, slug, target }) =>
              target === '_blank' ? (
                <a key={slug} href={slug} target={target} className="link">
                  {title}
                </a>
              ) : (
                <Link key={slug} to={slug} className="link">
                  {title}
                </Link>
              )
            )}
          </div>
        </div>
      </DropdownWrapper>
    </>
  );
};

export default StaleHeaderDropdown;
