import { FC } from 'react';
import { IInvoice } from 'types';
import { getInvoicePrebookLink, getInvoiceTransferLink } from 'utils/invoices';
import { useHistory, useLocation } from 'react-router-dom';
import useInvoiceRecord from 'pages/Invoices/hooks/useInvoiceRecord';
import { ActionButton } from 'components/shared/ActionButton/ActionButton.styles';
import useInvoicesApprovalStatus from 'hooks/useInvoiceApprovalStatus';

interface OwnProps {
  record: IInvoice;
}

const DoActionCell: FC<OwnProps> = ({ record }) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const risks = pathname.includes('risks');
  const {
    isUpdatingInvoicesApprovalStatus,
    updateInvoicesApprovalStatus,
  } = useInvoicesApprovalStatus();
  const {
    isDisabled,
    isSameCurrency,
    isPayable,
    daysUntilDueDate,
    isCanBePaid,
    isApprovable,
    isPrebookable,
    submittableForReview,
  } = useInvoiceRecord({ record });

  if (isDisabled || isSameCurrency) {
    return null;
  }

  if (submittableForReview) {
    return (
      <ActionButton
        disabled={isUpdatingInvoicesApprovalStatus}
        onClick={(event) => {
          event.stopPropagation();
          updateInvoicesApprovalStatus({
            invoiceIds: [record.id],
            approvalStatus: 'submitted',
          });
        }}
      >
        Submit
      </ActionButton>
    );
  }

  if (isApprovable) {
    return (
      <ActionButton
        disabled={isUpdatingInvoicesApprovalStatus}
        onClick={(event) => {
          event.stopPropagation();
          updateInvoicesApprovalStatus({
            invoiceIds: [record.id],
            approvalStatus: 'approved',
          });
        }}
      >
        Approve
      </ActionButton>
    );
  }

  if (isCanBePaid && isPayable && daysUntilDueDate < 7) {
    return (
      <ActionButton
        disabled={isUpdatingInvoicesApprovalStatus}
        onClick={(event) => {
          event.stopPropagation();

          history.push(getInvoiceTransferLink(record));
        }}
      >
        Pay
      </ActionButton>
    );
  }

  if (isPrebookable && !!risks) {
    return (
      <ActionButton
        disabled={isUpdatingInvoicesApprovalStatus}
        onClick={(event) => {
          event.stopPropagation();
          history.push(getInvoicePrebookLink(record));
        }}
      >
        Book
      </ActionButton>
    );
  }

  return null;
};

export default DoActionCell;
