import { IEntityDetails, IResponse } from 'types';
import { AxiosPrivateFirebaseInstance } from 'settings';
import { IEntity, TEntityPackages } from 'state/user';

export interface CreateEntityPayload {
  companyName: string;
}

export const createEntity = async (payload: CreateEntityPayload) => {
  try {
    const { data } = await AxiosPrivateFirebaseInstance.post<
      IResponse<IEntity>
    >('entities', payload);

    return data;
  } catch (error) {
    console.warn(error.message);
    return;
  }
};

export const getEntityUsers = async ({ entityId }: { entityId: string }) =>
  AxiosPrivateFirebaseInstance.get<IResponse>(`entities/${entityId}/users`);

export interface IPermitRoleV2 {
  id: string;
  key: string;
  name: string;
  description: string | null;
  permissions: string[];
  environmentId: string;
  extends: string[];
  organization_id: string;
  project_id: string;
  environment_id: string;
  created_at: string;
  updated_at: string;
}

export const getEntityRoles = async (entityId: string) =>
  AxiosPrivateFirebaseInstance.get<IResponse<IPermitRoleV2[]>>(
    `entities/${entityId}/roles`
  );

export const editEntityUserRoles = async ({
  entityId,
  userId,
  roles,
}: {
  entityId: string;
  userId: string;
  roles: string[];
}) =>
  AxiosPrivateFirebaseInstance.post<IResponse>(
    `entities/${entityId}/users/${userId}/roles`,
    { roles }
  );

export const getPackages = async (entityId: string) => {
  const { data } = await AxiosPrivateFirebaseInstance.get<
    IResponse<TEntityPackages>
  >(`entities/${entityId}/packages`);

  return data.data;
};

export const savePackages = async (
  entityId: string,
  packages: TEntityPackages
) => {
  const { data } = await AxiosPrivateFirebaseInstance.put<
    IResponse<TEntityPackages>
  >(`entities/${entityId}/packages`, packages);

  return data.data;
};

export const setEntityApprover = async ({
  userId,
  entityId,
}: {
  userId: string;
  entityId: string;
}) =>
  AxiosPrivateFirebaseInstance.post<IResponse>(
    `entities/${entityId}/approver/${userId}`
  );

export const enableApprovalFlowForEntity = async ({
  entityId,
}: {
  entityId: string;
}) =>
  AxiosPrivateFirebaseInstance.post<IResponse>(
    `entities/${entityId}/switch-approval-flow`
  );

export interface GetEntityCompanyDetailsParams {
  entityId: string;
}

export const getEntityCompanyDetails = async (entityId: string) =>
  await AxiosPrivateFirebaseInstance.get<
    IResponse<Pick<IEntityDetails, 'countriesSendingMoneyTo'>>
  >(`entities/${entityId}/companyDetails`);
