import { IInvoice } from './invoices';

export type TXeroInvoice = IInvoice & {
  externalRefs: { xeroId: string; xeroContactId?: string };
};

export interface IXeroInvoice extends IInvoice {
  externalRefs: { xeroId: string; xeroContactId?: string };
}

export const isXeroInvoice = (
  invoice: IInvoice | IXeroInvoice
): invoice is IXeroInvoice =>
  (invoice as IXeroInvoice)?.externalRefs?.xeroId !== undefined;
