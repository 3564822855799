import { useEffect, useState } from 'react';
import { getCodatAccounts } from 'services/firebase';
import { IAccountData, ICodatAccount } from 'types/integrations';
import { Notify } from 'utils';
import { generateCodatBankFeesAccountData } from 'utils/integrations';

interface IFilteredAccounts {
  codatAccounts: IAccountData[];
  bankFeesAccounts: IAccountData[];
}

const useCodatAccounts = (fetchAccounts = false) => {
  const [isLoadingAccounts, setIsLoadingAccounts] = useState(false);
  const [haveGBPAccount, setHaveGBPAccount] = useState(false);
  const [
    defaultBankFeesAccount,
    setDefaultBankFeesAccount,
  ] = useState<ICodatAccount | null>(null);
  const [codatAccounts, setCodatAccounts] = useState<IAccountData[]>([]);
  const [bankFeesAccounts, setBankFeesAccounts] = useState<IAccountData[]>([]);

  useEffect(() => {
    const fetchCodatAccounts = async () => {
      try {
        setIsLoadingAccounts(true);
        const response = await getCodatAccounts();
        if (response.data?.success) {
          const accounts = response.data.data;
          const foundGBPAccount = accounts?.some(
            (account) => account.name === 'HedgeFlows GBP'
          );
          if (foundGBPAccount) {
            setHaveGBPAccount(foundGBPAccount);
          }

          const findBankFeesAccount = accounts?.find(
            (account) => account.id === '27'
          );
          if (findBankFeesAccount) {
            setDefaultBankFeesAccount(findBankFeesAccount);
          }

          const filteredAccounts = accounts?.reduce<IFilteredAccounts>(
            (total, el) => {
              if (el.type === 'Asset' && el.currency === 'GBP') {
                total.codatAccounts = [
                  ...total?.codatAccounts,
                  generateCodatBankFeesAccountData(el),
                ];
              }
              if (el.type === 'Expense') {
                total.bankFeesAccounts = [
                  ...total?.bankFeesAccounts,
                  generateCodatBankFeesAccountData(el),
                ];
              }
              return total;
            },
            { codatAccounts: [], bankFeesAccounts: [] }
          );

          if (filteredAccounts?.codatAccounts?.length) {
            setCodatAccounts(filteredAccounts.codatAccounts);
          }
          if (filteredAccounts?.bankFeesAccounts?.length) {
            setBankFeesAccounts(filteredAccounts.bankFeesAccounts);
          }
        }
      } catch (error) {
        Notify.error(error.message);
      } finally {
        setIsLoadingAccounts(false);
      }
    };

    if (fetchAccounts) {
      fetchCodatAccounts();
    }
  }, [fetchAccounts]);

  return {
    isLoadingAccounts,
    haveGBPAccount,
    codatAccounts,
    bankFeesAccounts,
    defaultBankFeesAccount,
  };
};

export default useCodatAccounts;
