import styled from 'styled-components';
import StaleBtnGroup from '../StaleBtnGroup/StaleBtnGroup';
import { StaleContainer } from '../../StaleLayout/StaleLayout.styles';
import { Paragraph } from '../Typography/Typography';
import { Col } from '../Col/Col';

export const PopupContentWrapper = styled(Col)`
  height: 100%;
`;

export const PopupContentContainerWrapped = styled(StaleContainer)`
  flex: 0 1 100%;
  overflow: auto;
  padding: 20px 24px 0 !important;
`;

export const PopupContentParagraphWrapped = styled(Paragraph)`
  font-weight: 500;
  color: ${({ theme }) => theme.color.greyDark};
`;

export const PopupContentBtnGroupWrapped = styled(StaleBtnGroup)`
  button {
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  a {
    text-decoration: underline;
    text-underline-offset: 2px;
  }
`;
