import styled from 'styled-components';
import { Row, Title } from 'components';

export const ErrorPlaceholderWrapper = styled(Row)`
  flex: 1;
  align-self: stretch;
  padding: 0 ${({ theme }) => theme.spacing.xxxl};
  background: ${({ theme }) => theme.color.greyLight_2};
`;

export const ErrorPlaceholderTextsWrapper = styled.div`
  flex: 1;
  max-width: 540px;
`;

export const HeadingTitle = styled(Title)`
  font-weight: 800;
  font-size: 58px;
  line-height: 75px;
`;

export const TextTitle = styled(Title)`
  margin-bottom: ${({ theme }) => theme.spacing.xxl};
`;
