import styled, { css } from 'styled-components';

interface ExpansionProps {
  height?: number;
  delay?: boolean;
}

export const ExpansionWrapper = styled.div<ExpansionProps>(
  ({ theme, height = 0, delay = true }) => css`
    position: relative;
    overflow: hidden;
    transition: height ${theme.transition} ${delay && theme.transition} linear;
    height: ${`${height}px`};
  `
);
