import styled, { css } from 'styled-components';
import { StaleSubTitleBold, StaleParagraphMedium } from 'components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Info = styled.button`
  position: relative;

  &:disabled {
    pointer-events: none;
  }

  &:focus svg {
    fill: #1cbd85;
  }
`;

export const InfoPopup = styled.div(
  ({ theme }) => css`
    z-index: 9999;

    @media (max-width: ${theme.breakpoint.smallMax}px) {
      width: 100vw;
    }
    @media (min-width: ${theme.breakpoint.smallMin}px) {
      max-width: 500px;
    }

    @media (min-width: ${theme.breakpoint.largeMin}px) {
      max-width: 668px;
    }

    .wrap {
      padding: 16px;
      border-radius: 8px;
      background: ${theme.color.dark};
      color: ${theme.color.white};
      display: flex;
      flex-direction: column;

      svg {
        fill: ${theme.color.white};
      }
    }

    ${StaleSubTitleBold}, ${StaleParagraphMedium} {
      display: block !important;

      span {
        color: ${theme.color.emeraldDark};
      }
    }

    ${StaleParagraphMedium} {
      margin-bottom: 12px;
    }

    ${StaleSubTitleBold} {
      margin-bottom: 4px;
    }

    a {
      text-decoration: underline;
    }

    .arrow,
    .arrow::before {
      position: absolute;
      width: 8px;
      height: 8px;
      background: ${theme.color.dark};
    }

    .arrow {
      visibility: hidden;
    }

    .arrow::before {
      visibility: visible;
      content: '';
      transform: rotate(45deg);
    }

    &[data-popper-placement^='top'] > .arrow {
      bottom: -4px;
    }

    &[data-popper-placement^='bottom'] > .arrow {
      top: -4px;
    }

    &[data-popper-placement^='left'] > .arrow {
      right: -4px;
    }

    &[data-popper-placement^='right'] > .arrow {
      left: -4px;
    }
  `
);
