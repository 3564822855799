import {
  CSSProperties,
  FC,
  InputHTMLAttributes,
  SyntheticEvent,
  useRef,
  useState,
} from 'react';

import cx from 'classnames';
import { Wrapper } from './StaleInputSelect.styles';
import StaleInputSelectList from '../StaleInputSelectList/StaleInputSelectList';
import { createPortal } from 'react-dom';

export interface ISelectItem {
  id: any;
  name: string;
  value: any;
  icon?: string;
  disabled?: boolean;
}

interface OwnProps {
  id: any;
  label?: string;
  name?: string;
  view?: string;
  data?: ISelectItem[];
  onSelect?: (item: ISelectItem) => any;
  selected?: any;
  theme?: 'theme-grey' | 'theme-dark';
  withSearch?: boolean;
  searchValue?: string;
  onSearch?: (event: SyntheticEvent<HTMLInputElement>) => any;
  onClose?: (...args: any) => any;
  ref?: any;
  error?: string;
  disabled?: boolean;
  autoFocus?: InputHTMLAttributes<HTMLInputElement>['autoFocus'];
  strategy?: 'absolute' | 'fixed';
  style?: CSSProperties;
}

const StaleInputSelect: FC<OwnProps> = ({
  id,
  name = '',
  label = '',
  view = 'static',
  data = [],
  selected,
  onSelect = () => {},
  theme = 'theme-grey',
  withSearch = false,
  searchValue = '',
  onSearch = () => {},
  onClose = () => {},
  ref = null,
  error = '',
  disabled = false,
  autoFocus = false,
  strategy = 'absolute',
  style,
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  const [focused, setFocused] = useState(false);

  const toggleOpen = () => setOpen(!open);

  const selectedItem = data.find(
    (item) => item.id === selected || item.id === selected?.id
  );

  return (
    <Wrapper
      ref={wrapperRef}
      error={!!error}
      className={cx(
        view,
        open && 'show',
        selected && 'filled',
        focused && 'focused'
      )}
      style={style}
      disabled={disabled}
    >
      {view === 'static' && <label className="label">{label}</label>}

      {label && view === 'moving' && !!selected && (
        <label className="label" htmlFor={id}>
          {label}
        </label>
      )}

      <select
        id={id}
        disabled={disabled}
        name={name}
        defaultValue={label}
        onKeyPress={toggleOpen}
        ref={ref}
        autoFocus={autoFocus}
        onChange={(e) => {
          const item = data.find((item) => item.id === e.currentTarget.value);

          if (item) {
            onSelect(item);
          }
        }}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
      >
        {!selected && (
          <option disabled value={label}>
            {label}
          </option>
        )}
        {data.map((item) => (
          <option
            disabled={item.disabled}
            key={item.id}
            value={item.id}
            selected={selected?.id === item.id}
          >
            {item.name}
          </option>
        ))}
      </select>

      {/* TODO: remove this hack with the button and rework select, so it is made with valid input and not div */}
      <div
        role="button"
        data-testid={name}
        className={cx('input', disabled && 'disabled')}
        onClick={disabled ? undefined : toggleOpen}
      >
        {!selectedItem ? (
          <span className="placeholder">{label}</span>
        ) : (
          <span>
            {selectedItem.icon && (
              <svg>
                <use xlinkHref={`#${selectedItem.icon}`} />
              </svg>
            )}
            {selectedItem.name}
          </span>
        )}

        {!disabled && (
          <svg className="i-arrow">
            <use xlinkHref="#arrow-down" />
          </svg>
        )}
      </div>

      {error && <span className="error">{error}</span>}

      {open &&
        (strategy === 'absolute' ? (
          <StaleInputSelectList
            withClose
            withSearch={withSearch}
            onClose={() => {
              setOpen(!open);
              onClose();
            }}
            onSelect={onSelect}
            searchValue={searchValue}
            onSearch={onSearch}
            selectedValue={selected}
            data={data}
            title={label.length < 30 ? `Select ${label.toLowerCase()}` : label}
            theme={theme}
          />
        ) : (
          createPortal(
            <div
              style={{
                position: 'fixed',
                zIndex: 13,
                top:
                  wrapperRef.current?.getBoundingClientRect().top ??
                  0 + (wrapperRef.current?.getBoundingClientRect().height ?? 0),
                left: wrapperRef.current?.getBoundingClientRect().left,
                width: wrapperRef.current?.getBoundingClientRect().width,
              }}
            >
              <StaleInputSelectList
                withClose
                withSearch={withSearch}
                onClose={() => {
                  setOpen(!open);
                  onClose();
                }}
                onSelect={onSelect}
                searchValue={searchValue}
                onSearch={onSearch}
                selectedValue={selected}
                data={data}
                title={
                  label.length < 30 ? `Select ${label.toLowerCase()}` : label
                }
                theme={theme}
              />
            </div>,
            document.body
          )
        ))}
    </Wrapper>
  );
};

export default StaleInputSelect;
