import { createStore, createTypedHooks } from 'easy-peasy';
import LogRocket from 'logrocket';

import { UserStateModel, UserState } from './user';
import { RateContractsState, RateContractsStateModel } from './rateContracts';
import { TransfersState, TransfersStateModel } from './transfers';
import { CurrenciesState, CurrenciesStateModel } from './currencies';
import { ReferenceDataState, ReferenceDataStateModel } from './referenceData';
import { RecipientsState, RecipientsStateModel } from './recipients';
import { SessionStateModel, SessionState } from './session';
import { InvoicesStateModel, InvoicesState } from './invoices';
import { BulkPaymentsStateModel, BulkPaymentsState } from './bulkPayments';
import { CurrencyRatesState, CurrencyRatesStateModel } from './currencyRates';
import { BalancesState, BalancesStateModel } from './balances';
import {
  ExternalPaymentsState,
  ExternalPaymentsStateModel,
} from './externalPayments';
import {
  ExternalHedgesState,
  ExternalHedgesStateModel,
} from './externalHedges';
import { FeatureFlagsState, FeatureFlagsStateModel } from './featureFlags';
import { recursivelySanitiseAction } from 'settings/analytics/logRocket';
import { PaymentRunsState, PaymentRunsStateModel } from './paymentRuns';

const typedHooks = createTypedHooks<StateModel>();

export const useStoreActions = typedHooks.useStoreActions;
export const useStoreDispatch = typedHooks.useStoreDispatch;
export const useStoreState = typedHooks.useStoreState;

export interface StateModel {
  UserState: UserStateModel;
  RateContractsState: RateContractsStateModel;
  TransfersState: TransfersStateModel;
  CurrenciesState: CurrenciesStateModel;
  CurrencyRatesState: CurrencyRatesStateModel;
  ReferenceDataState: ReferenceDataStateModel;
  RecipientsState: RecipientsStateModel;
  SessionState: SessionStateModel;
  InvoicesState: InvoicesStateModel;
  BulkPaymentsState: BulkPaymentsStateModel;
  BalancesState: BalancesStateModel;
  ExternalPaymentsState: ExternalPaymentsStateModel;
  ExternalHedgesState: ExternalHedgesStateModel;
  FeatureFlagsState: FeatureFlagsStateModel;
  PaymentRunsState: PaymentRunsStateModel;
}

export const stateModel: StateModel = {
  UserState,
  RateContractsState,
  TransfersState,
  CurrenciesState,
  CurrencyRatesState,
  ReferenceDataState,
  RecipientsState,
  SessionState,
  InvoicesState,
  BulkPaymentsState,
  BalancesState,
  ExternalPaymentsState,
  ExternalHedgesState,
  FeatureFlagsState,
  PaymentRunsState,
};

const store = createStore(stateModel, {
  middleware: [
    LogRocket.reduxMiddleware({
      actionSanitizer: recursivelySanitiseAction,
    }),
  ],
});

export default store;
