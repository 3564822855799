import styled, { css } from 'styled-components';
import { StaleInputSearch } from 'components/shared/StaleInputSearch/StaleInputSearch';
import { Row } from 'components/shared/Row/Row';
import { CSSProperties } from 'react';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${Row} {
    margin: 0 0 12px;
  }

  ${StaleInputSearch} {
    margin-bottom: 12px;
  }
`;

export const AddRecipientButtonBig = styled.button(
  ({ theme }) => css`
    margin-top: 24px;
    border-radius: 8px;
    border: 1px solid ${theme.color.greyLight_1};
    transition: ${theme.transition};
    padding: 10px;
    width: 100%;

    &:hover {
      opacity: 0.5;
    }
  `
);

export const ListWrapper = styled.div<{
  maxHeight?: CSSProperties['maxHeight'];
}>`
  overflow-y: auto;
  max-height: ${({ maxHeight = 'none' }) => maxHeight};
`;
