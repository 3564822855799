import { AxiosPrivateFirebaseInstance } from 'settings';
import { IForwardRateAndFeesResponse, IRate } from 'types';
import { request } from 'utils/network';

export const getRate = async ({
  sellCurrency,
  buyCurrency,
  withCancel = true,
}: {
  sellCurrency: string;
  buyCurrency: string;
  withCancel?: boolean;
}) => {
  const url = `/rates/${sellCurrency}${buyCurrency}`;

  const { data: rateResponse } = await request<IRate>(
    {
      url,
      method: 'GET',
    },
    true,
    withCancel
  );

  return rateResponse;
};

export const getForwardRateAndFees = async ({
  sellCurrency,
  buyCurrency,
  date,
}: {
  sellCurrency: string;
  buyCurrency: string;
  date: string;
}) => {
  const {
    data,
  } = await AxiosPrivateFirebaseInstance.get<IForwardRateAndFeesResponse>(
    `/rates/${sellCurrency}${buyCurrency}/${date}`
  );

  return data;
};
