import styled from 'styled-components';

interface OwnProps {
  maxWidth?: number;
}

export const StaleField = styled.div<OwnProps>`
  flex: 0 1 100%;
  max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}px` : '100%')};
`;
