import { useEffect, useState } from 'react';
import { Firebase } from 'services';
import { RECIPIENT_STATUS, IInvoice, IRecipient } from 'types';

const useGetXeroInvoiceContact = (invoice?: IInvoice) => {
  const [
    recipientFromInvoice,
    setRecipientFromInvoice,
  ] = useState<IRecipient>();

  const [isLoadingXeroInvoice, setIsLoadingXeroInvoice] = useState(
    !!(
      !invoice?.contactId &&
      invoice?.externalRefs?.xeroContactId &&
      invoice.externalRefs.xeroId
    )
  );

  useEffect(() => {
    const getXeroInvoiceContact = async () => {
      try {
        if (
          !invoice?.contactId &&
          invoice?.externalRefs?.xeroContactId &&
          invoice.externalRefs.xeroId
        ) {
          setIsLoadingXeroInvoice(true);
          const invoiceFromXero = await Firebase.getXeroInvoice(
            invoice.externalRefs.xeroId
          );
          const contact = {
            ...invoiceFromXero?.contact,
            status: RECIPIENT_STATUS.draft,
            externalRefs: { xeroId: invoice.externalRefs.xeroContactId },
          };

          // TODO: check types
          // @ts-ignore
          setRecipientFromInvoice(contact);
          setIsLoadingXeroInvoice(false);
        } else if (invoice?.contact) {
          setRecipientFromInvoice(invoice.contact);
        }
      } catch (error) {
        console.warn(error);
      } finally {
        setIsLoadingXeroInvoice(false);
      }
    };

    getXeroInvoiceContact();
  }, [invoice]);

  return {
    isLoadingXeroInvoice,
    recipientFromInvoice,
  };
};

export default useGetXeroInvoiceContact;
