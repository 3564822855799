import { FC, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { RouteComponentProps } from 'react-router-dom';

import {
  StaleTitleH3,
  StaleTitleH5,
  StaleInput,
  Button,
  StaleCarousel,
} from 'components';
import {
  Wrapper,
  InnerWrap,
  NavWrap,
  ContentWrap,
} from './ResetPasswordConfirm.styles';
import { useForm } from 'react-hook-form';
import { HexagonLine } from 'pages/SignUp/components';
import { useQuery } from 'hooks';
import { Notify } from 'utils';
import { VALIDATION_MESSAGES } from 'variables';
import auth from 'services/auth';

const ResetPasswordConfirm: FC<RouteComponentProps> = ({ history }) => {
  const [isResetDone, setIsResetDone] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    criteriaMode: 'all',
  });

  const password = watch('password');
  const repeatPassword = watch('repeatPassword');

  const URLQuery = useQuery();
  const oobCode = URLQuery.get('oobCode');

  useEffect(() => {
    if (oobCode) {
      auth
        .verifyPasswordResetCode(oobCode)
        .then((response) => {
          if (!response) {
            Notify.error(
              'Reset password link is not valid. Please try again. If the error persists, contact support please.'
            );

            history.replace('/');
          }
        })
        .catch(() => {
          Notify.error(
            'Reset password link is not valid. Please try again. If the error persists, contact support please.'
          );
          history.replace('/');
        });
    } else {
      history.replace('/');
    }
  }, [oobCode, history]);

  const onSubmit = async (values) => {
    try {
      if (!oobCode) {
        return;
      }

      setIsLoading(true);

      await auth.confirmPasswordReset(oobCode, values.password);

      setIsResetDone(true);
    } catch (error) {
      Notify.open(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return oobCode ? (
    <Wrapper>
      <InnerWrap>
        <NavWrap>
          {isMobile ? (
            <>
              <StaleTitleH5>Enter your new password</StaleTitleH5>
            </>
          ) : (
            <>
              <StaleTitleH5>Sign in to HedgeFlows to:</StaleTitleH5>

              <StaleCarousel />
            </>
          )}
        </NavWrap>

        <ContentWrap className="rounded">
          <div className="block">
            {isResetDone ? (
              <>
                <StaleTitleH3>
                  Password reset is done. You may sign in with your new password
                  now.
                </StaleTitleH3>

                <Button
                  style={{
                    marginTop: 'auto',
                  }}
                  onClick={() => history.push('/login')}
                >
                  Back to Sign In
                </Button>
              </>
            ) : (
              <>
                <StaleTitleH5>Enter your new password</StaleTitleH5>

                <form
                  id="reset-password-confirm"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className="field">
                    <StaleInput
                      id="password"
                      label="New password"
                      view="moving"
                      type="password"
                      name="password"
                      defaultValue={''}
                      control={control}
                      rules={{
                        validate: {
                          minLength: (value) => value.length >= 8,
                          oneUppercase: (value) => /(?=.*[A-Z])/.test(value),
                          oneLowercase: (value) => /(?=.*[a-z])/.test(value),
                          oneNumber: (value) => /(?=.*\d)/.test(value),
                          oneSpecial: (value) =>
                            /(?=.*[-+_!@#$%^&*.,?])/.test(value),
                        },
                      }}
                      autoFocus
                    />
                  </div>

                  <ul style={{ marginTop: 16 }}>
                    {VALIDATION_MESSAGES.map(({ id, text, validate }) => {
                      return (
                        <HexagonLine
                          key={id}
                          isValid={!!validate(password)}
                          text={text}
                          hideWhenValid
                        />
                      );
                    })}
                  </ul>

                  {password && !errors.password && (
                    <>
                      <div
                        className="field"
                        style={{
                          marginTop: 0,
                        }}
                      >
                        <StaleInput
                          id="repeat-password"
                          label="Repeat new password"
                          view="moving"
                          type="password"
                          name="repeatPassword"
                          defaultValue={''}
                          control={control}
                          rules={{
                            required: true,
                            validate: {
                              matchesPasswordPassword: (value) => {
                                return (
                                  password === value ||
                                  'Passwords have to match'
                                );
                              },
                            },
                          }}
                        />
                      </div>

                      <ul style={{ marginTop: 16 }}>
                        <HexagonLine
                          isValid={repeatPassword === password}
                          text="Passwords have to match"
                          hideWhenValid
                        />
                      </ul>
                    </>
                  )}
                </form>

                <Button
                  style={{
                    marginTop: 'auto',
                  }}
                  form="reset-password-confirm"
                >
                  Change password
                  {isLoading && (
                    <svg width="16px" height="16px" className="loader">
                      <use xlinkHref="#loader-ico" />
                    </svg>
                  )}
                </Button>
              </>
            )}
          </div>
        </ContentWrap>
      </InnerWrap>
    </Wrapper>
  ) : null;
};

export default ResetPasswordConfirm;
