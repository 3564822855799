import { action, Action, thunk, Thunk, computed, Computed } from 'easy-peasy';

import { Firebase } from 'services';
import {
  ICurrency,
  IFollowedCurrencyPair,
  Nullable,
  UpdateFollowedCurrencyParams,
} from 'types';
import { Notify } from 'utils';

export interface CurrenciesStateModel {
  currencies: ICurrency[];
  followedCurrencies: IFollowedCurrencyPair[];
  setState: Action<CurrenciesStateModel, [string, any]>;
  getCurrencies: Thunk<CurrenciesStateModel>;
  currencyByCode: Computed<
    CurrenciesStateModel,
    (currencyCode: Nullable<ICurrency['code']>) => ICurrency | null
  >;
  followedCurrencyById: Computed<
    CurrenciesStateModel,
    (currencyId: IFollowedCurrencyPair['id']) => IFollowedCurrencyPair | null
  >;
  sellCurrencies: Computed<CurrenciesStateModel, ICurrency[]>;
  buyCurrencies: Computed<CurrenciesStateModel, ICurrency[]>;
  prebookableSellCurrencies: Computed<CurrenciesStateModel, ICurrency[]>;
  prebookableBuyCurrencies: Computed<CurrenciesStateModel, ICurrency[]>;
  subscribeToFollowedCurrencies: Thunk<
    CurrenciesStateModel,
    Omit<Firebase.SubscribeToFollowedCurrenciesParams, 'callback'>,
    null,
    object,
    (() => void) | undefined
  >;
  addFollowedCurrencyPair: Thunk<
    CurrenciesStateModel,
    Omit<IFollowedCurrencyPair, 'id'>
  >;
  deleteFollowedCurrencyPair: Thunk<
    CurrenciesStateModel,
    IFollowedCurrencyPair['id']
  >;
  updateFollowedCurrencyPair: Thunk<
    CurrenciesStateModel,
    {
      followedCurrencyId: IFollowedCurrencyPair['id'];
      followedCurrencyData: UpdateFollowedCurrencyParams;
    }
  >;
}

export const CurrenciesState: CurrenciesStateModel = {
  currencies: [],
  followedCurrencies: [],
  setState: action((state, payload) => {
    const [prop, to] = payload;
    state[prop] = to;
  }),
  getCurrencies: thunk(async (actions) => {
    const data = await Firebase.getCurrencies();

    if (data) {
      // put USD and EUR at the start
      const sortedCurrencies = data.sort((el) => {
        if (el.code === 'USD' || el.code === 'EUR') {
          return -1;
        } else {
          return 1;
        }
      });

      actions.setState(['currencies', sortedCurrencies]);
    }
  }),
  subscribeToFollowedCurrencies: thunk(({ setState }, payload) => {
    const subscriber = Firebase.subscribeToFollowedCurrencies({
      ...payload,
      callback: (followedCurrencies) =>
        setState(['followedCurrencies', followedCurrencies]),
    });

    return subscriber;
  }),
  addFollowedCurrencyPair: thunk(async (actions, payload) => {
    const data = await Firebase.addFollowedCurrencyPair(payload);

    if (data && data.success) {
      return data;
    } else {
      Notify.error(data?.message || 'Failed to create followed currency');
    }
  }),
  deleteFollowedCurrencyPair: thunk(async (actions, payload) => {
    const data = await Firebase.deleteFollowedCurrencyPair(payload);

    if (data && data.success) {
      return data;
    } else {
      Notify.error(data?.message || 'Failed to delete followed currency');
    }
  }),
  updateFollowedCurrencyPair: thunk(async (actions, payload) => {
    const data = await Firebase.updateFollowedCurrencyPair(payload);

    if (data && data.success) {
      return data;
    } else {
      Notify.error(data?.message || 'Failed to update followed currency');
    }
  }),
  currencyByCode: computed(
    [(state) => state.currencies],
    (currencies) => (currencyCode) =>
      currencies.find((item) => item.code === currencyCode) || null
  ),
  followedCurrencyById: computed(
    [(state) => state.followedCurrencies],
    (followedCurrencies) => (currencyId) =>
      followedCurrencies.find((item) => item.id === currencyId) || null
  ),
  sellCurrencies: computed(
    [(state) => state.currencies],
    (currencies) => currencies?.filter((currency) => currency.sell) || []
  ),
  prebookableSellCurrencies: computed(
    [(state) => state.currencies],
    (currencies) =>
      currencies?.filter((currency) => currency.sell && currency.prebookable) ||
      []
  ),
  buyCurrencies: computed(
    [(state) => state.currencies],
    (currencies) => currencies?.filter((currency) => currency.buy) || []
  ),
  prebookableBuyCurrencies: computed(
    [(state) => state.currencies],
    (currencies) =>
      currencies?.filter((currency) => currency.buy && currency.prebookable) ||
      []
  ),
};
