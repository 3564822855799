import { FC, useMemo } from 'react';
import { Paragraph, InlineLoader } from 'components';
import { parseIntoCurrencyString } from 'utils';
import useInvoiceRecord from 'pages/Invoices/hooks/useInvoiceRecord';
import { getInvoiceRemainingAmount } from 'utils/invoices';
import { useStoreState } from 'state';
import { IInvoiceForTable } from '../InvoicesTable/types';

interface OwnProps {
  record: IInvoiceForTable;
}

const AmountSellCurrencyCell: FC<OwnProps> = ({ record }) => {
  const { isSameCurrency } = useInvoiceRecord({ record });
  const { rateContractById } = useStoreState(
    (state) => state.RateContractsState
  );
  const { transferById } = useStoreState((state) => state.TransfersState);

  const rateToUse = useMemo(() => {
    const { transferId, contractId, invoiceRate } = record;

    if (transferId) {
      return transferById(transferId)?.rate ?? invoiceRate;
    }
    if (contractId) {
      return rateContractById(contractId)?.rate ?? invoiceRate;
    }

    return invoiceRate;
  }, [rateContractById, record, transferById]);

  if (isSameCurrency) {
    return null;
  }

  if (!rateToUse) {
    return <InlineLoader />;
  }

  return (
    <Paragraph>
      {`£${parseIntoCurrencyString(
        (getInvoiceRemainingAmount(record) ?? 0) / rateToUse
      )}`}
    </Paragraph>
  );
};

export default AmountSellCurrencyCell;
