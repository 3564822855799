import { FC, useEffect } from 'react';
import { RouteComponentProps, useRouteMatch } from 'react-router-dom';
import { useQuery } from 'hooks';
import { SliderWrapper } from './RiskSettings.styles';
import { getGoToStepQuery } from 'utils';
import { RISK_APPETITE_SLIDER_DATA } from 'variables';
import StepOne from './components/StepOne/StepOne';
import StepTwo from './components/StepTwo/StepTwo';
import StepThree from './components/StepThree/StepThree';
import StepFour from './components/StepFour/StepFour';
import { Paragraph, StaleCarousel, StaleLoader } from 'components';
import FullSizeStepsTemplate from 'components/pageTemplates/FullSizeStepsTemplate/FullSizeStepsTemplate';
import HorizontalSteps from 'components/shared/HorizontalSteps/HorizontalSteps';
import { GradientTitle } from 'components/shared/GradientTitle/GradientTitle.styles';

const RiskSettings: FC<RouteComponentProps> = ({ history }) => {
  const URLQuery = useQuery();
  const activeStep = URLQuery.get('step');
  const { url } = useRouteMatch();

  const goToStep = (stepNumber: number) =>
    history.push(getGoToStepQuery(url, URLQuery, stepNumber));

  const renderContent = (step: string) => {
    switch (parseInt(step)) {
      case 1:
        return <StepOne />;
      case 2:
        return <StepTwo />;
      case 3:
        return <StepThree />;
      case 4:
        return <StepFour />;
      default:
        return null;
    }
  };

  useEffect(() => {
    if (!activeStep) {
      history.push(`${url}?step=1`);
    }
  }, [activeStep, history, url]);

  if (!activeStep) {
    return <StaleLoader size="large" />;
  }

  return (
    <FullSizeStepsTemplate
      onClose={() => history.push('/app/dashboard')}
      leftColumn={
        <>
          <HorizontalSteps
            activeStep={parseInt(activeStep)}
            data={[
              {
                title: 'Risk tolerance',
                onClick: () => goToStep(1),
              },
              {
                title: 'Committed Cashflows',
                onClick: () => goToStep(2),
              },
              {
                title: 'Future Cashflows',
                onClick: () => goToStep(3),
              },
            ]}
          />
          <GradientTitle variant="h1">
            About risks from foreign trade and your finances
          </GradientTitle>
          <Paragraph color="white">
            Tell us how your business views financial risk from foreign trade
            and what you prefer to do about it
          </Paragraph>
          <SliderWrapper>
            <StaleCarousel data={RISK_APPETITE_SLIDER_DATA} />
          </SliderWrapper>
        </>
      }
      rightColumn={activeStep && renderContent(activeStep)}
    />
  );
};

export default RiskSettings;
