import React from 'react';
import { ContextMenu, Paragraph, Row } from 'components';
import AmountCell from 'components/shared/AmountCell/AmountCell';
import NumberCircle from 'components/shared/NumberCircle/NumberCircle';
import dayjs from 'dayjs';
import { Column, FilterTypes } from 'react-table';
import { BULK_PAYMENT_STATUS, IBulkPayment } from 'types';
import CurrencyCell from '../CurrencyCell/CurrencyCell';
import { viewTransactionDetails } from 'utils/transactions';
import { useHistory, useRouteMatch } from 'react-router';
import { useTheme } from 'styled-components';

const BulkPaymentStatusToText: Record<`${BULK_PAYMENT_STATUS}`, string> = {
  awaiting_payment: 'Scheduled',
  processing: 'Processing',
  completed: 'Completed',
  cancelled: 'Cancelled',
  transfers_failed: 'Transfers Failed',
};

const isScheduled = (bulkPayment: IBulkPayment) =>
  bulkPayment.status === BULK_PAYMENT_STATUS.awaitingPayment;

const isFailed = (bulkPayment: IBulkPayment) =>
  bulkPayment.status === BULK_PAYMENT_STATUS.transfersFailed;

const isRecent = (bulkPayment: IBulkPayment) =>
  dayjs(bulkPayment._created).isAfter(dayjs().subtract(7, 'days'));

export const paymentRunsTableTileFilterTypes: FilterTypes<IBulkPayment> = {
  buyCurrency: (rows, _, filterValue) => {
    if (filterValue === 'scheduled') {
      return rows.filter((row) => isScheduled(row.original));
    }

    if (filterValue === 'failed') {
      return rows.filter((row) => isFailed(row.original));
    }

    if (filterValue === 'recent') {
      return rows.filter((row) => isRecent(row.original));
    }

    return rows;
  },
};

export const generatePaymentRunTableTileColumns = (): Column<IBulkPayment>[] => [
  {
    id: 'buyCurrency',
    accessor: 'buyCurrency',
    Header: 'Currency',
    Cell: ({ value }) => <CurrencyCell currencyCode={value} />,
    width: 80,
    minWidth: 80,
    filter: 'buyCurrency',
  },
  {
    accessor: 'buyAmount',
    Header: 'Amount',
    Cell: ({ row, value }) => (
      <AmountCell amount={value} currencyCode={row.original.buyCurrency} />
    ),
    width: 80,
    minWidth: 80,
  },
  {
    Header: 'Transfers',
    disableSortBy: true,
    Cell: ({ row }) => {
      const theme = useTheme();

      return (
        <Row gap={theme.spacing.xs}>
          {!!row.original.localCount && (
            <Row columnGap={theme.spacing.xs}>
              <Paragraph>Local</Paragraph>
              <NumberCircle value={row.original.localCount} />
            </Row>
          )}
          {!!row.original.swiftCount && (
            <Row columnGap={theme.spacing.xs}>
              <Paragraph>SWIFT</Paragraph>
              <NumberCircle value={row.original.swiftCount} />
            </Row>
          )}
        </Row>
      );
    },
    width: 110,
  },
  {
    accessor: 'status',
    Header: 'Status',
    Cell: ({ value }) => (
      <Paragraph>{BulkPaymentStatusToText[value]}</Paragraph>
    ),
    width: 120,
    minWidth: 100,
    disableSortBy: true,
  },
  {
    accessor: 'fundedTimestamp',
    Header: 'Funding Details',
    Cell: ({ value, row }) => {
      const isAwaitingPayment =
        row.original.status === BULK_PAYMENT_STATUS.awaitingPayment;

      return (
        <Paragraph>
          {isAwaitingPayment ? (
            <>
              {row.original.payByDate && (
                <>
                  Fund by {dayjs(row.original.payByDate).format('DD MMM YYYY')}{' '}
                  @ {dayjs(row.original.payByTimeGMT, 'HH:mm').format('HH:mm')}
                </>
              )}
            </>
          ) : (
            <>{value && <>Funded on {dayjs(value).format('DD MMM YYYY')}</>}</>
          )}
        </Paragraph>
      );
    },
    width: 100,
    minWidth: 80,
  },
  {
    id: 'dots',
    Header: () => null,
    disableSortBy: true,
    Cell: ({ row }) => {
      const history = useHistory();
      const { url } = useRouteMatch();

      return (
        <Row justifyContent="flex-end" flex={1}>
          <ContextMenu
            list={[
              {
                id: 'removeTransfer',
                title: 'See details',
                onClick: () =>
                  viewTransactionDetails(url, history, {
                    id: row.original.id,
                    type: 'bulkPayment',
                  }),
              },
            ]}
            strategy="fixed"
            portal
          />
        </Row>
      );
    },
    width: 60,
    minWidth: 55,
  },
];
