import React, { FC } from 'react';
import { StaleParagraphMedium, Button } from 'components';
import { Wrapper, ContentWrapper } from './StaleGetStartedPopup.styles';

interface OwnProps {
  onContinue: () => void;
}

const StaleGetStartedPopup: FC<OwnProps> = ({ onContinue }) => (
  <Wrapper onClick={onContinue}>
    <ContentWrapper>
      <StaleParagraphMedium>
        Sign up will open to the public in the near future. To learn a better
        way to do currency payments for businesses and entrepreneurs email us on
        hello@hedgeflows.com
      </StaleParagraphMedium>

      <Button onClick={onContinue}>Ok</Button>
    </ContentWrapper>
  </Wrapper>
);

export default StaleGetStartedPopup;
