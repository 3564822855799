import { Column, FilterTypes } from 'react-table';
import { Col, Paragraph, StaleProgressBar, Row, Title, Icon } from 'components';
import {
  camelCaseToSentenceCase,
  canUseRateContract,
  getPrebookStatusDetails,
  parseIntoCurrencyString,
  parseRateWithPrecision,
} from 'utils';
import { CONTRACT_STATUS, IRateContract, ICurrency } from 'types';
import dayjs from 'dayjs';
import { getTransfersPageLink } from 'utils/links';
import { IExternalHedge } from 'types/externalHedges';
import { isExternalHedge } from './utils';
import { isAvailable, isExpired, isUsed } from 'utils/rateContracts';

export type TPrebookTableFilterValue =
  | 'default'
  | 'available'
  | 'expired'
  | 'used';

export const filterTypes: FilterTypes<IRateContract | IExternalHedge> = {
  filtering: (rows, _, filterValue) => {
    if (filterValue === 'default') {
      return rows;
    }

    if (filterValue === 'available') {
      return rows.filter(({ original }) => isAvailable(original));
    }

    if (filterValue === 'expired') {
      return rows.filter(({ original }) => isExpired(original));
    }

    if (filterValue === 'used') {
      return rows.filter(({ original }) => isUsed(original));
    }

    return rows;
  },
};

export const generatePrebookTableColumns = (
  currencies: ICurrency[],
  getCountryCodeByCurrency: (
    currencyCode: ICurrency['code'],
    currencies: ICurrency[]
  ) => string,
  currencyByCode: (currencyCode: string) => ICurrency | null,
  history
): Column<IRateContract | IExternalHedge>[] => {
  return [
    {
      id: 'rate',
      Header: 'Currency rate',
      disableSortBy: true,
      Cell: ({ row }) => {
        const { buyCurrency, sellCurrency, rate } = row.original;
        const countryCode = getCountryCodeByCurrency(buyCurrency, currencies);

        return (
          <Row justifyContent="start">
            <Icon mr width="42px" height="42px" icon={countryCode} />

            <Col>
              <Paragraph>1 {` ${sellCurrency} `} =</Paragraph>

              <Paragraph>
                <Title as="span" style={{ lineHeight: '24px' }} variant="h3">
                  {parseRateWithPrecision(rate)}
                </Title>{' '}
                {buyCurrency}
              </Paragraph>
            </Col>
          </Row>
        );
      },
      width: 100,
    },
    {
      id: 'filtering',
      filter: 'filtering',
      Header: 'Amount available',
      disableSortBy: true,
      Cell: ({ row }) => {
        const isRecordExternalHedge = isExternalHedge(row.original);
        const { buyCurrency, remainingBuyAmount, buyAmount } = row.original;

        return (
          <Col>
            <Paragraph>
              <Title as="span" style={{ lineHeight: '24px' }} variant="h3">
                {parseIntoCurrencyString(
                  isRecordExternalHedge ? buyAmount : remainingBuyAmount,
                  currencyByCode(buyCurrency)?.precision
                )}
              </Title>{' '}
              {buyCurrency}
            </Paragraph>

            {!isRecordExternalHedge && (
              <StaleProgressBar
                progress={remainingBuyAmount / (buyAmount / 100)}
              />
            )}
          </Col>
        );
      },
      width: 100,
    },
    {
      id: 'use',
      Header: 'Use by',
      disableSortBy: true,
      Cell: ({ row }) => {
        const { status, expiryDate } = row.original;
        const expiryDateTime = dayjs().isSame(expiryDate) ? '23:59:59' : '';
        const isExpiredDate = dayjs().isAfter(expiryDate);

        return (
          <Paragraph>
            {`${
              status === CONTRACT_STATUS.expired || isExpiredDate
                ? '0 days'
                : camelCaseToSentenceCase(
                    dayjs().to(dayjs(`${expiryDate} ${expiryDateTime}`), true)
                  )
            } left | ${dayjs(expiryDate).format('DD.MM.YYYY')}`}
          </Paragraph>
        );
      },
      width: 100,
    },
    {
      id: 'status',
      Header: 'Status',
      disableSortBy: true,
      Cell: ({ row }) => {
        const isRecordExternalHedge = isExternalHedge(row.original);
        const statusInfo = getPrebookStatusDetails(row.original);
        const { invoiceId, status } = row.original;

        const showInvoiceStatus =
          status !== CONTRACT_STATUS.expired &&
          status !== CONTRACT_STATUS.cancelled;

        return (
          <>
            {!isRecordExternalHedge &&
              ((!invoiceId && statusInfo) || !showInvoiceStatus) && (
                <Row>
                  <Paragraph
                    className="status"
                    style={{ color: statusInfo?.color }}
                  >
                    {statusInfo?.text}
                  </Paragraph>
                </Row>
              )}

            {!isRecordExternalHedge && !!invoiceId && showInvoiceStatus && (
              <Row>
                <Paragraph className="status">
                  Prebooked for an invoice
                </Paragraph>
              </Row>
            )}

            {isRecordExternalHedge && (
              <Row>
                <Paragraph className="status">External hedge</Paragraph>
              </Row>
            )}
          </>
        );
      },
      width: 100,
    },
    {
      id: 'dropdown',
      disableSortBy: true,
      Header: () => null,
      Cell: ({ row }) => {
        const {
          invoiceId,
          id,
          remainingBuyAmount,
          buyCurrency,
        }: IRateContract = row.original;

        return (
          <Row justifyContent="flex-end" flex={1}>
            {canUseRateContract(row.original) && (
              <button
                onClick={(e) => {
                  e.stopPropagation();

                  history.push(
                    getTransfersPageLink({
                      predefinedBuyAmount: remainingBuyAmount.toString(),
                      predefinedBuyCurrency: buyCurrency,
                      predefinedRateContractId: id,
                    })
                  );
                }}
              >
                <Row alignItems="center">
                  <Paragraph>Transfer now</Paragraph>

                  <Icon
                    ml
                    width="20px"
                    height="20px"
                    icon="transfer-ico"
                    fill={'#1CBD85'}
                  />
                </Row>
              </button>
            )}

            {!!invoiceId && (
              <button
                onClick={(e) => {
                  e.stopPropagation();

                  history.push(`/app/invoices/${invoiceId}`);
                }}
              >
                <Icon
                  ml
                  width="20px"
                  height="20px"
                  icon="file-ico"
                  fill={'#1CBD85'}
                />
              </button>
            )}

            <Icon ml mr width="20px" height="20px" icon="info-ico" />
          </Row>
        );
      },
      width: 100,
    },
  ];
};
