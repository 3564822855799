import styled, { css } from 'styled-components';

import { Col } from '../Col/Col';
import { Row } from '../Row/Row';

export const StepsHorizontalWrapper = styled(Col)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  @media (min-width: ${({ theme }) => theme.breakpoint.largeMin}px) {
    position: relative;
    width: 954px;
  }
`;

export const StepsHorizontalHeader = styled(Row)`
  padding: ${({ theme }) => `${theme.spacing.m} ${theme.spacing.l}`};
  background-color: ${({ theme }) => theme.color.black};

  @media (min-width: ${({ theme }) => theme.breakpoint.largeMin}px) {
    border-top-left-radius: ${({ theme }) => theme.borderRadius.m};
    border-top-right-radius: ${({ theme }) => theme.borderRadius.m};
  }
`;

export const Content = styled(Col)`
  flex: 1;
  align-self: stretch;
  background-color: ${({ theme }) => theme.color.white};
  overflow-y: auto;
`;

export const Stepper = styled(Row)`
  padding: ${({ theme }) =>
    `${theme.spacing.m} ${theme.spacing.m} ${theme.spacing.xs}`};
  background-color: ${({ theme }) => theme.color.white};
  justify-content: flex-start;
  overflow-x: auto;

  @media (min-width: ${({ theme }) => theme.breakpoint.largeMin}px) {
    padding: ${({ theme }) => `${theme.spacing.xl} ${theme.spacing.xxl} 0`};
    overflow-x: unset;
  }
`;

export const StepsClose = styled.button(
  ({ theme }) => css`
    svg {
      width: 32px;
      height: 32px;
      fill: ${theme.color.white};
    }
  `
);

interface StepItemProps {
  passed?: boolean;
  disabled?: boolean;
  current?: boolean;
}

export const StepperItem = styled.div<StepItemProps>(
  ({ theme, passed, disabled, current }) => css`
    display: flex;
    font-weight: 500;
    font-size: ${theme.fontSize.s};
    line-height: 20px;
    color: ${theme.color.grey};
    position: relative;
    padding: 0 ${`${theme.spacing.m} ${theme.spacing.xs}`} 0;
    white-space: nowrap;
    cursor: pointer;

    @media (min-width: ${theme.breakpoint.largeMin}px) {
      font-size: ${theme.fontSize.m};
      min-width: 152px;
      height: 100%;
      padding: 0 ${`${theme.spacing.xxl} ${theme.spacing.xs}`} 0;
    }

    &:last-child {
      padding-right: 0;
    }
    &:not(:last-of-type):after {
      content: '';
    }

    &:after {
      width: 0;
      height: 0;
      position: absolute;
      right: 0;
      bottom: 1px;
      margin: auto;
    }

    border-bottom: 2px solid ${theme.color.grey};

    span {
      display: block;
      padding-right: ${theme.spacing.xs};
      font-weight: bold;
    }

    ${passed &&
    css`
      color: ${theme.color.dark};
      cursor: pointer;
      border-bottom: 2px solid ${theme.color.dark};

      &:hover {
        opacity: 0.8;
      }

      &:after {
        border-left-color: ${theme.color.white};
      }
    `}

    ${disabled &&
    css`
      pointer-events: none;
    `}

    ${current &&
    css`
      color: ${theme.color.emeraldDark};
      border-bottom: 2px solid ${theme.color.emeraldDark};
    `}
  `
);

export const StepsNotification = styled.div(
  ({ theme }) => css`
    padding: ${`${theme.spacing.m} ${theme.spacing.s} ${theme.spacing.m} ${theme.spacing.xxxl}`};
    border-radius: ${theme.borderRadius.m};
    position: relative;
    color: ${theme.color.white};
    background: ${theme.color.emeraldDark};
    margin: 0 0 ${theme.spacing.xxl};

    &:before {
      content: '';
      position: absolute;
      left: 18px;
      top: 20px;
      width: 18px;
      height: 8px;
      border-bottom: 2px solid ${theme.color.white};
      border-left: 2px solid ${theme.color.white};
      transform: rotate(-45deg);
    }
  `
);
