import firebase from 'firebase/app';
import { IResponse } from 'types';
import { AxiosPrivateFirebaseInstance } from 'settings';
import firestore from 'services/firestore';

import { openDoc, openQuery } from 'utils';
import {
  CashflowsRisksData,
  TPastPerformanceItem,
  IPnlDataPerMonth,
} from 'types/analyses';

export interface RefreshCashflowsRisksParams {
  entityId: string;
  sellCurrency: string;
}

export const refreshCashflowRisks = async ({
  entityId,
  sellCurrency,
}: RefreshCashflowsRisksParams) => {
  return AxiosPrivateFirebaseInstance.get<IResponse>(
    `analysis/cashflow_risk/${entityId}/${sellCurrency}`
  );
};

export interface SubscribeToCashflowsRisksParams {
  entityId: string;
  callback: (cashflowsRisksData: CashflowsRisksData | null) => void;
}

export const subscribeToCashflowsRisks = ({
  entityId,
  callback,
}: SubscribeToCashflowsRisksParams) =>
  firestore
    .collection('entities')
    .doc(entityId)
    .collection('analyses')
    .doc('cashFlowRiskAnalysis')
    .onSnapshot(
      (doc) => {
        callback(openDoc(doc));
      },
      (error) =>
        console.log(
          `Failed to subscribe to cashflows risks for entity ID ${entityId}). Error: ${error}`
        )
    );

export interface SubscribeToPastPerformancePerRecordParams {
  entityId: string;
  currency: string;
  callback: (data: TPastPerformanceItem[]) => void;
}

export const subscribeToPastPerformancePerRecord = ({
  entityId,
  currency,
  callback,
}: SubscribeToPastPerformancePerRecordParams) =>
  firestore
    .collection('entities')
    .doc(entityId)
    .collection('pastPerformanceAnalysis')
    .doc(currency)
    .collection('perRecord')
    .onSnapshot(
      (query) => {
        callback(openQuery(query));
      },
      (error) =>
        console.log(
          `Failed to subscribe to past performance for entity ID ${entityId}). Error: ${error}`
        )
    );

export interface SubscribeToPastPerformanceCurrencies {
  entityId: string;
  callback: (data: { id: string }[]) => void;
}

export const subscribeToPastPerformanceCurrencies = ({
  entityId,
  callback,
}: SubscribeToPastPerformanceCurrencies) =>
  firestore
    .collection('entities')
    .doc(entityId)
    .collection('pastPerformanceAnalysis')
    .where(firebase.firestore.FieldPath.documentId(), '!=', 'status')
    .onSnapshot(
      (query) => {
        callback(openQuery(query));
      },
      (error) =>
        console.log(
          `Failed to subscribe to past performance for entity ID ${entityId}). Error: ${error}`
        )
    );

export type ReportCalculationStatus = 'inProgress' | 'complete';
export interface SubscribeToPastPerformanceStatus {
  entityId: string;
  callback: (status: ReportCalculationStatus) => void;
}
export const subscribeToPastPerformanceStatus = ({
  entityId,
  callback,
}: SubscribeToPastPerformanceStatus) =>
  firestore
    .collection('entities')
    .doc(entityId)
    .collection('pastPerformanceAnalysis')
    .doc('status')
    .onSnapshot(
      (query) => {
        const statusDoc = openDoc<{ status: ReportCalculationStatus }>(query);
        if (statusDoc?.status) {
          callback(statusDoc?.status);
        }
      },
      (error) =>
        console.log(
          `Failed to subscribe to past performance status for entity ID ${entityId}). Error: ${error}`
        )
    );

export interface SubscribeToPastPerformancePerMonthParams {
  entityId: string;
  currency: string;
  from: string;
  callback: (data: IPnlDataPerMonth[]) => void;
}
export const subscribeToPastPerformancePerMonth = ({
  entityId,
  currency,
  from,
  callback,
}: SubscribeToPastPerformancePerMonthParams) =>
  firestore
    .collection('entities')
    .doc(entityId)
    .collection('pastPerformanceAnalysis')
    .doc(currency)
    .collection('perMonth')
    .where(firebase.firestore.FieldPath.documentId(), '>=', from)
    .onSnapshot(
      (query) => {
        callback(openQuery(query));
      },
      (error) =>
        console.log(
          `Failed to subscribe to past performance for entity ID ${entityId}). Error: ${error}`
        )
    );

export interface GetPastPerformanceDataForEntityParams {
  entityId: string;
}

export interface PastPerformanceDataForEntityResponse {
  worstCumulativeImpact: number;
  worstPotentialLoss: number;
}

export const getPastPerformanceDataForEntity = async ({
  entityId,
}: GetPastPerformanceDataForEntityParams) => {
  return AxiosPrivateFirebaseInstance.get<
    IResponse<PastPerformanceDataForEntityResponse>
  >(`analysis/past_performance/${entityId}`);
};
