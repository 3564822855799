export enum IEntityAccountDetailsType {
  priority = 'priority',
  regular = 'regular',
  regularSwift = 'regularSwift',
}

export interface IEntityPriorityAccountDetails {
  id: string;
  type: IEntityAccountDetailsType.priority;
  accountHolder: string;
  iban: string;
  bankName: string;
  bic: string;
  bankAddress: string;
  bankCountry: string;
}

export interface IEntityRegularAccountDetails {
  id: string;
  type: IEntityAccountDetailsType.regular;
  accountHolder: string;
  accountNumber: string;
  sortCode: string;
  currencyCode: string;
}

export interface IEntityRegularSwiftAccountDetails {
  id: string;
  type: IEntityAccountDetailsType.regularSwift;
  accountHolder: string;
  iban: string;
  currencyCode: string;
}

export type OneOfEntityAccountDetails =
  | IEntityPriorityAccountDetails
  | IEntityRegularAccountDetails
  | IEntityRegularSwiftAccountDetails;

interface IHfGuruStatus {
  completed: boolean;
  deferUntil?: string;
}
export enum HF_GURU_TASKS {
  register = 'register',
  integrate = 'integrate',
  healthCheck = 'healthCheck',
  riskPreference = 'riskPreference',
  manageRisk = 'manageRisk',
  automateProcesses = 'automateProcesses',
  removeDeferrals = 'removeDeferrals',
}

export type IHfGuruStatuses = Record<HF_GURU_TASKS, IHfGuruStatus>;

export interface IHfGuru {
  completed: boolean;
  basic: boolean;
  progress: number;
  statuses?: IHfGuruStatuses;
}

export interface IEntityDetails {
  address: IAddress;
  addressCorrespondence: IAddress;
  companyRegistrarId?: string;
  countriesSendingMoneyTo: string[];
  natureOfBusiness: string;
  natureOfBusinessCode: string;
  natureOfBusinessSicFromCh?: string[];
  purposeOfAccount: string;
  vat: boolean;
  vatNumber?: string;
  invoicesAndReceivesPayments?: boolean;
  canProvideInvoicesAndBankStatements?: boolean;
  name?: string; //You can optionally also store the name here
  creditLimit?: number;
}

export interface IAddress {
  addressLine1: string;
  addressLine2?: string; //Sometimes referred to as "Premises", e.g. "Lower Ground Floor" or "New Burlington House"
  postalCode: string;
  city: string;
  country: string; //alpha2 code
  stateOrProvince?: string; //Used for some countries, e.g. Companies House usese "region": "Hertfordshire"
}

export enum ENTITY_TYPE {
  soleTrader = 'soleTrader',
  limitedCompany = 'limitedCompany',
  publicLimitedCompany = 'publicLimitedCompany',
  simplePartnership = 'simplePartnership',
  limitedLiabilityPartnership = 'limitedLiabilityPartnership',
}
