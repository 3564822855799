import { IInvoice } from 'types';

export interface IRatePnl {
  pnl: number;
  cashflowId: string;
  dateRange: number;
}

export interface IMonthHighLow {
  months: number;
  lowest: number;
  highest: number;
  rateRating: number;
  rateRatingText: string;
}

export interface IRiskDashboardItemPerMonth {
  netExposure: number;
  payables: number;
  receivables: number;
  prebooked: number;
  externalHedges: number;
  saleOrders: number;
  purchaseOrders: number;
  netExposureInSellCurrency: number;
}

export interface IRiskDashboardItemPerMonthWithDate
  extends IRiskDashboardItemPerMonth {
  date: string;
}

export interface IRiskDashboardItem {
  buyCurrency: string;
  sellCurrency: string;
  netExposure: number;
  netExposureInSellCurrency: number;
  netExposureEndDate: string;
  payables: number;
  receivables: number;
  prebooked: number;
  externalHedges: number;
  saleOrders: number;
  purchaseOrders: number;
  ranges: Record<string, { pnl: string }[]>;
  cashflowAtRisk: number;
  pnlByDate?: Record<string, IRatePnl[]>;
  cashflows: ICashFlow[];
  fromDate: string;
  sample: number;
  balance: number;
  externalBalance: number;
  monthlyHighLow: IMonthHighLow[];
  perMonth: Record<string, IRiskDashboardItemPerMonth>;
}

export enum CASHFLOW_TYPE {
  payable = 'payable',
  receivable = 'receivable',
  prebook = 'prebook',
  externalHedge = 'externalHedge',
  externalBalance = 'externalBalance',
  balance = 'balance',
  salesOrder = 'salesOrder',
  purchaseOrder = 'purchaseOrder',
}
export interface ICashFlow {
  id: string;
  type: CASHFLOW_TYPE;
  currency: string;
  amount: number;
  date: string;
}
export interface ICashFlowAtRisk {
  id: string;
  label: string;
  value: number;
}

export interface CashflowsRisksData {
  currencyRisk: Record<string, IRiskDashboardItem>;
  totalCashflowAtRisk: Array<ICashFlowAtRisk>;
}

export interface IMonthlyImpactContribution {
  /** Positive for Receivables and negative for Payables */
  averageOutstandingBalance: number;
  fxImpact: number;
  paidAmount: number;
}

export interface IPastPerformanceItemInvoice {
  recordType: 'invoice';
  invoiceId: string;
  contactName: string;
  creationDate: string;
  paidDate: string;
  paidDateMonth: string;
  status: IInvoice['status'];
  type: IInvoice['type'];
  amount: number;
  /**
   * Difference in value (in "home" currency) calculated using the original INVOICE rate
   * (or historical BoE FX rate on the INVOICE CREATION date) and
   * the historical BoE FX rate on the PAYMENT date of the same invoice
   */
  fxImpact: number;
  fxImpactPercentage: number;
  /**
   * Difference in value (in "home" currency) calculated using the rate which was actually used to pay the invoice
   * and the historical BoE FX rate on the payment date. When we cannot extract
   * the actually used rate then this becomes zero.
   */
  fxCost: number;
  fxCostPercentage: number;
  currency: string;
  invoiceRate: number;
  payRate: number;
  historicalPayRate: number;
  reference?: string;
  monthlyImpactContributions?: Record<string, IMonthlyImpactContribution>; // Not for PAID
}

export interface IPastPerformanceItemTransfer {
  recordType: 'bankTransfer';
  bankTransferId: string;
  contactName?: string;
  creationDate: string;
  paidDate: string;
  paidDateMonth: string;
  status: 'TRANSFER IN' | 'TRANSFER OUT';
  amount: number;
  /**
   * Difference in value (in "home" currency) calculated using the original INVOICE rate
   * (or historical BoE FX rate on the INVOICE CREATION date) and
   * the historical BoE FX rate on the PAYMENT date of the same invoice
   */
  fxImpact: number;
  fxImpactPercentage: number;
  /**
   * Difference in value (in "home" currency) calculated using the rate which was actually used to pay the invoice
   * and the historical BoE FX rate on the payment date. When we cannot extract
   * the actually used rate then this becomes zero.
   */
  fxCost: number;
  fxCostPercentage: number;
  currency: string;
  payRate: number;
  historicalPayRate: number;
  reference?: string;
}

export interface IPnlDataPerMonth {
  averageOutstandingBalance: number;
  turnover: number;
  fxImpactTotal: number;
  /**
   * fxImpact for past performance items with status not equal to PAID
   */
  fxImpactForUnpaid: number;
  /**
   * fxImpact from paid invoices with monthly contribution in this month
   */
  fxImpactForPaid: number;
  /**
   * sum of invoices fxImpact paid this month
   */
  totalFxImpactForPaid: number;
  fxCost: number;
  /**
   * fxCost from cashflows paid this month
   */
  transactionCost: number;
  id: string; // YYYY-MM
  [key: string]: number | string;
}

export type TPastPerformanceItem =
  | IPastPerformanceItemInvoice
  | IPastPerformanceItemTransfer;

export const isPastPerformanceItemInvoice = (
  item: TPastPerformanceItem
): item is IPastPerformanceItemInvoice =>
  (item as IPastPerformanceItemInvoice).invoiceId !== undefined;

export const isPastPerformanceItemTransfer = (
  item: TPastPerformanceItem
): item is IPastPerformanceItemTransfer =>
  (item as IPastPerformanceItemTransfer).bankTransferId !== undefined;
