import { Row } from 'components';
import Button from 'components/shared/Button/Button';
import TabsShared, { ITab } from 'components/shared/Tabs/Tabs';
import useCodat from 'hooks/useCodat';
import useUrlValues from 'hooks/useUrlValues';
import useXero from 'hooks/useXero';
import { ITabsCounts } from 'pages/Invoices/hooks/useInvoicesPageTabsCountsAndCurrencies';
import { TInvoicePageTabs } from 'pages/Invoices/types';
import { FC, useMemo } from 'react';
import { useHistory } from 'react-router';
import { useStoreState } from 'state';
import { useTheme } from 'styled-components';
import { getInvoiceManualUploadLink } from 'utils/invoices';
import { TabsWrapper } from './Tabs.style';

interface OwnProps {
  counts: ITabsCounts;
}

const Tabs: FC<OwnProps> = ({ counts }) => {
  const theme = useTheme();
  const history = useHistory();
  const { setUrlValue, tab } = useUrlValues('tab');
  const {
    hasApprovalFlow,
    isAutomationPackageEnabled,
    isFxManagementPackageEnabled,
  } = useStoreState(({ UserState }) => UserState);
  const {
    onImportData: onImportXeroData,
    isImporting: isImportingXeroData,
    isIntegrated: isIntegratedWithXero,
  } = useXero();
  const {
    onImportCodatData,
    isImportingFromCodat,
    isIntegratedViaCodat,
  } = useCodat();

  const tabs = useMemo(
    () =>
      [
        { id: 'outstanding', title: `Outstanding (${counts.all})` },
        ...(hasApprovalFlow
          ? [
              {
                id: 'submitted',
                title: `To approve (${counts.submitted})`,
              },
              {
                id: 'approved',
                title: `Approved (${counts.approved})`,
              },
            ]
          : []),
        ...(isAutomationPackageEnabled || isFxManagementPackageEnabled
          ? [{ id: 'paymentRun', title: `Payment run (${counts.paymentRun})` }]
          : []),
        { id: 'paid', title: `Paid (${counts.paid})` },
        {
          id: 'prebookings',
          title: `Prebooked FX (${counts.prebookedFx})`,
        },
      ] as ITab<TInvoicePageTabs>[],
    [
      hasApprovalFlow,
      counts,
      isAutomationPackageEnabled,
      isFxManagementPackageEnabled,
    ]
  );

  return (
    <TabsWrapper>
      <Row>
        <TabsShared<TInvoicePageTabs>
          data={tabs}
          activeTab={(tab as TInvoicePageTabs) ?? tabs[0].id}
          setActiveTab={({ id }) => {
            setUrlValue('tab', id);
            setUrlValue('filter', '');
            setUrlValue('currency', 'all');
          }}
        />
      </Row>
      <Row>
        {isIntegratedWithXero && (
          <Button
            disabled={isImportingXeroData}
            onClick={onImportXeroData}
            variant="link"
            isLoading={isImportingXeroData}
            icon="refresh"
          >
            Refresh
          </Button>
        )}

        {isIntegratedViaCodat && (
          <Button
            disabled={isImportingFromCodat}
            onClick={onImportCodatData}
            variant="link"
            isLoading={isImportingFromCodat}
            icon="refresh"
          >
            Refresh
          </Button>
        )}

        <Button
          onClick={() => history.push(getInvoiceManualUploadLink())}
          variant="link"
          ml
          mlValue={theme.spacing.xs}
          icon="import-ico"
        >
          Manual upload
        </Button>
      </Row>
    </TabsWrapper>
  );
};

export default Tabs;
