import { Fragment } from 'react';
import { useTheme } from 'styled-components';
import Icon from '../Icon/Icon';
import { Row } from '../Row/Row';
import { HorizontalStepsItem } from './HorizontalSteps.styles';

interface HorizontalStepsProps {
  data: {
    title: string;
    disabled?: boolean;
    onClick?: () => void;
  }[];
  activeStep: number;
}

const HorizontalSteps: React.FC<HorizontalStepsProps> = ({
  data,
  activeStep,
}) => {
  const theme = useTheme();
  const renderContent = () =>
    data.map(({ title, disabled, onClick }, i) => (
      <Fragment key={title}>
        {i > 0 && (
          <Icon
            icon="step-arrow"
            fill={activeStep >= i + 1 ? theme.color.white : theme.color.grey}
            width="6px"
            height="10px"
          />
        )}
        <HorizontalStepsItem
          onClick={onClick}
          current={activeStep === i + 1}
          passed={activeStep > i + 1}
          disabled={disabled}
        >
          {title}
        </HorizontalStepsItem>
      </Fragment>
    ));

  return <Row justifyContent="center">{renderContent()}</Row>;
};

export default HorizontalSteps;
