import {
  Paragraph,
  Row,
  StaleInputSelect,
  StaleLoader,
  StaleProgressBar,
  TableSearchInput,
} from 'components';
import { FilterButton } from 'components/shared/FilterButton/FilterButton.styles';
import NumberCircle from 'components/shared/NumberCircle/NumberCircle';
import { useDebounce } from 'hooks';
import useUrlValues from 'hooks/useUrlValues';
import {
  ITabsCounts,
  ICurrenciesByTab,
} from 'pages/Invoices/hooks/useInvoicesPageTabsCountsAndCurrencies';
import { FC, useEffect, useMemo, useState } from 'react';
import { useStoreState } from 'state';
import { useTheme } from 'styled-components';
import { parseIntoShortNumberString } from 'utils';
import { ControlsWrapper, ProgressBarWrapper } from './Controls.style';

interface OwnProps {
  counts: ITabsCounts;
  currenciesByTab: ICurrenciesByTab;
}

const Controls: FC<OwnProps> = ({ counts, currenciesByTab }) => {
  const theme = useTheme();
  const { setUrlValue, tab, currency, filter, search } = useUrlValues(
    'tab',
    'currency',
    'filter',
    'search'
  );
  const { currencyByCode } = useStoreState(
    ({ CurrenciesState }) => CurrenciesState
  );
  const { cashflowsRisks, isRefreshingCashflowsRisksData } = useStoreState(
    (state) => state.ReferenceDataState
  );
  const cashflowRisksPerCurrency = currency
    ? cashflowsRisks?.currencyRisk[currency]
    : undefined;
  const prebooked = cashflowRisksPerCurrency?.prebooked || 0;
  const externalHedges = cashflowRisksPerCurrency?.externalHedges || 0;
  const netExposure = cashflowRisksPerCurrency?.netExposure || 0;
  const [searchInputValue, setSearchInputValue] = useState(search || '');
  const debouncedSetSearchValue = useDebounce(searchInputValue, 500);
  const activeCurrencySymbol = currencyByCode(currency)?.symbol;

  useEffect(() => {
    setUrlValue('search', debouncedSetSearchValue);
  }, [debouncedSetSearchValue, setUrlValue]);

  const progressBarValue = useMemo(() => {
    const progress = ((prebooked + externalHedges) / netExposure) * 100;
    if (progress < 0) {
      return progress * -1;
    }
    return progress;
  }, [prebooked, externalHedges, netExposure]);

  const detectedCurrencies = useMemo<string[]>(
    () => Array.from(currenciesByTab[tab || 'outstanding']),
    [currenciesByTab, tab]
  );

  const selectData = useMemo(
    () => [
      {
        id: 'all',
        name: 'All CCY',
        value: 'all',
      },
      ...detectedCurrencies.sort().map((currencyCode) => ({
        id: currencyCode,
        name: currencyCode,
        value: currencyCode,
        icon: currencyByCode(currencyCode)?.countryCode,
      })),
    ],
    [currencyByCode, detectedCurrencies]
  );

  return (
    <ControlsWrapper>
      <Row justifyContent="flex-start" gap={theme.spacing.xl}>
        <StaleInputSelect
          selected={currency}
          data={selectData}
          onSelect={(item) => setUrlValue('currency', item.value)}
          id="invoices-currency"
          style={{ minWidth: '132px' }}
        />

        {tab !== 'prebookings' && (
          <TableSearchInput
            placeholder="Search"
            value={searchInputValue}
            onChange={(e) => setSearchInputValue(e.target.value)}
          />
        )}

        {tab === 'outstanding' && (
          <Row gap={theme.spacing.xs} flex={1} justifyContent="flex-end">
            <FilterButton
              onClick={() => setUrlValue('filter', '')}
              active={!filter}
            >
              <Paragraph>All</Paragraph>
            </FilterButton>

            <FilterButton
              onClick={() =>
                setUrlValue('filter', filter !== 'payables' ? 'payables' : '')
              }
              active={filter === 'payables'}
            >
              <Row gap={theme.spacing.xs}>
                <Paragraph>Payables</Paragraph>
                <NumberCircle value={counts.payables} />
              </Row>
            </FilterButton>

            <FilterButton
              onClick={() =>
                setUrlValue('filter', filter !== 'overdue' ? 'overdue' : '')
              }
              active={filter === 'overdue'}
            >
              <Row gap={theme.spacing.xs}>
                <Paragraph>Overdue</Paragraph>
                <NumberCircle value={counts.overdue} />
              </Row>
            </FilterButton>

            <FilterButton
              onClick={() =>
                setUrlValue(
                  'filter',
                  filter !== 'dueIn14Days' ? 'dueIn14Days' : ''
                )
              }
              active={filter === 'dueIn14Days'}
            >
              <Row gap={theme.spacing.xs}>
                <Paragraph>Due in 14 days</Paragraph>
                <NumberCircle value={counts.dueIn14Days} />
              </Row>
            </FilterButton>
          </Row>
        )}

        {tab === 'prebookings' && (
          <Row gap={theme.spacing.xs} flex={1} justifyContent="flex-end">
            <FilterButton
              onClick={() => setUrlValue('filter', '')}
              active={!filter}
            >
              <Paragraph>All</Paragraph>
            </FilterButton>

            <FilterButton
              onClick={() =>
                setUrlValue('filter', filter !== 'available' ? 'available' : '')
              }
              active={filter === 'available'}
            >
              <Row gap={theme.spacing.xs}>
                <Paragraph>Available</Paragraph>
                <NumberCircle value={counts.available} />
              </Row>
            </FilterButton>

            <FilterButton
              onClick={() =>
                setUrlValue('filter', filter !== 'expired' ? 'expired' : '')
              }
              active={filter === 'expired'}
            >
              <Row gap={theme.spacing.xs}>
                <Paragraph>Expired</Paragraph>
                <NumberCircle value={counts.expired} />
              </Row>
            </FilterButton>

            <FilterButton
              onClick={() =>
                setUrlValue('filter', filter !== 'used' ? 'used' : '')
              }
              active={filter === 'used'}
            >
              <Row gap={theme.spacing.xs}>
                <Paragraph>Used</Paragraph>
                <NumberCircle value={counts.used} />
              </Row>
            </FilterButton>
          </Row>
        )}
      </Row>

      {currency && currency !== 'all' && tab !== 'paymentRun' && (
        <ProgressBarWrapper>
          {isRefreshingCashflowsRisksData && <StaleLoader size="medium" />}
          {!isRefreshingCashflowsRisksData && (
            <>
              <StaleProgressBar
                progress={progressBarValue}
                color={theme.color.coral}
                fullWidth
              />

              <Row>
                <Row columnGap={theme.spacing.xxs}>
                  <Paragraph variant="bold">
                    {activeCurrencySymbol}
                    {parseIntoShortNumberString(Math.abs(netExposure))}
                  </Paragraph>
                  <Paragraph>net outstanding</Paragraph>
                </Row>

                <Row columnGap={theme.spacing.xxs}>
                  <Paragraph variant="bold">
                    {activeCurrencySymbol}
                    {parseIntoShortNumberString(prebooked + externalHedges)}
                  </Paragraph>
                  <Paragraph>prebooked</Paragraph>
                </Row>
              </Row>
            </>
          )}
        </ProgressBarWrapper>
      )}
    </ControlsWrapper>
  );
};

export default Controls;
