import { FC, useState } from 'react';
import { createPortal } from 'react-dom';
import { useLocation } from 'react-router-dom';
import { useStoreState } from 'state';
import { useTheme } from 'styled-components';
import { getLink } from 'utils/links';
import { Backdrop } from 'components/shared/Backdrop/Backdrop.styles';
import useHasBalance from 'hooks/useHasBalance';
import Icon from 'components/shared/Icon/Icon';
import {
  SidebarMobileHeader,
  SidebarMobileWrapper,
  CloseButton,
  Nav,
  NavLink,
} from './SidebarMobile.styles';
import Button from 'components/shared/Button/Button';

const SidebarMobile: FC = () => {
  const theme = useTheme();
  const { pathname } = useLocation();
  const { showReports } = useStoreState((state) => state.UserState);
  const { featureFlagById } = useStoreState((state) => state.FeatureFlagsState);
  const { hasBalance } = useHasBalance();
  const [show, setShow] = useState(false);

  const renderBar = () =>
    createPortal(
      <>
        <SidebarMobileWrapper show={show}>
          <SidebarMobileHeader>
            <CloseButton onClick={() => setShow(false)}>
              <Icon fill={theme.color.greyDark} icon="cross-ico" />
            </CloseButton>
          </SidebarMobileHeader>
          <Nav>
            <NavLink
              to="/app/dashboard"
              active={pathname.includes('dashboard')}
            >
              <Icon icon="dashboard-ico" />
              Dashboard
            </NavLink>
            <NavLink
              to={getLink('/app/transfers')}
              active={pathname.includes('transfers')}
            >
              <Icon icon="transfer-ico" />
              FX transfer
            </NavLink>
            <NavLink
              to={
                hasBalance
                  ? getLink('/app/currency-exchange?step=1')
                  : getLink('/app/prebook')
              }
              active={
                (pathname.includes('prebook') ||
                  pathname.includes('currency-exchange')) &&
                !pathname.includes('prebookings')
              }
            >
              <Icon icon="prebook-filled" />
              Exchange
            </NavLink>

            <NavLink to="/app/invoices" active={pathname.includes('invoices')}>
              <Icon icon="invoices" />
              Invoices
            </NavLink>

            {featureFlagById('backtester') && showReports && (
              <NavLink to="/app/reports" active={pathname.includes('reports')}>
                <Icon icon="backtester" />
                Reports
              </NavLink>
            )}
            <NavLink to="/app/contacts" active={pathname.includes('contacts')}>
              <Icon icon="users-group" />
              Contacts
            </NavLink>
          </Nav>
        </SidebarMobileWrapper>

        {show && <Backdrop transparent onClick={() => setShow(false)} />}
      </>,
      document.body
    );

  return (
    <>
      <Button
        variant="link"
        onClick={() => setShow(!show)}
        icon={show ? 'cross-ico' : 'menu-ico'}
      />

      {renderBar()}
    </>
  );
};

export default SidebarMobile;
