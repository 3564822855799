import styled, { css } from 'styled-components';

export const Wrapper = styled.div<{
  disabled?: boolean;
}>(
  ({ theme, disabled }) => css`
    transition: ${theme.transition};

    label {
      position: relative;
      padding-left: 24px;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      display: flex;
      flex-direction: column;
      cursor: pointer;

      &:before {
        content: '';
        width: 16px;
        height: 16px;
        position: absolute;
        left: 0;
        top: 3px;
        border-radius: ${theme.borderRadius.round};
        background: ${theme.color.emeraldDark};
        border: 2px solid ${theme.color.emeraldDark};
        box-shadow: inset 0 0 0 7px ${theme.color.white};
        transition: box-shadow 0.2s linear;
      }
    }

    input {
      position: absolute;
      opacity: 0;

      &:checked + label:before {
        box-shadow: inset 0 0 0 2px ${theme.color.white};
      }

      &:focus-visible {
        + label {
          &:before {
            box-shadow: inset 0 0 0 7px ${theme.color.white},
              0 0 16px 1px ${theme.color.emeraldDark};
          }
        }
      }

      &:focus-visible:checked {
        + label:before {
          box-shadow: inset 0 0 0 2px ${theme.color.white},
            0 0 16px 1px ${theme.color.emeraldDark};
        }
      }
    }

    ${disabled &&
    css`
      opacity: 0.5;
      cursor: none;
    `}
  `
);
