import { FC, InputHTMLAttributes, RefObject } from 'react';
import { useTheme } from 'styled-components';

import {
  CheckboxWrapper,
  Label,
  CheckboxInnerWrapper,
  HiddenCheckbox,
  StyledCheckbox,
} from './Checkbox.styles';

interface OwnProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'type'> {
  inputRef?: RefObject<HTMLInputElement>;
  label?: string;
}

const Checkbox: FC<OwnProps> = ({
  className,
  checked,
  label,
  inputRef,
  ...props
}) => {
  const theme = useTheme();

  return (
    <CheckboxWrapper
      style={{
        pointerEvents: props.disabled ? 'none' : 'auto',
      }}
    >
      <CheckboxInnerWrapper className={className}>
        <HiddenCheckbox checked={checked} ref={inputRef} {...props} />
        <StyledCheckbox checked={!!checked} aria-hidden="true">
          <svg
            width="100%"
            height="100%"
            style={{ fill: checked ? theme.color.white : 'transparent' }}
          >
            <use xlinkHref="#checkbox-checkmark" />
          </svg>
        </StyledCheckbox>
      </CheckboxInnerWrapper>

      {label ? <Label>{label}</Label> : null}
    </CheckboxWrapper>
  );
};

export default Checkbox;
