import { Dispatch, SetStateAction, useMemo } from 'react';
import {
  IntegrationTileWrapper,
  IntegrationSvg,
  IntegrationTextHint,
  IntegrationParagraph,
  IntegrationRow,
  IntegrationButtonNew,
  ComingSoon,
  CSVRow,
} from './IntegrationTile.styles';
import { Col } from 'components';
import { Notify } from 'utils';
import OrderWiseLoginPopup from '../OrderWiseLoginPopup/OrderWiseLoginPopup';
import { useHistory } from 'react-router-dom';
import { IntegrateWithOrderWiseParams } from 'services/firebase/integrations';
import { INTEGRATION_TYPE } from 'types';
import useIntegration from './useIntegration';
import { IAvailableIntegration } from 'types/integrations';
import { getInvoiceManualUploadLink } from 'utils/invoices';
import Button from 'components/shared/Button/Button';

interface ExportedDataProps {
  onLogin?: () => Promise<void>;
  onImportData?: () => Promise<void>;
  onDisconnect?: () => Promise<void>;
  isLoading?: boolean;
  isImporting?: boolean;
  isDisconnecting?: boolean;
  isIntegrated?: boolean;
  onManageSettings?: () => void;
  connectionName?: string;
  isLoginOrderWise?: boolean;
  setIsLoginOrderWise?: Dispatch<SetStateAction<boolean>>;
  onLoginOrderWise?: (values: IntegrateWithOrderWiseParams) => Promise<void>;
  onManageLogs?: () => void;
}

interface Props {
  integration: IAvailableIntegration;
}
const IntegrationTile: React.FC<Props> = ({ integration }) => {
  const history = useHistory();
  const { id, name, img, type, available } = integration;

  const {
    onLogin,
    onImportData,
    isImporting,
    isDisconnecting,
    onDisconnect,
    isLoading,
    isIntegrated,
    connectionName,
    onManageSettings,
    isLoginOrderWise,
    setIsLoginOrderWise,
    onLoginOrderWise,
    onManageLogs,
  }: ExportedDataProps = useIntegration({
    withStartAction: true,
    variant: id,
    codatShortCode: integration.codatShortCode,
    platformNames: integration.platformNames,
  });

  const connect = async () => {
    try {
      await onLogin?.();
    } catch (error) {
      Notify.error(error);
    }
  };

  const disconnect = async () => {
    try {
      await onDisconnect?.();
    } catch (error) {
      Notify.error(error);
    }
  };

  const additionalText = useMemo(() => {
    if (isIntegrated && !!connectionName) {
      return connectionName;
    } else {
      return type === INTEGRATION_TYPE.business
        ? 'Business system'
        : 'Accounting system';
    }
  }, [connectionName, isIntegrated, type]);

  return (
    <>
      <IntegrationTileWrapper>
        {id !== 'csv' && (
          <>
            <IntegrationSvg>
              <use xlinkHref={img} />
            </IntegrationSvg>
            <Col>
              <IntegrationParagraph variant="bold">{name}</IntegrationParagraph>
              <IntegrationTextHint>{additionalText}</IntegrationTextHint>
            </Col>
          </>
        )}

        <IntegrationRow>
          {isIntegrated && id === 'orderWise' && (
            <IntegrationButtonNew
              variant="link"
              onClick={connect}
              disabled={isLoading}
            >
              Reconnect
            </IntegrationButtonNew>
          )}

          {id === 'csv' && (
            <CSVRow>
              Or manually
              <Button
                variant="link"
                onClick={() => history.push(getInvoiceManualUploadLink())}
              >
                upload CSV invoices
              </Button>
            </CSVRow>
          )}

          {isIntegrated ? (
            <>
              {!!onImportData && (
                <Button
                  variant="link"
                  onClick={onImportData}
                  disabled={isImporting}
                  isLoading={isImporting}
                >
                  Refresh data
                </Button>
              )}
              {!!onManageLogs && (
                <IntegrationButtonNew variant="link" onClick={onManageLogs}>
                  View logs
                </IntegrationButtonNew>
              )}
              {!!onManageSettings && (
                <IntegrationButtonNew variant="link" onClick={onManageSettings}>
                  Settings
                </IntegrationButtonNew>
              )}
              <IntegrationButtonNew
                variant="link"
                onClick={!!onDisconnect ? disconnect : undefined}
                disabled={!!onDisconnect ? isDisconnecting : true}
              >
                Disconnect {!onDisconnect && '(coming soon)'}
              </IntegrationButtonNew>
            </>
          ) : (
            !!onLogin && (
              <IntegrationButtonNew
                variant="link"
                onClick={connect}
                disabled={isLoading}
              >
                Connect
              </IntegrationButtonNew>
            )
          )}

          {!available && <ComingSoon>Coming soon</ComingSoon>}
        </IntegrationRow>
      </IntegrationTileWrapper>

      {isLoginOrderWise && (
        <OrderWiseLoginPopup
          onClose={() => setIsLoginOrderWise?.(false)}
          onSubmit={onLoginOrderWise}
          isIntegrated={!!isIntegrated}
        />
      )}
    </>
  );
};

export default IntegrationTile;
