import { CSSProperties, FC, ReactNode } from 'react';
import { Wrapper } from './StaleInputRadio.styles';

export interface OwnProps {
  id: HTMLInputElement['id'];
  name?: string;
  onChange?: () => any;
  cardRow?: boolean;
  cardCol?: boolean;
  cardTransparent?: boolean;
  cardRowRevert?: boolean;
  checked?: HTMLInputElement['checked'];
  value?: HTMLInputElement['value'];
  autoFocus?: HTMLInputElement['autofocus'];
  disabled?: HTMLInputElement['disabled'];
  label?: string | ReactNode;
  style?: CSSProperties;
}

const StaleInputRadio: FC<OwnProps> = ({
  id,
  name,
  onChange,
  cardRow,
  cardCol,
  cardTransparent,
  cardRowRevert,
  checked,
  value,
  autoFocus,
  label,
  disabled,
  style,
}) => (
  <Wrapper
    style={style}
    cardRow={cardRow}
    cardRowRevert={cardRowRevert}
    cardCol={cardCol}
    cardTransparent={cardTransparent}
    disabled={disabled}
  >
    <input
      type="radio"
      id={id}
      name={name}
      checked={checked}
      value={value}
      onChange={onChange}
      autoFocus={autoFocus}
      disabled={disabled}
    />
    <label htmlFor={id}>{label}</label>
  </Wrapper>
);

StaleInputRadio.defaultProps = {
  name: '',
  cardRow: false,
  cardCol: false,
  cardTransparent: false,
  cardRowRevert: false,
  onChange: () => {},
};

export default StaleInputRadio;
