import styled from 'styled-components';

import { Flex } from '../Flex/Flex.styles';
import { SpacingCssProps } from '../Spacing/Spacing.styles';

export const Col = styled(Flex).attrs<SpacingCssProps>(
  ({ theme, mbValue, mlValue, mtValue, mrValue }) => ({
    mbValue: mbValue || theme.spacing.m,
    mtValue: mtValue || theme.spacing.m,
    mrValue: mrValue || theme.spacing.m,
    mlValue: mlValue || theme.spacing.m,
  })
)`
  flex-direction: column;
`;
