import { FC, useState } from 'react';
import {
  StalePopup,
  StaleCheckbox,
  PopupContentWrapper,
  PopupContentContainerWrapped,
  PopupContentParagraphWrapped,
  PopupContentBtnGroupWrapped,
} from 'components';
import { useStoreState } from 'state';
import { saveXeroPermissions } from 'services/firebase';
import Button from '../Button/Button';

interface OwnProps {
  title: string;
  onClose: () => void;
  onConfirm: () => void;
}

const StaleXeroPermissionContinuePopup: FC<OwnProps> = ({
  onClose,
  onConfirm,
  title,
  children,
}) => {
  const [updateAllXeroTransfers, setUpdateAllXeroTransfers] = useState(true);
  const { integrationsSummary } = useStoreState(({ UserState }) => UserState);

  const xeroPermissions = integrationsSummary?.xero?.permissions;

  const onConfirmHandler = () => {
    onUpdateXero();
    onConfirm();
  };

  const onUpdateXero = async () => {
    if (updateAllXeroTransfers) {
      await saveXeroPermissions({
        permissions: {
          global: updateAllXeroTransfers,
        },
      });
    }
    onClose();
  };

  const showUpdateXeroCheckbox =
    !xeroPermissions?.global &&
    (!xeroPermissions?.invoices || !xeroPermissions.payments);

  return (
    <StalePopup
      title={title}
      theme="grey"
      width="439px"
      onClose={onClose}
      contentStyle={{ minHeight: '250px' }}
    >
      <PopupContentWrapper>
        <>
          <PopupContentContainerWrapped>
            <PopupContentParagraphWrapped>
              {children}
            </PopupContentParagraphWrapped>
            <br />
            {showUpdateXeroCheckbox && (
              <PopupContentParagraphWrapped>
                <StaleCheckbox
                  id="Automatically update Xero in future?"
                  name="Automatically update Xero in future?"
                  checked={updateAllXeroTransfers}
                  label="Automatically update Xero in future?"
                  onChange={() =>
                    setUpdateAllXeroTransfers(!updateAllXeroTransfers)
                  }
                />
              </PopupContentParagraphWrapped>
            )}
          </PopupContentContainerWrapped>

          <PopupContentBtnGroupWrapped container={false} horizontal={true}>
            <Button onClick={onClose} variant="secondary">
              Cancel
            </Button>
            <Button onClick={onConfirmHandler}>
              Confirm
            </Button>
          </PopupContentBtnGroupWrapped>
        </>
      </PopupContentWrapper>
    </StalePopup>
  );
};

export default StaleXeroPermissionContinuePopup;
