import styled, { css } from 'styled-components';

export const Wrapper = styled.div(
  ({ theme }) => css`
    flex: 0 1 100%;
    border-radius: 8px 0 0 8px;
    background: ${theme.color.greyLight_2};
    padding: 20px;
    overflow: auto;

    @media (min-width: ${theme.breakpoint.largeMin}px) {
      padding: ${theme.spacing.xxl} ${theme.spacing.xxxl};
      display: flex;
      flex-direction: column;
    }
  `
);

export const FixedContent = styled.div<{ maxWidth?: string }>(
  ({ theme, maxWidth }) => css`
    max-width: ${maxWidth};
    width: 100%;
    margin: auto;

    @media (min-width: ${theme.breakpoint.largeMin}px) {
      flex: 0 0 100%;
    }
  `
);
