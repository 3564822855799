import { ITransfer, ITransferInput, TRANSFER_TYPE } from 'types';
import { IRecipient } from './recipients';

export interface IBulkTransferCreateFromInvoicesInput
  extends IBulkTransferSummary {
  fromUpload: boolean;
  transfers: ITransferInput[];
}

export interface IBulkTransferSummary {
  buyAmountTotal: number;
  sellAmountTotal: number;
  rate: number;
  paymentFeesTotal: number;
  paymentTotal: number;
}

export interface IBulkSingleTransfer {
  sellCurrency: string;
  buyAmount: number;
  buyCurrency: string;
  transferType: TRANSFER_TYPE;
  reference?: string;
}

export interface IBulkPayment
  extends IBulkPaymentBase,
    IBulkPaymentSystemFields {}

export interface IBulkPaymentSystemFields {
  id: string;
  _created: any;
  _createdBy: string;
  _updated?: any;
  _updatedBy?: string;
  _version: number;
  _owner: string;
  status: BULK_PAYMENT_STATUS;
}

export interface IBulkPaymentBase {
  paymentRunId?: string;
  scheduledPaymentDate?: string;
  fundedTimestamp: any;
  localCount?: number;
  swiftCount?: number;
  openBankingCount?: number;
  invoicesCount?: number;
  sellCurrency: string;
  buyCurrency: string;
  sellAmount: number;
  buyAmount: number;
  paymentFee: number;
  payAmount: number;
  payByDate: string;
  payByTimeGMT: string;
  rate: number;
}

export interface IBulkTransferOutput extends IBulkPaymentBase {
  success: boolean;
  error?: string;
  data?: IBulkSingleTransferOutput[];
}

export enum BULK_PAYMENT_STATUS {
  awaitingPayment = 'awaiting_payment',
  processing = 'processing',
  completed = 'completed',
  cancelled = 'cancelled',
  transfersFailed = 'transfers_failed',
}

export interface IBulkSingleTransferOutput {
  success: boolean;
  id?: string;
  error?: string;
  data?: IBulkSingleTransferOutputData;
}
export interface IBulkSingleTransferOutputData extends ITransfer {
  recipient: IRecipient;
}

export interface IBulkTransferExecuteInput {
  buyAmountTotal: number;
  sellAmountTotal: number;
  rate: number;
  paymentFeesTotal: number;
  paymentTotal: number;
  transfers: IBulkTransferInput[];
}

interface IBulkTransferInput {
  sellCurrency: string;
  buyAmount: number;
  buyCurrency: string;
  transferType: TRANSFER_TYPE;
  recipient: IRecipient;
}
