import { FC, Dispatch, SetStateAction } from 'react';
import { parseIntoCurrencyString } from '../../../utils';
import { StaleRateMore, Paragraph, Row, Icon } from '../';
import { RATE_TYPE, ICurrency, IRateContract, Nullable } from '../../../types';
import dayjs from 'dayjs';
import { useTheme } from 'styled-components';
import StaleRegisterCompanyNotification from '../StaleRegisterCompanyNotification/StaleRegisterCompanyNotification';
import StaleAwaitingReviewNotification from '../StaleAwaitingReviewNotification/StaleAwaitingReviewNotification';
import StaleTransferNotification from '../StaleTransferNotification/StaleTransferNotification';
import { UseCurrencyRateReturnValues } from 'hooks/useCurrencyRate';
import useDeviceWidth from 'hooks/useDeviceWidth';
import Button from '../Button/Button';

interface OwnProps {
  isMarketRate: boolean;
  buyCurrency: ICurrency;
  sellCurrency: ICurrency;
  sellAmountAsNumber: number;
  buyAmountAsNumber: number;
  rate: UseCurrencyRateReturnValues['rate'];
  selectedRateContract?: Nullable<IRateContract>;
  prebookAndMarketRateDifference: number;
  setRateType: Dispatch<SetStateAction<RATE_TYPE>>;
  setSelectedRateContract: Dispatch<SetStateAction<IRateContract | null>>;
}

const PrebookedRateBlock: FC<OwnProps> = ({
  isMarketRate,
  buyCurrency,
  sellCurrency,
  sellAmountAsNumber,
  buyAmountAsNumber,
  rate,
  selectedRateContract,
  prebookAndMarketRateDifference,
  setRateType,
  setSelectedRateContract,
}) => {
  const theme = useTheme();
  const { isMobile } = useDeviceWidth();

  if (isMarketRate) {
    return (
      <>
        <StaleRegisterCompanyNotification style={{ maxWidth: 'unset' }} />
        <StaleAwaitingReviewNotification
          bgColor={theme.gradients.blue}
          style={{
            maxWidth: 'unset',
          }}
        />
        {!isMobile && (
          <StaleRateMore
            months={3}
            buyCurrency={buyCurrency}
            sellCurrency={sellCurrency}
            sellAmountAsNumber={sellAmountAsNumber}
            buyAmountAsNumber={buyAmountAsNumber}
            rate={rate}
          />
        )}

        {selectedRateContract && prebookAndMarketRateDifference > 0 && (
          <StaleTransferNotification type="success">
            <Paragraph>
              Your <strong>prebooked rate is better</strong> than current rates.
              Save{' '}
              {` ${sellCurrency.symbol}${parseIntoCurrencyString(
                prebookAndMarketRateDifference,
                sellCurrency.precision
              )} `}{' '}
              by using it
            </Paragraph>
          </StaleTransferNotification>
        )}
      </>
    );
  }

  return (
    <>
      {selectedRateContract && (
        <>
          <Row
            mt
            mtValue={theme.spacing.xl}
            mb
            mbValue={theme.spacing.s}
            justifyContent="space-between"
          >
            <Paragraph>Prebooked amount outstanding</Paragraph>
            <Paragraph>{`${buyCurrency.symbol}${parseIntoCurrencyString(
              selectedRateContract.remainingBuyAmount,
              buyCurrency.precision
            )}`}</Paragraph>
          </Row>
          <Row justifyContent="space-between">
            <Paragraph>Available until</Paragraph>
            <Paragraph>
              {dayjs(selectedRateContract.expiryDate).format('D MMM YYYY')}
            </Paragraph>
          </Row>
        </>
      )}

      {selectedRateContract && rate && prebookAndMarketRateDifference > 0 ? (
        <StaleTransferNotification icon type="success">
          <Icon icon="tick-ico" />
          <Paragraph>
            With HedgeFlows you’re saving
            {` ${sellCurrency.symbol}${parseIntoCurrencyString(
              prebookAndMarketRateDifference,
              sellCurrency.precision
            )} `}
            comparing to market rate.
          </Paragraph>
        </StaleTransferNotification>
      ) : (
        prebookAndMarketRateDifference < 0 && (
          <StaleTransferNotification icon type="warning">
            <Icon icon="warning-ico" />
            <Paragraph>The current market rate is better.</Paragraph>
            <Button
              variant="link"
              onClick={(e) => {
                e.preventDefault();

                setRateType(RATE_TYPE.market);
                setSelectedRateContract(null);
              }}
            >
              Switch to the current market rate
            </Button>
          </StaleTransferNotification>
        )
      )}
    </>
  );
};

export default PrebookedRateBlock;
