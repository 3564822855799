import { action, Action } from 'easy-peasy';

export interface ICurrencyRate {
  buyCurrency: string;
  sellCurrency: string;
  rate: number;
  status: string; // usually "valid", but can't find alternatives
  lastUpdated: {
    nanoseconds: number;
    seconds: number;
  };
}

export interface CurrencyRatesStateModel {
  currencyRates: Record<string, ICurrencyRate>;
  setCurrencyRates: Action<
    CurrencyRatesStateModel,
    Record<string, ICurrencyRate>
  >;
}

export const CurrencyRatesState: CurrencyRatesStateModel = {
  currencyRates: {},
  setCurrencyRates: action((state, payload) => {
    state.currencyRates = {
      ...state.currencyRates,
      ...payload,
    };
  }),
};
