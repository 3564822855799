import { action, Action, thunk, Thunk, computed, Computed } from 'easy-peasy';
import firebase from 'firebase/app';

import { Firebase } from 'services';
import { StateModel } from 'state';
import {
  ENTITY_TYPE,
  ICountry,
  ICurrency,
  IResponse,
  IUser,
  Nullable,
} from 'types';
import { Notify } from 'utils';
import auth from 'services/auth';
import LogRocket from 'logrocket';
import { IIntegration } from 'types/integrations';
import { OneOfEntityAccountDetails } from 'types/entities';
import {
  getEntityAccountDetails,
  GetEntityAccountDetailsParams,
} from 'services/firebase';

export interface User {
  id: IUser['id'];
  email: IUser['email'];
  name: IUser['name'];
  firstName?: IUser['firstName'];
  lastName?: IUser['lastName'];
  entityId: string;
  enabled: boolean;
  status: string;
  onboardingId?: string;
  debuggable?: boolean | undefined;
  isSuperAdmin?: boolean | undefined;
  isAccountant?: IUser['isAccountant'];
}

export interface IntegrationPermissions {
  global: boolean;
}

export enum CODAT_SYNC_STATUS {
  QUEUED = 'QUEUED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETE = 'COMPLETE',
  ERROR = 'ERROR',
}

export interface IEntityIntegrations {
  codat?: {
    _codatCompanyId?: string;
    company?: Record<string, unknown>;
    syncDetails?: {
      status: CODAT_SYNC_STATUS;
      syncIds: Record<string, string>;
    };
  };
}

export interface IEntitySettings {
  email?: string;
  shouldSendTransactionConfirmations?: boolean;
  shouldSendApprovalAlerts?: boolean;
  shouldSendRemittanceCopiesToSelf?: boolean;
  shouldSendEmailIfRiskToleranceExceeded?: boolean;
  riskTolerance?: number;
  accountingSystem?: string;
  howDidYouFindUs?: string;
  riskSettingCommittedCashFlows?: string;
  riskSettingExpectedCashFlows?: string;
  HFGuru?: {
    integrations: {
      isDone: boolean;
    };
  };
  deferredSuggestedFollowedCurrencies?: Record<ICurrency['code'], string>; // "YYYY-MM-DD"
}

export type TEntityPackageKeys = 'global' | 'automation' | 'fxManagement';
export type TEntityPackages = Record<TEntityPackageKeys, boolean>;

export interface IEntity extends IEntitySettings {
  expectedAnnualTurnover: number;
  expectedFxTurnoverUpperLimit: number;
  externalRefs: {
    ccId: string; // "9367e94f-7015-4a78-882c-67009c14e74c"
    ccOnBehalfOfId: string; //"91f2cfb7-2eb0-4ab9-9fef-9469fee1280"
    ccShortReference: string; // "210908-58552"
  };
  invoicesAndReceivesPayments: boolean;
  name: string;
  onboarded: boolean;
  status?: string;
  companyCountry?: string;
  canProvideInvoicesAndBankStatements: boolean;
  companyType: ENTITY_TYPE; // "limitedCompany"
  enabled: boolean;
  entityCurrency: string; // GBP
  integrations?: IEntityIntegrations;
  remainingCredit?: number;
  hfGuru?: boolean;
  hasApprovalFlow?: boolean;
  approverId?: string;
  shouldShowReports?: boolean;
  packages?: TEntityPackages;
  isUsingDeposits?: boolean; // true if entity wants to always use deposits during prebooking
}

export interface IMinimalEntity {
  id: string;
  name: IEntity['name'];
  entityCurrency: IEntity['entityCurrency'];
}

export interface IClient extends IMinimalEntity {
  worstRiskRatingStars: number;
  fxGainLossSum: number;
  accountBalancesInEntityCurrencySum: number;
  netOutstandingTotalInEntityCurrency: number;
  topCurrenciesByCashflowRisk: string[];
}

export type IRole = 'admin';

export interface IEntityUser {
  id: string;
  firstName: string;
  lastName: string;
  name: string;
  email: string;
  roles: IRole[];
  enabled: boolean;
  isApprover: boolean;
}

export interface UserStateModel {
  user: User | null;
  isUserApprover: Computed<UserStateModel, boolean>;
  // TODO: add correct interface for user entity
  userEntity: IEntity;
  entityOnboardingRecord: any;
  setState: Action<UserStateModel, [string, any]>;
  setUser: Action<UserStateModel, User | null>;
  setUserEntity: Action<UserStateModel, any>;
  integrationsSummary: IIntegration;
  setUserEntityIntegrations: Action<UserStateModel, IIntegration>;
  setEntityOnboardingRecord: Action<UserStateModel, any>;
  signIn: Thunk<
    UserStateModel,
    {
      email: string;
      password: string;
    }
  >;
  signInWithGoogle: Thunk<UserStateModel>;
  createUser: Thunk<
    UserStateModel,
    Omit<Firebase.CreateUserPayload, 'id'>,
    null,
    StateModel,
    Promise<IResponse | undefined>
  >;
  createAuthUser: Thunk<
    UserStateModel,
    {
      email: string;
      password: string;
    }
  >;
  getUser: Thunk<UserStateModel, Firebase.GetUserParams>;
  signOut: Thunk<UserStateModel>;
  getUserEntity: Thunk<UserStateModel, Firebase.GetUserEntityParams>;
  getEntityOnboardingRecord: Thunk<UserStateModel>;
  getEntityAccountDetails: Thunk<UserStateModel, GetEntityAccountDetailsParams>;
  userId: Computed<UserStateModel, Nullable<User['id']>>;
  userFirstName: Computed<UserStateModel, Nullable<User['firstName']>>;
  userEmail: Computed<UserStateModel, Nullable<User['email']>>;
  isUserRegistrationDone: Computed<UserStateModel, Nullable<User['enabled']>>;
  entityId: Computed<UserStateModel, Nullable<User['entityId']>>;
  entityType: Computed<UserStateModel, ENTITY_TYPE>;
  entityCountry: Computed<UserStateModel, Nullable<ICountry['alpha2']>>;
  isEntityEnabled: Computed<UserStateModel, boolean>;
  isEntityOnboarded: Computed<UserStateModel, boolean>;
  showReports: Computed<UserStateModel, boolean>;
  isWithinCreditLimit: Computed<UserStateModel, (amount: number) => boolean>;
  hasApprovalFlow: Computed<UserStateModel, boolean>;
  entityDefaultCurrency: Computed<UserStateModel, Nullable<ICurrency['code']>>;
  setUserEntityIntegrationCodat: Action<
    UserStateModel,
    IEntityIntegrations['codat']
  >;
  entityAccountDetails: OneOfEntityAccountDetails[];
  isAuthLoading: boolean;
  isAccountant: Computed<UserStateModel, boolean>;
  isAutomationPackageEnabled: Computed<UserStateModel, boolean>;
  isFxManagementPackageEnabled: Computed<UserStateModel, boolean>;
}

export const UserState: UserStateModel = {
  user: null,
  isUserApprover: computed(
    [(state) => state],
    ({ userEntity, user }) => userEntity?.approverId === user?.id
  ),
  entityAccountDetails: [],
  integrationsSummary: {},
  userEntity: {} as IEntity,
  entityOnboardingRecord: null,
  isAuthLoading: true,
  setState: action((state, payload) => {
    const [prop, to] = payload;
    state[prop] = to;
  }),
  setEntityOnboardingRecord: action((state, payload) => {
    state.entityOnboardingRecord = payload;
  }),
  setUserEntity: action((state, payload) => {
    state.userEntity = { ...state.userEntity, ...payload };
  }),
  setUserEntityIntegrations: action((state, payload) => {
    state.integrationsSummary = payload;
  }),
  setUser: action((state, payload) => {
    state.user = payload;
  }),
  getEntityAccountDetails: thunk(async ({ setState }, payload) => {
    try {
      const entityAccountDetails = await getEntityAccountDetails(payload);

      if (entityAccountDetails) {
        setState(['entityAccountDetails', entityAccountDetails]);
      }
    } catch (error) {
      console.log(error);
    }
  }),
  getUserEntity: thunk(async (actions, payload) => {
    const entity = await Firebase.getUserEntity(payload);

    if (entity) {
      actions.setUserEntity(entity);
    }
  }),
  signOut: thunk(async (actions, payload, { getState }) => {
    try {
      const userId = getState().userId;

      if (!userId) {
        return;
      }

      await Firebase.removeUserConnections({ userId });
      await auth.signOut();
      actions.setState(['userEntity', null]);
      actions.setState(['integrationsSummary', {}]);
    } catch (error) {
      console.log(error);
    }
  }),
  signIn: thunk(async (actions, payload) => {
    try {
      const { user: authUser } = await auth.signInWithEmailAndPassword(
        payload.email,
        payload.password
      );

      // can't be here if user entered incorrect details
      if (!authUser) {
        // user not found
        throw new Error(
          'Internal authentication error. Please try again after a short pause, or try another browser.'
        );
      }

      const userFromDatabase = await Firebase.getUser({
        id: authUser.uid,
      });

      // this means user did not complete sign up step 3
      if (!userFromDatabase) {
        actions.setUser({
          id: authUser.uid,
          email: payload.email,
          name: authUser.displayName || '',
          firstName: '',
          lastName: '',
          enabled: false,
          status: '',
          entityId: '',
        });
      } else {
        actions.setUser(userFromDatabase);
      }

      return true;
    } catch (error) {
      if (error.code === 'auth/multi-factor-auth-required') {
        return error.resolver;
      } else if (error.code === 'auth/too-many-requests') {
        throw error;
      } else if (error.code === 'auth/user-disabled') {
        Notify.error(
          'There is an issue with signing you in. Please contact support.'
        );
        throw error;
      } else {
        Notify.error(error.message);
        throw error;
      }
    }
  }),
  signInWithGoogle: thunk(async (actions, payload) => {
    try {
      const authProvider = new firebase.auth.GoogleAuthProvider();

      const response = await auth.signInWithPopup(authProvider);

      const { user } = response;

      if (user) {
        const { uid, email, displayName } = user;

        const userFromDatabase = await Firebase.getUser({
          id: uid,
        });

        if (userFromDatabase) {
          actions.setUser(userFromDatabase as IUser);
        } else {
          actions.setUser({
            id: uid,
            email: email || '',
            name: displayName || '',
            entityId: '',
            status: '',
            enabled: false,
          });
        }
      }
    } catch (error) {
      if (error.code === 'auth/multi-factor-auth-required') {
        return error.resolver;
      } else if (error.code === 'auth/user-disabled') {
        Notify.error(
          'There is an issue with signing you in. Please contact support.'
        );
        throw error;
      } else {
        Notify.error(error.message);
      }
    }
  }),
  createUser: thunk(async (actions, payload, { getState }) => {
    const { userId } = getState();

    if (!userId) {
      return undefined;
    }

    // TODO: discuss if we can get back newly created user
    const response = await Firebase.createUser({ ...payload, id: userId });

    if (response?.success) {
      actions.setUser({
        id: response.id,
        ...response.data,
      });
    } else {
      Notify.error(response?.message ?? '');
    }

    return response;
  }),
  createAuthUser: thunk(async (actions, payload) => {
    const { user } = await auth.createUserWithEmailAndPassword(
      payload.email,
      payload.password
    );

    if (user) {
      const { uid, email, displayName } = user;

      actions.setUser({
        id: uid,
        email: email || '',
        name: displayName || '',
        entityId: '',
        status: '',
        enabled: false,
      });
    }
  }),
  getUser: thunk(async (actions, payload) => {
    const user = await Firebase.getUser(payload);

    if (user) {
      actions.setUser({
        ...user,
      });

      if (process.env.REACT_APP_LOGROCKET_APP_ID) {
        LogRocket.identify(user.id, {
          name: user.name,
          firstName: user?.firstName,
          lastName: user?.lastName,
          email: user.email,
          entityId: user.entityId,
          isSuperAdmin: !!user.isSuperAdmin,
        });
      }
    }
  }),
  getEntityOnboardingRecord: thunk(async (actions, payload, { getState }) => {
    const { entityId, user } = getState();

    const { onboardingId } = user || {};

    if (!entityId || !onboardingId) {
      console.warn(
        'Cannot get entity onboarding record, entity ID is not defined or user has no onboarding ID.'
      );
      return;
    }

    const onboardingRecord = await Firebase.getEntityOnboardingRecord({
      entityId,
      onboardingId,
    });

    if (onboardingRecord) {
      actions.setEntityOnboardingRecord(onboardingRecord);
    }
  }),
  userId: computed([(state) => state.user], (user) => user?.id || null),
  userFirstName: computed([(state) => state.user], (user) => {
    if (user?.firstName) {
      return user?.firstName;
    } else if (user?.name) {
      return user.name.substring(0, user.name.lastIndexOf(' '));
    } else {
      return null;
    }
  }),
  userEmail: computed([(state) => state.user], (user) => user?.email),
  isUserRegistrationDone: computed(
    [(state) => state.user],
    (user) => user?.enabled
  ),
  entityId: computed([(state) => state.user], (user) => user?.entityId || null),
  entityType: computed(
    [(state) => state.userEntity],
    (userEntity) => userEntity?.companyType || null
  ),
  entityCountry: computed(
    [(state) => state.userEntity],
    (userEntity) => userEntity?.companyCountry || null
  ),
  isEntityEnabled: computed(
    [(state) => state.userEntity],
    (userEntity) => userEntity?.enabled
  ),
  isEntityOnboarded: computed(
    [(state) => state.userEntity],
    (userEntity) =>
      userEntity?.status === 'onboardingStep4Completed' ||
      userEntity?.status === 'onboarded'
  ),
  hasApprovalFlow: computed(
    [(state) => state.userEntity],
    (userEntity) => !!userEntity?.hasApprovalFlow
  ),
  showReports: computed(
    [(state) => state.userEntity],
    (userEntity) => !!userEntity?.shouldShowReports
  ),
  entityDefaultCurrency: computed(
    [(state) => state.userEntity],
    (userEntity) => userEntity?.entityCurrency || null
  ),
  isWithinCreditLimit: computed(
    [(state) => state.userEntity],
    (userEntity) => (amount: number) => {
      const creditLimit = userEntity?.remainingCredit ?? 0;
      return amount <= creditLimit;
    }
  ),
  setUserEntityIntegrationCodat: action((state, payload) => {
    if (!state?.userEntity?.integrations) {
      state.userEntity.integrations = {};
    }
    state.userEntity.integrations.codat = payload;
  }),
  isAccountant: computed(
    [(state) => state.user],
    (user) => !!user?.isAccountant
  ),
  isAutomationPackageEnabled: computed(
    [(state) => state.userEntity],
    (userEntity) => !!userEntity?.packages?.automation
  ),
  isFxManagementPackageEnabled: computed(
    [(state) => state.userEntity],
    (userEntity) => !!userEntity?.packages?.fxManagement
  ),
};
