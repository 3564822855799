import {
  FC,
  Dispatch,
  SetStateAction,
  useCallback,
  useState,
  useMemo,
} from 'react';
import { Subtitle, Title } from 'components';
import Popup from 'components/shared/Popup/Popup';

import InvoiceTable from './components/InvoiceTable';
import LinkPaymentsTable from './components/LinkPaymentsTable';
import { IContact, IInvoice } from 'types';
import { useStoreState } from 'state';

import { Notify, parseIntoCurrencyString } from 'utils';
import { IExternalPayment } from 'types/externalPayments';
import { transformExternalPaymentToPaymentOnInvoice } from './transformer';
import { updateInvoice } from 'services/firebase/invoices';
import { useTheme } from 'styled-components';
import { Row } from 'react-table';
import Button from 'components/shared/Button/Button';

interface OwnProps {
  invoice: IInvoice;
  onClose: () => void;
  setContactForEdit: Dispatch<SetStateAction<IContact>>;
  setInvoiceForAddContact: Dispatch<SetStateAction<IInvoice | null>>;
}

const PrePaymentsOnPurchaseOrder: FC<OwnProps> = ({
  invoice: originalInvoice,
  onClose,
  setContactForEdit,
  setInvoiceForAddContact,
}) => {
  const theme = useTheme();

  const [invoice, setInvoice] = useState(originalInvoice);
  const { currencyByCode } = useStoreState((state) => state.CurrenciesState);
  const { externalPayments } = useStoreState(
    (state) => state.ExternalPaymentsState
  );
  const currency = currencyByCode(invoice.currency);
  const amountDueParsed = parseIntoCurrencyString(
    invoice.amountDue ?? invoice.totalAmount,
    currency?.precision
  );

  const [
    selectedExternalPayment,
    setSelectedExternalPayment,
  ] = useState<IExternalPayment>();

  const amountDue = `${currency?.symbol}${amountDueParsed}`;

  const filteredPayments = useMemo(
    () =>
      externalPayments.filter(
        (payment) =>
          // always show selected payments
          (invoice.payments &&
            invoice.payments[0] &&
            invoice.payments[0].id) === payment.externalRefs.codatId ||
          // otherwise filter by attributes
          (!payment.invoiceId && // filter payments already attached to other invoices
            payment.supplierRef.id === invoice.externalRefs?.codatContactId && // filter payments by supplierRef.id
            payment.currency === invoice.currency && // filter by currency
            Math.abs(payment.totalAmount) <
              (invoice.amountDue ?? invoice.totalAmount)) // no need to show payments with amounts greater than the invoice
      ),
    [
      externalPayments,
      invoice.amountDue,
      invoice.currency,
      invoice.externalRefs?.codatContactId,
      invoice.payments,
      invoice.totalAmount,
    ]
  );

  const onSelectExternalPayment = useCallback(
    (_, selectedRows: Array<{ original: IExternalPayment }>) => {
      setSelectedExternalPayment(selectedRows[0]?.original);
    },
    [setSelectedExternalPayment]
  );

  const isRowSelectable = (props: {
    row: Row<IExternalPayment>;
    selectedFlatRows: Row<IExternalPayment>[];
  }) => {
    const record = props.row.original;
    const selectedRows = props.selectedFlatRows;
    return (
      !selectedRows.length ||
      record.externalRefs.codatId ===
        selectedRows[0].original.externalRefs.codatId
    );
  };

  const onButtonClick = useCallback(async () => {
    try {
      const newInvoice: Partial<IInvoice> = {};
      if (!selectedExternalPayment) {
        // reset amountDue to the totalAmount
        newInvoice.amountDue = invoice.totalAmount;
      } else if (selectedExternalPayment) {
        const newPayment = transformExternalPaymentToPaymentOnInvoice(
          selectedExternalPayment
        );
        newInvoice.payments = [newPayment];
        newInvoice.amountDue = invoice.totalAmount - newPayment.amount;
      }

      const updatedInvoiceResponse = await updateInvoice({
        invoiceId: invoice.id,
        data: newInvoice,
      });
      setInvoice((updatedInvoiceResponse.data as unknown) as IInvoice);
    } catch (error) {
      console.error(error);
      Notify.error('Failed to link payment to invoice');
    }
  }, [invoice, selectedExternalPayment]);

  return (
    <Popup
      HeaderContent={
        <Title variant="h3">
          Linked Purchase Orders and Payments on Account
        </Title>
      }
      FooterContent={
        <Button onClick={onButtonClick}>
          Link / Unlink
        </Button>
      }
      width="680px"
      onClose={onClose}
    >
      <Subtitle mb>Purchase Order:</Subtitle>
      <InvoiceTable
        invoice={invoice}
        setContactForEdit={setContactForEdit}
        setInvoiceForAddContact={setInvoiceForAddContact}
      />

      <Subtitle mtValue={theme.spacing.xxl} mt mb>
        Link existing prepayments to Purchase Order {invoice.invoiceNumber} to
        plan with correct currency needs:
      </Subtitle>
      <LinkPaymentsTable
        payments={filteredPayments}
        invoice={invoice}
        onSelectExternalPayment={onSelectExternalPayment}
        isRowSelectable={isRowSelectable}
      />

      <Subtitle>
        The outstanding balance on the Purchase Order is <b>{amountDue}</b>
      </Subtitle>
    </Popup>
  );
};

export default PrePaymentsOnPurchaseOrder;
