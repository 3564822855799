import { useEffect, useState } from 'react';
import { subscribeToPastPerformancePerMonth } from 'services/firebase/analysis';
import { useStoreState } from 'state';
import { IPnlDataPerMonth } from 'types/analyses';

const usePastPerformanceDataPerMonth = ({
  currencyCode,
  from,
}: {
  currencyCode: string;
  from: string;
}) => {
  const { entityId } = useStoreState((state) => state.UserState);
  const [data, setData] = useState<IPnlDataPerMonth[]>([]);

  useEffect(() => {
    let unsubscribe: (() => void) | undefined;

    if (entityId) {
      unsubscribe = subscribeToPastPerformancePerMonth({
        entityId,
        currency: currencyCode,
        from,
        callback: setData,
      });
    }

    return () => unsubscribe?.();
  }, [currencyCode, entityId, from]);

  const calculatedData = data.reduce(
    (
      {
        totalTurnover,
        totalRealisedImpact,
        totalUnrealisedImpact,
        averageNetOutstanding,
        totalFxCost,
        totalTransactionCost,
        totalGainLoss,
      },
      item
    ) => ({
      totalTurnover: totalTurnover + item.turnover,
      totalRealisedImpact: totalRealisedImpact + item.totalFxImpactForPaid,
      totalUnrealisedImpact:
        totalUnrealisedImpact +
        (item.fxImpactTotal ? item.fxImpactTotal - item.fxImpactForPaid : 0),
      averageNetOutstanding:
        averageNetOutstanding + item.averageOutstandingBalance,
      totalFxCost: totalFxCost + item.fxCost,
      totalTransactionCost: totalTransactionCost + item.transactionCost,
      totalGainLoss:
        totalGainLoss + item.totalFxImpactForPaid + item.transactionCost,
    }),
    {
      totalRealisedImpact: 0,
      totalUnrealisedImpact: 0,
      averageNetOutstanding: 0,
      totalTurnover: 0,
      totalFxCost: 0,
      totalTransactionCost: 0,
      totalGainLoss: 0,
    }
  );

  return {
    data,
    averageMonthlyRealisedImpact:
      calculatedData.totalRealisedImpact / data.length,
    averageMonthlyUnrealisedImpact:
      calculatedData.totalUnrealisedImpact / data.length,
    averageTurnover: calculatedData.totalTurnover / data.length,
    averageMonthlyFxCost: calculatedData.totalFxCost / data.length,
    averageTransactionCost: calculatedData.totalTransactionCost / data.length,
    averageGainLoss: calculatedData.totalGainLoss / data.length,
    ...calculatedData,
  };
};

export default usePastPerformanceDataPerMonth;
