import { CSSProperties, FC, ReactNode, ReactText } from 'react';
import { InfoBoxText, InfoBoxWrapper } from './StaleInfoBox.styles';

interface OwnProps {
  text: ReactNode | ReactText;
  style?: CSSProperties;
}

const InfoBox: FC<OwnProps> = ({ text, style }) => (
  <InfoBoxWrapper style={style} >
    <svg width="24px" height="24px">
      <use xlinkHref="#clock" />
    </svg>
    <InfoBoxText>{text}</InfoBoxText>
  </InfoBoxWrapper>
);

export default InfoBox;
