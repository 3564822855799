import styled, { css } from 'styled-components';
import { Button, StaleParagraphMedium } from 'components';

export const Wrapper = styled.div`
  position: fixed;
  background: rgb(18, 18, 19, 0.4);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
`;

export const ContentWrapper = styled.div(
  ({ theme }) => css`
    background: ${theme.color.white};
    padding: 16px;
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.12);
    border-radius: 16px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: ${theme.breakpoint.extraSmallMin}px;

    ${Button} {
      flex: 0 0 48%;
      max-width: 48%;
      margin-top: 16px;
      padding: 0;
    }

    ${StaleParagraphMedium} {
      color: ${theme.color.black};
      text-align: center;
    }
  `
);
