import { AllHTMLAttributes } from 'react';
import { CSSProperties, FC } from 'react';
import { StaleButtonNew } from './StaleButton.styles';

export interface ButtonProps
  extends Pick<AllHTMLAttributes<HTMLButtonElement>, 'as'> {
  variant?: 'primary' | 'secondary' | 'link';
  disabled?: boolean;
  className?: string;
  // TODO: add correct type
  onClick?: any;
  style?: CSSProperties;
  isLoading?: boolean;
  href?: string;
  download?: boolean;
  target?: string;
  // TODO: generic types based on as prop
  nativeButtonProps?: any;
}

const StaleCustomButton: FC<ButtonProps> = ({
  as,
  variant,
  disabled,
  children,
  className,
  onClick,
  style,
  isLoading,
  href,
  download = false,
  nativeButtonProps,
  target,
}) => {
  return (
    <StaleButtonNew
      as={as}
      variant={variant}
      disabled={disabled}
      onClick={onClick}
      className={className}
      style={style}
      href={href}
      download={download}
      target={target}
      {...nativeButtonProps}
    >
      {children}

      {isLoading && (
        <svg className="loader">
          <use xlinkHref="#loader-ico" />
        </svg>
      )}
    </StaleButtonNew>
  );
};

StaleCustomButton.defaultProps = {
  as: 'button',
  variant: 'primary',
  disabled: false,
  className: '',
  nativeButtonProps: {},
};

export default StaleCustomButton;
