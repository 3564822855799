import { ComputedBarDatum } from '@nivo/bar';
import { Col, Paragraph } from 'components';
import { parseIntoCurrencyStringWithSymbol } from 'utils';
import ChartTooltip from '../ChartTooltip/ChartTooltip';
import useChartTooltip from 'pages/Reports/hooks/useChartTooltip';
import ChartBarsGroupWrapper from '../ChartBarsGroupWrapper/ChartBarsGroupWrapper.styles';
import ChartBarMarketImpact from '../ChartBarMarketImpact/ChartBarMarketImpact';
import { IPnlDataPerMonth } from 'types';
import { FC } from 'react';

interface OwnProps {
  groupId: string;
  barsGroup: ComputedBarDatum<IPnlDataPerMonth>[];
  barExtraProps: any;
  onClick: (month: string) => void;
}

const ChartMarketImpactBarsGroup: FC<OwnProps> = ({
  groupId,
  barsGroup,
  barExtraProps,
  onClick,
}) => {
  const { tooltipRef, ...mouseEvents } = useChartTooltip();

  return (
    <ChartBarsGroupWrapper onClick={() => onClick(groupId)} {...mouseEvents}>
      {barsGroup.map((bar) => (
        <ChartBarMarketImpact
          key={bar.data.id}
          bar={bar}
          activeMonth={barExtraProps.activeMonth}
        />
      ))}

      <ChartTooltip wrapperRef={tooltipRef}>
        <Col>
          <Paragraph color="white">{`Paid invoices: ${parseIntoCurrencyStringWithSymbol(
            barsGroup[0].data.value,
            '£',
            0
          )}`}</Paragraph>
          <Paragraph color="white">{`Outstanding invoices: ${parseIntoCurrencyStringWithSymbol(
            barsGroup[1].data.value,
            '£',
            0
          )}`}</Paragraph>
          <Paragraph color="white">{`Total: ${parseIntoCurrencyStringWithSymbol(
            (barsGroup[1].data.value ?? 0) + (barsGroup[0].data.value ?? 0),
            '£',
            0
          )}`}</Paragraph>
        </Col>
      </ChartTooltip>
    </ChartBarsGroupWrapper>
  );
};

export default ChartMarketImpactBarsGroup;
