import { action, Action, Thunk, thunk } from 'easy-peasy';
import {
  subscribeToExternalPayments,
  SubscribeToExternalPaymentsParams,
} from 'services/firebase/externalPayments';
import { IExternalPayment } from 'types/externalPayments';

export interface ExternalPaymentsStateModel {
  externalPayments: IExternalPayment[];
  isLoadingExternalPayments: boolean;
  setState: Action<ExternalPaymentsStateModel, [string, any]>;
  subscribeToExternalPayments: Thunk<
    ExternalPaymentsStateModel,
    Omit<SubscribeToExternalPaymentsParams, 'callback'>,
    null,
    object
  >;
}

export const ExternalPaymentsState: ExternalPaymentsStateModel = {
  externalPayments: [],
  isLoadingExternalPayments: true,
  setState: action((state, payload) => {
    const [prop, to] = payload;
    state[prop] = to;
  }),
  subscribeToExternalPayments: thunk(({ setState }, payload) => {
    const subscriber = subscribeToExternalPayments({
      ...payload,
      callback: (externalPayments) => {
        setState(['externalPayments', externalPayments]);
        setState(['isLoadingExternalPayments', false]);
      },
    });

    return subscriber;
  }),
};
