import styled, { css } from 'styled-components';

export const Wrapper = styled.div<{ color?: string; fullWidth?: boolean }>(
  ({ theme, color, fullWidth = false }) => css`
    width: 100%;
    height: 4px;
    background: ${theme.color.greyLight_2};
    border-radius: 4px;
    overflow: hidden;
    max-width: ${!fullWidth && '154px'};
    margin-top: 8px;

    .progress {
      background: ${color || theme.color.emeraldDark};
      height: 100%;
      border-radius: 4px;
      overflow: hidden;
    }
  `
);
