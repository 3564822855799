import { SetStateAction, useEffect, Dispatch } from 'react';
import { IRateContract, RATE_TYPE, ICurrency, Nullable } from 'types';
import { useStoreState } from 'state';

interface UseAmountsParams {
  selectedRateContract?: Nullable<IRateContract>;
  updateBuyCurrency: (value: ICurrency) => void;
  updateSellCurrency: (value: ICurrency) => void;
  buyAmountAsNumber: number;
  setSelectedRateContract: Dispatch<SetStateAction<IRateContract | null>>;
  setRateType: Dispatch<SetStateAction<RATE_TYPE>>;
  checkSelectedRateContract?: boolean;
}

const useSelectedRateContract = ({
  selectedRateContract,
  updateBuyCurrency,
  updateSellCurrency,
  buyAmountAsNumber,
  setSelectedRateContract,
  setRateType,
  checkSelectedRateContract = true,
}: UseAmountsParams) => {
  const { sellCurrencies, buyCurrencies } = useStoreState(
    (state) => state.CurrenciesState
  );

  useEffect(() => {
    if (selectedRateContract) {
      const newBuyCurrency = buyCurrencies.find(
        (item) => item.code === selectedRateContract.buyCurrency
      );

      if (newBuyCurrency) {
        updateBuyCurrency(newBuyCurrency);
      }

      // we still need to set sell currency
      const sellCurrencyToUse = sellCurrencies.find(
        (item) => item.code === selectedRateContract.sellCurrency
      );

      if (sellCurrencyToUse) {
        updateSellCurrency(sellCurrencyToUse);
      }
    }
  }, [
    buyCurrencies,
    selectedRateContract,
    sellCurrencies,
    updateBuyCurrency,
    updateSellCurrency,
  ]);

  useEffect(() => {
    if (
      checkSelectedRateContract &&
      selectedRateContract &&
      buyAmountAsNumber > selectedRateContract.remainingBuyAmount
    ) {
      setSelectedRateContract(null);
      setRateType(RATE_TYPE.market);
    }
  }, [
    buyAmountAsNumber,
    checkSelectedRateContract,
    selectedRateContract,
    setRateType,
    setSelectedRateContract,
  ]);
};

export default useSelectedRateContract;
