import { StaleTitleH1 } from 'components';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div(
  ({ theme }) => css`
    padding-top: 56px;
    width: 100%;
    overflow: auto;
    background: ${theme.color.dark};
    height: calc(var(--vh, 1vh) * 100);

    @media (min-width: ${theme.breakpoint.largeMin}px) {
      padding-top: 68px;
    }

    .onfido-sdk-ui-Modal-inner {
      position: relative;
      box-shadow: rgb(0 0 0 / 10%) 0px 0px 9px;
      border-color: lightgrey;
    }
  `
);

export const InvalidLinkWrapper = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;

    @media (min-width: ${theme.breakpoint.largeMin}px) {
      max-width: 600px;
    }

    ${StaleTitleH1} {
      text-align: center;
    }
  `
);
