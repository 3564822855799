import { InputRadio } from './StaleRadioButton.styles';

const StaleRadioButtonGroup = ({
  list,
  name = '',
  onChange,
  cardRow = false,
  cardCol = false,
  cardTransparent = false,
  cardRowRevert = false,
}) =>
  list.map((item) =>
    item ? (
      <InputRadio
        key={item.id}
        cardRow={cardRow}
        cardRowRevert={cardRowRevert}
        cardCol={cardCol}
        cardTransparent={cardTransparent}
      >
        <input
          type="radio"
          id={item.id}
          name={name}
          value={item.value}
          checked={item.checked}
          onChange={() => onChange(item)}
          autoFocus={item.autoFocus}
        />
        <label htmlFor={item.id}>{item.name}</label>
      </InputRadio>
    ) : null
  );

export default StaleRadioButtonGroup;
