import { BarCustomLayerProps } from '@nivo/bar';
import groupBy from 'lodash.groupby';
import { FC, useMemo } from 'react';
import { IPnlDataPerMonth } from 'types/analyses';
import ChartMarketImpactBarsGroup from '../ChartMarketImpactBarsGroup/ChartMarketImpactBarsGroup';

interface OwnProps {
  data: BarCustomLayerProps<IPnlDataPerMonth>;
  activeMonth: string;
  onClick: (barMonth: string) => void;
}

const ChartMarketImpactBarsLayer: FC<OwnProps> = ({
  data,
  activeMonth,
  onClick,
}) => {
  const groupedBars = useMemo(
    () => Object.entries(groupBy(data.bars, 'data.indexValue')),
    [data.bars]
  );

  return (
    <>
      {groupedBars.map(([id, barsGroup]) => (
        <ChartMarketImpactBarsGroup
          key={id}
          groupId={id}
          barsGroup={barsGroup}
          barExtraProps={{
            activeMonth,
            data,
          }}
          onClick={onClick}
        />
      ))}
    </>
  );
};

export default ChartMarketImpactBarsLayer;
