import useFindAccountingIntegration from 'hooks/useFindAccountingIntegration';
import { IInvoice } from 'types';

export const useInvoiceLastSyncDetails = (invoice: IInvoice) => {
  const { lastSyncFromExternalSource, lastSyncToExternalSource } = invoice;
  const accountingIntegration = useFindAccountingIntegration();

  return {
    accountingIntegration,
    lastSyncFromExternalSource,
    lastSyncToExternalSource,
  };
};
