import { StaleParagraphMedium } from 'components';
import { IInvoice } from 'types';

interface Props {
  rate: number | null;
  currency: string;
  invoiceType: IInvoice['type'];
}

const MarketInvoiceRate: React.FC<Props> = ({
  rate,
  currency,
  invoiceType,
}) => (
  <div className="row-info">
    <StaleParagraphMedium>Current rate:</StaleParagraphMedium>
    <StaleParagraphMedium
      style={{
        opacity: 1,
      }}
    >
      {` 1 ${invoiceType === 'Payable' ? 'GBP' : currency} = ${rate} ${
        invoiceType === 'Payable' ? currency : 'GBP'
      }`}
    </StaleParagraphMedium>
  </div>
);

export default MarketInvoiceRate;
