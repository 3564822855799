import { Dispatch, FC, ReactNode, SetStateAction, useState } from 'react';
import {
  NetworthSectionExpansionContent,
  NetworthSectionMainContent,
  NetworthSectionWrapper,
  NetworthSectionWrapperProps,
} from './NetworkSection.styles';

interface OwnProps extends Pick<NetworthSectionWrapperProps, 'zIndex'> {
  expansionContent?: ReactNode;
  renderContent: (
    setIsExpanded: Dispatch<SetStateAction<boolean>>,
    isExpanded: boolean,
  ) => ReactNode;
}

const NetworthSection: FC<OwnProps> = ({
  expansionContent,
  zIndex,
  renderContent,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <NetworthSectionWrapper isExpanded={isExpanded} zIndex={zIndex}>
      <NetworthSectionMainContent>
        {renderContent(setIsExpanded, isExpanded)}
      </NetworthSectionMainContent>
      {!!expansionContent && (
        <NetworthSectionExpansionContent>
          {expansionContent}
        </NetworthSectionExpansionContent>
      )}
    </NetworthSectionWrapper>
  );
};

export default NetworthSection;
