import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import {
  CONTRACT_STATUS,
  IExternalHedge,
  IRateContract,
  Nullable,
} from 'types';

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(customParseFormat);

export const getPrebookStatusDetails = (
  rateContract: Nullable<IRateContract>
) => {
  if (!rateContract) return null;

  switch (rateContract.status) {
    case CONTRACT_STATUS.awaitingPrepayment:
      return {
        text: 'Awaiting prepayment',
        color: '#E69138',
      };
    case CONTRACT_STATUS.prepaymentOverdue:
      return {
        text: 'Prepayment overdue',
        color: '#FF0102',
      };
    case CONTRACT_STATUS.readyToUse:
      return {
        // @ts-ignore
        text: rateContract.numberOfTransfers
          ? // @ts-ignore
            `${rateContract.numberOfTransfers} transfer${
              // @ts-ignore
              rateContract.numberOfTransfers !== 1 ? 's' : ''
            } made`
          : 'Ready to use',
        color: '#39771D',
      };
    case CONTRACT_STATUS.used:
      return {
        text: 'Used',
        color: '#000',
      };
    case CONTRACT_STATUS.expired:
      return {
        text: 'Expired',
        color: '#FF0102',
      };
    case CONTRACT_STATUS.cancelled:
      return {
        text: 'Cancelled',
        color: '#000',
      };

    default:
      return null;
  }
};

export const canUseRateContract = (
  rateContract: Nullable<IRateContract>,
  invoiceId?: string | null
) => {
  if (!rateContract) {
    return false;
  }

  return (
    (!rateContract.invoiceId || rateContract.invoiceId === invoiceId) &&
    rateContract.status === CONTRACT_STATUS.readyToUse &&
    rateContract.remainingBuyAmount > 0
  );
};

export const getClosestRate = (data, toFind) =>
  data.reduce(({ rate }, { rate: a }) =>
    Math.abs(toFind - a) < Math.abs(toFind - rate) ? { rate: a } : { rate }
  );

export const isRateContractActive = (contract: IRateContract) =>
  contract.status === CONTRACT_STATUS.awaitingPrepayment ||
  contract.status === CONTRACT_STATUS.readyToUse;

export const isRateContract = (
  record: IRateContract | IExternalHedge
): record is IRateContract => (record as IRateContract).status !== undefined;

export const isAvailable = (record: IRateContract | IExternalHedge) =>
  isRateContract(record) &&
  record.status !== CONTRACT_STATUS.expired &&
  record.status !== CONTRACT_STATUS.used &&
  record.status !== CONTRACT_STATUS.cancelled;

export const isExpired = (record: IRateContract | IExternalHedge) =>
  isRateContract(record) && record.status === CONTRACT_STATUS.expired;

export const isUsed = (record: IRateContract | IExternalHedge) =>
  isRateContract(record) && record.status === CONTRACT_STATUS.used;
