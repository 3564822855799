import styled, { css } from 'styled-components';

export const Wrapper = styled.button(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    cursor: pointer;
    margin: 0 !important;
    transition: ${theme.transition};

    svg {
      width: 24px;
      height: 24px;
      margin-left: 6px;
      fill: ${theme.color.emeraldDark};
    }

    p {
      margin-bottom: 0 !important;
    }
  `
);
