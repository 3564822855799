import styled, { css } from 'styled-components';
import { isMobile } from 'react-device-detect';

export const Wrapper = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    background: ${theme.color.white};
    padding: 14px 16px;

    .row {
      justify-content: space-between;
      margin-bottom: 16px;
    }

    .rates-slider {
      margin: 42px 0 32px;
      padding-bottom: 8px;

      .col {
        flex: 0 0 50%;
        max-width: 50%;

        &:last-of-type {
          text-align: right;
        }
      }

      .cost-range {
        background: linear-gradient(
          90deg,
          #cfeeea 1.31%,
          #e7edd0 27.56%,
          #facba0 70.76%,
          #ffb8bd 98.26%
        );
        height: ${isMobile ? '40px' : '20px'};
        border-bottom: ${isMobile ? '3px' : '2px'} solid ${theme.color.dark};
        margin: 36px 0 8px;

        .example-thumb {
          bottom: -2px;
        }

        .tooltip {
          color: ${theme.color.dark};
          font-size: 24px;
          font-weight: 700;
          text-align: center;
        }
      }

      .time-range {
        height: 40px;
        border-bottom: 3px solid ${theme.color.dark};
        margin: 36px 0 20px;

        .example-thumb {
          bottom: ${isMobile ? '-20px' : '-13px'};
          width: ${isMobile ? '40px' : '23px'};
          border: 2px solid ${theme.color.dark};
          background: ${theme.color.dark};
          box-shadow: inset 0 0 0 ${isMobile ? '7px' : '3px'}
            ${theme.color.white};
        }

        .tooltip {
          color: ${theme.color.white};
          background: ${theme.color.dark};
        }
      }

      .example-thumb {
        height: ${isMobile ? '40px' : '23px'};
        border-radius: 40px;
        border: 2px solid ${theme.color.dark};
      }

      .tooltip {
        position: absolute;
        bottom: calc(100% + 6px);
        padding: 6px 10px;

        border-radius: 6px;

        left: 50%;
        transform: translate(-50%, 0);
        display: flex;
        flex-direction: column;
        white-space: nowrap;
      }
    }

    .content {
      padding: 0 16px;
    }

    .gear {
      width: 174px;
      height: 174px;
      background: url('/assets/imgs/bg/gear-rates.svg') no-repeat center top;
      position: relative;

      .arrow {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        height: 12px;
        width: 52px;

        &:after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          border-radius: ${theme.borderRadius.round};
          margin: auto;
          width: 12px;
          height: 12px;
          background: ${theme.color.dark};
        }
        &:before {
          content: '';
          position: absolute;
          width: 24px;
          height: 4px;
          background: ${theme.color.dark};
          border-radius: 4px 0 0 4px;
          left: 1px;
          top: 13px;
          bottom: 0;
          margin: auto;
          transform: rotate(-28deg);
        }
      }

      &-rates {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .text {
          margin-top: -70px;
          text-align: center;
        }
        .amount {
          font-weight: 700;
          font-size: 24px;
          line-height: 27px;
        }
      }
    }

    .info {
      display: flex;
      align-items: center;
      line-height: 24px;
      text-decoration: underline;

      button {
        margin: -2px 4px 0 0;
      }
    }

    .cheaper-than-amount {
      display: flex;
      flex-direction: row;
      align-items: center;

      span {
        white-space: pre-wrap;
      }
    }
  `
);

export const RateAnalysisContent = styled.div`
  padding: 0 16px;
  background: red;

  &:first-of-type {
    padding-top: 8px;
  }
`;
