import { Title, StaleSelectMenu, Col, Paragraph } from 'components';
import { FC, useState } from 'react';
import { Notify } from 'utils';
import InfoCard from 'components/shared/InfoCard/InfoCard';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { saveRegistrationSettings } from 'services/entities';
import { useQuery } from 'hooks';
import { Controller, useForm } from 'react-hook-form';
import useIntegrationDefaultValues from 'hooks/useIntegrationDefaultValues';
import { ICurrencyInput } from 'types';
import Button from 'components/shared/Button/Button';

type Inputs = {
  entityCurrency: ICurrencyInput;
};

const StepInfo: FC = () => {
  const theme = useTheme();
  const history = useHistory();
  const URLQuery = useQuery();
  const { url } = useRouteMatch();
  const [isGlobalOn, setIsGlobalOn] = useState(false);
  const [isAutomationOn, setIsIAutomationOn] = useState(false);
  const [isRiskManagementOn, setIsRiskManagementOn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { defaultCurrencies, parsedCurrencies } = useIntegrationDefaultValues();

  const { control, handleSubmit, errors } = useForm<Inputs>({
    mode: 'all',
    defaultValues: {
      entityCurrency: defaultCurrencies[0],
    },
  });

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      const response = await saveRegistrationSettings({
        entityCurrency: data.entityCurrency.value.code || 'GBP',
        objectiveGlobalBusinessAccount: isGlobalOn,
        objectivePaymentAutomation: isAutomationOn,
        objectiveFxRiskAndCostManagement: isRiskManagementOn,
      });

      if (response?.data?.success) {
        URLQuery.set('step', '3');
        history.push(`${url}?${URLQuery.toString()}`);
      } else {
        Notify.error(response?.data?.message ?? '');
        setIsLoading(false);
      }
    } catch (error) {
      Notify.error(error.response?.data?.error);
      setIsLoading(false);
    }
  };

  return (
    <>
      <Title mb mbValue={theme.spacing.xl}>
        Two more questions:
      </Title>
      <form id="info-form" onSubmit={handleSubmit(onSubmit)}>
        <Controller
          id="entityCurrency"
          name="entityCurrency"
          control={control}
          render={({ onChange, value, name }) => (
            <StaleSelectMenu
              id={name}
              name={name}
              label="What is your main currency?"
              data={parsedCurrencies}
              value={value}
              onChange={onChange}
              disabled
              withBackdrop={false}
              //@ts-ignore
              error={errors.mainSellCurrency?.message}
            />
          )}
        />

        <Title variant="h4" mt mtValue={theme.spacing.xl}>
          What are you interested in?
        </Title>

        <Paragraph mb mbValue={theme.spacing.xl}>
          (select all that apply)
        </Paragraph>

        <Col mb mbValue={theme.spacing.xxxl} gap={theme.spacing.xs}>
          <InfoCard
            isOn={isGlobalOn}
            onChange={() => setIsGlobalOn(!isGlobalOn)}
            id="objectiveGlobalBusinessAccount"
            icon="transfer"
            title="Global Business Account"
            description="Save on foreign currencies, international transfers and collections for a small business"
          />
          <InfoCard
            isOn={isAutomationOn}
            onChange={() => setIsIAutomationOn(!isAutomationOn)}
            id="objectivePaymentAutomation"
            icon="prebook"
            title="Payment automation in 30 currencies"
            description="Streamline payments and reconciliation by connecting HedgeFlows to your accounting"
          />
          <InfoCard
            isOn={isRiskManagementOn}
            onChange={() => setIsRiskManagementOn(!isRiskManagementOn)}
            id="objectiveFxRiskAndCostManagement"
            icon="rates"
            title="FX risk and cost management"
            description="Manage risks and control costs of doing business in foreign currencies"
          />
        </Col>
      </form>
      <Button form="info-form" disabled={isLoading} isLoading={isLoading}>
        Continue
      </Button>
    </>
  );
};

export default StepInfo;
