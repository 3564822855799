import { Button, StaleTitleH1 } from 'components';
import { FC } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { Wrapper } from './PageNotFound.styles';

const PageNotFound: FC<RouteComponentProps> = ({ history }) => (
  <Wrapper>
    <StaleTitleH1>Page not found.</StaleTitleH1>

    <Button onClick={history.goBack}>Go back</Button>
  </Wrapper>
);

export default PageNotFound;
