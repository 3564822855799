import styled, { css } from 'styled-components';
import {
  ButtonStyleLink,
  Button,
  ButtonSecondary,
  StaleParagraphMedium,
} from 'components';

export const Wrapper = styled.div(
  ({ theme }) => css`
    width: 100%;
    height: calc(var(--vh, 1vh) * 100);
    background: ${theme.color.white};

    @media (max-width: ${theme.breakpoint.largeMax}px) {
      padding-top: 68px;
    }

    .field {
      margin-top: 16px;

      ${StaleParagraphMedium} {
        margin-bottom: 14px;
      }

      ${StaleParagraphMedium} {
        ${ButtonStyleLink} {
          display: inline;
          border-bottom: 1px solid ${theme.color.dark};
          padding-bottom: 1px;
          line-height: 14px;

          &:hover {
            border-color: ${theme.color.transparent};
          }
        }
      }

      .resend {
        font-size: 14px;
        color: ${theme.color.greyDark};
        margin-top: 7px;
      }
    }

    .btns-group {
      margin-top: auto;
      padding: 16px;
      text-align: center;
      color: ${theme.color.greyDark};
      background: ${theme.color.white};
      box-shadow: ${theme.shadow};
    }

    .bot {
      margin-top: auto;
      padding: 32px 16px 16px;
      text-align: center;
      color: ${theme.color.greyDark};

      ${ButtonStyleLink} {
        display: inline;
        border-bottom: 1px solid ${theme.color.dark};
        padding-bottom: 1px;

        &:focus,
        &:active {
          color: ${theme.color.emeraldDark};
          border-color: ${theme.color.emeraldDark};

          svg {
            fill: ${theme.color.black};
          }
        }

        &:hover {
          border-color: ${theme.color.transparent};
        }
      }
    }

    .or {
      display: flex;
      justify-content: center;
      position: relative;
      margin: 24px 0;

      span {
        font-weight: 500;
        padding: 0 10px;
        background: ${theme.color.white};
        position: relative;
      }

      &:before {
        content: '';
        width: 100%;
        height: 1px;
        background: ${theme.color.greyLight_2};
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto 0;
      }
    }

    ${ButtonSecondary}.google {
      position: relative;
      font-weight: 500;
      margin-bottom: 40px;
      margin-top: 16px;

      &:disabled {
        svg {
          transition: 0.3s opacity ease;
          opacity: 0.6;
        }
      }

      svg {
        position: absolute;
        left: 0;
      }
    }

    form {
      & > ${Button} {
        margin: 24px 0;
      }
    }

    .block {
      flex: 1 0 auto;
      display: flex;
      flex-direction: column;
    }
  `
);

export const Block = styled.div`
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 20%;
`;
