import styled, { css } from 'styled-components';

import { StaleParagraphMedium, StaleTextHint } from 'components';
import { isMobile } from 'react-device-detect';

export const RateSelectWrapper = styled.div(
  ({ theme }) => css`
    height: 56px;
    margin-bottom: 14px;
    position: ${!isMobile && 'relative'};

    &.show {
      &:before {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgb(18, 18, 19, 0.24);
        height: 100%;
        z-index: 2;
      }
      .select {
        opacity: 1;
        box-shadow: 0 0 9px rgba(0, 0, 0, 0.1);
        border-radius: 6px;
      }

      .i-arrow {
        transform: rotate(180deg);
      }
    }
    ${isMobile &&
    css`
      &.show {
        &:before {
          content: '';
        }
      }
    `}

    .i-arrow {
      position: absolute;
      right: 8px;
      top: 0;
      bottom: 0;
      margin: auto;
      transition: ${theme.transition};

      @media (min-width: ${theme.breakpoint.largeMin}px) {
        cursor: pointer;
      }
    }

    .wrap {
      z-index: 2;
      left: 16px;
      right: 16px;

      @media (min-width: ${theme.breakpoint.largeMin}px) {
        left: 0;
        right: 0;
      }

      &-card {
        background: ${theme.color.greyLight_3};
        padding: 4px;
        border-radius: 8px;
        display: flex;
        position: ${isMobile && 'relative'};
      }
    }

    .card {
      padding: 5px 13px 2px;
      flex: 0 0 50%;
      position: relative;

      @media (min-width: ${theme.breakpoint.largeMin}px) {
        &:not(.card_active) {
          cursor: pointer;
        }
      }

      &_active {
        padding-left: 11px;
        background: ${theme.color.white};
        border: 1px solid ${theme.color.greyLight_1};
        border-radius: 8px;
      }

      &:not(.card_active) {
        ${StaleTextHint} {
          font-size: 14px;
          line-height: 20px;
        }

        button {
          width: 16px;
          height: 16px;
          right: 11px;
          top: 8px;
          position: absolute;
        }

        .wrap ${StaleParagraphMedium} {
          color: ${theme.color.white};
          margin-bottom: 0;

          &:not(:last-child) {
            margin-bottom: 12px;
          }
        }
      }
    }

    ${StaleParagraphMedium} {
      color: ${theme.color.greyDark};
    }

    .select {
      background: ${theme.color.white};
      border-radius: 8px;
      margin-top: 4px;
      opacity: 0;
      transition: ${theme.transition};
      max-height: 46vh;
      overflow: auto;

      .row {
        justify-content: space-between;
      }
    }

    .checkbox {
      position: relative;
      padding: 9px 16px;
      transition: background ${theme.transition};

      &:first-of-type {
        padding-top: 20px;

        &:before {
          top: 20px;
        }
      }

      &:last-of-type {
        padding-bottom: 20px;
        border-radius: 0 0 8px 8px;
      }

      &:before {
        content: '';
        width: 16px;
        height: 16px;
        position: absolute;
        right: 18px;
        top: 12px;
        border-radius: ${theme.borderRadius.round};
        background: ${theme.color.emeraldDark};
        border: 2px solid ${theme.color.emeraldDark};
        box-shadow: inset 0 0 0 7px ${theme.color.white};
        transition: box-shadow 0.2s linear;
      }

      &.checked {
        &:before {
          box-shadow: inset 0 0 0 2px ${theme.color.white};
        }

        ${StaleParagraphMedium} {
          font-weight: 700;
        }
      }

      &.disabled {
        background-color: ${theme.color.greyLight_1};
        pointer-events: none;
      }

      ${StaleParagraphMedium} {
        text-indent: 36px;
        color: ${theme.color.dark};
        position: relative;
        max-width: 80%;
        margin-bottom: 8px;
      }

      @media (min-width: ${theme.breakpoint.largeMin}px) {
        cursor: pointer;

        &:hover {
          background: ${theme.color.emeraldLight};
        }
      }
    }

    .icon {
      width: 24px;
      height: 21px;
      position: absolute;
      top: -2px;
      left: 0;

      svg {
        position: absolute;
      }

      .from {
        top: 0;
        left: 0;
      }

      .to {
        bottom: 0;
        right: 0;
      }
    }
  `
);
