import { FC, useMemo } from 'react';
import { Title } from 'components';
import { useQuery } from 'hooks';
import TitleLoader from '../TitleLoader/TitleLoader';
import StepAccountsForm, {
  IOnSaveValues,
  IOnSaveValuesArguments,
} from './components/StepAccountsForm/StepAccountsForm';

import { useTheme } from 'styled-components';
import { useStoreState } from 'state';
import {
  generateCodatBankFeesAccountData,
  generateXeroBankFeesAccountData,
} from 'utils/integrations';
import {
  isCodatAccount,
  isXeroAccount,
  isCodatPlatform,
  isXeroPlatform,
  TPlatformNames,
} from 'types/integrations';
import useIntegrationAccounts from '../../../hooks/useIntegrationAccounts';

export type { IOnSaveValuesArguments };

interface OwnProps {
  onSaveValues: IOnSaveValues;
  onContinue: () => void;
  platformName: TPlatformNames;
}

const StepAccountsFirst: FC<OwnProps> = ({
  onSaveValues,
  onContinue,
  platformName,
}) => {
  const theme = useTheme();
  const URLQuery = useQuery();
  const firstIntegration = URLQuery.get('firstIntegration') === 'true';
  const {
    isLoadingAccounts,
    bankFeesAccounts,
    defaultBankFeesAccount,
  } = useIntegrationAccounts(platformName);

  const isXeroIntegration = isXeroPlatform(platformName);
  const isCodatIntegration = isCodatPlatform(platformName);

  const { xero, codat } = useStoreState(
    ({ UserState }) => UserState.integrationsSummary
  );

  const defaultBankFeeAccount = useMemo(() => {
    if (isXeroIntegration) {
      if (xero?.settings?.bankFeesAccount) {
        return generateXeroBankFeesAccountData(xero?.settings?.bankFeesAccount);
      }
      if (
        firstIntegration &&
        defaultBankFeesAccount &&
        isXeroAccount(defaultBankFeesAccount)
      ) {
        return generateXeroBankFeesAccountData(defaultBankFeesAccount);
      }
      return null;
    } else if (isCodatIntegration) {
      if (codat?.settings?.bankFeesAccount) {
        return generateCodatBankFeesAccountData(
          codat?.settings?.bankFeesAccount
        );
      }
      if (
        firstIntegration &&
        defaultBankFeesAccount &&
        isCodatAccount(defaultBankFeesAccount)
      ) {
        return generateCodatBankFeesAccountData(defaultBankFeesAccount);
      }
      return null;
    }

    return null;
  }, [
    isXeroIntegration,
    isCodatIntegration,
    xero?.settings?.bankFeesAccount,
    firstIntegration,
    defaultBankFeesAccount,
    codat?.settings?.bankFeesAccount,
  ]);

  if (isLoadingAccounts) {
    return <TitleLoader title="Getting expense accounts." />;
  }

  return (
    <>
      <Title mb mbValue={theme.spacing.xl}>
        What activity should we export to {platformName}?
      </Title>

      <StepAccountsForm
        onContinue={onContinue}
        onSaveValues={onSaveValues}
        feesAccounts={bankFeesAccounts}
        defaultBankFeesAccount={defaultBankFeeAccount}
        platformName={platformName}
      />
    </>
  );
};

export default StepAccountsFirst;
