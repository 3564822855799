import { AxiosPrivateFirebaseInstance } from 'settings';
import memoize from 'lodash.memoize';
import { IResponse } from 'types';
import {
  ICodatAccount,
  IIntegrationPermissions,
  TCodatSettingsInput,
} from 'types/integrations';

// accepts paramter so we can break memoization
// if user logs in and out with different accounts
export const getCodatRedirectUri = memoize(async (_: string) => {
  try {
    const { data } = await AxiosPrivateFirebaseInstance.put<{
      data: {
        redirect: string;
      };
    }>('codat/company');

    return data.data.redirect;
  } catch (error) {
    console.warn(error.message);
  }
});

export const queueNewCodatSync = async () => {
  try {
    await AxiosPrivateFirebaseInstance.post<{
      data: {};
    }>('codat/company/data/all');

    return;
  } catch (error) {
    console.warn(error.message);
  }
};

export const getCodatAccounts = async () =>
  AxiosPrivateFirebaseInstance.get<IResponse<Array<ICodatAccount>>>(
    `codat/accounts`
  );

interface ICodatPermissionsPayload {
  permissions: IIntegrationPermissions;
}
export const saveCodatPermissions = async (payload: ICodatPermissionsPayload) =>
  AxiosPrivateFirebaseInstance.post<IResponse<ICodatPermissionsPayload>>(
    `codat/permissions`,
    payload
  );

interface ICodatSettingsPayload {
  settings: TCodatSettingsInput;
}
export const saveCodatSettings = async (payload: ICodatSettingsPayload) => {
  const { settingsType, ...rest } = payload.settings;
  return AxiosPrivateFirebaseInstance.post<IResponse<ICodatSettingsPayload>>(
    `/codat/settings`,
    { settings: { ...rest } }
  );
};
