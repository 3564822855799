import styled, { css } from 'styled-components';

import { StaleParagraphMedium } from 'components';

export const Content = styled.div`
  overflow: hidden;
`;

export const Wrapper = styled.div<{ show: boolean; collapsible?: boolean }>(
  ({ theme, show, collapsible }) => css`
    max-width: 100%;
    margin-top: 13px;

    .accordion-head {
      position: relative;
      cursor: ${collapsible ? 'pointer' : 'default'};
      padding: 4px 0;
      background: none;
      max-width: 100%;

      ${StaleParagraphMedium} {
        margin-bottom: 0;
      }

      .i-arrow {
        width: 24px;
        height: 24px;
        top: 3px;
        right: 0;
        position: absolute;
        transition: ${theme.transition} ${theme.transition} linear;
        transform: ${show && 'rotate(180deg)'};
      }
    }

    ${Content} {
      transition: ${show ? '.4s .4s' : '.8s'} linear;
      max-height: ${show ? '100vh' : '0'};
    }
  `
);
