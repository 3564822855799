import { FC, useState } from 'react';
import { CalendarProps } from 'react-calendar';
import { createPortal } from 'react-dom';
import { IMaskInput, IMask } from 'react-imask';
import dayjs from 'dayjs';
import { PopperProps, usePopper } from 'react-popper';
import OutsideClickHandler from 'react-outside-click-handler';
import Icon from '../Icon/Icon';
import Calendar from '../Calendar/Calendar';
import { DATE_FORMAT } from 'variables';
import { useTheme } from 'styled-components';
import InputBase from '../InputBase/InputBase';
import { IMaskInputProps } from 'react-imask/dist/mixin';

interface OwnProps
  extends Pick<PopperProps<unknown>, 'modifiers' | 'placement' | 'strategy'> {
  id: string;
  type?: HTMLInputElement['type'];
  label: string;
  view?: 'static' | 'moving';
  icon?: string;
  onChange?: (string: string) => any;
  locked?: boolean;
  placeholder?: string;
  error?: string;
  calendarProps?: CalendarProps;
  disabledDates?: string[];
  withCalendar?: boolean;
  isRequired?: boolean;
  defaultValue?: string;
  value: string;
  inputRef?: React.Ref<
    React.ComponentType<
      IMaskInputProps<IMask.AnyMaskedOptions, boolean | 'typed', unknown>
    >
  >;
}

const InputDateUncontrolled: FC<OwnProps> = ({
  id,
  type = 'text',
  locked = false,
  placeholder = '',
  placement = 'auto',
  strategy = 'absolute',
  modifiers = [],
  disabledDates = [],
  calendarProps = {
    minDetail: 'year',
  },
  onChange,
  value,
  inputRef,
  label,
  error,
}) => {
  const theme = useTheme();
  const [show, setShow] = useState(false);

  const [
    referenceElement,
    setReferenceElement,
  ] = useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 8],
        },
      },
      ...modifiers,
    ],
    placement,
    strategy,
  });

  const onSave = (valueToSave: Date | Date[]) => {
    if (!Array.isArray(valueToSave)) {
      onChange?.(dayjs(valueToSave).format(DATE_FORMAT));
    }

    setShow(false);
  };

  const onClose = () => {
    setShow(false);
  };

  const renderContent = () => {
    const {
      defaultActiveStartDate,
      minDate,
      maxDate,
      minDetail,
    } = calendarProps;

    const content = (
      <OutsideClickHandler onOutsideClick={onClose}>
        <div
          ref={setPopperElement}
          style={{ ...styles.popper, zIndex: theme.zIndex.calendar }}
          {...attributes.popper}
        >
          <Calendar
            formatShortWeekday={(_, val) => dayjs(val).format('dd')}
            defaultActiveStartDate={defaultActiveStartDate}
            minDate={minDate}
            maxDate={maxDate}
            minDetail={minDetail}
            nextLabel={<Icon icon="calendar-arrow-next" />}
            prevLabel={<Icon icon="calendar-arrow-prev" />}
            tileDisabled={(val) =>
              disabledDates.includes(dayjs(val.date).format('YYYY-MM-DD'))
            }
            defaultValue={
              value && dayjs(value, DATE_FORMAT).isValid()
                ? dayjs(value, DATE_FORMAT).toDate()
                : undefined
            }
            onSave={onSave}
            onClose={onClose}
          />
        </div>
      </OutsideClickHandler>
    );

    return createPortal(content, document.body);
  };

  return (
    <>
      <InputBase
        error={error}
        label={label}
        icon="calendar-ico"
        onRightButtonClick={() => setShow(true)}
        wrapperRef={setReferenceElement}
        value={value}
        renderInputComponent={({ onFocus, onBlur, isFocused }) => (
          <IMaskInput
            mask={Date}
            inputMode="decimal"
            style={{
              zIndex: 1,
              paddingLeft: 16,
              fontSize: 14,
              fontWeight: 500,
              alignSelf: 'stretch',
              flex: 1,
              fontFamily: 'gilroy, Verdana, sans-serif',
              lineHeight: 52,
              paddingTop: 16,
              maxHeight: 52,
            }}
            value={value}
            format={(date) => dayjs(date).format(DATE_FORMAT)}
            parse={(str) => dayjs(str, DATE_FORMAT).toDate()}
            onAccept={(val) => {
              if (typeof val === 'string' && onChange) {
                onChange(val);
              }
            }}
            lazy={!isFocused}
            autofix
            pattern="d `m `Y"
            blocks={{
              d: {
                mask: IMask.MaskedRange,
                from: 1,
                to: 31,
                maxLength: 2,
                placeholderChar: 'D',
              },
              m: {
                mask: IMask.MaskedRange,
                from: 1,
                to: 12,
                maxLength: 2,
                placeholderChar: 'M',
              },
              Y: {
                mask: IMask.MaskedRange,
                from: 1900,
                to: 9999,
                placeholderChar: 'Y',
              },
            }}
            ref={inputRef}
            id={id}
            type={type}
            placeholder={placeholder}
            autoComplete="new-password"
            disabled={locked}
            onFocus={onFocus}
            onBlur={onBlur}
          />
        )}
      />

      {show && renderContent()}
    </>
  );
};

export default InputDateUncontrolled;
