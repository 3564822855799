import styled from 'styled-components';

export const Wrapper = styled.span`
  position: relative;
`;

export const Content = styled.b`
  position: absolute;
  color: ${({ theme }) => theme.color.red};
  top: -20px;
  right: -10px;
  font-size: 12px;
`;
