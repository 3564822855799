import { FC, useMemo, useEffect } from 'react';
import { ICurrency, IRateContract } from 'types';
import Table, { TableProps } from '../Table/Table';
import { useHistory } from 'react-router-dom';
import { IExternalHedge } from 'types/externalHedges';
import { getCountryCodeByCurrency } from 'utils';
import { useStoreState } from 'state';
import CounterTitle from '../CounterTitle/CounterTitle';
import { useTheme } from 'styled-components';
import _orderBy from 'lodash.orderby';
import PrebookInner from '../PrebookInner/PrebookInner';
import HedgeInner from '../HedgeInner/HedgeInner';
import TransferInner from '../TransferInner/TransferInner';
import useCancelDeletePrebook from 'hooks/useCancelDeletePrebook';
import {
  generatePrebookTableColumns,
  TPrebookTableFilterValue,
  filterTypes,
} from './tableColumnsGenerator';
import useUrlValues from 'hooks/useUrlValues';
import useTableFiltering from 'hooks/useTableFiltering';

interface OwnProps
  extends Pick<TableProps<IRateContract>, 'isVirtualized' | 'onRowClick'> {
  currencyCode?: ICurrency['code'];
  withTitle?: boolean;
}

const PrebookTable: FC<OwnProps> = ({
  isVirtualized,
  currencyCode,
  withTitle,
  onRowClick,
}) => {
  const theme = useTheme();
  const history = useHistory();
  const {
    contractId,
    transferId,
    hedgeId,
    filter: filterFromUrl,
  } = useUrlValues('contractId', 'transferId', 'hedgeId', 'filter');

  const { setFilter, tableRef } = useTableFiltering<
    TPrebookTableFilterValue,
    IRateContract | IExternalHedge
  >({
    filterTypeName: 'filtering',
    initialFilterValue:
      (filterFromUrl as TPrebookTableFilterValue) ?? 'default',
  });

  useEffect(() => {
    setFilter(filterFromUrl as TPrebookTableFilterValue);
  }, [filterFromUrl, setFilter]);

  const renderCancelDeletePrebookPopups = useCancelDeletePrebook();

  const { currencyByCode, currencies } = useStoreState(
    (state) => state.CurrenciesState
  );
  const { rateContracts, rateContractsByCurrency } = useStoreState(
    (state) => state.RateContractsState
  );
  const { externalHedges, externalHedgesByCurrency } = useStoreState(
    (state) => state.ExternalHedgesState
  );

  const tableColumns = useMemo(
    () =>
      generatePrebookTableColumns(
        currencies,
        getCountryCodeByCurrency,
        currencyByCode,
        history
      ),
    [currencies, currencyByCode, history]
  );

  const tableData = useMemo(() => {
    if (currencyCode && currencyCode !== 'all') {
      return [
        ...rateContractsByCurrency(currencyCode),
        ...externalHedgesByCurrency(currencyCode),
      ];
    }
    return [...rateContracts, ...externalHedges];
  }, [
    currencyCode,
    rateContracts,
    rateContractsByCurrency,
    externalHedges,
    externalHedgesByCurrency,
  ]);

  return (
    <>
      {withTitle && (
        <CounterTitle
          mb
          mbValue={theme.spacing.l}
          title="Prebooked"
          count={tableData.length}
        />
      )}

      <Table<IRateContract | IExternalHedge>
        ref={tableRef}
        onRowClick={onRowClick}
        data={_orderBy(tableData, '_created', 'desc')}
        columns={tableColumns}
        defaultRowHeight={70}
        isVirtualized={isVirtualized}
        autoResetGlobalFilter={false}
        autoResetSortBy={false}
        autoResetFilters={false}
        autoResetSelectedRows={false}
        filterTypes={filterTypes}
        initialState={{
          filters: [{ id: 'direction', value: filterFromUrl ?? 'default' }],
        }}
      />

      {contractId && <PrebookInner />}
      {hedgeId && <HedgeInner />}
      {transferId && <TransferInner />}
      {renderCancelDeletePrebookPopups()}
    </>
  );
};

export default PrebookTable;
