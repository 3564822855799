import styled, { css } from 'styled-components';
import { Row, Col, Paragraph } from 'components';

export const RowNameInputs = styled(Row)(
  ({ theme }) => css`
    > div {
      width: 100%;
      max-width: 48%;
    }
  `
);

export const ColRadio = styled(Col)(
  ({ theme }) => css`
    margin-top: 48px;

    ${Paragraph} {
      margin-bottom: 24px;
    }
  `
);
