import React, { FC } from 'react';

import { Wrapper } from './NotificationMessage.styles';
import { NotificationType } from '../Notifications';
import { StaleParagraphMedium } from 'components/shared';

const NotificationMessage: FC<Omit<NotificationType, 'id'>> = ({
  message = '',
  icon = '',
  color = '#121213',
  duration = 3000,
}) => {
  return (
    <Wrapper duration={duration} color={color}>
      <svg>
        <use xlinkHref={`#${icon}`} />
      </svg>

      <StaleParagraphMedium>{message}</StaleParagraphMedium>
    </Wrapper>
  );
};

export default NotificationMessage;
