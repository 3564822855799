import { FC } from 'react';
import { useStoreState } from 'state';
import { Col, Paragraph, Row, StaleLoader } from 'components';
import useUrlValues from 'hooks/useUrlValues';
import DirectionIcon from 'components/shared/DirectionIcon/DirectionIcon';
import { parseIntoCurrencyStringWithSymbol } from 'utils';
import NetworthInfoRow from './components/NetworthInfoRow/NetworthInfoRow';
import { NetWrapper } from './Networth.styles';
import NetworthSection from './components/NetworthSection/NetworthSection';
import { getCashflowsPerCurrencyValues } from 'utils/analysis';

const Networth: FC = () => {
  const { userEntity } = useStoreState((state) => state.UserState);
  const { cashflowsRisks } = useStoreState((state) => state.ReferenceDataState);
  const { currencyByCode } = useStoreState((state) => state.CurrenciesState);
  const { currency: currencyCode } = useUrlValues('currency');
  const currency = currencyByCode(currencyCode);
  const cashflowRisksByCurrency = currencyCode
    ? cashflowsRisks?.currencyRisk[currencyCode]
    : undefined;
  const {
    netExposure,
    netExposureInSellCurrency,
    payables,
    receivables,
    prebooked,
    balances,
    externalBalances,
    externalHedges,
    saleOrders,
    purchaseOrders,
  } = getCashflowsPerCurrencyValues(cashflowRisksByCurrency);

  return (
    <Col alignSelf="stretch" justifyContent="flex-start" flex={0.4}>
      {!cashflowRisksByCurrency ? (
        <StaleLoader size="large" />
      ) : (
        <>
          <NetworthSection
            renderContent={() => (
              <NetWrapper>
                <Row>
                  <Paragraph variant="bold">Net in {currencyCode}</Paragraph>

                  <Row>
                    <DirectionIcon
                      direction={netExposure >= 0 ? 'in' : 'out'}
                    />
                    <Paragraph variant="bold">
                      {parseIntoCurrencyStringWithSymbol(
                        netExposure,
                        currency?.symbol
                      )}
                    </Paragraph>
                  </Row>
                </Row>
                <Row>
                  <Paragraph>
                    Current volume in {userEntity.entityCurrency}
                  </Paragraph>
                  <Paragraph>
                    {parseIntoCurrencyStringWithSymbol(
                      netExposureInSellCurrency,
                      currencyByCode(userEntity.entityCurrency)?.symbol
                    )}
                  </Paragraph>
                </Row>
              </NetWrapper>
            )}
          />

          <NetworthSection
            zIndex={4}
            renderContent={(setIsExpanded, isExpanded) => (
              <NetworthInfoRow
                title="Cash balance"
                amount={balances + externalBalances}
                currencySymbol={currency?.symbol}
                showDirection={false}
                onArrowClick={() => setIsExpanded((prevState) => !prevState)}
                rotateArrow={isExpanded}
              />
            )}
            expansionContent={
              <>
                <NetworthInfoRow
                  title="with HedgeFlows"
                  amount={balances}
                  variant="grey"
                  currencySymbol={currency?.symbol}
                  showDirection={false}
                />
                <NetworthInfoRow
                  title="with 3d parties"
                  amount={externalBalances}
                  variant="grey"
                  currencySymbol={currency?.symbol}
                  showDirection={false}
                />
              </>
            }
          />
          <NetworthSection
            zIndex={3}
            renderContent={(setIsExpanded, isExpanded) => (
              <NetworthInfoRow
                title="Pay / Receive"
                amount={payables + receivables}
                currencySymbol={currency?.symbol}
                onArrowClick={() => setIsExpanded((prevState) => !prevState)}
                rotateArrow={isExpanded}
              />
            )}
            expansionContent={
              <>
                <NetworthInfoRow
                  title="Payables"
                  amount={payables}
                  variant="grey"
                  currencySymbol={currency?.symbol}
                />
                <NetworthInfoRow
                  title="Receivables"
                  amount={receivables}
                  variant="grey"
                  currencySymbol={currency?.symbol}
                />
              </>
            }
          />
          <NetworthSection
            zIndex={2}
            renderContent={(setIsExpanded, isExpanded) => (
              <NetworthInfoRow
                title="Forecasted"
                amount={saleOrders + purchaseOrders}
                currencySymbol={currency?.symbol}
                onArrowClick={() => setIsExpanded((prevState) => !prevState)}
                rotateArrow={isExpanded}
              />
            )}
            expansionContent={
              <>
                <NetworthInfoRow
                  title="Sale Orders"
                  amount={saleOrders}
                  variant="grey"
                  currencySymbol={currency?.symbol}
                />
                <NetworthInfoRow
                  title="Purchase Orders"
                  amount={purchaseOrders}
                  variant="grey"
                  currencySymbol={currency?.symbol}
                />
              </>
            }
          />
          <NetworthSection
            zIndex={1}
            renderContent={(setIsExpanded, isExpanded) => (
              <NetworthInfoRow
                title="FX Hedges"
                amount={prebooked + externalHedges}
                currencySymbol={currency?.symbol}
                onArrowClick={() => setIsExpanded((prevState) => !prevState)}
                rotateArrow={isExpanded}
              />
            )}
            expansionContent={
              <>
                <NetworthInfoRow
                  title="with HedgeFlows"
                  amount={prebooked}
                  variant="grey"
                  currencySymbol={currency?.symbol}
                />
                <NetworthInfoRow
                  title="with 3d parties"
                  amount={externalHedges}
                  variant="grey"
                  currencySymbol={currency?.symbol}
                />
              </>
            }
          />
        </>
      )}
    </Col>
  );
};

export default Networth;
