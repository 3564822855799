import styled, { css } from 'styled-components';

// This is not stale
export const Layout = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

export const StaleContainer = styled.div(
  ({ theme }) => css`
    position: relative;
    margin-left: auto;
    margin-right: auto;
    padding-right: 16px;
    padding-left: 16px;
    max-width: 100%;
    width: 100%;
    z-index: 1;

    @media (min-width: ${theme.breakpoint.smallMin}px) {
      width: 576px;
    }
    @media (min-width: ${theme.breakpoint.mediumMin}px) {
      padding-right: 20px;
      padding-left: 20px;
      width: 768px;
    }
    @media (min-width: ${theme.breakpoint.largeMin}px) {
      width: 1024px;
    }
    @media (min-width: ${theme.breakpoint.extraLargeMin}px) {
      width: 1320px;
    }
  `
);
