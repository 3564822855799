import { FC, useCallback, useMemo } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useStoreState } from 'state';
import { ITransfer } from 'types';
import { generateTransferTableColumns } from './utils';
import Table, { TableProps } from '../Table/Table';

export interface OwnProps
  extends Pick<TableProps<ITransfer>, 'isVirtualized' | 'data'> {
  data: ITransfer[];
}

const TransferTable: FC<OwnProps> = ({ data, isVirtualized }) => {
  const history = useHistory();
  const { url } = useRouteMatch();
  const { transfersByBulkId } = useStoreState((state) => state.TransfersState);
  const { currencyByCode } = useStoreState((state) => state.CurrenciesState);

  const goToTransfer = useCallback(
    (record) =>
      record?.recipient
        ? history.push(`${url}?transferId=${record.id}`)
        : history.push(`${url}?bulkTransferId=${record.id}`),
    [history, url]
  );

  const transferTableColumns = useMemo(
    () =>
      generateTransferTableColumns(
        transfersByBulkId,
        goToTransfer,
        currencyByCode
      ),
    [currencyByCode, goToTransfer, transfersByBulkId]
  );

  return (
    <Table<ITransfer>
      onRowClick={goToTransfer}
      data={data}
      columns={transferTableColumns}
      sortable
      expansionRender={(record) => {
        const relatedTransfers = transfersByBulkId(record.id);

        return (
          relatedTransfers && (
            <Table<ITransfer>
              data={relatedTransfers}
              columns={transferTableColumns}
            />
          )
        );
      }}
      isExpandable={(record) => !!transfersByBulkId(record.id).length}
      isVirtualized={isVirtualized}
      defaultRowHeight={48}
    />
  );
};
export default TransferTable;
