import { InlineLoader, Paragraph, Row, StaleSwitch } from 'components';
import Button from 'components/shared/Button/Button';
import {
  SettingsContent,
  SettingsFooter,
} from 'pages/Settings/Settings.styles';
import { useEffect, useState } from 'react';
import { getPackages, savePackages } from 'services/firebase/entities';
import { useStoreState } from 'state';
import { Notify } from 'utils';
import { useForm, Controller } from 'react-hook-form';
import { TEntityPackageKeys, TEntityPackages } from 'state/user';

const packageKeysToNames: Record<TEntityPackageKeys, string> = {
  automation: 'Payment automation in 30 currencies',
  global: 'Global Business Account',
  fxManagement: 'FX Risk and cost management',
};

const Packages = () => {
  const { entityId } = useStoreState(({ UserState }) => UserState);
  const [isLoadingPackages, setIsLoadingPackages] = useState(false);
  const [errorFetchingPackages, setErrorFetchingPackages] = useState(false);
  const [packages, setPackages] = useState<TEntityPackages>();

  const { control, handleSubmit, reset } = useForm<TEntityPackages>();

  useEffect(() => {
    const getPackagesFromApi = async () => {
      try {
        if (!entityId) {
          return;
        }

        setIsLoadingPackages(true);
        const packages = await getPackages(entityId);
        if (!packages) {
          throw new Error();
        }

        setPackages(packages);
        reset(packages);
      } catch (error) {
        Notify.error(error.message || 'Error getting existing packages');
        setErrorFetchingPackages(true);
      } finally {
        setIsLoadingPackages(false);
      }
    };

    if (entityId) {
      getPackagesFromApi();
    }
  }, [entityId, reset]);

  const onSubmit = async (values: TEntityPackages) => {
    try {
      if (!entityId) {
        return;
      }

      await savePackages(entityId, values);
      Notify.success('Successfully updated subscribed services');
    } catch (error) {
      Notify.error(error.message || 'Error saving packages');
    }
  };

  return (
    <form>
      <SettingsContent>
        {errorFetchingPackages && (
          <Paragraph color="red">
            There was an error fetching your packages.
          </Paragraph>
        )}
        {isLoadingPackages && (
          <Paragraph>
            We are getting your packages <InlineLoader />
          </Paragraph>
        )}
        {!isLoadingPackages &&
          !errorFetchingPackages &&
          !!packages &&
          Object.keys(packages)
            .sort()
            .map((packageKey) => (
              <Row mb>
                {packageKeysToNames[packageKey]}
                <Controller
                  name={packageKey}
                  control={control}
                  defaultValue={packages[packageKey]}
                  render={({ onChange, value }) => (
                    <StaleSwitch
                      id={packageKey}
                      isOn={value}
                      handleToggle={() => onChange(!value)}
                    />
                  )}
                />
              </Row>
            ))}
      </SettingsContent>
      <SettingsFooter>
        <Row>
          <Button
            onClick={handleSubmit(onSubmit)}
            disabled={isLoadingPackages || errorFetchingPackages}
          >
            Save packages
          </Button>
        </Row>
      </SettingsFooter>
    </form>
  );
};

export default Packages;
