import { FC, useEffect, useMemo } from 'react';
import { useStoreState } from 'state';
import Card from 'components/shared/Card/Card.styles';
import { Paragraph, Row, StaleInputSelect, StaleLoader } from 'components';
import useUrlValues from 'hooks/useUrlValues';
import MonthlyBreakdownChartBar from './components/MonthlyBreakdownChartBar/MonthlyBreakdownChartBar';
import { formatValuesPerMonth, formatValuesPerQuarter } from '../../utils';

const MonthlyBreakdown: FC = () => {
  const { currencyByCode } = useStoreState((state) => state.CurrenciesState);
  const { cashflowsRisks } = useStoreState((state) => state.ReferenceDataState);
  const { setUrlValue, currency, period } = useUrlValues('currency', 'period');
  const currencyRiskPerCurrency = currency
    ? cashflowsRisks?.currencyRisk[currency]
    : undefined;

  useEffect(() => {
    if (!period) {
      setUrlValue('period', 'monthly');
    }
  }, [period, setUrlValue]);

  const chartData = useMemo(() => {
    const monthlyData = currencyRiskPerCurrency
      ? formatValuesPerMonth(currencyRiskPerCurrency.perMonth).map((value) => ({
          ...value,
          payables: Math.abs(value.payables),
          saleOrders: Math.abs(value.saleOrders),
          purchaseOrders: Math.abs(value.purchaseOrders),
        }))
      : [];

    if (period === 'quarterly') {
      return formatValuesPerQuarter(monthlyData);
    }

    return monthlyData.slice(-6);
  }, [currencyRiskPerCurrency, period]);

  return (
    <Card alignSelf="stretch" justifyContent="space-between" flex={1}>
      <Row alignItems="flex-start" mb>
        <Paragraph variant="bold">{`${
          period === 'monthly' ? 'Monthly' : 'Quarterly'
        } breakdown`}</Paragraph>
        <StaleInputSelect
          selected={period}
          data={[
            {
              id: 'monthly',
              name: 'Monthly',
              value: 'monthly',
            },
            {
              id: 'quarterly',
              name: 'Quarterly',
              value: 'quarterly',
            },
          ]}
          onSelect={(item) => setUrlValue('period', item.value)}
          id="risks-data-duration"
        />
      </Row>

      <Row flex={1} alignItems="unset">
        {currencyRiskPerCurrency ? (
          <MonthlyBreakdownChartBar
            chartData={chartData}
            currencySymbol={currencyByCode(currency)?.symbol}
          />
        ) : (
          <StaleLoader size="large" />
        )}
      </Row>
    </Card>
  );
};

export default MonthlyBreakdown;
