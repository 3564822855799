import { FC, useState } from 'react';

import { Paragraph, StaleSubTitleBold } from 'components';
import { ReviewTitle } from './Review.styles';
import ExchangeSummary from '../ExchangeSummary/ExchangeSummary';
import { Notify } from 'utils';
import dayjs from 'dayjs';
import { exchangeCurrency } from 'services/firebase/conversions';
import { IConversion } from 'types/conversions';
import InfoBox from 'components/shared/StaleInfoBox/StaleInfoBox';
import {
  FlowContentLeft,
  FlowContentRight,
  FlowStepContent,
  FlowStepFooter,
  FlowStepWrapper,
} from 'components/shared/FlowStep/FlowStep.styles';
import { UseExchangeDetailsReturnValues } from 'pages/CurrencyExchange/hooks/useExchangeDetails';
import Button from 'components/shared/Button/Button';

interface OwnProps {
  onContinue: (conversionData: IConversion) => void;
  savedValues: UseExchangeDetailsReturnValues;
}

const Review: FC<OwnProps> = ({ onContinue, savedValues }) => {
  const [isLoading, setIsLoading] = useState(false);

  const onConfirm = async () => {
    try {
      setIsLoading(true);

      const {
        sellAmountAsNumber: sellAmount,
        buyAmountAsNumber: buyAmount,
        sellCurrency,
        buyCurrency,
        rateToUse,
        rateType,
        selectedRateContract,
        conversionFeeRate,
        invoiceId,
      } = savedValues;

      if (!rateToUse) {
        return;
      }

      const { data } = await exchangeCurrency({
        sellAmount,
        sellCurrency: sellCurrency.code,
        buyCurrency: buyCurrency.code,
        buyAmount,
        rate: rateToUse,
        rateType,
        contractId: selectedRateContract?.id,
        conversionFeeRate,
        invoiceId: invoiceId || undefined,
      });

      if (data.success && data.data) {
        onContinue(data.data);
      } else {
        Notify.error(data.message ?? '');
      }
    } catch (error) {
      console.warn('Failed to exchange currency. Error: ', error);
      Notify.error(error.response?.data?.error);
    } finally {
      setIsLoading(false);
    }
  };

  const {
    sellAmountAsNumber: sellAmount,
    buyAmountAsNumber: buyAmount,
    sellCurrency,
    buyCurrency,
    rateToUse,
    conversionFeeRate,
  } = savedValues;

  return (
    <FlowStepWrapper>
      <FlowStepContent>
        <FlowContentLeft>
          <ReviewTitle variant="h5">Check details</ReviewTitle>
          <Paragraph>
            Review the details of exchange you wish to make.
          </Paragraph>
          <InfoBox
            style={{ marginTop: 14 }}
            text={`Please note that exchanges that are made after ${dayjs(
              savedValues?.conversionDates?.firstConversionCutoffDatetime
            ).format('HH:mm')} are processed by
                10 am on the next working day.`}
          />
        </FlowContentLeft>
        <FlowContentRight>
          <StaleSubTitleBold>Summary</StaleSubTitleBold>
          <ExchangeSummary
            sellCurrency={sellCurrency}
            buyCurrency={buyCurrency}
            buyAmountAsNumber={buyAmount}
            sellAmountAsNumber={sellAmount}
            rate={rateToUse}
            conversionFeeRate={conversionFeeRate}
          />
        </FlowContentRight>
      </FlowStepContent>

      <FlowStepFooter>
        <Button onClick={onConfirm} isLoading={isLoading}>
          Confirm
        </Button>
      </FlowStepFooter>
    </FlowStepWrapper>
  );
};

export default Review;
