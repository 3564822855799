import { useStoreState } from 'state';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { isCodatAccountingIntegration } from 'hooks/useCodatIntegration';
import {
  platformNamesToSettingsPaths,
  codatPlatformNamesToSettingsPaths,
} from 'routes/IntegrationsRouteUtil';

const codatSettingsPaths: string[] = Object.values(
  codatPlatformNamesToSettingsPaths
);

/**
 * Redirect user to codat platform settings page flow if user has just integrated with a Codat platform
 */
const useOnCodatConnection = () => {
  const history = useHistory();
  const location = useLocation();
  const { codat } = useStoreState(
    ({ UserState }) => UserState.integrationsSummary
  );

  useEffect(() => {
    if (
      codat &&
      !codat.settings && // if user has gone through settings once, this will be true
      !!codat.connected &&
      codat.platformName &&
      isCodatAccountingIntegration([codat.platformName]) &&
      !codatSettingsPaths.includes(location.pathname)
    ) {
      history.push(
        `${
          platformNamesToSettingsPaths[codat.platformName]
        }?step=1&firstIntegration=true`
      );
    }
  }, [history, codat, location.pathname]);
};

export default useOnCodatConnection;
