import useXero from '../../../../hooks/useXero';
import useCodatIntegration from '../../../../hooks/useCodatIntegration';
import useOrderWise from '../../../../hooks/useOrderWise';
import {
  IAvailableIntegrationIds,
  TCodatPlatformNames,
} from 'types/integrations';

interface IUseIntegration {
  withStartAction?: boolean;
  variant: IAvailableIntegrationIds;
  codatShortCode?: string;
  platformNames?: TCodatPlatformNames[];
}

const useIntegration = ({
  codatShortCode,
  variant,
  withStartAction = false,
  platformNames = [],
}: IUseIntegration) => {
  const isXero = variant === 'xero';
  const isCodat = variant === 'codat';
  const isOrderWise = variant === 'orderWise';

  const xeroData = useXero({
    retrieveStateToken: withStartAction,
  });
  const codatData = useCodatIntegration({
    codatShortCode,
    platformNames,
  });
  const orderWiseData = useOrderWise();

  return {
    ...(isXero && xeroData),
    ...(isCodat && codatData),
    ...(isOrderWise && orderWiseData),
  };
};

export default useIntegration;
