import { BarCustomLayerProps } from '@nivo/bar';
import { FC } from 'react';
import { useTheme } from 'styled-components';
import { IPnlDataPerMonth } from 'types/analyses';

interface OwnProps {
  data: BarCustomLayerProps<IPnlDataPerMonth>;
  activeMonth: string;
  onClick: (barMonth: string) => void;
}

const ChartReleasedLegendsLayer: FC<OwnProps> = ({ data }) => {
  const { color } = useTheme();

  return (
    <g>
      <rect
        fill={color.green}
        x={data.width / 2 - 235}
        y={data.height - 35}
        style={{
          width: 15,
          height: 5,
        }}
      />

      <line
        stroke={color.black}
        x1={data.width / 2 - 215}
        x2={data.width / 2 - 215}
        y1={data.height - 38}
        y2={data.height - 28}
      />

      <rect
        fill={color.red}
        x={data.width / 2 - 210}
        y={data.height - 35}
        style={{
          width: 15,
          height: 5,
        }}
      />

      <text
        y={data.height - 29}
        x={data.width / 2 - 190}
        style={{
          fontSize: 12,
        }}
      >
        Market impact
      </text>

      <rect
        fill={color.purple}
        x={data.width / 2 - 90}
        y={data.height - 35}
        style={{
          width: 15,
          height: 5,
        }}
      />

      <text
        y={data.height - 29}
        x={data.width / 2 - 70}
        style={{
          fontSize: 12,
        }}
      >
        Transaction costs
      </text>

      <rect
        fill={color.blueLight}
        x={data.width / 2 + 50}
        y={data.height - 34}
        style={{
          width: 20,
          height: 2,
        }}
      />

      <text
        y={data.height - 29}
        x={data.width / 2 + 75}
        style={{
          fontSize: 12,
        }}
      >
        Total costs
      </text>
    </g>
  );
};

export default ChartReleasedLegendsLayer;
