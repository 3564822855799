import { useMemo } from 'react';
import { useStoreState } from 'state';

const useHasBalance = () => {
  const { balances } = useStoreState((state) => state.BalancesState);

  const hasBalance = useMemo(
    () => balances.some((balance) => balance.amount > 0),
    [balances]
  );

  return { hasBalance };
};

export default useHasBalance;
