import { Dispatch, FC, SetStateAction, useEffect } from 'react';

import { Subtitle } from 'components';
import { Wrapper } from './Exchange.styles';
import TransferSummary, {
  TransferSummaryProps,
} from '../TransferSummary/TransferSummary';
import { StepsNotification } from 'components/shared/StaleStepsHorizontal/StaleStepsHorizontal.styles';
import { parseIntoCurrencyString } from 'utils';
import {
  FlowContentLeft,
  FlowContentRight,
  FlowStepContent,
  FlowStepFooter,
  FlowStepWrapper,
} from 'components/shared/FlowStep/FlowStep.styles';
import Button from 'components/shared/Button/Button';

interface OwnProps {
  onContinue: () => void;
  exchangeSummaryProps: TransferSummaryProps;
  setIsFinishedTransfer: Dispatch<SetStateAction<boolean>>;
}

const Exchange: FC<OwnProps> = ({
  onContinue,
  exchangeSummaryProps,
  setIsFinishedTransfer,
}) => {
  const { sellCurrency, sellAmount } = exchangeSummaryProps;

  useEffect(() => {
    setIsFinishedTransfer(true);
  }, [setIsFinishedTransfer]);

  return (
    <FlowStepWrapper>
      <FlowStepContent>
        <FlowContentLeft>
          <Wrapper>
            <StepsNotification>
              <Subtitle color="white">
                {sellCurrency.code}{' '}
                {parseIntoCurrencyString(sellAmount, sellCurrency.precision)}{' '}
                was transferred successfully!
              </Subtitle>
            </StepsNotification>
          </Wrapper>
        </FlowContentLeft>
        <FlowContentRight>
          <Subtitle variant="bold">Summary</Subtitle>

          <TransferSummary {...exchangeSummaryProps} />
        </FlowContentRight>
      </FlowStepContent>

      <FlowStepFooter>
        <Button onClick={onContinue}>Back to Dashboard</Button>
      </FlowStepFooter>
    </FlowStepWrapper>
  );
};

export default Exchange;
