import { AxiosPrivateFirebaseInstance } from 'settings';
import { IResponse } from 'types';
import {
  IConversion,
  IConversionDates,
  IConversionInput,
} from 'types/conversions';

export const exchangeCurrency = async (payload: IConversionInput) => {
  return AxiosPrivateFirebaseInstance.post<IResponse<IConversion>>(
    '/conversions',
    payload
  );
};

export interface GetConversionDatesParams {
  currencyPair: string;
}

export const getConversionDates = async ({
  currencyPair,
}: GetConversionDatesParams) => {
  return AxiosPrivateFirebaseInstance.get<IResponse<IConversionDates>>(
    `/conversions/dates/${currencyPair}`
  );
};
