import { useState } from 'react';
import {
  integrateWithOrderWise,
  IntegrateWithOrderWiseParams,
} from 'services/firebase/integrations';

import { useStoreState } from 'state';
import { Notify } from 'utils';

const useOrderWise = () => {
  const { integrationsSummary } = useStoreState((state) => state.UserState);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoginOrderWise, setIsLoginOrderWise] = useState(false);

  const isIntegrated = integrationsSummary.orderWise?.connected;

  const onLogin = async () => {
    setIsLoginOrderWise(true);
  };

  const onLoginOrderWise = async (values: IntegrateWithOrderWiseParams) => {
    try {
      setIsLoading(true);
      await integrateWithOrderWise(values);
    } catch (error) {
      Notify.error(error?.message);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    onLogin,
    isLoginOrderWise,
    setIsLoginOrderWise,
    onLoginOrderWise,
    isLoading,
    isIntegrated,
  };
};

export default useOrderWise;
