import {
  Col,
  InlineLoader,
  Paragraph,
  RedHint,
  Row,
  StaleLoader,
  StaleSwitch,
  Table,
} from 'components';
import Button from 'components/shared/Button/Button';
import Field from 'components/shared/Field/Field.styles';
import InputDateUncontrolled from 'components/shared/InputDateUncontrolled/InputDateUncontrolled';
import StaleReAuthenticate from 'components/shared/StaleReAuthenticate/StaleReAuthenticate';
import TooManyTimePasswordAttemptsPopup from 'components/shared/TooManyTimePasswordAttemptsPopup/TooManyTimePasswordAttemptsPopup';
import dayjs from 'dayjs';
import { useReAuthenticate } from 'hooks';
import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { Firebase } from 'services';
import {
  confirmPaymentRun,
  updatePaymentRun,
  updatePaymentRunItemSummary,
} from 'services/paymentRuns';
import { useStoreState } from 'state';
import { useTheme } from 'styled-components';
import {
  IPaymentRun,
  IPaymentRunItemSummary,
  IPaymentRunCurrencyTotal,
} from 'types/paymentRuns';
import { getFirstValidDay } from 'utils/dates';
import { errorHandler } from 'utils/errors';
import { DATE_FORMAT } from 'variables';
import { generateAuthoriseTableColumns } from '../../tableColumnsGenerator';
import FundsRequired from '../FundsRequired/FundsRequired';

interface OwnProps {
  paymentRunId: string;
  paymentRunTotals: IPaymentRunCurrencyTotal[];
  isPaymentRunValid: boolean;
  data: IPaymentRunItemSummary[];
  initialDate: string;
  setPaymentRun: Dispatch<SetStateAction<IPaymentRun>>;
  onContinue: () => void;
}

const AuthoriseStep: FC<OwnProps> = ({
  paymentRunId,
  paymentRunTotals,
  data,
  isPaymentRunValid,
  initialDate,
  setPaymentRun,
  onContinue,
}) => {
  const theme = useTheme();
  const history = useHistory();
  const { currencyByCode } = useStoreState(
    ({ CurrenciesState }) => CurrenciesState
  );
  const { entityDefaultCurrency } = useStoreState(({ UserState }) => UserState);
  const { control, errors, handleSubmit, watch } = useForm<{
    password: string;
    date: string;
  }>({
    defaultValues: {
      date: dayjs(initialDate).format(DATE_FORMAT),
    },
  });
  const date = watch('date');
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingBuyFxAll, setIsLoadingBuyFxAll] = useState(false);
  const [isLoadingSubmission, setIsLoadingSubmission] = useState(false);
  const {
    onReAuthenticateClick,
    isTooManyPasswordAttemptsError,
    setIsTooManyPasswordAttemptsError,
  } = useReAuthenticate();
  const [nonTradingDays, setNonTradingDays] = useState<string[]>([]);
  const nonTradingDaysToUse = new Set(nonTradingDays);

  useEffect(() => {
    const getNonTradingDays = async () => {
      const detectedCurrencies = new Set<string>();

      data.forEach(({ invoicesCurrency }) =>
        detectedCurrencies.add(invoicesCurrency)
      );

      const bulkNonTradingDaysResponse = await Promise.all(
        Array.from(detectedCurrencies).map((currencyCode) =>
          Firebase.getNonTradingDays({
            ccyPair: `${currencyCode}${entityDefaultCurrency}`,
          })
        )
      );

      bulkNonTradingDaysResponse.forEach((response) => {
        if (response?.success && response?.data) {
          setNonTradingDays((prevState) => [...prevState, ...response.data]);
        }
      });
    };

    getNonTradingDays();
  }, [data, entityDefaultCurrency]);

  const minDate = getFirstValidDay(
    new Date(),
    2,
    Array.from(nonTradingDaysToUse)
  );

  useEffect(() => {
    if (
      dayjs(date, DATE_FORMAT).format('YYYY-MM-DD') !== initialDate &&
      dayjs(date, DATE_FORMAT).isValid()
    ) {
      const updatePaymentRunPaymentDate = async () => {
        try {
          setIsLoading(true);
          const { data: response } = await updatePaymentRun({
            paymentRunId,
            paymentDate: dayjs(date, DATE_FORMAT).format('YYYY-MM-DD'),
          });

          if (response.data) {
            setPaymentRun(response.data);
          }
        } catch (error) {
          errorHandler(error);
        } finally {
          setIsLoading(false);
        }
      };

      updatePaymentRunPaymentDate();
    }
  }, [date, initialDate, paymentRunId, setPaymentRun]);

  const onEdit = useCallback(
    async (recordId: string, updatedData: IPaymentRunItemSummary) => {
      try {
        setIsLoading(true);
        const { data: response } = await updatePaymentRunItemSummary({
          paymentRunId,
          summaryItemId: recordId,
          buyFx: updatedData.buyFx,
        });

        if (response.data) {
          setPaymentRun(response.data);
        }
      } catch (error) {
        errorHandler(error);
      } finally {
        setIsLoading(false);
      }
    },
    [paymentRunId, setPaymentRun]
  );

  const onBuyFxAll = async () => {
    try {
      const currentValue = !data.some((item) => !item.buyFx);

      setIsLoadingBuyFxAll(true);
      const { data: response } = await updatePaymentRunItemSummary({
        paymentRunId,
        summaryItemId: 'all',
        buyFx: !currentValue,
      });

      if (response.data) {
        setPaymentRun(response.data);
      }
    } catch (error) {
      errorHandler(error);
    } finally {
      setIsLoadingBuyFxAll(false);
    }
  };

  const tableColumns = useMemo(
    () =>
      generateAuthoriseTableColumns({
        onEdit,
        currencyByCode,
        entityDefaultCurrencyCode: entityDefaultCurrency,
        isLoadingBuyFxAll,
      }),
    [currencyByCode, entityDefaultCurrency, isLoadingBuyFxAll, onEdit]
  );

  const onSubmit = async (values) => {
    try {
      setIsLoadingSubmission(true);

      await onReAuthenticateClick(values);

      await confirmPaymentRun({ paymentRunId, paymentRunTotals });

      onContinue();
    } catch (error) {
      errorHandler(error);
    } finally {
      setIsLoadingSubmission(false);
    }
  };

  const canSelectBuyFx = data.some(
    ({ buyFx, canChooseBuyFx }) => canChooseBuyFx
  );

  return (
    <>
      <Col style={{ position: 'relative' }}>
        {(isLoading || isLoadingBuyFxAll || isLoadingSubmission) && (
          <StaleLoader
            withBackdrop
            size="large"
            style={{ position: 'absolute', inset: 0, zIndex: 3 }}
          />
        )}
        <Table<IPaymentRunItemSummary>
          data={data}
          columns={tableColumns}
          isRowDisabled={(record) => !record.valid}
          defaultRowHeight={56}
          renderFooterContent={
            <Row alignItems="flex-end">
              <Row gap={theme.spacing.m} justifyContent="flex-start">
                <form
                  id="payment-run-authorise-form"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <Col alignItems="flex-start">
                    <Row mb>
                      <Field mr>
                        <Controller
                          id="date"
                          name="date"
                          control={control}
                          error={errors?.date?.message}
                          render={({ onChange, value, name }) => {
                            return (
                              <InputDateUncontrolled
                                id={name}
                                view="moving"
                                label="Date to schedule payment"
                                error={errors?.date?.message}
                                value={value}
                                calendarProps={{
                                  defaultActiveStartDate: dayjs(
                                    initialDate
                                  ).toDate(),
                                  minDate,
                                  minDetail: 'month',
                                }}
                                onChange={onChange}
                                disabledDates={Array.from(nonTradingDaysToUse)}
                              />
                            );
                          }}
                        />
                      </Field>
                      {canSelectBuyFx && (
                        <Row>
                          {isLoading || isLoadingBuyFxAll ? (
                            <Row style={{ width: 46 }}>
                              <InlineLoader height="24px" />
                            </Row>
                          ) : (
                            <StaleSwitch
                              id="all-payment-run-summary-items"
                              isOn={!data.some((item) => !item.buyFx)}
                              handleToggle={onBuyFxAll}
                            />
                          )}
                          <Paragraph ml>
                            Book required currency conversions
                          </Paragraph>
                        </Row>
                      )}
                    </Row>

                    {isPaymentRunValid ? (
                      <StaleReAuthenticate
                        control={control}
                        errors={errors}
                        withLabel={false}
                      >
                        <Button type="submit" ml>
                          Authorize payments
                        </Button>
                      </StaleReAuthenticate>
                    ) : (
                      <Button onClick={history.goBack} type="button">
                        Back to review
                      </Button>
                    )}
                  </Col>
                </form>
              </Row>

              <Col
                gap={theme.spacing.xs}
                alignItems="flex-start"
                justifyContent="space-between"
                alignSelf="stretch"
              >
                <FundsRequired paymentRunTotals={paymentRunTotals} />
                <RedHint>
                  You can purchase FX now or anytime prior to the payment date
                </RedHint>
              </Col>
            </Row>
          }
          sortable
        />
      </Col>
      {isTooManyPasswordAttemptsError && (
        <TooManyTimePasswordAttemptsPopup
          onClose={() => setIsTooManyPasswordAttemptsError(false)}
        />
      )}
    </>
  );
};

export default AuthoriseStep;
