import styled, { css } from 'styled-components';
import { Col } from '../Col/Col';
import { Paragraph } from '../Typography/Typography';

interface OwnProps {
  fullWidth?: boolean;
}

export const PlaceholderWrapper = styled(Col)<OwnProps>`
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.color.white};
  border-radius: ${({ theme }) => theme.borderRadius.m};
  border: 1px dashed ${({ theme }) => theme.color.greyLight_1};
  padding: ${({ theme }) => `0 ${theme.spacing.xxl}`};
  box-shadow: ${({ theme }) => theme.shadow};
  min-height: 136px;
  align-self: stretch;
  position: relative;

  ${({ fullWidth }) =>
    !fullWidth &&
    css`
      min-width: 98%;

      @media (min-width: ${({ theme }) => theme.breakpoint.mediumMin}px) {
        max-width: 49%;
        min-width: 388px;
      }

      @media (min-width: ${({ theme }) => theme.breakpoint.extraLargeMin}px) {
        max-width: 32.7%;
      }
    `}
`;

export const PlaceholderParagraph = styled(Paragraph)`
  text-align: center;
`;
