import styled, { css } from 'styled-components';
import { Title, StaleParagraphMedium, Button } from 'components';

const StaleWhatNext = styled.div(
  ({ theme }) => css`
    color: ${theme.color.white};
    background: ${theme.color.dark};
    position: fixed;
    transition: ${theme.transition};
    padding: 16px 16px 40px;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 12px 12px 0 0;

    .close {
      width: 24px;
      height: 24px;
      position: absolute;
      right: 20px;
      top: 16px;
      padding: 0;

      svg {
        width: 100%;
        height: 100%;
        fill: ${theme.color.white};
        transition: ${theme.transition};
      }
    }

    ${Title} {
      margin-bottom: 16px;
    }

    ${StaleParagraphMedium} {
      padding-left: 36px;
      min-height: 24px;
      position: relative;
      margin-bottom: 20px;

      svg {
        position: absolute;
        top: -3px;
        left: 0;
        width: 24px;
        height: 24px;
        fill: ${theme.color.white};
      }
    }

    ${Button} {
      margin-top: 32px;
      border: 1px solid ${theme.color.white};
      background: ${theme.color.dark};
    }
  `
);

export default StaleWhatNext;
