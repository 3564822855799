import { AxiosPrivateFirebaseInstance } from 'settings';
import { IResponse } from 'types';
import {
  IPaymentRunCreateInput,
  IPaymentRunCurrencyTotal,
  IPaymentRun,
} from 'types/paymentRuns';

export const createPaymentRun = async ({
  invoiceIds,
}: IPaymentRunCreateInput) =>
  AxiosPrivateFirebaseInstance.post<IResponse<IPaymentRun>>('/payment_runs', {
    invoiceIds,
  });

interface UpdatePaymentRunItemSummaryParams {
  paymentRunId: string;
  summaryItemId: string;
  buyFx: boolean;
}

export const updatePaymentRunItemSummary = async ({
  paymentRunId,
  summaryItemId,
  buyFx,
}: UpdatePaymentRunItemSummaryParams) =>
  AxiosPrivateFirebaseInstance.put<IResponse<IPaymentRun>>(
    `/payment_runs/${paymentRunId}/summary/${summaryItemId}`,
    {
      buyFx,
    }
  );

interface UpdatePaymentRunTransferParams {
  paymentRunId: string;
  paymentRunTransferId: string;
  reference: string;
}

export const updatePaymentRunTransfer = async ({
  paymentRunId,
  paymentRunTransferId,
  reference,
}: UpdatePaymentRunTransferParams) =>
  AxiosPrivateFirebaseInstance.put<IResponse<IPaymentRun>>(
    `/payment_runs/${paymentRunId}/transfers/${paymentRunTransferId}`,
    {
      reference,
    }
  );
interface DeletePaymentRunTransferParams {
  paymentRunId: string;
  paymentRunTransferId: string;
}

export const deletePaymentRunTransfer = async ({
  paymentRunId,
  paymentRunTransferId,
}: DeletePaymentRunTransferParams) =>
  AxiosPrivateFirebaseInstance.delete<IResponse<IPaymentRun>>(
    `/payment_runs/${paymentRunId}/transfers/${paymentRunTransferId}`
  );

interface DeletePaymentRunInvoiceParams {
  paymentRunId: string;
  paymentRunInvoiceId: string;
}

export const deletePaymentRunInvoice = async ({
  paymentRunId,
  paymentRunInvoiceId,
}: DeletePaymentRunInvoiceParams) =>
  AxiosPrivateFirebaseInstance.delete<IResponse<IPaymentRun>>(
    `/payment_runs/${paymentRunId}/invoices/${paymentRunInvoiceId}`
  );

interface UpdatePaymentRunParams {
  paymentRunId: string;
  paymentDate?: string;
  combine?: boolean;
}

export const updatePaymentRun = async ({
  paymentRunId,
  paymentDate,
  combine,
}: UpdatePaymentRunParams) =>
  AxiosPrivateFirebaseInstance.put<IResponse<IPaymentRun>>(
    `/payment_runs/${paymentRunId}`,
    {
      paymentDate,
      combine,
    }
  );

interface ConfirmPaymentRunParams {
  paymentRunId: string;
  paymentRunTotals: IPaymentRunCurrencyTotal[];
}

export const confirmPaymentRun = async ({
  paymentRunId,
  paymentRunTotals,
}: ConfirmPaymentRunParams) =>
  AxiosPrivateFirebaseInstance.post<IResponse<IPaymentRun>>(
    `/payment_runs/${paymentRunId}/confirm`,
    {
      paymentRunTotals,
    }
  );

interface GetDraftPaymentRunParams {
  entityId: string;
  generate?: boolean;
}

export const getDraftPaymentRun = async ({
  entityId,
  generate = false,
}: GetDraftPaymentRunParams) =>
  AxiosPrivateFirebaseInstance.get<IResponse<IPaymentRun>>(
    `/payment_runs/entity/${entityId}/${generate}`
  );

interface AddInvoicesToPaymentRunParams {
  paymentRunId: string;
  invoiceIds: string[];
}

export const addInvoicesToPaymentRun = async ({
  paymentRunId,
  invoiceIds,
}: AddInvoicesToPaymentRunParams) =>
  AxiosPrivateFirebaseInstance.put<IResponse<IPaymentRun>>(
    `/payment_runs/${paymentRunId}/invoices`,
    {
      invoiceIdsToAdd: invoiceIds,
    }
  );
