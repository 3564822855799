import { FC } from 'react';
import { useTheme } from 'styled-components';
import { Title, StaleLoader } from 'components';
import { useHistory } from 'react-router';
import { getMyClientsLink } from 'utils/links';
import Button from 'components/shared/Button/Button';

interface Props {
  isCompleted: boolean;
  isError: boolean;
  isCreatingEntity: boolean;
  isCanceledFromXero?: boolean;
  companyName?: string;
}

const StepCreatingClient: FC<Props> = ({
  isCompleted,
  isError,
  isCreatingEntity,
  companyName,
  isCanceledFromXero,
}) => {
  const theme = useTheme();
  const history = useHistory();

  return (
    <>
      <Title mb mbValue={theme.spacing.xl}>
        {isCanceledFromXero && 'Connection was cancelled'}
        {isCreatingEntity &&
          'Hold on tight. We are just creating your new Xero Client.'}
        {isError && 'Something went wrong. Please contact support.'}
        {isCompleted &&
          !isError &&
          `Congratulations. We've just set up ${companyName} for you. We will redirect you in 5 seconds...`}
      </Title>
      {isCreatingEntity && <StaleLoader size="large" />}

      {((!isCreatingEntity && !isCompleted) || isCanceledFromXero) && (
        <Button onClick={() => history.push(getMyClientsLink())}>
          Back to clients
        </Button>
      )}
    </>
  );
};

export default StepCreatingClient;
