import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { StaleBtnGroup, StaleField, StalePopup, StaleInput } from 'components';
import { IntegrateWithOrderWiseParams } from 'services/firebase/integrations';
import { ERROR_MESSAGES } from 'variables';
import Button from 'components/shared/Button/Button';

interface OrderWiseLoginProps {
  isIntegrated: boolean;
  onClose: () => void;
  onSubmit?: (values: IntegrateWithOrderWiseParams) => Promise<void>;
}

const OrderWiseLoginPopup: FC<OrderWiseLoginProps> = ({
  isIntegrated,
  onClose,
  onSubmit,
}) => {
  const { control, handleSubmit } = useForm();

  return (
    <StalePopup
      title={`${isIntegrated ? 'ReLogin' : 'Login'} to OrderWise`}
      theme="small"
      width="347px"
      minHeight="auto"
      onClose={onClose}
    >
      <form
        style={{ width: '100%' }}
        onSubmit={onSubmit && handleSubmit(onSubmit)}
      >
        <StaleField>
          <StaleInput
            control={control}
            name="username"
            label="Username"
            view="moving"
            id="username"
            rules={{
              required: ERROR_MESSAGES.requiredField,
            }}
          />
        </StaleField>
        <StaleField>
          <StaleInput
            control={control}
            name="password"
            type="password"
            label="Password"
            view="moving"
            id="password"
            rules={{
              required: ERROR_MESSAGES.requiredField,
            }}
          />
        </StaleField>

        <StaleBtnGroup horizontal container={false}>
          <Button variant="secondary" onClick={onClose}>
            Close
          </Button>
          <Button type="submit">
            {isIntegrated ? 'Reintegrate' : 'Submit'}
          </Button>
        </StaleBtnGroup>
      </form>
    </StalePopup>
  );
};

export default OrderWiseLoginPopup;
