import styled, { css } from 'styled-components';
import { StaleBtnGroup } from 'components';
import { isMobile } from 'react-device-detect';

export const Wrapper = styled.div(
  ({ theme }) => css`
    padding: ${isMobile ? '24px 16px 34px' : '24px 40px 34px'};

    ${StaleBtnGroup} {
      box-shadow: ${isMobile && 'none'};
      padding: 16px 0 0 0;
    }

    .hexagon-list {
      margin-top: 8px;
    }
  `
);
