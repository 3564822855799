import styled, { css } from 'styled-components';
import { StaleSubTitleBold, StaleParagraphMedium } from 'components';
import { isMobile } from 'react-device-detect';

export const Wrapper = styled.div<{ byTime: boolean }>(
  ({ theme, byTime }) => css`
    padding: ${byTime ? '16px 12px' : '9px 12px 16px'};
    border-radius: 8px;
    margin: 0 auto 12px;
    position: ${!byTime ? 'relative' : !isMobile ? 'absolute' : 'fixed'};
    color: ${theme.color.white};
    min-height: 64px;
    display: flex;
    max-width: ${byTime && !isMobile && '640px'};
    flex-direction: column;
    justify-content: center;
    right: ${byTime && '16px'};
    left: ${byTime && '16px'};
    top: ${byTime && '-100vh'};
    z-index: ${byTime && '12'};
    opacity: ${byTime && '0'};

    animation: ${byTime && 'hide 4s'};

    .top {
      display: flex;
      align-items: center;
      margin-bottom: ${!byTime && '6px'};
      padding-right: 30px;

      svg {
        margin: -3px 6px 0 0;
      }
    }

    ${StaleSubTitleBold} {
      font-size: ${byTime && '14px'};
      font-weight: ${byTime && '500'};
    }

    ${StaleParagraphMedium} {
      margin-bottom: 7px;
    }

    a {
      text-decoration: underline;
      font-size: inherit;
      line-height: inherit;
      color: inherit;
      display: inline;
      cursor: pointer;

      &:hover {
        color: inherit;
        text-decoration: none;
      }
    }

    svg {
      width: 24px;
      height: 24px;
    }

    .close {
      position: absolute;
      right: 12px;
      top: 12px;
      width: 24px;
      height: 24px;

      svg {
        fill: ${theme.color.white};
      }
    }

    .row {
      justify-content: space-between;
      align-items: center;
    }

    @keyframes hide {
      0% {
        opacity: 1;
        top: 40px;
      }
      70% {
        opacity: 1;
        top: 40px;
      }
      100% {
        opacity: 0;
        top: 0;
      }
    }
  `
);

export default Wrapper;
