import { Col, Row, StaleParagraphAsLabel, StaleInput } from 'components';
import { StalePasswordField } from './StaleReAuthenticate.styles';
import React from 'react';
import { Control, FieldErrors } from 'react-hook-form';
import { useReAuthenticate } from 'hooks';
import { ERROR_MESSAGES } from 'variables';

interface Props {
  control: Control;
  errors?: FieldErrors<{ password: string }>;
  withLabel?: boolean;
}

const StaleReAuthenticate: React.FC<Props> = ({
  children,
  control,
  errors,
  withLabel = true
}) => {
  const { isPasswordProvider } = useReAuthenticate();

  return (
    <Col>
      {isPasswordProvider && withLabel && (
        <Row>
          <StaleParagraphAsLabel variant="bold">
            Re-enter your password to authorise the transfer
          </StaleParagraphAsLabel>
        </Row>
      )}

      <Row style={{ justifyContent: 'flex-start' }}>
        {isPasswordProvider && (
          <StalePasswordField>
            <StaleInput
              control={control}
              view="moving"
              name="password"
              id="password"
              label="Password"
              type="password"
              defaultValue={''}
              rules={{
                required: ERROR_MESSAGES.requiredField,
              }}
              error={errors?.password?.message}
            />
          </StalePasswordField>
        )}
        {children}
      </Row>
    </Col>
  );
};

export default StaleReAuthenticate;
