import { Subtitle } from '../Typography/Typography';
import { TabItem, TabsWrapper } from './Tabs.styles';

export interface ITab<T> {
  id: T;
  title: string;
}

interface OwnProps<T> {
  setActiveTab: (tab: ITab<T>) => void;
  activeTab: ITab<T>['id'];
  data: ITab<T>[];
}

const Tabs = <T extends string>({
  setActiveTab,
  activeTab,
  data,
}: OwnProps<T>) => (
  <TabsWrapper>
    {data.map((tab) => (
      <TabItem
        key={tab.id}
        active={activeTab === tab.id}
        onClick={() => setActiveTab(tab)}
      >
        <Subtitle color={activeTab === tab.id ? 'dark' : 'greyDark'}>
          {tab.title}
        </Subtitle>
      </TabItem>
    ))}
  </TabsWrapper>
);

export default Tabs;
