import { FC, useState, useEffect } from 'react';
import { StaleSwitch, Subtitle, StaleInputSelect, Row, Col } from 'components';
import { Controller, useForm } from 'react-hook-form';
import { useTheme } from 'styled-components';
import { useStoreState } from 'state';
import {
  IAccountData,
  isCodatPlatform,
  isXeroPlatform,
  isXeroSettings,
  TCodatSettingsInput,
  TPlatformNames,
  TXeroSettingsInput,
} from 'types/integrations';
import {
  saveCodatPermissions,
  saveCodatSettings,
  saveXeroPermissions,
  saveXeroSettings,
} from 'services/firebase';
import { Notify } from 'utils';
import Field from '../../../Field/Field.styles';
import { ICurrencyInput } from 'types';
import Button from 'components/shared/Button/Button';

interface Inputs {
  bankFeesAccount: IAccountData | null;
}

export interface IOnSaveValuesArguments extends Inputs {
  invoicesAndBills: boolean;
  paymentsAndConversions: true;
  paymentFees: boolean;
  withBankFees?: boolean;
  withExistingAccount?: boolean;
  mainSellCurrencyAccount?: IAccountData | null;
  createNewAccount?: boolean;
  newAccountCurrencies?: ICurrencyInput[];
}
export interface IOnSaveValues {
  (data: IOnSaveValuesArguments): void;
}

export interface StepAccountsFormProps {
  onContinue: () => void;
  onSaveValues: IOnSaveValues;
  feesAccounts: IAccountData[];
  defaultBankFeesAccount: IAccountData | null;
  platformName: TPlatformNames;
}

const StepAccountsForm: FC<StepAccountsFormProps> = ({
  onContinue,
  onSaveValues,
  feesAccounts,
  defaultBankFeesAccount,
  platformName,
}) => {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const [invoicesAndBills, setInvoicesAndBills] = useState(true);
  const [paymentsAndConversions, setPaymentsAndConversions] = useState(true);
  const [paymentFees, setPaymentFees] = useState(true);

  const { xero, codat } = useStoreState(
    ({ UserState }) => UserState.integrationsSummary
  );

  const isXeroIntegration = isXeroPlatform(platformName);
  const isCodatIntegration = isCodatPlatform(platformName);

  const savedXeroSettings = xero?.settings;
  const savedCodatSettings = codat?.settings;

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm<Inputs>({
    mode: 'all',
    defaultValues: {
      bankFeesAccount: defaultBankFeesAccount,
    },
  });

  useEffect(() => {
    if (!paymentFees) {
      setValue('bankFeesAccount', null);
    }
  }, [paymentFees, setValue]);

  const onSubmit = async (values: Inputs) => {
    try {
      if (!isCodatIntegration && !isXeroIntegration) {
        console.error('attempting to save accounts for unsupported platform');
        Notify.error(
          'Attempting to save accounts for unsupported platform. Please contact support'
        );
        return;
      }

      if (paymentsAndConversions) {
        onSaveValues({
          ...values,
          invoicesAndBills,
          paymentsAndConversions,
          paymentFees,
        });
        return;
      }

      setIsLoading(true);

      const permissions = {
        global: false,
        invoices: invoicesAndBills,
        payments: !!paymentsAndConversions,
        purchaseOrders: invoicesAndBills,
        bills: invoicesAndBills,
        bankFees: !!values.bankFeesAccount,
        accounts: !!paymentsAndConversions,
      };

      const settings = {
        ...(isCodatIntegration ? savedCodatSettings : savedXeroSettings),
        settingsType: isCodatIntegration ? 'Codat' : 'Xero',
        bankFeesAccount: values.bankFeesAccount?.value || null,
      } as TXeroSettingsInput | TCodatSettingsInput;

      const [permissionsResponse, settingsResponse] = isXeroSettings(settings)
        ? await Promise.all([
            saveXeroPermissions({ permissions }),
            saveXeroSettings({ settings }),
          ])
        : await Promise.all([
            saveCodatPermissions({ permissions }),
            saveCodatSettings({ settings }),
          ]);

      if (permissionsResponse.data?.success && settingsResponse.data?.success) {
        onContinue();
      } else {
        Notify.error(
          permissionsResponse?.data?.message ||
            settingsResponse?.data?.message ||
            ''
        );
        setIsLoading(false);
      }
    } catch (error) {
      Notify.error(error.message);
      setIsLoading(false);
    }
  };

  return (
    <form id="step-accounts-form" onSubmit={handleSubmit(onSubmit)}>
      <Row mtValue={theme.spacing.xl} mt justifyContent="flex-start">
        <StaleSwitch
          id="invoicesAndBills"
          isOn={invoicesAndBills}
          handleToggle={() => setInvoicesAndBills(!invoicesAndBills)}
        />
        <Subtitle ml>
          Update purchase orders, invoices and bills with prebooked exchange
          rates
        </Subtitle>
      </Row>

      <Row mtValue={theme.spacing.xl} mt justifyContent="flex-start">
        <StaleSwitch
          id="paymentsAndConversions"
          isOn={paymentsAndConversions}
          handleToggle={() =>
            setPaymentsAndConversions(!paymentsAndConversions)
          }
        />
        <Subtitle ml>
          Automatically record processed payments and conversions in{' '}
          {platformName}
        </Subtitle>
      </Row>

      <Row mtValue={theme.spacing.xl} mt mb justifyContent="flex-start">
        <StaleSwitch
          id="paymentFees"
          isOn={paymentFees}
          handleToggle={() => setPaymentFees(!paymentFees)}
        />
        <Subtitle ml>
          Record payment fees (e.g. SWIFT fees) as separate expenses to:
        </Subtitle>
      </Row>
      <Col flex={1}>
        <Field fluid>
          <Controller
            id="bankFeesAccount"
            name="bankFeesAccount"
            control={control}
            defaultValue={null}
            rules={{
              validate: (value: IAccountData | null) => {
                if (paymentFees && !value) {
                  return 'Choose expense account';
                }
                return true;
              },
            }}
            render={({ onChange, value, name }) => {
              return (
                <StaleInputSelect
                  id={name}
                  name={name}
                  label="Expense account"
                  view="moving"
                  data={feesAccounts}
                  selected={value}
                  onSelect={(item) => {
                    onChange(item);
                  }}
                  strategy="fixed"
                  disabled={!paymentFees}
                  // @ts-ignore
                  error={errors.bankFeesAccount?.message}
                  style={{ width: '100%' }}
                />
              );
            }}
          />
        </Field>
      </Col>
      <Button
        isLoading={isLoading}
        mt
        mb
        mlValue={theme.spacing.xl}
        mrValue={theme.spacing.xl}
        type="submit"
      >
        Confirm
      </Button>
    </form>
  );
};

export default StepAccountsForm;
