import { FC, useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';

import {
  Button,
  StaleBtnGroup,
  StaleTitleH5,
  StaleParagraphMedium,
  StaleRadioButton,
  StaleInputFile,
  ButtonStyleLink,
  StaleTitleH1,
  StaleLoader,
  Row,
} from 'components';
import { SignUpContent } from '..';
import { isMobile } from 'react-device-detect';
import { Firebase } from 'services';
import { useStoreActions, useStoreState } from 'state';
import { asyncForEach, Notify } from 'utils';
import { useTheme } from 'styled-components';

type Inputs = {
  files: {
    id: string;
    file: File;
  }[];
};

type DownloadFileInfo = {
  id: string;
  name: string;
  downloadUrl: string;
};

const StepFive: FC = () => {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const [downloadUrls, setDownloadUrls] = useState<DownloadFileInfo[]>([]);
  const [uploadingFilesIds, setUploadingFilesIds] = useState<string[]>([]);
  const [failedUploadFilesIds, setFailedUploadFilesIds] = useState<string[]>(
    []
  );

  const { userId, entityId } = useStoreState((state) => state.UserState);
  const { getUserEntity } = useStoreActions((actions) => actions.UserState);
  const { control, handleSubmit, watch, setValue } = useForm<Inputs>();

  const canProvideFiles = watch('canProvideFiles');
  const canReceivePayments = watch('canReceivePayments');
  const files = watch('files');

  useEffect(() => {
    const filesToUpload = files?.filter(
      (item) =>
        !downloadUrls.find(
          (uploadedItem) =>
            uploadedItem.id === item.id ||
            uploadedItem.downloadUrl.includes(item.file.name)
        )
    );

    if (userId && filesToUpload?.length) {
      const uploadFiles = async (filesToUpload) => {
        const urlsToDownload: any[] = [];

        setUploadingFilesIds(filesToUpload.map((item) => item.id));

        await asyncForEach(filesToUpload, async (itemFile) => {
          try {
            if (!entityId) {
              return;
            }

            const data = await Firebase.uploadCompanyFile({
              userId,
              entityId,
              fileToUpload: itemFile.file,
            });

            if (data) {
              urlsToDownload.push({
                id: itemFile.id,
                name: itemFile.file.name,
                downloadUrl: data,
              });

              setFailedUploadFilesIds((prevState) =>
                prevState.filter((item) => item !== itemFile.id)
              );
              setUploadingFilesIds((prevState) =>
                prevState.filter((item) => item !== itemFile.id)
              );
            }
          } catch (error) {
            setFailedUploadFilesIds((prevState) => [...prevState, itemFile.id]);
          }
        });

        setDownloadUrls((prevState) => [...prevState, ...urlsToDownload]);
      };

      uploadFiles(filesToUpload);
    }
  }, [files, userId, entityId, downloadUrls]);

  const onRemoveFile = async (file) => {
    if (!userId || !entityId) return;

    await Firebase.removeCompanyFile({
      userId,
      entityId,
      filename: file.file.name,
    });

    setValue(
      'files',
      files.filter((item) => item.id !== file.id)
    );

    setDownloadUrls((prevState) =>
      prevState.filter((item) => item.id !== file.id)
    );
  };

  const onSubmit = async (values) => {
    setIsLoading(true);

    const response = await Firebase.updateCompanyFilesStatus({
      invoicesAndReceivesPayments: values.canReceivePayments,
      canProvideInvoicesAndBankStatements: values.canProvideFiles,
      fileDetails: downloadUrls.map((item) => ({
        name: item.name,
        location: item.downloadUrl,
      })),
    });

    setIsLoading(false);

    if (response?.success && entityId) {
      await getUserEntity({
        entityId,
      });
    } else {
      Notify.error(response?.message ?? '');
    }
  };

  return (
    <>
      <SignUpContent>
        {!isMobile && (
          <ButtonStyleLink className="cross">
            <svg width="24" height="24">
              <use xlinkHref="#cross-ico" />
            </svg>
          </ButtonStyleLink>
        )}

        <div className="overflow">
          <div className="block">
            {isMobile ? (
              <StaleTitleH5>Please answer questions below</StaleTitleH5>
            ) : (
              <StaleTitleH1>Please answer questions below</StaleTitleH1>
            )}

            <form>
              <div className="quiz">
                <StaleParagraphMedium>
                  We are required to check that payments you plan to make or
                  collect come from legitimate sources and commercial purposes
                  only. Please answer questions below:
                </StaleParagraphMedium>
                <br />
                <StaleParagraphMedium>
                  Do you invoice your customers and receive payments for goods
                  or services in the UK or abroad?
                </StaleParagraphMedium>

                <Row mtValue={theme.spacing.xs} mt>
                  <Controller
                    name="canReceivePayments"
                    control={control}
                    defaultValue={null}
                    render={({ name, onChange }) => (
                      <StaleRadioButton
                        onChange={(item) => onChange(item.value)}
                        list={[
                          {
                            id: 'invoice-yes',
                            value: true,
                            checked: canReceivePayments,
                            name: <p>Yes</p>,
                          },
                          {
                            id: 'invoice-no',
                            value: false,
                            checked: canReceivePayments === false,
                            name: <p>No</p>,
                          },
                        ]}
                        name={name}
                        cardCol
                      />
                    )}
                  />
                </Row>
              </div>

              <div className="quiz">
                <StaleParagraphMedium>
                  Can you provide a copy of a recent invoice to a customer and a
                  bank statement showing the receipt of payment for such
                  invoice?
                </StaleParagraphMedium>

                <Row mtValue={theme.spacing.xs} mt>
                  <Controller
                    name="canProvideFiles"
                    control={control}
                    defaultValue={null}
                    render={({ name, onChange }) => (
                      <StaleRadioButton
                        name={name}
                        onChange={(item) => onChange(item.value)}
                        list={[
                          {
                            id: 'provide-yes',
                            value: true,
                            checked: canProvideFiles,
                            name: <p>Yes</p>,
                          },
                          {
                            id: 'provide-no',
                            value: false,
                            checked: canProvideFiles === false,
                            name: <p>No</p>,
                          },
                        ]}
                        cardCol
                      />
                    )}
                  />
                </Row>
              </div>

              {canProvideFiles && (
                <div className="quiz">
                  <StaleParagraphMedium>
                    Please upload Recent Invoice and Bank Statement{' '}
                  </StaleParagraphMedium>

                  <StaleParagraphMedium
                    style={{
                      fontStyle: 'italic',
                      fontSize: 12,
                    }}
                  >
                    (Allowed file types are PDF, JPG, JPEG, PNG, XLS, XLSX, DOC,
                    DOCX, CSV, ODS and ODT)
                  </StaleParagraphMedium>

                  <div className="field">
                    <Controller
                      control={control}
                      name="files"
                      defaultValue={[]}
                      render={({ onChange, value }) => (
                        <StaleInputFile
                          accept=".pdf, .jpg, .jpeg, .png, .xls, .xlsx, .doc, .docx, .ods, .odt, .csv"
                          uploadingFilesIds={uploadingFilesIds}
                          failedUploadFilesIds={failedUploadFilesIds}
                          files={value}
                          onChange={(values) => {
                            const newValues = values.filter(
                              (item) =>
                                !downloadUrls.find((existingItem) =>
                                  existingItem.downloadUrl.includes(
                                    item.file.name
                                  )
                                )
                            );

                            onChange([...newValues, ...value]);
                          }}
                          onRemoveFile={onRemoveFile}
                        />
                      )}
                    />
                  </div>
                </div>
              )}
            </form>

            {!isMobile && (
              <StaleBtnGroup>
                <Button disabled={isLoading} onClick={handleSubmit(onSubmit)}>
                  Continue
                  {isLoading && <StaleLoader />}
                </Button>
              </StaleBtnGroup>
            )}
          </div>
        </div>
      </SignUpContent>

      {isMobile && (
        <StaleBtnGroup>
          <Button disabled={isLoading} onClick={handleSubmit(onSubmit)}>
            Continue
            {isLoading && <StaleLoader />}
          </Button>
        </StaleBtnGroup>
      )}
    </>
  );
};

export default StepFive;
