import styled from 'styled-components';
import { spacingCss, SpacingCssProps } from '../Spacing/Spacing.styles';

export const StyledInlineLoader = styled.svg.attrs<SpacingCssProps>(
    ({ theme, mbValue, mlValue, mtValue, mrValue }) => ({
      mbValue: mbValue || theme.spacing.s,
      mtValue: mtValue || theme.spacing.s,
      mrValue: mrValue || theme.spacing.s,
      mlValue: mlValue || theme.spacing.s,
    })
  )`
  @keyframes loading {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  animation: loading 1.2s linear infinite;

  ${spacingCss}
`;
