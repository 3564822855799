import { ComputedBarDatum } from '@nivo/bar';
import { Col, Paragraph } from 'components';
import { parseIntoCurrencyStringWithSymbol } from 'utils';
import ChartBarRealised from '../ChartBarRealised/ChartBarRealised';
import ChartTooltip from '../ChartTooltip/ChartTooltip';
import useChartTooltip from 'pages/Reports/hooks/useChartTooltip';
import ChartBarsGroupWrapper from '../ChartBarsGroupWrapper/ChartBarsGroupWrapper.styles';

const ChartBarsGroup = <T extends any>({
  groupId,
  barsGroup,
  barExtraProps,
  onClick,
}: {
  groupId: string;
  barsGroup: ComputedBarDatum<T>[];
  barExtraProps: any;
  onClick: any;
}) => {
  const { tooltipRef, ...mouseEvents } = useChartTooltip();

  return (
    <ChartBarsGroupWrapper onClick={() => onClick(groupId)} {...mouseEvents}>
      {barsGroup.map((bar) => (
        <ChartBarRealised
          key={bar.data.id}
          bar={bar as any}
          activeMonth={barExtraProps.activeMonth}
        />
      ))}

      <ChartTooltip wrapperRef={tooltipRef}>
        <Col>
          <Paragraph color="white">{`Realised impact: ${parseIntoCurrencyStringWithSymbol(
            barsGroup[0].data.value,
            '£',
            0
          )}`}</Paragraph>
          <Paragraph color="white">{`Transaction costs: ${parseIntoCurrencyStringWithSymbol(
            barsGroup[1].data.value,
            '£',
            0
          )}`}</Paragraph>
          <Paragraph color="white">{`Total: ${parseIntoCurrencyStringWithSymbol(
            (barsGroup[1].data.value ?? 0) + (barsGroup[0].data.value ?? 0),
            '£',
            0
          )}`}</Paragraph>
        </Col>
      </ChartTooltip>
    </ChartBarsGroupWrapper>
  );
};

export default ChartBarsGroup;
