import { FC } from 'react';
import Slider from 'react-slick';
import { StaleCarouselWrapper } from './StaleCarousel.styles';
import { Subtitle, Title } from '../Typography/Typography';
import { Col } from '../Col/Col';

const SLIDER_DATA = [
  {
    id: 0,
    title: 'Prebook exchange',
    text:
      'Book your exchange in advance with a small prepayment and save time and money.',
    icon: 'prebook',
  },
  {
    id: 1,
    title: 'Follow currencies',
    text:
      'Add a currency pair to follow their rate changes. It may help you to pre-book a rate or make transfers when the rate is suitable.',
    icon: 'rates',
  },
  {
    id: 2,
    title: 'Transfer money',
    text: 'Pay securely in 38 currencies using current or prebooked rates.',
    icon: 'transfer',
  },
];

export const SLIDER_OPTIONS = {
  speed: 400,
  slidesToShow: 1,
  fade: true,
  arrows: false,
  dots: true,
  autoplay: true,
  autoplaySpeed: 3000,
};

export interface ISliderData {
  id: number;
  icon: string;
  title: string;
  text: string;
}

interface StaleCarouselProps {
  data?: ISliderData[];
}

const StaleCarousel: FC<StaleCarouselProps> = ({ data = SLIDER_DATA }) => {
  return (
    <StaleCarouselWrapper>
      <Slider {...SLIDER_OPTIONS}>
        {data.map((elem) => (
          <div key={elem.id}>
            <Col mb alignItems="center">
              <svg width="200px" height="200px" className="icon">
                <use xlinkHref={`#${elem.icon}`} />
              </svg>

              <Title mb color="white" variant="h3">
                {elem.title}
              </Title>
              <Subtitle color="greyLight_1">{elem.text}</Subtitle>
            </Col>
          </div>
        ))}
      </Slider>
    </StaleCarouselWrapper>
  );
};

export default StaleCarousel;
