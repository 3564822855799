import { action, Action, computed, Computed, thunk, Thunk } from 'easy-peasy';
import { Firebase } from 'services';
import { IBulkPayment } from 'types/bulkPayments';

export interface BulkPaymentsStateModel {
  bulkPayments: IBulkPayment[];
  bulkPaymentById: Computed<
    BulkPaymentsStateModel,
    (id: string) => IBulkPayment | null
  >;
  setState: Action<BulkPaymentsStateModel, [string, IBulkPayment[]]>;
  // TODO: remove either bulkTransferById or bulkPaymentById, seems to be the same
  bulkTransferById: Computed<
    BulkPaymentsStateModel,
    (bulkPaymentId: string) => IBulkPayment | null
  >;
  subscribeToBulkPayments: Thunk<
    BulkPaymentsStateModel,
    Omit<Firebase.SubscribeToTransfersParams, 'callback'>,
    null,
    object,
    (() => void) | undefined
  >;
}

export const BulkPaymentsState: BulkPaymentsStateModel = {
  bulkPayments: [],
  bulkPaymentById: computed(
    [(state) => state.bulkPayments],
    (bulkPayments) => (id) =>
      bulkPayments.find((item) => item.id === id) || null
  ),
  setState: action((state, payload) => {
    const [prop, to] = payload;
    state[prop] = to;
  }),
  bulkTransferById: computed(
    [(state) => state.bulkPayments],
    (bulkPayments) => (bulkId) =>
      bulkPayments.find((item) => item.id === bulkId) || null
  ),
  subscribeToBulkPayments: thunk(({ setState }, payload) => {
    const subscriber = Firebase.subscribeToBulkPayments({
      ...payload,
      callback: (bulkPayments) => setState(['bulkPayments', bulkPayments]),
    });

    return subscriber;
  }),
};
