import React from 'react';
import { Paragraph } from 'components';
import { useHistory } from 'react-router';
import { parseRateWithPrecision } from 'utils';
import { IInvoice } from 'types';
import Button from 'components/shared/Button/Button';

interface TransferOrPrebookInvoiceRateProps {
  rate: number;
  currency: string;
  rateType: 'prebook' | 'transfer';
  link: string;
  invoiceType: IInvoice['type'];
}

const TransferOrPrebookInvoiceRate: React.FC<TransferOrPrebookInvoiceRateProps> = ({
  rate,
  currency,
  rateType,
  link,
  invoiceType,
}) => {
  const history = useHistory();

  return (
    <>
      <div
        className="row-info"
        style={{
          marginBottom: 4,
        }}
      >
        <Paragraph>
          {`${rateType === 'prebook' ? 'Prebooked' : 'Transfer'} rate: 1 ${
            // TODO: Replace GBP with entity default currency when we have it
            invoiceType === 'Payable' ? 'GBP' : currency
          } = ${parseRateWithPrecision(rate)} ${
            invoiceType === 'Payable' ? currency : 'GBP'
          }`}
        </Paragraph>

        <svg
          width="18px"
          height="18px"
          fill="#E87C53"
          style={{ marginLeft: '6px' }}
        >
          <use xlinkHref="#lock-ico" />
        </svg>
      </div>

      <Button
        variant="link"
        onClick={() => {
          history.push(link);
        }}
        mb
      >
        View {`${rateType === 'prebook' ? 'prebooking' : 'transfer'}`}
      </Button>
    </>
  );
};

export default TransferOrPrebookInvoiceRate;
