import { FC } from 'react';
import { DefaultTheme } from 'styled-components';
import { Paragraph } from '../Typography/Typography';
import { NumberCircleWrapper } from './NumberCircle.styles';

interface OwnProps {
  value?: number;
  background?: keyof DefaultTheme['color'];
  color?: keyof DefaultTheme['color'];
}

const NumberCircle: FC<OwnProps> = ({ value, background, color = 'black' }) => {
  if (value === undefined) {
    return null;
  }

  return (
    <NumberCircleWrapper background={background}>
      <Paragraph color={color}>{value}</Paragraph>
    </NumberCircleWrapper>
  );
};

export default NumberCircle;
