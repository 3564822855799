import styled from 'styled-components';

export const ChartTooltipWrapper = styled.div`
  position: absolute;
  background-color: ${({ theme }) => theme.color.dark};
  box-shadow: ${({ theme }) => theme.shadow};
  padding: ${({ theme }) => theme.spacing.xs};
  border-radius: ${({ theme }) => theme.borderRadius.s};
  display: none;
`;
