import { useMemo } from 'react';
import { useStoreState } from 'state';
import { transformCurrencyToSelectOption } from 'utils';
import _orderBy from 'lodash.orderby';
import {
  IXeroSettings,
  ICodatSettings,
  isXeroAccount,
} from 'types/integrations';

const useIntegrationDefaultValues = (
  savedSettings?: IXeroSettings | ICodatSettings | null
) => {
  const { currencies } = useStoreState((state) => state.CurrenciesState);

  const defaultSellCurrencyAccount = useMemo(() => {
    if (savedSettings?.mainSellCurrencyAccount) {
      let nameValue: string;
      if (isXeroAccount(savedSettings?.mainSellCurrencyAccount)) {
        const { code, name } = savedSettings.mainSellCurrencyAccount;
        nameValue = `${name} (${code})`;
      } else {
        nameValue = savedSettings?.mainSellCurrencyAccount?.name;
      }

      return {
        id: nameValue,
        value: savedSettings.mainSellCurrencyAccount,
        name: nameValue,
      };
    }

    return null;
  }, [savedSettings?.mainSellCurrencyAccount]);

  const defaultCurrencies = useMemo(() => {
    const currency = currencies.find((item) => item.code === 'GBP');
    return currency ? [transformCurrencyToSelectOption(currency)] : [];
  }, [currencies]);

  const parsedCurrencies = useMemo(
    () => _orderBy(currencies.map(transformCurrencyToSelectOption), 'label'),
    [currencies]
  );

  return {
    defaultSellCurrencyAccount,
    defaultCurrencies,
    parsedCurrencies,
  };
};

export default useIntegrationDefaultValues;
