import { Fragment, PropsWithChildren } from 'react';
import { Row } from 'react-table';

import { TableProps } from './Table';
import { TableDataCell, TableRow } from './Table.styles';

interface OwnProps<T extends object>
  extends Pick<
    TableProps<T>,
    'isRowDisabled' | 'onRowClick' | 'expansionRender'
  > {
  rowHeight: number;
  row: Row<T>;
  prepareRow: (row: Row<T>) => void;
}

const NormalRow = <T extends object>({
  row,
  rowHeight,
  expansionRender,
  prepareRow,
  isRowDisabled,
  onRowClick,
}: PropsWithChildren<OwnProps<T>>) => {
  prepareRow(row);

  const isDisabled = isRowDisabled?.(row.original);

  return (
    <Fragment key={row.getRowProps().key}>
      <TableRow
        role="row"
        onClick={() => onRowClick?.(row.original)}
        disabled={isDisabled}
        isClickable={!!onRowClick}
        height={rowHeight}
      >
        {row.cells.map((cell) => {
          const isExpanderCell = cell.column.id === 'expander';
          const isSelectableCell = cell.column.id === 'selection';

          return (
            <TableDataCell
              isExpanderCell={isExpanderCell}
              isSelectableCell={isSelectableCell}
              {...cell.getCellProps()}
            >
              {cell.render('Cell')}
            </TableDataCell>
          );
        })}
      </TableRow>

      {row.isExpanded && (
        <TableRow role="row">
          {row.isExpanded && expansionRender?.(row.original)}
        </TableRow>
      )}
    </Fragment>
  );
};

export default NormalRow;
