import { ComputedBarDatum } from '@nivo/bar';
import { FC } from 'react';
import { useTheme } from 'styled-components';
import { IPnlDataPerMonth } from 'types/analyses';
import ChartRect from '../ChartRect/ChartRect.styles';

interface OwnProps {
  bar: ComputedBarDatum<IPnlDataPerMonth>;
  activeMonth: string;
}

const ChartBarMarketImpact: FC<OwnProps> = ({ bar, activeMonth }) => {
  const barData = bar.data;
  const { color } = useTheme();

  const isActive = barData?.indexValue === activeMonth;
  const getColor = () => {
    if (!barData.value) {
      return;
    }

    if (barData.id === 'fxImpactForUnpaid') {
      return color.purple;
    }

    return color.grey;
  };

  return (
    <>
      <ChartRect
        active={isActive}
        fill={getColor()}
        x={bar.x}
        y={bar.y}
        style={{
          width: bar.width,
          height: bar.height,
        }}
      />
    </>
  );
};

export default ChartBarMarketImpact;
