import { Row } from 'components';
import styled from 'styled-components';

export const Wrapper = styled(Row)`
  width: 100%;
  margin-bottom: 40px;
  padding-bottom: 8px;
  align-items: flex-start;
  justify-content: flex-start;
`;
