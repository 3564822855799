import { FC, Dispatch, SetStateAction, useMemo } from 'react';
import { ContextMenu } from 'components';
import dayjs from 'dayjs';
import { IInvoice, CONTRACT_STATUS, INVOICE_STATUSES } from 'types';
import { useStoreActions, useStoreState } from 'state';

import {
  isReceivableInvoice,
  isInvoiceDisabled,
  getInvoiceTransferLink,
  getInvoicePrebookLink,
  isInvoiceCanBePaid,
  isPayableInvoice,
  getInvoiceDaysLeft,
  isInvoiceFromXero,
  isInvoiceNotPaid,
  isInvoiceDeletable,
  isInvoiceTrackable,
} from 'utils/invoices';
import { useHistory } from 'react-router-dom';
import useInvoiceRecord from 'pages/Invoices/hooks/useInvoiceRecord';
import useXero from 'hooks/useXero';
import { getLink } from 'utils/links';
import useInvoicesApprovalStatus from 'hooks/useInvoiceApprovalStatus';

interface OwnProps {
  record: IInvoice;
  setInvoiceDecide: Dispatch<SetStateAction<IInvoice>>;
  setCancelPrebookInvoice: Dispatch<SetStateAction<IInvoice>>;
  setExistingPrebookInvoice: Dispatch<SetStateAction<IInvoice>>;
  setShowAllDecideFields: Dispatch<SetStateAction<boolean>>;
  setShowInvoiceDelete: Dispatch<SetStateAction<IInvoice>>;
  setShowPurchaseOrderPaymentsOnInvoice: Dispatch<SetStateAction<IInvoice>>;
  setExistingInvoiceTracking: Dispatch<SetStateAction<IInvoice>>;
}

const InvoiceActionsMenu: FC<OwnProps> = ({
  record,
  setInvoiceDecide,
  setCancelPrebookInvoice,
  setExistingPrebookInvoice,
  setShowAllDecideFields,
  setShowInvoiceDelete,
  setShowPurchaseOrderPaymentsOnInvoice,
  setExistingInvoiceTracking,
}) => {
  const history = useHistory();
  const { isSameCurrency } = useInvoiceRecord({ record });
  const {
    deleteInvoiceTracking,
    updateInvoice: toggleInvoiceExcludeFromRisk,
    unbindContractRateToInvoice,
  } = useStoreActions((actions) => actions.InvoicesState);
  const { rateContractsByCurrencyPair } = useStoreState(
    (state) => state.RateContractsState
  );
  const { hasApprovalFlow, isUserApprover } = useStoreState(
    ({ UserState }) => UserState
  );
  const { deepLinkKey } = useXero();
  const { updateInvoicesApprovalStatus } = useInvoicesApprovalStatus();

  const dropdownItems = useMemo(() => {
    const dropdownItems: any[] = [];

    const isPayable = isPayableInvoice(record);
    const isCanBePaid = isInvoiceCanBePaid(record);
    const isNotPaid = isInvoiceNotPaid(record);
    const isFromXero = isInvoiceFromXero(record);
    const daysLeft = getInvoiceDaysLeft(record);
    const isDisabled = isInvoiceDisabled(record);
    const isDeletable = isInvoiceDeletable(record);
    const isTrackable = isInvoiceTrackable(record);

    const rateContractsToUse = rateContractsByCurrencyPair(
      'GBP',
      record.currency
    ).filter(
      (item) =>
        item.remainingBuyAmount >= (record.amountDue ?? 0) &&
        item.status === CONTRACT_STATUS.readyToUse &&
        dayjs(item.expiryDate).isAfter(dayjs().subtract(1, 'day'))
    );

    if (isFromXero) {
      dropdownItems.push({
        id: 'show-in-xero',
        title: 'Open in Xero',
        onClick: () => {
          if (isReceivableInvoice(record)) {
            window.open(
              `https://go.xero.com/organisationlogin/default.aspx?shortcode=${deepLinkKey}&redirecturl=/AccountsReceivable/Edit.aspx?InvoiceID=${record.externalRefs?.xeroId}`
            );
          } else {
            window.open(
              `https://go.xero.com/organisationlogin/default.aspx?shortcode=${deepLinkKey}&redirecturl=/AccountsPayable/Edit.aspx?InvoiceID=${record.externalRefs?.xeroId}`
            );
          }
        },
      });
    }

    if (!isDisabled) {
      if (isPayable) {
        if (isCanBePaid && !record.contractId) {
          dropdownItems.push({
            id: 'prebook',
            title: 'Prebook',
            onClick: () => history.push(getInvoicePrebookLink(record)),
          });
        }

        if (isCanBePaid && !record.contractId && !!rateContractsToUse?.length) {
          dropdownItems.push({
            id: 'apply-prebooked-rate',
            title: 'Apply prebooked rate',
            onClick: () => {
              setExistingPrebookInvoice(record);
            },
          });
        }

        if (!!record.contractId && record.contractAssignment) {
          dropdownItems.push({
            id: 'remove-prebook',
            title: 'Remove prebooked rate',
            onClick: () => {
              if (record.contractId) {
                unbindContractRateToInvoice({
                  contractId: record.contractId,
                  payload: { invoiceId: record.id },
                });
              }
            },
          });
        }

        if (!!record.contractId && !record.contractAssignment) {
          dropdownItems.push({
            id: 'cancel-prebook',
            title: 'Cancel prebooking',
            onClick: () => setCancelPrebookInvoice(record),
          });
        }

        if (
          ((hasApprovalFlow && record.approval?.status === 'approved') ||
            (hasApprovalFlow && isUserApprover) ||
            !hasApprovalFlow) &&
          isCanBePaid
        ) {
          dropdownItems.push({
            id: 'pay',
            title: 'Pay',
            onClick: () => history.push(getInvoiceTransferLink(record)),
          });
        }

        if (hasApprovalFlow && isUserApprover && !!record.approval?.status) {
          dropdownItems.push({
            id: 'reject',
            title: 'Reject',
            onClick: () =>
              updateInvoicesApprovalStatus({
                invoiceIds: [record.id],
                approvalStatus: null,
              }),
          });
        }

        if (
          isTrackable &&
          isNotPaid &&
          !record.contractId &&
          !record.trackingId &&
          daysLeft >= 7
        ) {
          dropdownItems.push({
            id: 'track',
            title: 'Track',
            onClick: () => {
              setInvoiceDecide(record);
              setShowAllDecideFields(true);
            },
          });
        }

        if (record.trackingId) {
          dropdownItems.push({
            id: 'review-tracking',
            title: 'Review tracking',
            onClick: () => {
              setExistingInvoiceTracking(record);
            },
          });

          if (isNotPaid) {
            dropdownItems.push({
              id: 'stop-tracking',
              title: 'Stop tracking',
              onClick: () => {
                if (record.trackingId) {
                  deleteInvoiceTracking({
                    trackingId: record.trackingId,
                  });
                }
              },
            });
          }
        }
      } else {
        if (isNotPaid && !!record.contractId) {
          dropdownItems.push({
            id: 'collect',
            title: 'Collect',
            onClick: () => {
              const link = getLink('/app/currency-exchange?step=1', {
                predefinedSellAmount: record.amountDue.toString(),
                predefinedSellCurrency: record.currency,
                predefinedRateContractId: record.contractId,
                step: '2',
                invoiceId: record.id,
              });

              history.push(link);
            },
          });
        }

        if (isNotPaid && !record.contractId) {
          dropdownItems.push({
            id: 'prebook',
            title: 'Prebook',
            onClick: () => history.push(getInvoicePrebookLink(record)),
          });
        }

        if (!!record.contractId && !record.contractAssignment) {
          dropdownItems.push({
            id: 'cancel-prebook',
            title: 'Cancel prebooking',
            onClick: () => setCancelPrebookInvoice(record),
          });
        }
      }
    }

    if (isTrackable) {
      dropdownItems.push({
        id: 'toggle-exclude-from-risks',
        title: record.excludeFromRisk ? 'Add to Risk' : 'Remove from Risk',
        onClick: () => {
          toggleInvoiceExcludeFromRisk({
            invoiceId: record.id,
            data: {
              excludeFromRisk: !record.excludeFromRisk,
            },
          });
        },
      });
    }

    if (isDeletable) {
      dropdownItems.push({
        id: 'delete',
        title: 'Delete from list',
        onClick: () => setShowInvoiceDelete(record),
      });
    }

    dropdownItems.push({
      id: 'see-details',
      title: 'Details',
      link: `/app/invoices/${record.id}`,
    });

    if (record.status === INVOICE_STATUSES.purchaseOrder) {
      dropdownItems.push({
        id: 'allocate-prepayment',
        title: !!record.payments?.[0]
          ? 'View prepayment'
          : 'Allocate prepayment',
        onClick: () => setShowPurchaseOrderPaymentsOnInvoice(record),
      });
    }

    return dropdownItems;
  }, [
    record,
    rateContractsByCurrencyPair,
    deepLinkKey,
    hasApprovalFlow,
    isUserApprover,
    history,
    setExistingPrebookInvoice,
    unbindContractRateToInvoice,
    setCancelPrebookInvoice,
    updateInvoicesApprovalStatus,
    setInvoiceDecide,
    setShowAllDecideFields,
    setExistingInvoiceTracking,
    deleteInvoiceTracking,
    toggleInvoiceExcludeFromRisk,
    setShowInvoiceDelete,
    setShowPurchaseOrderPaymentsOnInvoice,
  ]);

  if (isSameCurrency) {
    return null;
  }

  return <ContextMenu list={dropdownItems} strategy="fixed" portal />;
};

export default InvoiceActionsMenu;
