import { FC, useState, useMemo, useEffect, useCallback } from 'react';
import EntityUserPopup from './components/EntityUserPopup/EntityUserPopup';
import Button from 'components/shared/Button/Button';
import { useStoreState } from 'state';
import { Row, StaleLoader, Table } from 'components';
import { IEntityUser } from 'state/user';
import { isEmulated, Notify } from 'utils';
import { Firebase } from 'services';
import Placeholder from 'components/shared/Placeholder/Placeholder';
import {
  SettingsContent,
  SettingsFooter,
} from 'pages/Settings/Settings.styles';
import { IPermitRoleV2 } from 'services/firebase';
import { generateEntityUserTableColumns } from './tableColumnsGenerator';

const TeamSettings: FC = () => {
  const {
    userId,
    entityId,
    hasApprovalFlow,
    isAutomationPackageEnabled,
  } = useStoreState(({ UserState }) => UserState);
  const [users, setUsers] = useState<IEntityUser[]>([]);
  const [roles, setRoles] = useState<IPermitRoleV2[]>([]);
  const [userForEdit, setUserForEdit] = useState<IEntityUser | null>(null);
  const [isLoadingUsers, setIsLoadingUsers] = useState(false);
  const [isLoadingRoles, setIsLoadingRoles] = useState(false);
  const [isUpdatingEntityApprover, setIsUpdatingEntityApprover] = useState(
    false
  );
  const [isInvitePopupOpen, setIsInvitePopupOpen] = useState(false);

  const getEntityUsers = useCallback(async () => {
    if (!entityId) {
      return;
    }

    try {
      setIsLoadingUsers(true);
      const response = await Firebase.getEntityUsers({ entityId });

      if (response?.data?.success) {
        setUsers(response.data.data);
      } else {
        Notify.error(response?.data?.message ?? '');
      }
    } catch (error) {
      Notify.error(error.message);
    } finally {
      setIsLoadingUsers(false);
    }
  }, [entityId]);

  const getEntityRoles = useCallback(async () => {
    if (!entityId || !isEmulated()) {
      return;
    }

    try {
      setIsLoadingRoles(true);
      const response = await Firebase.getEntityRoles(entityId);

      if (response?.data?.success && response.data?.data) {
        setRoles(response.data.data);
      } else {
        Notify.error(response?.data?.message ?? '');
      }
    } catch (error) {
      Notify.error(error.message);
    } finally {
      setIsLoadingRoles(false);
    }
  }, [entityId]);

  const onSetEntityApprover = useCallback(
    async (id: string) => {
      if (!entityId) {
        return;
      }

      try {
        setIsUpdatingEntityApprover(true);
        const response = await Firebase.setEntityApprover({
          userId: id,
          entityId,
        });

        if (response?.data?.success) {
          Notify.success('User successfully set as approver');
          getEntityUsers();
        } else {
          Notify.error(response?.data?.message ?? '');
        }
      } catch (error) {
        Notify.error(error.message);
      } finally {
        setIsUpdatingEntityApprover(false);
      }
    },
    [getEntityUsers, entityId]
  );

  const usersTableColumns = useMemo(
    () =>
      generateEntityUserTableColumns({
        userId,
        isLoadingRoles,
        isUpdatingEntityApprover,
        onEditEntityUser: (user) => {
          setUserForEdit(user);
          setIsInvitePopupOpen(true);
        },
        onSetEntityApprover,
        hasApprovalFlow,
        isAutomationPackageEnabled,
      }),
    [
      userId,
      isLoadingRoles,
      hasApprovalFlow,
      onSetEntityApprover,
      isUpdatingEntityApprover,
      isAutomationPackageEnabled,
    ]
  );

  useEffect(() => {
    getEntityRoles();
    getEntityUsers();
  }, [getEntityRoles, getEntityUsers]);

  if (isLoadingUsers) {
    return (
      <SettingsContent>
        <StaleLoader size="large" />
      </SettingsContent>
    );
  }

  return (
    <>
      <SettingsContent>
        {!!users.length ? (
          <Table<IEntityUser>
            data={users}
            columns={usersTableColumns}
            sortable
            defaultRowHeight={56}
          />
        ) : (
          <Row>
            <Placeholder
              fullWidth
              description="You will see your team members here when you have some."
            />
          </Row>
        )}
      </SettingsContent>

      <SettingsFooter>
        <Row>
          <Button
            variant="secondary"
            disabled={false}
            isLoading={false}
            onClick={() => setIsInvitePopupOpen(true)}
          >
            + Add new
          </Button>
        </Row>
      </SettingsFooter>
      {isInvitePopupOpen && (
        <EntityUserPopup
          userForEdit={userForEdit}
          roles={roles}
          getEntityUsers={getEntityUsers}
          onClose={() => {
            setUserForEdit(null);
            setIsInvitePopupOpen(false);
          }}
        />
      )}
    </>
  );
};

export default TeamSettings;
