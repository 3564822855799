import { Col, Paragraph, Row, Title } from 'components';
import Button from 'components/shared/Button/Button';
import Popup, { PopupProps } from 'components/shared/Popup/Popup';
import dayjs from 'dayjs';
import { FC } from 'react';
import { useTheme } from 'styled-components';
import {
  isPastPerformanceItemInvoice,
  TPastPerformanceItem,
} from 'types/analyses';
import {
  parseIntoCurrencyString,
  parseIntoCurrencyStringWithSymbol,
  parseRateWithPrecision,
} from 'utils';

interface OwnProps extends Pick<PopupProps, 'onClose'> {
  data: TPastPerformanceItem;
}

const FxBreakdownPopup: FC<OwnProps> = ({ data, onClose }) => {
  const theme = useTheme();

  const {
    amount,
    paidDate,
    payRate,
    historicalPayRate,
    fxCost,
    fxCostPercentage,
    fxImpact,
    fxImpactPercentage,
    creationDate,
  } = data;

  const isInvoice = isPastPerformanceItemInvoice(data);

  const getColorName = (value: number) => {
    if (value < 0) {
      return 'red';
    }

    if (value > 0) {
      return 'green';
    }

    return 'black';
  };

  return (
    <Popup
      width="539px"
      HeaderContent={
        <Title variant="h3">
          {isInvoice ? 'FX impact breakdown' : 'FX Impact breakdown - transfer'}
        </Title>
      }
      FooterContent={<Button onClick={onClose}>Close</Button>}
      onClose={onClose}
    >
      <Paragraph>
        {`We estimate the impact of ${
          isInvoice ? 'currency fluctuations and' : ''
        }
        conversion spread using the daily rates by Bank of England:`}
      </Paragraph>

      <Row mt>
        <Col
          alignItems="flex-start"
          justifyContent="flex-end"
          alignSelf="stretch"
          flex={1.5}
        >
          {isInvoice && (
            <Paragraph>{`Invoice created on ${dayjs(
              creationDate,
              'YYYY-MM-DD'
            ).format('DD/MM/YY')}`}</Paragraph>
          )}
          <Paragraph
            mt
            mtValue={theme.spacing.xxs}
          >{`Market value on payment date ${dayjs(
            paidDate,
            'YYYY-MM-DD'
          ).format('DD/MM/YY')}`}</Paragraph>
        </Col>

        <Col alignItems="flex-end" alignSelf="stretch" flex={0.5}>
          <Paragraph variant="bold">FX Rate</Paragraph>
          {isPastPerformanceItemInvoice(data) && (
            <Paragraph variant="bold" mt mtValue={theme.spacing.xxs}>
              {parseRateWithPrecision(data.invoiceRate)}
            </Paragraph>
          )}
          <Paragraph variant="bold" mt mtValue={theme.spacing.xxs}>
            {parseRateWithPrecision(historicalPayRate)}
          </Paragraph>
        </Col>

        <Col alignItems="flex-end" alignSelf="stretch" flex={0.5}>
          <Paragraph variant="bold">GBP Value</Paragraph>
          {isPastPerformanceItemInvoice(data) && (
            <Paragraph variant="bold" mt mtValue={theme.spacing.xxs}>
              {parseIntoCurrencyStringWithSymbol(
                amount / data.invoiceRate,
                '£'
              )}
            </Paragraph>
          )}
          <Paragraph variant="bold" mt mtValue={theme.spacing.xxs}>
            {parseIntoCurrencyStringWithSymbol(amount / historicalPayRate, '£')}
          </Paragraph>
        </Col>
      </Row>

      {isInvoice && (
        <Row mt mtValue={theme.spacing.xxs}>
          <Paragraph>Gain/loss from FX moves</Paragraph>
          <Paragraph
            variant="bold"
            color={getColorName(fxImpactPercentage)}
          >{`${parseIntoCurrencyStringWithSymbol(
            fxImpact,
            '£'
          )} (${parseIntoCurrencyString(
            fxImpactPercentage * 100
          )}%)`}</Paragraph>
        </Row>
      )}

      <Row mt={isInvoice}>
        <Col
          alignItems="flex-start"
          justifyContent="flex-end"
          alignSelf="stretch"
          flex={1.5}
        >
          <Paragraph mt mtValue={theme.spacing.xxs}>{`Paid on ${dayjs(
            paidDate,
            'YYYY-MM-DD'
          ).format('DD/MM/YY')}`}</Paragraph>
        </Col>

        <Col alignItems="flex-end" alignSelf="stretch" flex={0.5}>
          <Paragraph variant="bold" mt mtValue={theme.spacing.xxs}>
            {parseRateWithPrecision(payRate)}
          </Paragraph>
        </Col>

        <Col alignItems="flex-end" alignSelf="stretch" flex={0.5}>
          <Paragraph variant="bold" mt mtValue={theme.spacing.xxs}>
            {parseIntoCurrencyStringWithSymbol(amount / payRate, '£')}
          </Paragraph>
        </Col>
      </Row>

      <Row mt mtValue={theme.spacing.xxs}>
        <Paragraph>Estimated conversion cost</Paragraph>
        <Paragraph
          variant="bold"
          color={getColorName(fxCostPercentage)}
        >{`${parseIntoCurrencyStringWithSymbol(
          fxCost,
          '£'
        )} (${parseIntoCurrencyString(fxCostPercentage * 100)}%)`}</Paragraph>
      </Row>
    </Popup>
  );
};

export default FxBreakdownPopup;
