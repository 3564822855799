import { FC } from 'react';
import { Row, Paragraph } from 'components';
import { useTheme } from 'styled-components';
import NumberCircle from 'components/shared/NumberCircle/NumberCircle';
import { IPaymentRunItemSummary } from 'types/paymentRuns';

interface OwnProps {
  record: IPaymentRunItemSummary;
}

const AuthoriseTransfersCell: FC<OwnProps> = ({ record }) => {
  const theme = useTheme();

  return (
    <Row columnGap={theme.spacing.m}>
      {!!record.localCount && (
        <Row columnGap={theme.spacing.xs}>
          <Paragraph>Local</Paragraph>
          <NumberCircle value={record.localCount} />
        </Row>
      )}
      {!!record.swiftCount && (
        <Row columnGap={theme.spacing.xs}>
          <Paragraph>SWIFT</Paragraph>
          <NumberCircle value={record.swiftCount} />
        </Row>
      )}
    </Row>
  );
};

export default AuthoriseTransfersCell;
