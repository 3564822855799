import styled from 'styled-components';

import { Row } from '../Row/Row';

export const TabsWrapper = styled(Row)`
  border-radius: ${({ theme }) => theme.borderRadius.m};
  background-color: ${({ theme }) => theme.color.greyLight_2};
  justify-content: flex-start;
  width: min-content;
`;

interface TabItemProps {
  active?: boolean;
}

export const TabItem = styled.button<TabItemProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  cursor: pointer;
  min-height: 48px;
  padding: ${({ theme }) => `${theme.spacing.s} ${theme.spacing.m}`};
  transition: ${({ theme }) => theme.transition};
  border: 1px solid
    ${({ theme, active }) =>
      active ? theme.color.greyLight_1 : theme.color.transparent};
  border-radius: ${({ theme }) => theme.borderRadius.m};
  background-color: ${({ theme, active }) =>
    active ? theme.color.white : theme.color.transparent};
`;
