import styled from 'styled-components';
import { Col } from '../Col/Col';

const Card = styled(Col)`
  background: ${({ theme }) => theme.color.white};
  padding: ${({ theme }) => theme.spacing.xl};
  box-shadow: ${({ theme }) => theme.shadow};
  border-radius: ${({ theme }) => theme.borderRadius.m};
`;

export default Card;
