import { FC } from 'react';
import Tabs from './components/Tabs/Tabs';
import Controls from './components/Controls/Controls';
import Tables from './components/Tables/Tables';
import { useStoreState } from 'state';
import useIsIntegratedWithAnything from 'hooks/useIsIntegratedWithAnything';
import { IntegrationWrapper } from './Invoices.styles';
import { StaleIntegrationSettings } from 'components';
import useInvoicesPageTabsCountsAndCurrencies from './hooks/useInvoicesPageTabsCountsAndCurrencies';

const Invoices: FC = () => {
  const { isLoadingInvoices, invoices } = useStoreState(
    ({ InvoicesState }) => InvoicesState
  );
  const isIntegratedWithAnything = useIsIntegratedWithAnything();
  const { counts, currenciesByTab } = useInvoicesPageTabsCountsAndCurrencies();

  return (
    <>
      {!isIntegratedWithAnything && !invoices.length && !isLoadingInvoices ? (
        <IntegrationWrapper>
          <StaleIntegrationSettings />
        </IntegrationWrapper>
      ) : (
        <>
          <Tabs counts={counts} />
          <Controls counts={counts} currenciesByTab={currenciesByTab} />
          <Tables />
        </>
      )}
    </>
  );
};

export default Invoices;
