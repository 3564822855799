import dayjs from 'dayjs';
import { useStoreState } from 'state';
import { IInvoice } from 'types';
import {
  isInvoiceDisabled,
  isInvoiceCanBePaid,
  isPayableInvoice,
  isReceivableInvoice,
  isInvoicePrebookable,
  isInvoiceHasValidContact,
  isInvoiceSubmittableForReview,
  isInvoiceApprovable,
} from 'utils/invoices';

interface UseInvoiceRecordParams {
  record: IInvoice;
}

const useInvoiceRecord = ({ record }: UseInvoiceRecordParams) => {
  const { currencyByCode } = useStoreState((state) => state.CurrenciesState);
  const { hasApprovalFlow, isUserApprover } = useStoreState(
    (state) => state.UserState
  );

  const currency = currencyByCode(record.currency);
  const isDisabled = isInvoiceDisabled(record);
  // TODO: replace later with real sell currency instead of GBP
  const isSameCurrency = record.currency === 'GBP';
  const daysUntilDueDate = dayjs(record.dueDate).diff(dayjs(), 'days');
  const isPayable = isPayableInvoice(record);
  const isReceivable = isReceivableInvoice(record);
  const isCanBePaid =
    isInvoiceCanBePaid(record) &&
    ((hasApprovalFlow && record.approval?.status === 'approved') ||
      (hasApprovalFlow && isUserApprover) ||
      !hasApprovalFlow);
  const isPrebookable = isInvoicePrebookable(record);
  const hasTracking = !!record.trackingId;
  const hasPrebook = !!record.contractId;
  const hasValidContact = isInvoiceHasValidContact(record);
  const submittableForReview =
    isInvoiceSubmittableForReview(record) && hasApprovalFlow && !isUserApprover;
  const isApprovable =
    isInvoiceApprovable(record) && hasApprovalFlow && isUserApprover;

  return {
    currency,
    isDisabled: !!isDisabled,
    isSameCurrency,
    daysUntilDueDate,
    isPayable,
    isReceivable,
    hasTracking,
    hasPrebook,
    hasValidContact,
    isCanBePaid,
    isPrebookable,
    submittableForReview,
    isApprovable,
  };
};

export default useInvoiceRecord;
