import useCodatIntegration from './useCodatIntegration';

const useQuickBooks = () => {
  const {
    onImportData: onImportQuickBooksData,
    isLoading: isLoadingQuickBooksData,
    isIntegrated: isIntegratedWithQuickBooks,
    onLogin: onQuickBooksLogin,
    isImporting: isImportingQuickBooksData,
    queueNewCodatSync: queueNewSQuickBooksSync,
    syncStatus: quickBooksSyncStatus,
  } = useCodatIntegration({
    platformNames: ['QuickBooks Online Sandbox', 'QuickBooks Online'],
  });

  return {
    isImportingQuickBooksData,
    isIntegratedWithQuickBooks,
    isLoadingQuickBooksData,
    onImportQuickBooksData,
    onQuickBooksLogin,
    queueNewSQuickBooksSync,
    quickBooksSyncStatus,
  };
};

export default useQuickBooks;
