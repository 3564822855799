import { useRef, useState } from 'react';
import auth from 'services/auth';
import { useStoreActions, useStoreState } from 'state';
import { Notify } from 'utils';

const useReAuthenticate = () => {
  const { user } = useStoreState(({ UserState }) => UserState);
  const [isReAuthenticated, setIsReAuthenticated] = useState(false);
  const [isReAuthenticating, setIsReAuthenticating] = useState(false);
  const [
    isTooManyPasswordAttemptsError,
    setIsTooManyPasswordAttemptsError,
  ] = useState(false);

  const { signIn, signInWithGoogle } = useStoreActions(
    (actions) => actions.UserState
  );

  const isPasswordProvider = auth.currentUser?.providerData.find(
    (item) => item?.providerId === 'password'
  );
  const isGoogleProvider = auth.currentUser?.providerData.find(
    (item) => item?.providerId === 'google.com'
  );

  const currentUserEmail = useRef(user?.email ?? '');

  const setReAuthenticatedToTrue = () => setIsReAuthenticated(true);

  const handlePasswordReAuthentication = async ({
    password,
  }: {
    password: string;
  }) => {
    try {
      setIsReAuthenticating(true);
      const response = await signIn({
        email: currentUserEmail.current,
        password,
      });

      setIsReAuthenticating(false);

      if (response) {
        setReAuthenticatedToTrue();
      }
    } catch (error) {
      if (error.code === 'auth/too-many-requests') {
        setIsTooManyPasswordAttemptsError(true);
      }
      throw error;
    }
  };

  const onSignInWithGoogle = async () => {
    setIsReAuthenticating(true);
    const response = await signInWithGoogle();
    if (response) {
      // user can sign in using a different account/email to the one
      // they initially signed into HedgeFlows with
      if (response.auth?.currentUser?.email === currentUserEmail.current) {
        setReAuthenticatedToTrue();
      } else {
        Notify.error('Signed with a different user');
      }
    }
    setIsReAuthenticating(false);
  };

  const onReAuthenticateClick = async (values: { password: string }) => {
    if (!isReAuthenticated) {
      if (isPasswordProvider) {
        await handlePasswordReAuthentication(values);
      } else if (isGoogleProvider) {
        await onSignInWithGoogle();
      } else {
        console.error('No re-authentication found');
      }
    }
  };

  return {
    isReAuthenticating,
    onReAuthenticateClick,
    isPasswordProvider,
    isGoogleProvider,
    isTooManyPasswordAttemptsError,
    setIsTooManyPasswordAttemptsError,
  };
};

export default useReAuthenticate;
