import { FC, useMemo } from 'react';
import { useStoreState } from 'state';
import { INTEGRATION_TYPE } from 'types';
import { IAvailableIntegration, IIntegration } from 'types/integrations';
import { isLocal, isProduction } from 'utils';
import IntegrationTile from '../IntegrationTile/IntegrationTile';

const INTEGRATIONS_DATA: {
  [idx: string]: IAvailableIntegration[];
} = {
  accounting: [
    {
      id: 'xero',
      name: 'Xero',
      img: '#xero-ico',
      available: true,
      type: INTEGRATION_TYPE.accounting,
    },
    {
      id: 'codat',
      name: 'Sage 50',
      platformNames: ['Sage 50 (UK)'],
      img: '#sage50Xml-ico',
      available: true,
      codatShortCode: 'hbql',
      type: INTEGRATION_TYPE.accounting,
    },
    {
      id: 'codat',
      name: 'QuickBooks Sandbox',
      platformNames: ['QuickBooks Online Sandbox'],
      codatShortCode: 'ndsk',
      img: '#quick-books-ico',
      available: true,
      type: INTEGRATION_TYPE.accounting,
    },
    {
      id: 'codat',
      name: 'QuickBooks',
      platformNames: ['QuickBooks Online'],
      codatShortCode: 'qhyg',
      img: '#quick-books-ico',
      available: true,
      type: INTEGRATION_TYPE.accounting,
    },
    {
      id: 'codat',
      name: 'Dynamics 365 Business Central',
      platformNames: ['Dynamics 365 Business Central'],
      img: '#dynamics-365-ico',
      codatShortCode: 'trji',
      available: true,
      type: INTEGRATION_TYPE.accounting,
    },
    {
      id: 'csv',
      name: 'CSV',
      available: true,
      type: INTEGRATION_TYPE.accounting,
    },
  ],
  business: [
    {
      id: 'orderWise',
      name: 'OrderWise',
      img: '#orderWise-ico',
      available: true,
      type: INTEGRATION_TYPE.business,
    },
  ],
};

INTEGRATIONS_DATA.accounting = INTEGRATIONS_DATA.accounting.filter(
  (integration) => !isProduction() || integration.name !== 'QuickBooks Sandbox'
);

interface OwnProps {
  variant: 'accounting' | 'business';
}

const isIntegrationConnected = ({
  integrationsSummary,
  integrationDoc,
}: {
  integrationsSummary: IIntegration;
  integrationDoc: IAvailableIntegration;
}) => {
  const externalSystem = integrationDoc?.id;
  const isConnected = !!integrationsSummary?.[externalSystem]?.connected;

  // if codat, cross reference platformNames to find the instance that is connected
  if (externalSystem !== 'codat') {
    return isConnected;
  } else {
    return (
      isConnected &&
      integrationsSummary?.codat?.platformName &&
      integrationDoc?.platformNames?.includes(
        integrationsSummary.codat.platformName
      )
    );
  }
};

const Integrations: FC<OwnProps> = ({ variant }) => {
  const { integrationsSummary } = useStoreState((state) => state.UserState);

  const connectedIntegration = useMemo(
    () =>
      INTEGRATIONS_DATA[variant].find((integrationDoc) =>
        isIntegrationConnected({
          integrationsSummary,
          integrationDoc,
        })
      ),
    [integrationsSummary, variant]
  );

  const integrationsToRender = useMemo(
    () =>
      INTEGRATIONS_DATA[variant].filter((integration) => {
        if (!connectedIntegration) {
          return true;
        } else {
          return (
            isLocal() ||
            integration.id === 'csv' ||
            integration.name === connectedIntegration.name
          );
        }
      }),
    [connectedIntegration, variant]
  );

  return (
    <>
      {integrationsToRender.map((integration) => (
        <IntegrationTile integration={integration} key={integration.name} />
      ))}
    </>
  );
};

export default Integrations;
