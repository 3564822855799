import { FC, useMemo, useState } from 'react';
import { Col, Popup, StaleSelectMenu, Title } from 'components';
import { Controller, useForm } from 'react-hook-form';
import { useStoreState } from 'state';
import { useTheme } from 'styled-components';
import { Firebase } from 'services';
import { isEmulated, Notify } from 'utils';
import { ERROR_MESSAGES } from 'variables';
import Button from 'components/shared/Button/Button';
import { IEntityUser } from 'state/user';
import Field from 'components/shared/Field/Field.styles';
import InputBase from 'components/shared/InputBase/InputBase';
import { IMenuItem } from 'components/shared/StaleSelectMenu/StaleSelectMenu';
import { IPermitRoleV2 } from 'services/firebase';

interface OwnProps {
  userForEdit: IEntityUser | null;
  onClose: () => void;
  roles: IPermitRoleV2[];
  getEntityUsers: () => Promise<void>;
}

interface IFormValues {
  email: string;
  firstName: string;
  lastName: string;
  roles: IMenuItem[];
}

const generateRolesMenuItem = (role: IPermitRoleV2) => ({
  label: role.name,
  value: role,
});

const EntityUserPopup: FC<OwnProps> = ({
  userForEdit,
  onClose,
  roles,
  getEntityUsers,
}) => {
  const theme = useTheme();
  const { entityId } = useStoreState((state) => state.UserState);
  const [isSubmittingInvite, setIsSubmittingInvite] = useState(false);

  const rolesMenuItems = useMemo(() => roles.map(generateRolesMenuItem), [
    roles,
  ]);

  const defaultRoles = useMemo(() => {
    if (userForEdit) {
      return roles
        .reduce<IPermitRoleV2[]>((accumulator, role) => {
          if (!!userForEdit.roles.find((roleKey) => roleKey === role.name)) {
            return [...accumulator, role];
          }
          return accumulator;
        }, [])
        .map(generateRolesMenuItem);
    }
    return [];
  }, [roles, userForEdit]);

  const {
    control,
    handleSubmit,
    errors,
    formState: { isValid },
    watch,
  } = useForm<IFormValues>({
    mode: 'onChange',
    defaultValues: userForEdit
      ? {
          ...userForEdit,
          roles: defaultRoles,
        }
      : {},
  });

  const watchRoles = watch('roles');

  const onInviteSubmit = async (values: IFormValues) => {
    try {
      if (!entityId) {
        return;
      }
      setIsSubmittingInvite(true);

      await Firebase.createInvitation({
        ...values,
        roles: !isEmulated() ? [] : values.roles.map(({ value }) => value.id),
        entityId,
      });

      Notify.success('Invitation sent');
      onClose();
    } catch (error) {
      Notify.error(error.response?.data?.error || error.message);
    } finally {
      setIsSubmittingInvite(false);
    }
  };

  const onEditUserRolesSubmit = async (values: IFormValues) => {
    try {
      if (!entityId || !userForEdit) {
        return;
      }
      setIsSubmittingInvite(true);

      await Firebase.editEntityUserRoles({
        userId: userForEdit.id,
        roles: isEmulated() ? [] : values.roles.map(({ value }) => value.id),
        entityId,
      });

      Notify.success('Roles edited successfully');
      getEntityUsers();
      onClose();
    } catch (error) {
      Notify.error(error.response?.data?.error || error.message);
    } finally {
      setIsSubmittingInvite(false);
    }
  };

  const requiredRules = userForEdit
    ? {}
    : { required: ERROR_MESSAGES.requiredField };

  return (
    <Popup
      HeaderContent={
        <Title variant="h3">{userForEdit ? 'Edit' : 'Invite'} colleague</Title>
      }
      FooterContent={
        <Button
          form="invite-member"
          disabled={
            userForEdit ? !watchRoles?.length : !isValid || isSubmittingInvite
          }
        >
          {userForEdit ? 'Edit' : 'Invite'} colleague
        </Button>
      }
      width="439px"
      onClose={onClose}
    >
      <form
        id="invite-member"
        onSubmit={handleSubmit(
          userForEdit ? onEditUserRolesSubmit : onInviteSubmit
        )}
        // TODO: remove after fix overlay of StaleSelectMenu
        style={!isEmulated() ? { height: 360 } : {}}
      >
        <Col gap={theme.spacing.m}>
          <Field flexDirection="column" fluid>
            <Controller
              name="firstName"
              control={control}
              rules={requiredRules}
              render={({ onChange, value, name }) => (
                <InputBase
                  id={name}
                  label="First Name"
                  value={value}
                  onChange={onChange}
                  error={errors?.[name]?.message}
                  disabled={!!userForEdit}
                />
              )}
            />
          </Field>

          <Field flexDirection="column" fluid>
            <Controller
              name="lastName"
              control={control}
              rules={requiredRules}
              render={({ onChange, value, name }) => (
                <InputBase
                  id={name}
                  label="Last Name"
                  value={value}
                  onChange={onChange}
                  error={errors?.[name]?.message}
                  disabled={!!userForEdit}
                />
              )}
            />
          </Field>
          <Field flexDirection="column" fluid>
            <Controller
              name="email"
              control={control}
              rules={requiredRules}
              disabled={!!userForEdit}
              render={({ onChange, value, name }) => (
                <InputBase
                  id={name}
                  label="Email"
                  value={value}
                  onChange={onChange}
                  error={errors?.[name]?.message}
                  disabled={!!userForEdit}
                />
              )}
            />
          </Field>
          {isEmulated() && (
            <Controller
              id="roles"
              name="roles"
              control={control}
              rules={{
                required: ERROR_MESSAGES.requiredField,
              }}
              render={({ onChange, name, value }) => (
                <StaleSelectMenu
                  id={name}
                  name={name}
                  label="Roles"
                  isMulti
                  data={rolesMenuItems}
                  value={value}
                  onChange={(item) => {
                    if (!Array.isArray(item)) {
                      onChange(null);
                    } else {
                      onChange(item);
                    }
                  }}
                  menuPosition="absolute"
                  withBackdrop={false}
                />
              )}
            />
          )}
        </Col>
      </form>
    </Popup>
  );
};

export default EntityUserPopup;
