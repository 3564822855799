import { AxisTickProps } from '@nivo/axes';
import dayjs from 'dayjs';

const ChartTick = (tick: AxisTickProps<any>) => (
  <g transform={`translate(${tick.x},${tick.y + 16})`}>
    <text
      style={{
        fontSize: 12,
      }}
      textAnchor="middle"
    >
      {dayjs(tick.value, 'YYYY-MM').format('MMM')}
    </text>

    {(dayjs(tick.value, 'YYYY-MM')
      .subtract(1, 'month')
      .isBefore(dayjs(tick.value, 'YYYY-MM').startOf('year')) ||
      tick.tickIndex === 0) && (
      <text
        transform={`translate(0, ${24})`}
        style={{
          fontSize: 12,
        }}
        textAnchor="middle"
      >
        {dayjs(tick.value, 'YYYY-MM').format('YYYY')}
      </text>
    )}
  </g>
);

export default ChartTick;
