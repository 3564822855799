import { Line, LineSvgProps } from '@nivo/line';
import AutoSizer from 'react-virtualized-auto-sizer';

const ChartLine = (props: LineSvgProps) => {
  return (
    <AutoSizer>
      {({ height, width }) => <Line height={height} width={width} {...props} />}
    </AutoSizer>
  );
};

export default ChartLine;
