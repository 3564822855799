export enum EXTERNAL_PAYMENT_TYPE {
  PAYMENT_ON_ACCOUNT = 'PaymentOnAccount',
  BILL = 'Bill',
}

export interface IExternalPayment {
  supplierRef: {
    id: string; // "MONK01",
    supplierName?: string; // "Tenda UK LTD"
  };
  totalAmount: number; // If of type bill, this is negative
  currency?: string; // "USD",
  currencyRate: number; // 0.735294118,
  date: string; // "2021-11-16T00:00:00",
  note?: string; // "Purchase payment on account linked to customer monk01.", <= auto generated by Sage50
  type: EXTERNAL_PAYMENT_TYPE;
  invoiceId?: string;
  lines: [
    {
      amount: number; // If of type bill, this is negative
      links: [
        {
          type: EXTERNAL_PAYMENT_TYPE;
          id: 'MONK01';
          amount: number; // If of type bill, this is positive
          currencyRate: number;
        }
      ];
    }
  ];
  paymentMethodRef?: {
    id: string;
    name?: string;
  };
  modifiedDate: '2022-03-02T11:55:03Z';
  sourceModifiedDate?: string;
  reference?: string;
  externalRefs: {
    codatId: string;
  };
}
