import styled, { css } from 'styled-components';
import { isMobile } from 'react-device-detect';
import { StaleTitleH3, StaleBtnGroup } from 'components';

export const Wrapper = styled.div<{
  width: string;
  minHeight: string;
  popupTheme: string;
}>(
  ({ theme, width, minHeight, popupTheme }) => css`
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 13;
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: calc(var(--vh, 1vh) * 100);

    @media (min-width: ${theme.breakpoint.largeMin}px) {
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      max-height: 100vh;
      padding: 24px 0;
    }

    @media (max-width: ${theme.breakpoint.largeMax}px) {
      padding-top: 56px;
    }

    .backdrop {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.4);
    }

    .head {
      display: flex;
      align-items: center;
      color: ${theme.color.dark};
      background: ${theme.color.greyLight_2};

      @media (min-width: ${theme.breakpoint.largeMin}px) {
        max-height: 72px;
        flex: 0 0 72px;
        padding: 0 48px;
        position: relative;
        width: ${width};
        border-radius: 6px 6px 0 0;
      }
      @media (max-width: ${theme.breakpoint.largeMax}px) {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 1;
        height: 56px;
        padding: 0 16px;
        background: ${theme.color.white};
        box-shadow: inset 0 -1px 0 rgba(140, 140, 140, 0.2);
      }

      svg {
        width: 24px;
        height: 24px;
        transition: ${theme.transition};
      }

      button {
        display: flex;
        align-items: center;
        width: 24px;
        height: 24px;
        margin: -4px 12px 0 0;
        transition: ${theme.transition};

        &:hover,
        &:focus {
          color: ${theme.color.emeraldDark};

          svg {
            fill: ${theme.color.emeraldDark};
          }
        }
      }

      .status {
        &:after {
          content: '•';
          margin-left: 4px;
          font-size: 28px;
          color: inherit;
          position: relative;
          bottom: -5px;
        }
      }
    }

    ${StaleTitleH3} {
      @media (min-width: ${theme.breakpoint.largeMin}px) {
        font-size: 20px;
        line-height: 28px;
        margin-right: 20px;
      }
    }

    .wrap-popup {
      background: ${theme.color.white};
      z-index: 1;

      @media (min-width: ${theme.breakpoint.largeMin}px) {
        width: ${width};
        min-height: ${minHeight};
        border-radius: 0 0 6px 6px;
        overflow: hidden;
      }
      @media (max-width: ${theme.breakpoint.largeMax}px) {
        flex: 1 0 auto;
        height: 100%;
      }
    }

    ${StaleBtnGroup} {
      padding: ${!isMobile && `30px 24px 40px`};
    }

    ${popupTheme === 'grey' &&
    css`
      .head {
        @media (max-width: ${theme.breakpoint.largeMax}px) {
          background: ${theme.color.greyLight_2};
        }
        padding: 0 48px 0 24px;

        button {
          position: absolute;
          right: 16px;
          top: 0;
          bottom: 0;
          margin: auto;

          @media (min-width: ${theme.breakpoint.largeMin}px) {
            right: 24px;
          }
        }
      }
    `}

    ${popupTheme === 'red' &&
    css`
      .head {
        background: ${theme.color.red};
        color: ${theme.color.white};
        @media (min-width: ${theme.breakpoint.largeMin}px) {
          padding: 24px 32px 18px;
        }

        button {
          position: absolute;
          right: 16px;
          top: 0;
          bottom: 0;
          margin: auto;

          @media (min-width: ${theme.breakpoint.largeMin}px) {
            right: 32px;
          }
          svg {
            fill: ${theme.color.white};
          }
        }
      }
    `}

    /* ${StaleBtnGroup} {
      padding: ${!isMobile && `30px 48px 40px`};
    } */
    ${popupTheme === 'small' &&
    css`
      .head {
        flex: 0 0 52px;
        background: ${theme.color.white};
        padding: 16px 32px 0 16px;

        @media (min-width: ${theme.breakpoint.largeMin}px) {
          border-radius: 16px 16px 0 0;
        }

        button {
          position: absolute;
          right: 16px;
          top: 2px;
          bottom: 0;
          margin: auto;
        }
      }
      .wrap-popup {
        padding: 6px 16px 16px;
        @media (min-width: ${theme.breakpoint.largeMin}px) {
          border-radius: 0 0 16px 16px;
        }
      }
      ${StaleBtnGroup} {
        padding: ${!isMobile && `16px 0px 0px`};
      }
    `}
  `
);
