import styled from 'styled-components';
import { StaleOverflowScroll } from 'components';

export const MainContent = styled.div`
  flex: 0 1 auto;
  height: 100%;
  overflow: auto;

  ${StaleOverflowScroll} {
    padding: 8px 0 16px;
  }
`;
