import { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';
import cx from 'classnames';

import { Wrapper } from './StaleSidebar.styles';
import { useStoreState } from 'state';
import { getInvoicesPageLink, getLink, getMyClientsLink } from 'utils/links';
import useHasBalance from 'hooks/useHasBalance';
import { Icon } from 'components/shared';

interface OwnProps {
  hideIconLabels?: boolean;
}

const StaleSidebar: FC<OwnProps> = ({ hideIconLabels }) => {
  const { pathname } = useLocation();
  const { showReports, isAccountant } = useStoreState(
    (state) => state.UserState
  );
  const { featureFlagById } = useStoreState((state) => state.FeatureFlagsState);
  const { hasBalance } = useHasBalance();

  return (
    <Wrapper isIconsOnly={!hideIconLabels}>
      <a
        href="https://www.hedgeflows.com"
        rel="noreferrer"
        target="_blank"
        className="logo"
      >
        <img src="/assets/imgs/logo.png" alt="Logo" />
        HedgeFlows
      </a>

      <div className="menu-wrap">
        <div className="menu">
          {isAccountant && (
            <Link
              to={getMyClientsLink()}
              className={cx(
                'link',
                pathname.includes('my-clients') && 'active'
              )}
            >
              <Icon icon="briefcase-ico" />
              My Clients
            </Link>
          )}
          <Link
            to="/app/dashboard"
            className={cx('link', pathname.includes('dashboard') && 'active')}
          >
            <Icon icon="dashboard-ico" />
            Dashboard
          </Link>
          {!isAccountant && (
            <>
              <Link
                to={getLink('/app/transfers')}
                className={cx(
                  'link',
                  pathname.includes('transfers') && 'active'
                )}
              >
                <Icon icon="transfer-ico" />
                FX transfer
              </Link>
              <Link
                to={
                  hasBalance
                    ? getLink('/app/currency-exchange?step=1')
                    : getLink('/app/prebook')
                }
                className={cx(
                  'link',
                  (pathname.includes('prebook') ||
                    pathname.includes('currency-exchange')) &&
                    !pathname.includes('prebookings') &&
                    'active'
                )}
              >
                <Icon icon="prebook-filled" />
                Exchange
              </Link>
            </>
          )}

          <Link
            to={getInvoicesPageLink({
              currency: 'all',
              tab: 'outstanding',
            })}
            className={cx('link', pathname.includes('invoices') && 'active')}
          >
            <Icon icon="invoices" />
            Invoices
          </Link>

          {featureFlagById('backtester') && showReports && (
            <Link
              to="/app/reports"
              className={cx('link', pathname.includes('reports') && 'active')}
            >
              <Icon icon="backtester" />
              Reports
            </Link>
          )}
          {!isAccountant && (
            <Link
              to="/app/contacts"
              className={cx('link', pathname.includes('contacts') && 'active')}
            >
              <Icon icon="users-group" />
              Contacts
            </Link>
          )}
        </div>
      </div>
    </Wrapper>
  );
};

export default StaleSidebar;
