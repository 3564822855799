import { FC } from 'react';
import { useTheme } from 'styled-components';

interface OwnProps {
  chartWidth: number;
  chartHeight: number
}

const ChartNetOutstandingLegendsLayer: FC<OwnProps> = ({ chartWidth, chartHeight }) => {
  const { color } = useTheme();

  return (
    <g>
      <circle
        fill={color.green}
        cx={chartWidth / 2 - 150}
        cy={chartHeight + 55}
        r={6}
      />

      <text
        y={chartHeight + 58}
        x={chartWidth / 2 - 140}
        style={{
          fontSize: 12,
        }}
      >
        Turnover
      </text>

      <circle
        fill={color.blueLight}
        cx={chartWidth / 2 - 70}
        cy={chartHeight + 55}
        r={6}
      />

      <text
        y={chartHeight + 58}
        x={chartWidth/ 2 - 60}
        style={{
          fontSize: 12,
        }}
      >
        Outstanding
      </text>
    </g>
  );
};

export default ChartNetOutstandingLegendsLayer;
