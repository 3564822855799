import { FC, useState, useMemo, useEffect } from 'react';
import {
  StalePopup,
  PopupContentWrapper,
  PopupContentContainerWrapped,
  Paragraph,
  Title,
  PopupContentBtnGroupWrapped,
} from 'components';
import { parseIntoCurrencyString } from 'utils';
import { useStoreActions, useStoreState } from 'state';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useHistory } from 'react-router-dom';
import { CustomParagraph } from './ReduceRiskPopup.styles';
import { getLink } from 'utils/links';
import Button from 'components/shared/Button/Button';

interface OwnProps {
  activeCurrency: any;
  setShowPrebookAllPopup: (val: boolean) => void;
}

const ReduceRiskPopup: FC<OwnProps> = ({
  activeCurrency,
  setShowPrebookAllPopup,
}) => {
  const history = useHistory();
  const {
    prebookAll,
    prebookAllCount,
    refreshCashflowsRisksData,
  } = useStoreActions((actions) => actions.ReferenceDataState);
  const { entityId } = useStoreState((state) => state.UserState);
  const { cashflowsRisks } = useStoreState((state) => state.ReferenceDataState);
  const [isPrebookingAll, setIsPrebookingAll] = useState(false);
  const [prebookAllCountAndCost, setPrebookAllCountAndCost] = useState<any>(
    null
  );

  useEffect(() => {
    const getCountAndCost = async () => {
      try {
        const countRes = await prebookAllCount({
          buyCurrency: activeCurrency?.currencyCode,
        });
        if (countRes.success) {
          setPrebookAllCountAndCost(countRes.data);
        }
      } catch (error) {
        //Nothing to do on error
      }
    };

    if (activeCurrency) {
      getCountAndCost();
    }
  }, [prebookAllCount, activeCurrency]);

  const currencyRisk = useMemo(
    () =>
      activeCurrency &&
      cashflowsRisks?.currencyRisk[activeCurrency?.currencyCode],
    [activeCurrency, cashflowsRisks]
  );

  const onClose = () => setShowPrebookAllPopup(false);

  const onPrebookAll = async () => {
    if (!activeCurrency || !entityId) {
      return;
    }

    setIsPrebookingAll(true);

    await prebookAll({ buyCurrency: activeCurrency.currencyCode });

    setIsPrebookingAll(false);

    await refreshCashflowsRisksData({
      entityId,
      sellCurrency: currencyRisk.sellCurrency,
    });

    onClose();
  };

  const onReduceRisks = () => {
    if (!currencyRisk) {
      return;
    }

    dayjs.extend(utc);

    const link = getLink('/app/prebook', {
      predefinedBuyCurrency: currencyRisk.buyCurrency,
      predefinedDate: dayjs(currencyRisk.netExposureEndDate).toISOString(),
      predefinedBuyAmount: currencyRisk.netExposure,
    });

    history.push(link);
  };

  return (
    <StalePopup
      title={'Reduce risks'}
      theme="grey"
      width="439px"
      onClose={onClose}
      contentStyle={{ minHeight: '250px' }}
    >
      <PopupContentWrapper>
        <PopupContentContainerWrapped>
          <Title variant="h3">How do you want to reduce risks?</Title>
          <CustomParagraph variant="bold">
            Option 1: Book rates for all invoices
          </CustomParagraph>

          <Paragraph>
            Fix today's exchange rates
            {prebookAllCountAndCost &&
              ` for ${prebookAllCountAndCost.count} invoices`}
            .<br />
            The booking cost will be added on to the exchange rate
            {prebookAllCountAndCost &&
              ` (expected to be GBP ${parseIntoCurrencyString(
                prebookAllCountAndCost.prebookFee
              )} in total)`}
            .
          </Paragraph>

          <PopupContentBtnGroupWrapped style={{ paddingBottom: 0 }}>
            <Button
              variant="secondary"
              isLoading={isPrebookingAll}
              onClick={onPrebookAll}
            >
              Book for all invoices
            </Button>
          </PopupContentBtnGroupWrapped>
          <CustomParagraph variant="bold">
            Option 2: Decide how much to book
          </CustomParagraph>
          <Paragraph>
            Book guaranteed rates for the period and amount you choose.
            <br />
            {currencyRisk &&
              `We recommend you book 
            ${currencyRisk.buyCurrency} ${parseIntoCurrencyString(
                Math.abs(currencyRisk.netExposure)
              )} 
            until ${dayjs(currencyRisk.netExposureEndDate).format(
              'DD MMM YYYY'
            )} 
            to cover all invoices based on your data`}
          </Paragraph>
          <PopupContentBtnGroupWrapped>
            <Button variant="secondary" onClick={onReduceRisks}>
              Book a single amount
            </Button>
          </PopupContentBtnGroupWrapped>
        </PopupContentContainerWrapped>
      </PopupContentWrapper>
    </StalePopup>
  );
};

export default ReduceRiskPopup;
