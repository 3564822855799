import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Paragraph,
  StaleContainer,
  StaleBtnGroup,
  StalePopup,
} from 'components';
import useXero from 'hooks/useXero';
import { Wrapper } from './StaleXeroReauthenitcatePopup.styles';
import Button from '../Button/Button';

const StaleXeroReauthenticatePopup: FC = () => {
  const history = useHistory();
  const { reauthenticate, onLogin, isLoading, syncMessage } = useXero({
    retrieveStateToken: true,
  });

  const goToDashboard = () => history.push('/app/dashboard');

  if (!reauthenticate) {
    return null;
  }

  const defaultMessage =
    "We've recently made some improvements to this screen. Before you can use it, we need to ask you to reapprove the integration with Xero.";
  return (
    <StalePopup
      title="Reauthenticate with Xero"
      theme="grey"
      width="439px"
      onClose={goToDashboard}
      contentStyle={{ minHeight: '250px' }}
    >
      <Wrapper>
        <>
          <StaleContainer>
            <Paragraph>{syncMessage || defaultMessage}</Paragraph>
          </StaleContainer>

          <StaleBtnGroup container={false}>
            <Button onClick={onLogin} disabled={isLoading}>
              Reauthenticate with Xero
            </Button>
          </StaleBtnGroup>
        </>
      </Wrapper>
    </StalePopup>
  );
};

export default StaleXeroReauthenticatePopup;
