import { action, Action, Computed, computed, thunk, Thunk } from 'easy-peasy';
import { getFeatureFlags } from 'services/featureFlags';
import { IFeatureFlagName, IFeatureFlagsGlobal } from 'types/featureFlags';

export interface FeatureFlagsStateModel {
  isLoadingFeatureFlags: boolean;
  featureFlags: IFeatureFlagsGlobal;
  getFeatureFlags: Thunk<FeatureFlagsStateModel>;
  setFeatureFlags: Action<FeatureFlagsStateModel, IFeatureFlagsGlobal>;
  featureFlagById: Computed<
    FeatureFlagsStateModel,
    (featureFlagId: IFeatureFlagName) => boolean
  >;
}

export const FeatureFlagsState: FeatureFlagsStateModel = {
  isLoadingFeatureFlags: false,
  featureFlags: {
    backtester: false,
  },
  setFeatureFlags: action((state, payload) => {
    state.featureFlags = payload;
  }),
  getFeatureFlags: thunk(async ({ setFeatureFlags }) => {
    try {
      const { data } = await getFeatureFlags();

      if (data.data) {
        setFeatureFlags(data.data);
      }
    } catch (error) {
      console.warn('Failed to get feature flags. Error: ', error);
    }
  }),
  featureFlagById: computed(
    [(state) => state.featureFlags],
    (featureFlags) => (featureFlagId) => featureFlags[featureFlagId]
  ),
};
