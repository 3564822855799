import { FC, useState, useMemo, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import orderBy from 'lodash.orderby';
import { isMobile } from 'react-device-detect';

import {
  StaleTitleH5,
  StaleParagraphMedium,
  Paragraph,
  StaleBtnGroup,
  Button,
  ButtonStyleLink,
  StaleInput,
  StaleInputSelect,
  StaleCarousel,
  StaleTitleH1,
  StaleOverflowScroll,
  Row,
  StaleRadioButton,
} from 'components';
import { SignUpWrap, SignUpNav, SignUpContent, Field } from '..';
import { RowNameInputs, ColRadio } from './StepThree.styles';
import { useStoreState, useStoreActions } from 'state';
import { ENTITY_TYPE, ICountry, RECIPIENT_TYPE } from 'types';
import { ERROR_MESSAGES } from 'variables';

const BUSINESS_TYPES = [
  {
    id: 'soleTrader',
    name: 'Sole Trader',
    value: ENTITY_TYPE.soleTrader,
  },
  {
    id: 'limitedCompany',
    name: 'Limited Company (LTD)',
    value: ENTITY_TYPE.limitedCompany,
  },
  {
    id: 'simplePartnership',
    name: 'Simple Partnership (coming soon)',
    value: ENTITY_TYPE.simplePartnership,
    disabled: true,
  },
  {
    id: 'limitedLiabilityPartnership',
    name: 'Limited Liability Partnership (LLP) (coming soon)',
    value: ENTITY_TYPE.limitedLiabilityPartnership,
    disabled: true,
  },
  {
    id: 'PLC',
    name: 'PLC (coming soon)',
    value: ENTITY_TYPE.publicLimitedCompany,
    disabled: true,
  },
];

enum BUSINESS_TYPE {
  soleTrader = 'soleTrader',
  limitedCompany = 'limitedCompany',
  limitedLiabilityPartnership = 'limitedLiabilityPartnership',
  simplePartnership = 'simplePartnership',
  PLC = 'PLC',
}

type Inputs = {
  firstName: string;
  lastName: string;
  companyName: string;
  country: ICountry | string;
  businessType: BUSINESS_TYPE | string;
};

interface OwnProps {
  onContinueHandler: (values: {
    firstName: string;
    lastName: string;
    companyCountry: string;
    companyType: RECIPIENT_TYPE;
    companyName: string;
  }) => void;
  onNavItemClick: (stepNumber?: number) => void;
  onCloseHandler: () => void;
}

const StepThree: FC<OwnProps> = ({
  onContinueHandler,
  onNavItemClick,
  onCloseHandler,
}) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<Inputs>();

  const { countries } = useStoreState((state) => state.ReferenceDataState);
  const { getCountries } = useStoreActions(
    (actions) => actions.ReferenceDataState
  );

  const [isCompany, setIsCompany] = useState(false);
  const [isAnswered, setIsAnswered] = useState(false);

  useEffect(() => {
    getCountries();
  }, [getCountries]);

  const [companiesSearchValue, setCompaniesSearchValue] = useState('');

  const orderedCountries = useMemo(
    () =>
      orderBy(countries, 'name', 'asc').filter((item) => item.alpha2 !== 'gb'),
    [countries]
  );

  const onSubmit = async ({
    firstName,
    lastName,
    country,
    businessType,
    companyName,
  }) => {
    onContinueHandler({
      firstName,
      lastName,
      companyCountry: isCompany ? 'gb' : country.alpha2,
      companyType: isCompany ? 'limitedCompany' : businessType,
      companyName,
    });
  };

  return (
    <SignUpWrap>
      <SignUpNav>
        {isMobile ? (
          <>
            <StaleTitleH5>Sign up</StaleTitleH5>

            <ButtonStyleLink className="back " onClick={onCloseHandler}>
              <svg width="24px" height="24px">
                <use xlinkHref="#cross-ico" />
              </svg>
            </ButtonStyleLink>

            <div className="row">
              <div className="item passed" onClick={() => onNavItemClick()}>
                Create account
              </div>
              <div className="item passed" onClick={() => onNavItemClick(2)}>
                Confirm email
              </div>
              <div className="item current">Basic details</div>
            </div>
          </>
        ) : (
          <>
            <StaleTitleH5>Let’s sign you up to HedgeFlows</StaleTitleH5>

            <StaleParagraphMedium>
              You are about 10 minutes away from having access to our best
              features:{' '}
            </StaleParagraphMedium>

            <StaleCarousel />
          </>
        )}
      </SignUpNav>

      <SignUpContent className="rounded">
        <StaleOverflowScroll>
          <div className="block">
            {!isMobile && (
              <ButtonStyleLink className="cross" onClick={onCloseHandler}>
                <svg width="24" height="24">
                  <use xlinkHref="#cross-ico" />
                </svg>
              </ButtonStyleLink>
            )}

            {isMobile ? (
              <StaleTitleH5>Basic info</StaleTitleH5>
            ) : (
              <StaleTitleH1 style={{ marginBottom: 24 }}>
                Basic info
              </StaleTitleH1>
            )}

            <form>
              <Field>
                <Paragraph>What is your name?</Paragraph>

                <RowNameInputs>
                  <StaleInput
                    id="first-name"
                    label="First name"
                    view="moving"
                    type="text"
                    name="firstName"
                    control={control}
                    rules={{
                      required: ERROR_MESSAGES.requiredField,
                    }}
                    autoFocus
                    error={errors?.firstName?.message}
                  />

                  <StaleInput
                    id="last-name"
                    label="Last name"
                    view="moving"
                    type="text"
                    name="lastName"
                    control={control}
                    rules={{
                      required: ERROR_MESSAGES.requiredField,
                    }}
                    error={errors?.lastName?.message}
                  />
                </RowNameInputs>
              </Field>

              <Field>
                <Paragraph>
                  What's the name of the business you represent?
                </Paragraph>

                <StaleInput
                  id="entity-name"
                  label="Business name"
                  view="moving"
                  type="text"
                  name="companyName"
                  control={control}
                  rules={{
                    required: ERROR_MESSAGES.requiredField,
                  }}
                  error={errors?.companyName?.message}
                />
              </Field>

              <ColRadio>
                <Paragraph>
                  Are you representing a Limited Liability company registered in
                  UK?
                </Paragraph>

                <Row>
                  <Controller
                    name="isCompany"
                    control={control}
                    defaultValue={null}
                    render={({ name }) => (
                      <StaleRadioButton
                        onChange={(item) => {
                          setIsAnswered(true);
                          setIsCompany(item.value);
                        }}
                        list={[
                          {
                            id: 'isCompany-yes',
                            value: true,
                            checked: isAnswered && isCompany,
                            name: <p>Yes</p>,
                          },
                          {
                            id: 'isCompany-no',
                            value: false,
                            checked: isAnswered && !isCompany,
                            name: <p>No</p>,
                          },
                        ]}
                        name={name}
                        cardCol
                      />
                    )}
                  />
                </Row>
              </ColRadio>

              {isAnswered && !isCompany && (
                <>
                  {!!orderedCountries.length && (
                    <Field>
                      <Controller
                        control={control}
                        name="country"
                        defaultValue={null}
                        rules={{ required: ERROR_MESSAGES.requiredField }}
                        render={({ onChange, value }) => {
                          return (
                            <StaleInputSelect
                              id="country"
                              label="Country of business registration"
                              view="moving"
                              data={[
                                countries.find((item) => item.id === 'GB'),
                                ...orderedCountries,
                              ]
                                .filter((item) =>
                                  item?.name
                                    .toLowerCase()
                                    .trim()
                                    .includes(
                                      companiesSearchValue.toLowerCase().trim()
                                    )
                                )
                                .map((item) => ({
                                  name: item?.name ?? '',
                                  id: item?.name,
                                  icon: item?.alpha2.toLowerCase(),
                                  value: { ...item, id: item?.name },
                                }))}
                              selected={value}
                              onSelect={(item) => {
                                onChange(item.value);
                              }}
                              withSearch
                              searchValue={companiesSearchValue}
                              onSearch={(e) =>
                                setCompaniesSearchValue(e.currentTarget.value)
                              }
                              onClose={() => setCompaniesSearchValue('')}
                              error={errors?.country?.message}
                            />
                          );
                        }}
                      />
                    </Field>
                  )}

                  <Field>
                    <Controller
                      control={control}
                      name="businessType"
                      defaultValue={null}
                      rules={{ required: ERROR_MESSAGES.requiredField }}
                      render={({ onChange, value }) => {
                        return (
                          <StaleInputSelect
                            id="businessType"
                            label="Type of business"
                            view="moving"
                            data={BUSINESS_TYPES}
                            selected={value}
                            onSelect={(item) => {
                              onChange(item.value);
                            }}
                            error={errors?.businessType?.message}
                          />
                        );
                      }}
                    />
                  </Field>
                </>
              )}
            </form>

            <StaleBtnGroup>
              <Button disabled={!isAnswered} onClick={handleSubmit(onSubmit)}>
                Continue
              </Button>
            </StaleBtnGroup>
          </div>
        </StaleOverflowScroll>
      </SignUpContent>
    </SignUpWrap>
  );
};

export default StepThree;
