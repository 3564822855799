import { FC } from 'react';
import {
  StaleFinalStepInfoRow,
  Row,
  FinalStepInfoWrapper,
  Title,
} from 'components';
import { RedHint } from './StaleFinalStep.styles';
import useDeviceWidth from 'hooks/useDeviceWidth';

interface OwnProps {
  fundingAccount: any;
  ccShortReference?: string;
  sellCurrencyCode?: string;
}

const StaleFinalStepInfo: FC<OwnProps> = ({
  fundingAccount,
  ccShortReference,
  sellCurrencyCode,
}) => {
  const { isMobile } = useDeviceWidth();

  return (
    <FinalStepInfoWrapper>
      <StaleFinalStepInfoRow
        title="Account name"
        text="The Currency Cloud Limited"
      />

      {sellCurrencyCode !== 'GBP' ? (
        <>
          <StaleFinalStepInfoRow
            title="SWIFT / BIC"
            text={fundingAccount?.bicSwift}
            notification="Copied SWIFT / BIC to clipboard!"
            variant="copy"
          />

          <StaleFinalStepInfoRow
            title="IBAN"
            text={fundingAccount?.iban}
            notification="Copied IBAN to clipboard!"
            variant="copy"
          />
        </>
      ) : (
        <>
          <StaleFinalStepInfoRow
            title="Account Number"
            text={fundingAccount?.accountNumber}
            notification="Copied account number to clipboard!"
            variant="copy"
          />

          <StaleFinalStepInfoRow
            title="Sort Code"
            text={fundingAccount?.sortCode}
            notification="Copied sort code to clipboard!"
            variant="copy"
          />
        </>
      )}

      <StaleFinalStepInfoRow
        title="Reference"
        text={ccShortReference ?? ''}
        notification="Copied reference to clipboard!"
        variant="copy"
      />

      <Row justifyContent="flex-end">
        <RedHint>
          Please ensure the reference is added to your payment details.
        </RedHint>
      </Row>

      {!isMobile && (
        <>
          <Row mt>
            <Title variant="h4">Optional details</Title>
          </Row>

          <StaleFinalStepInfoRow title="Bank name" text="Barclays Bank plc" />
          <StaleFinalStepInfoRow
            title="Bank address"
            text="1 Churchill Place, London, E14 546"
          />

          {sellCurrencyCode === 'GBP' && (
            <>
              <StaleFinalStepInfoRow
                title="SWIFT / BIC"
                text={fundingAccount?.bicSwift}
              />
              <StaleFinalStepInfoRow title="IBAN" text={fundingAccount?.iban} />
            </>
          )}
        </>
      )}
    </FinalStepInfoWrapper>
  );
};

export default StaleFinalStepInfo;
