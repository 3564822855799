import { PrebookTable } from 'components';
import { isExternalHedge } from 'components/shared/PrebookTable/utils';
import useUrlValues from 'hooks/useUrlValues';
import { FC, useCallback } from 'react';
import InvoicesTable from '../InvoicesTable/InvoicesTable';
import PaymentRunInvoicesTable from '../PaymentRunInvoicesTable/PaymentRunInvoicesTable';

const Tables: FC = () => {
  const { setUrlValue, tab, currency } = useUrlValues('tab', 'currency');

  const onPrebookTableRowClick = useCallback(
    (record) =>
      setUrlValue(
        isExternalHedge(record) ? 'hedgeId' : 'contractId',
        record.id
      ),
    [setUrlValue]
  );

  if (tab === 'prebookings') {
    // TODO: remove currencyCode prop, shared table should be more generic
    return (
      <PrebookTable
        isVirtualized
        currencyCode={currency ?? undefined}
        onRowClick={onPrebookTableRowClick}
      />
    );
  }

  if (tab === 'paymentRun') {
    return <PaymentRunInvoicesTable />;
  }

  return <InvoicesTable />;
};

export default Tables;
