import { IInvoice, IInvoiceApproval, INVOICE_STATUSES } from 'types';
import dayjs from 'dayjs';
import { getLink } from './links';
import { IInvoiceForTable } from 'components/shared/InvoicesTable/types';
import { isDateOverdue } from './dates';

export const isReceivableInvoice = (invoice: IInvoice) =>
  invoice.type === 'Receivable';

export const isPayableInvoice = (invoice: IInvoice) =>
  invoice.type === 'Payable';

export const isInvoiceNotPaid = (invoice: IInvoice) =>
  invoice.status !== INVOICE_STATUSES.paid;

export const isInvoiceFromXero = (invoice: IInvoice) =>
  !!invoice.externalRefs?.xeroId;

export const isInvoiceFromCodat = (invoice: IInvoice) =>
  !!invoice.externalRefs?.codatId;

export const getInvoiceDaysLeft = (invoice: IInvoice) =>
  dayjs(invoice.dueDate).endOf('day').diff(dayjs().startOf('day'), 'days');

export const isInvoiceCanBePaid = (invoice: IInvoice) =>
  invoice.status === INVOICE_STATUSES.authorised ||
  invoice.status === INVOICE_STATUSES.submitted ||
  invoice.status === INVOICE_STATUSES.partiallyPaid;

export const isInvoiceDisabled = (invoice: IInvoice) =>
  invoice.status === INVOICE_STATUSES.voided ||
  invoice.status === INVOICE_STATUSES.deleted ||
  !!invoice.excludeFromRisk;

export const isInvoiceDeletable = (invoice: IInvoice) =>
  !invoice?.contractId && !invoice?.transferId;

export const isInvoicePrebookable = (invoice: IInvoice) =>
  !invoice?.contractId &&
  !invoice.trackingId &&
  ((isPayableInvoice(invoice) && isInvoiceCanBePaid(invoice)) ||
    isReceivableInvoice(invoice));

export const isInvoiceTrackable = (invoice: IInvoice) =>
  invoice.status !== INVOICE_STATUSES.paymentScheduled;

export const isInvoiceHasValidContact = (invoice: IInvoice) =>
  !!invoice.contact && !!invoice.contactId && !!invoice.contact.enabled;

export const isInvoiceApprovable = (invoice: IInvoice) =>
  isPayableInvoice(invoice) &&
  isInvoiceHasValidContact(invoice) &&
  isInvoiceCanBePaid(invoice) &&
  invoice.approval?.status === 'submitted';

export const isInvoiceSubmittableForReview = (invoice: IInvoice) =>
  isPayableInvoice(invoice) &&
  isInvoiceHasValidContact(invoice) &&
  isInvoiceCanBePaid(invoice) &&
  !invoice.approval?.status;

export const getInvoiceRemainingAmount = (invoice: IInvoice) =>
  invoice.status === INVOICE_STATUSES.partiallyPaid ||
  (invoice.status === INVOICE_STATUSES.purchaseOrder &&
    invoice.amountDue !== undefined)
    ? invoice.amountDue ?? 0
    : invoice.totalAmount;

export const isInvoiceRowSelectable = (invoice: IInvoice) =>
  invoice.status !== INVOICE_STATUSES.paid &&
  !isInvoiceDisabled(invoice) &&
  isInvoiceHasValidContact(invoice) &&
  !isReceivableInvoice(invoice) &&
  isInvoiceCanBePaid(invoice);

export const getInvoiceTransferLink = (invoice: IInvoice) => {
  if (!invoice.id) {
    return '';
  }

  return getLink('/app/transfers', {
    step: '1',
    invoiceId: invoice.id,
    predefinedBuyCurrency: invoice.currency,
    predefinedBuyAmount: getInvoiceRemainingAmount(invoice).toString(),
    predefinedRateContractId: invoice.contractId,
  });
};

export const getInvoicePrebookLink = (invoice: IInvoice) => {
  if (invoice.type === 'Receivable') {
    return getLink('/app/prebook', {
      step: '2',
      invoiceId: invoice.id,
      // TODO: replace GBP with entity default currency when we have it
      predefinedBuyCurrency: 'GBP',
      predefinedSellCurrency: invoice.currency,
      predefinedSellAmount: getInvoiceRemainingAmount(invoice).toString(),
      predefinedDate: dayjs(invoice.dueDate).toISOString(),
    });
  }

  return getLink('/app/prebook', {
    step: '2',
    invoiceId: invoice.id,
    predefinedBuyCurrency: invoice.currency,
    predefinedBuyAmount: getInvoiceRemainingAmount(invoice).toString(),
    predefinedDate: dayjs(invoice.dueDate).toISOString(),
  });
};

export const getInvoiceInnerLink = (invoice: IInvoice) =>
  `/app/invoices/${invoice.id}`;

export const getInvoiceManualUploadLink = () => `/app/invoices/manual-upload`;

export const getInvoiceNumber = (invoice: IInvoice) =>
  invoice?.invoiceNumber || '"no ref"';

export const getInvoiceApprovalNotificationMessage = (
  approvalStatus: IInvoiceApproval['status'],
  isMoreThenOneInvoice: boolean
) => {
  const invoiceMsg = isMoreThenOneInvoice ? 'Invoices have' : 'Invoice has';
  if (approvalStatus === 'submitted') {
    return `${invoiceMsg} been submitted for approval`;
  }
  if (approvalStatus === 'approved') {
    return `${invoiceMsg} been approved`;
  }

  return `${invoiceMsg} been rejected`;
};

export const appendRateAndProfitAndLossToInvoice = (
  invoice: IInvoice,
  rateToUse?: number
): IInvoiceForTable => {
  const invoiceRate = getInvoiceRate(invoice, rateToUse);
  const profitAndLoss = generateProfitAndLoss(invoice, invoiceRate, rateToUse);
  return { ...invoice, profitAndLoss, invoiceRate };
};

const getInvoiceRate = (record: IInvoice, rateToUse?: number) => {
  if (!record.externalCurrencyRate) {
    return rateToUse ?? null;
  }

  if (isInvoiceFromCodat(record) && isReceivableInvoice(record)) {
    return 1 / record.externalCurrencyRate;
  }

  return record.externalCurrencyRate;
};

const generateProfitAndLoss = (
  record: IInvoice,
  invoiceRate: number | null,
  rateToUse?: number
) => {
  if (!invoiceRate || !rateToUse) {
    return null;
  }
  const profitAndLoss =
    record.totalAmount / invoiceRate - record.totalAmount / rateToUse;
  return isReceivableInvoice(record) ? -1 * profitAndLoss : profitAndLoss;
};

export const isOutstandingInvoice = ({ status, type }: IInvoice) =>
  (type === 'Receivable' && status === INVOICE_STATUSES.authorised) ||
  status === INVOICE_STATUSES.partiallyPaid ||
  status === INVOICE_STATUSES.authorised ||
  status === INVOICE_STATUSES.paymentScheduled ||
  status === INVOICE_STATUSES.processingPayment;

export const isPaidInvoice = ({ status }: IInvoice) =>
  status === INVOICE_STATUSES.paid;

export const isApprovedInvoice = ({ approval, status }: IInvoice) =>
  approval?.status === 'approved' && status !== INVOICE_STATUSES.paid;

export const isSubmittedInvoice = ({ approval, status }: IInvoice) =>
  approval?.status === 'submitted' && status !== INVOICE_STATUSES.paid;

export const isInvoiceOverdue = (invoice: IInvoice) =>
  isDateOverdue(invoice.dueDate) &&
  isPayableInvoice(invoice) &&
  invoice.status !== INVOICE_STATUSES.paid;

export const isInvoiceDueIn14Days = (invoice: IInvoice) => {
  const daysLeft = getInvoiceDaysLeft(invoice);

  return (
    daysLeft <= 14 &&
    daysLeft > 0 &&
    isPayableInvoice(invoice) &&
    invoice.status !== INVOICE_STATUSES.paid
  );
};
