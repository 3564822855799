import styled from 'styled-components';
import { StaleField } from 'components/shared/StaleField/StaleField';
import { Col } from 'components/shared/Col/Col';

export const SettingsContent = styled(Col)`
  flex: 1;
  align-self: stretch;
  padding: ${({ theme }) => `${theme.spacing.xxl} ${theme.spacing.xxxl}`};
`;

export const SettingsHeader = styled(SettingsContent)`
  padding: ${({ theme }) => `${theme.spacing.xxl} ${theme.spacing.xxxl} 0`};
`;

export const SettingsFooter = styled(SettingsContent)`
  border-top: 1px solid ${({ theme }) => theme.color.greyLight_2};
`;

export const SettingsField = styled(StaleField)`
  margin-bottom: ${({ theme }) => theme.spacing.m};
`;
