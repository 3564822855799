import { Col, Row } from 'components';
import styled from 'styled-components';

export const TopContentWrapper = styled(Row)`
  justify-content: flex-start;
  height: 38%;
`;

export const BottomContentWrapper = styled(Col)`
  height: 62%;
`;
