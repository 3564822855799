import { FC, CSSProperties } from 'react';
import { StaleTextHint, Subtitle, Col } from 'components';
import { Wrapper, MenuItem } from './StaleRegistrationNav.styles';
import { GradientTitle } from '../GradientTitle/GradientTitle.styles';
import { useTheme } from 'styled-components';

interface ItemNavMenu {
  id: number;
  name: string;
  description?: string;
  step?: string;
  onClick?: () => void;
}

interface OwnProps {
  title: string;
  description?: string;
  navMenu: ItemNavMenu[];
  activeStep: number;
  wrapperStyle?: CSSProperties;
}

const StaleRegistrationNav: FC<OwnProps> = ({
  title,
  description,
  navMenu,
  activeStep,
  wrapperStyle,
}) => {
  const theme = useTheme();
  return (
    <Wrapper style={wrapperStyle}>
      <GradientTitle variant="h1">{title}</GradientTitle>

      {description && (
        <Subtitle mb mbValue={theme.spacing.m} color="white">
          {description}
        </Subtitle>
      )}

      <Col>
        {navMenu.map(({ id, onClick, step, name, description }) => (
          <MenuItem
            key={id}
            passed={id < activeStep}
            current={id === activeStep}
            className="item"
            onClick={() => onClick?.()}
          >
            {step && <span>{step}</span>}
            <strong>{name}</strong>
            {description && <StaleTextHint>{description}</StaleTextHint>}
          </MenuItem>
        ))}
      </Col>
    </Wrapper>
  );
};

export default StaleRegistrationNav;
