import { ButtonStyleLink, Col, Paragraph, Subtitle } from 'components';
import { RowInfo } from 'components/shared/RowInfo/RowInfo.styles';
import { FC } from 'react';
import { isMobile } from 'react-device-detect';
import { useHistory } from 'react-router';
import { useStoreState } from 'state';
import { ITransfer, RATE_TYPE } from 'types';

import {
  getTransferStatusDetails,
  parseIntoCurrencyString,
  parseRateWithPrecision,
} from 'utils';
import { getDashboardPageLink } from 'utils/links';

interface OwnProps {
  // TODO: sometimes it is IBUlkPayment, fix types
  transfer: ITransfer;
}

const TransferDetails: FC<OwnProps> = ({ transfer }) => {
  const { currencyByCode } = useStoreState(
    ({ CurrenciesState }) => CurrenciesState
  );
  const statusInfo = getTransferStatusDetails(transfer);
  const history = useHistory();

  return (
    <Col flex={1} alignSelf="stretch">
      <Subtitle variant="bold" mb>
        Transfer details
      </Subtitle>

      {transfer && (
        <>
          <RowInfo>
            <Paragraph>Amount to transfer</Paragraph>

            <Paragraph variant="bold">
              {`${parseIntoCurrencyString(
                transfer.buyAmount,
                currencyByCode(transfer.buyCurrency)?.precision
              )} ${transfer.buyCurrency}`}
            </Paragraph>
          </RowInfo>

          <RowInfo>
            <Paragraph>Transfer type</Paragraph>
            <Paragraph>
              {transfer.transferType === 'priority'
                ? 'Priority (SWIFT)'
                : 'Regular'}
            </Paragraph>
          </RowInfo>

          <RowInfo>
            <Paragraph>Payment fee</Paragraph>
            <Paragraph>{`${parseIntoCurrencyString(
              transfer.priorityTransferFeeAmount || 0,
              currencyByCode(transfer.sellCurrency)?.precision
            )} ${transfer.sellCurrency}`}</Paragraph>
          </RowInfo>

          <RowInfo>
            <Paragraph variant="bold">
              {transfer.status === 'awaiting_payment'
                ? 'Amount to pay'
                : 'Amount paid'}
            </Paragraph>
            <Paragraph variant="bold">
              {`${parseIntoCurrencyString(
                transfer?.payAmount,
                currencyByCode(transfer.sellCurrency)?.precision
              )} ${transfer?.sellCurrency}`}
            </Paragraph>
          </RowInfo>

          <RowInfo>
            <Paragraph>Exchange rate</Paragraph>
            <Col>
              <Paragraph>{`1 ${
                transfer?.sellCurrency
              } = ${parseRateWithPrecision(transfer?.rate)} ${
                transfer?.buyCurrency
              }`}</Paragraph>
              {transfer.rateType === RATE_TYPE.prebooked && (
                <ButtonStyleLink
                  style={{
                    textDecoration: 'underline',
                  }}
                  onClick={() =>
                    history.push(
                      getDashboardPageLink({ contractId: transfer?.contractId })
                    )
                  }
                >
                  <Paragraph>Prebooked</Paragraph>
                </ButtonStyleLink>
              )}
            </Col>
          </RowInfo>

          {transfer?.bulkPaymentId && (
            <RowInfo>
              <Paragraph variant="bold">
                This transfer is part of a Bulk Payment
              </Paragraph>
            </RowInfo>
          )}

          {statusInfo && isMobile && (
            <RowInfo>
              <Paragraph>Status</Paragraph>
              <Paragraph>{statusInfo.text}</Paragraph>
            </RowInfo>
          )}
        </>
      )}
    </Col>
  );
};

export default TransferDetails;
