import React, { FC } from 'react';
import {
  StaleTitleH4,
  StaleParagraphMedium,
  Button,
  ButtonSecondary,
  StaleBtnGroup,
} from 'components';
import { Wrapper } from './StaleCancelTransfer.styles';

interface OwnProps {
  onCancel: () => void;
  onContinue: () => void;
  onClose: () => void;
}

const CancelTransfer: FC<OwnProps> = ({ onCancel, onContinue, onClose }) => (
  <Wrapper>
    <div className="popup">
      <button className="close" onClick={onClose}>
        <svg width="24" height="24">
          <use xlinkHref="#cross-ico" />
        </svg>
      </button>

      <StaleTitleH4>Cancel transfer?</StaleTitleH4>

      <StaleParagraphMedium>
        Are you sure you want to close transfer process? Changes will not be
        saved.
      </StaleParagraphMedium>

      <StaleBtnGroup horizontal container={false}>
        <ButtonSecondary onClick={onContinue}>Continue</ButtonSecondary>
        <Button onClick={onCancel}>Yes, cancel</Button>
      </StaleBtnGroup>
    </div>
  </Wrapper>
);

export default CancelTransfer;
