import styled from 'styled-components';
import { Col } from 'components/shared/Col/Col';
import { Row } from 'components/shared/Row/Row';
import { Link } from 'react-router-dom';

export const SidebarMobileWrapper = styled(Col)<{ show: boolean }>`
  position: fixed;
  top: 0;
  bottom: 0;
  width: 220px;
  left: ${({ show }) => (show ? 0 : '-220px')};
  z-index: ${({ theme }) => theme.zIndex.drawer};
  transition: left ${({ theme }) => theme.transition} ease;
  box-shadow: ${({ theme }) => theme.shadow};
`;

export const SidebarMobileHeader = styled(Row)`
  padding: ${({ theme }) => `${theme.spacing.m} ${theme.spacing.m}`};
  background-color: ${({ theme }) => theme.color.white};
  border-bottom: ${({ theme }) => `1px solid ${theme.color.greyLight_2}`};
`;

export const CloseButton = styled.button`
  display: flex;
  align-items: center;
  height: 32px;
  margin-left: ${({ theme }) => theme.spacing.xxs};
`;

export const Nav = styled(Col)`
  background-color: ${({ theme }) => theme.color.white};
  padding: ${({ theme }) => theme.spacing.m};
  gap: ${({ theme }) => theme.spacing.m};
  overflow-y: auto;
  flex: 1;
`;

export const NavLink = styled(Link)<{ active: boolean }>`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => `${theme.spacing.s}`};

  line-height: ${({ theme }) => theme.fontSize.xxl};
  font-size: ${({ theme }) => theme.fontSize.m};
  color: ${({ theme, active }) =>
    active ? theme.color.emeraldDark : theme.color.greyDark};

  svg {
    fill: ${({ theme, active }) =>
      active ? theme.color.emeraldDark : theme.color.greyDark};
  }
`;
