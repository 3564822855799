import { useState } from 'react';
import { useHistory } from 'react-router';
import { cancelContract } from 'services/firebase';
import { Notify } from 'utils';
import useQuery from './useQuery';
import {
  StaleCancellingBalance,
  StalePopupDeleteConfirmation,
} from 'components';

const useCancelDeletePrebook = () => {
  const history = useHistory();
  const URLQuery = useQuery();
  const cancelContractId = URLQuery.get('cancelContractId');
  const deleteContractId = URLQuery.get('deleteContractId');

  const [isLoading, setIsLoading] = useState(false);

  const onCancelPrebook = async () => {
    try {
      if (!deleteContractId) {
        return;
      }
      setIsLoading(true);
      const { data } = await cancelContract(deleteContractId);
      if (data.success) {
        Notify.success('Prebook canceled successfully');
        history.goBack();
      } else {
        Notify.error(data?.message ?? '');
        setIsLoading(false);
      }
    } catch (error) {
      Notify.error(error.message);
      history.goBack();
      setIsLoading(false);
    }
  };

  return () => {
    if (cancelContractId) {
      return (
        <StaleCancellingBalance
          prebookExchangeId={cancelContractId}
          onClose={history.goBack}
        />
      );
    }

    if (deleteContractId) {
      return (
        <StalePopupDeleteConfirmation
          type="cancel"
          title="Cancel Prebook?"
          text="Please confirm that you want to cancel this prebook."
          onCancel={history.goBack}
          onClose={history.goBack}
          onContinue={() => {
            onCancelPrebook();
          }}
          isLoading={isLoading}
        />
      );
    }

    return null;
  };
};

export default useCancelDeletePrebook;
