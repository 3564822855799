import { FC, CSSProperties } from 'react';
import { useTheme } from 'styled-components';
import Icon from '../Icon/Icon';
import { StaleLoaderWrapper } from './StaleLoader.styles';

interface OwnProps {
  size?: 'small' | 'medium' | 'large';
  withBackdrop?: boolean;
  style?: CSSProperties;
}

const StaleLoader: FC<OwnProps> = ({
  size = 'small',
  withBackdrop = false,
  style,
}) => {
  const theme = useTheme();

  return (
    <StaleLoaderWrapper
      style={{
        ...style,
        backgroundColor: withBackdrop
          ? theme.color.backdropLight
          : theme.color.transparent,
      }}
      data-testid="loader"
      size={size}
    >
      <Icon icon="loader-ico" width="16px" height="16px" />
    </StaleLoaderWrapper>
  );
};

export default StaleLoader;
