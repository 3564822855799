import { FC, useEffect, useState } from 'react';
import { useQuery } from 'hooks';
import { StaleCarousel, StaleLoader } from 'components';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { SliderWrapper } from 'pages/RiskSettings/RiskSettings.styles';
import FullSizeStepsTemplate from 'components/pageTemplates/FullSizeStepsTemplate/FullSizeStepsTemplate';
import StepCreatingClient from './components/StepCreatingClient/StepCreatingClient';
import useXero from 'hooks/useXero';
import { GradientTitle } from 'components/shared/GradientTitle/GradientTitle.styles';
import useNewClientXero from './useNewClientXero';
import { getMyClientsLink } from 'utils/links';

const NewClientXero: FC = () => {
  const history = useHistory();
  const URLQuery = useQuery();
  const code = URLQuery.get('code');
  const activeStep = URLQuery.get('step');
  const firstIntegration = URLQuery.get('firstIntegration');
  const queryError = URLQuery.get('error');
  const isCanceledFromXero = queryError === 'access_denied';
  const { url } = useRouteMatch();
  const { onSignUp } = useXero();
  const [isLoading, setIsLoading] = useState(!code);
  const activeStepAsNumber = activeStep && parseInt(activeStep);

  const {
    createNewClientEntity,
    companyName,
    isCompleted,
    isCreatingEntity,
    isError,
  } = useNewClientXero();

  useEffect(() => {
    if (!activeStep) {
      URLQuery.append('step', '1');
      history.push(`${url}?${URLQuery.toString()}`);
    }
  }, [activeStep, history, url, firstIntegration, URLQuery]);

  useEffect(() => {
    if (isCanceledFromXero) {
      setIsLoading(false);
      return;
    }
    if (!code) {
      onSignUp();
    } else {
      setIsLoading(false);
      if (!isCreatingEntity && !isCompleted && !isError) {
        createNewClientEntity();
      }
    }
  }, [
    code,
    createNewClientEntity,
    isCompleted,
    isCreatingEntity,
    isError,
    onSignUp,
    isCanceledFromXero,
  ]);

  useEffect(() => {
    if (isCompleted && !isError) {
      setTimeout(() => history.push(getMyClientsLink()), 5000);
    }
  }, [history, isCompleted, isError]);

  if (isLoading || !activeStep || !activeStepAsNumber) {
    return <StaleLoader size="large" />;
  }

  return (
    <FullSizeStepsTemplate
      onClose={() => history.push(getMyClientsLink())}
      leftColumn={
        <>
          {activeStepAsNumber < 2 && (
            <GradientTitle variant="h1">
              Let’s sign up a new client to HedgeFlows
            </GradientTitle>
          )}

          {activeStepAsNumber === 2 && (
            <GradientTitle variant="h1">
              Now you’re ready to start:
            </GradientTitle>
          )}

          <SliderWrapper>
            <StaleCarousel />
          </SliderWrapper>
        </>
      }
      rightColumn={
        activeStep && (
          <StepCreatingClient
            isCompleted={isCompleted}
            isError={isError}
            isCanceledFromXero={isCanceledFromXero}
            isCreatingEntity={isCreatingEntity}
            companyName={companyName}
          />
        )
      }
    />
  );
};

export default NewClientXero;
