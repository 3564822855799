import { FC } from 'react';
import Button from '../Button/Button';
import { PlaceholderWrapper, PlaceholderParagraph } from './Placeholder.styles';

interface OwnProps {
  actionText?: string;
  onAction?: () => void;
  description?: string;
  fullWidth?: boolean;
}

const Placeholder: FC<OwnProps> = ({
  actionText,
  onAction,
  description,
  fullWidth,
  children,
}) => (
  <PlaceholderWrapper fullWidth={fullWidth}>
    {children}
    {!!actionText && !!onAction && (
      <Button variant="link" onClick={onAction}>
        {actionText}
      </Button>
    )}
    {!!description && (
      <PlaceholderParagraph color="grey" mt>
        {description}
      </PlaceholderParagraph>
    )}
  </PlaceholderWrapper>
);
export default Placeholder;
