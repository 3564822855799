import styled, { css } from 'styled-components';

export const Wrapper = styled.div(
  ({ theme }) => css`
    background: #ffbf00;
    text-align: center;
    color: red;
    font-weight: bold;
  `
);
