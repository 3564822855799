import { FC } from 'react';
import { useHistory } from 'react-router';
import { GoBackWrapper } from './GoBack.styles';
import { Subtitle } from '../Typography/Typography';
import Icon from '../Icon/Icon';
import { FlexProps } from '../Flex/Flex.styles';

interface GoBackProps extends FlexProps {
  text?: string;
}
const GoBack: FC<GoBackProps> = ({ text = 'Back', ...rest }) => {
  const history = useHistory();
  return (
    <GoBackWrapper as="button" onClick={history.goBack} {...rest}>
      <Icon icon="arrow-left" />
      <Subtitle>{text}</Subtitle>
    </GoBackWrapper>
  );
};

export default GoBack;
