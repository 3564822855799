import { useState, useEffect } from 'react';
import firebase from 'firebase/app';

const useRecaptchaVerifier = (containerId: string, callback?: () => {}) => {
  const [
    recaptchaVerifier,
    setRecaptchaVerifier,
  ] = useState<firebase.auth.RecaptchaVerifier | null>(null);

  useEffect(() => {
    if (!recaptchaVerifier) {
      const container = new firebase.auth.RecaptchaVerifier(containerId, {
        size: 'invisible',
        callback: function (response) {
          // reCAPTCHA solved
          callback?.();
        },
      });

      setRecaptchaVerifier(container);
    }
  }, [recaptchaVerifier, containerId, callback]);

  return recaptchaVerifier;
};

export default useRecaptchaVerifier;
