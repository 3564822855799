import {
  Title,
  StaleTitleH5,
  StaleParagraphMedium,
  StaleCarousel,
  ButtonStyleLink,
  StaleInputSelect,
  Icon,
  SignUpContent,
  SignUpNav,
  SignUpWrap,
  Paragraph,
  Col,
} from 'components';
import { Block } from '../../SignUp.styles';
import { FC, useState } from 'react';
import { Notify } from 'utils';
import InfoCard from 'components/shared/InfoCard/InfoCard';
import { useHistory } from 'react-router-dom';
import { useStoreActions, useStoreState } from 'state';
import { useTheme } from 'styled-components';
import { saveRegistrationSettings } from 'services/entities';
import { RECIPIENT_TYPE } from 'types';
import Field from 'components/shared/Field/Field.styles';
import { getSettingsPageLink } from 'utils/links';
import { ISelectItem } from 'components/shared/StaleInputSelect/StaleInputSelect';
import Button from 'components/shared/Button/Button';

const accountingSystems: ISelectItem[] = [
  {
    id: 'xero',
    value: 'xero',
    name: 'Xero',
    icon: 'xero-ico',
  },
  {
    id: 'hbql',
    value: 'hbql',
    name: 'Sage 50',
    icon: 'sage50Xml-ico',
  },
  {
    id: 'ndsk',
    value: 'ndsk',
    name: 'QuickBooks',
    icon: 'quick-books-ico',
  },
  {
    id: 'trji',
    value: 'trji',
    name: 'Dynamics 365 Business Central',
    icon: 'dynamics-365-ico',
  },
  {
    id: 'other',
    value: 'other',
    name: 'Other',
    icon: 'other-ico',
  },
];

const howDidYouFindUsData: ISelectItem[] = [
  {
    id: 'searchEngine',
    value: 'searchEngine',
    name: 'Search engine',
  },
  {
    id: 'socialMedia',
    value: 'socialMedia',
    name: 'Social media',
  },
  {
    id: 'publication',
    value: 'publication',
    name: 'Blog or publication',
  },
  {
    id: 'xero',
    value: 'xero',
    name: 'Xero Marketplace/Forums',
  },
  {
    id: 'recommendation',
    value: 'recommendation',
    name: 'Recommendation',
  },
  {
    id: 'other',
    value: 'other',
    name: 'Other',
  },
];

interface OwnProps {
  firstName: string;
  lastName: string;
  companyCountry: string;
  companyType: RECIPIENT_TYPE;
  companyName: string;
  onNavItemClick: (stepNumber?: number) => void;
  onCloseHandler: () => void;
}

const StepFour: FC<OwnProps> = ({
  firstName,
  lastName,
  companyCountry,
  companyName,
  companyType,
}) => {
  const [isGlobalOn, setIsGlobalOn] = useState(false);
  const [isAutomationOn, setIsIAutomationOn] = useState(false);
  const [isRiskManagementOn, setIsRiskManagementOn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const shouldIntegrate = false;
  const history = useHistory();

  const { userEmail } = useStoreState((state) => state.UserState);
  const { systemVariables } = useStoreState(
    (state) => state.ReferenceDataState
  );
  const { sellCurrencies, currencyByCode } = useStoreState(
    (state) => state.CurrenciesState
  );
  const { createUser } = useStoreActions(({ UserState }) => UserState);
  const [sellCurrency, setSellCurrency] = useState(
    currencyByCode(systemVariables?.defaultSellCurrency)
  );
  const [accountingSystem, setAccountingSystem] = useState<
    ISelectItem['value']
  >();
  const [howDidYouFindUs, setHowDidYouFindUs] = useState<
    ISelectItem['value']
  >();

  const handleSubmit = async () => {
    try {
      if (!userEmail) {
        return;
      }

      setIsLoading(true);

      const response = await createUser({
        email: userEmail,
        firstName,
        lastName,
        name: `${firstName} ${lastName}`,
        companyCountry,
        companyType,
        companyName,
      });

      if (!response?.success) {
        Notify.error(response?.message ?? '');
        setIsLoading(false);
        return;
      }

      const res = await saveRegistrationSettings({
        entityCurrency: sellCurrency?.code || 'GBP',
        accountingSystem: accountingSystem || 'none',
        howDidYouFindUs: howDidYouFindUs || 'none',
        objectiveGlobalBusinessAccount: isGlobalOn,
        objectivePaymentAutomation: isAutomationOn,
        objectiveFxRiskAndCostManagement: isRiskManagementOn,
      });

      if (res.data.success) {
        if (shouldIntegrate) {
          // TODO: Once the integration flows have been updated, this should kick off the relevant integration
          history.push(getSettingsPageLink({ tab: 'integrations' }));
        } else {
          history.push('/app/dashboard');
        }
      } else {
        Notify.error(res.data?.message ?? '');
      }
    } catch (error) {
      Notify.error(
        'Sign up was unsuccessful. If the issue persists then please contact support (support@hedgeflows.com)'
      );
    } finally {
      setIsLoading(false);
    }
  };

  const theme = useTheme();

  return (
    <SignUpWrap>
      <SignUpNav style={{ justifyContent: 'flex-start' }}>
        <StaleTitleH5>Let’s sign you up to HedgeFlows</StaleTitleH5>

        <StaleParagraphMedium>
          You are about 10 minutes away from having access to our best features:
        </StaleParagraphMedium>

        <StaleCarousel />
      </SignUpNav>

      <SignUpContent className="rounded">
        <ButtonStyleLink className="cross" onClick={() => history.push('/')}>
          <Icon icon="cross-ico" />
        </ButtonStyleLink>
        <Block>
          <Title variant="h1" mb>
            Few more questions
          </Title>

          <Col mb gap={theme.spacing.m}>
            <Field fluid>
              <StaleInputSelect
                id="sell-currency-input"
                data={sellCurrencies.map((item) => ({
                  name: item.name,
                  id: item.code,
                  icon: item.countryCode,
                  value: { ...item },
                }))}
                label="What is your main currency?"
                view="moving"
                selected={sellCurrency}
                onSelect={(item) => {
                  setSellCurrency(item.value);
                }}
                autoFocus
                disabled
                strategy="fixed"
                style={{
                  flex: 1,
                }}
              />
            </Field>
            <Field fluid>
              <StaleInputSelect
                id="accounting-system-input"
                data={accountingSystems}
                label="What accounting system do you use?"
                view="moving"
                selected={accountingSystems.find(
                  ({ value }) => value === accountingSystem
                )}
                onSelect={(item) => setAccountingSystem(item.value)}
                strategy="fixed"
                style={{
                  flex: 1,
                }}
              />
            </Field>
            <Field fluid>
              <StaleInputSelect
                id="how-did-you-find-us-input"
                data={howDidYouFindUsData}
                label="How did you find out about HedgeFlows?"
                view="moving"
                selected={howDidYouFindUsData.find(
                  ({ value }) => value === howDidYouFindUs
                )}
                onSelect={(item) => setHowDidYouFindUs(item.value)}
                strategy="fixed"
                style={{
                  flex: 1,
                }}
              />
            </Field>
          </Col>

          <Title variant="h4" mt mtValue={theme.spacing.xl}>
            What are you interested in?
          </Title>

          <Paragraph mb mbValue={theme.spacing.xl}>
            (select all that apply)
          </Paragraph>

          <Col mb mbValue={theme.spacing.xxxl} gap={theme.spacing.xs}>
            <InfoCard
              isOn={isGlobalOn}
              onChange={() => setIsGlobalOn(!isGlobalOn)}
              id="objectiveGlobalBusinessAccount"
              icon="transfer"
              title="Global Business Account"
              description="Save on foreign currencies, international transfers and collections for a small business"
            />
            <InfoCard
              isOn={isAutomationOn}
              onChange={() => setIsIAutomationOn(!isAutomationOn)}
              id="objectivePaymentAutomation"
              icon="prebook"
              title="Payment automation in 30 currencies"
              description="Streamline payments and reconciliation by connecting HedgeFlows to your accounting"
            />
            <InfoCard
              isOn={isRiskManagementOn}
              onChange={() => setIsRiskManagementOn(!isRiskManagementOn)}
              id="objectiveFxRiskAndCostManagement"
              icon="rates"
              title="FX risk and cost management"
              description="Manage risks and control costs of doing business in foreign currencies"
            />
          </Col>

          <Button
            onClick={handleSubmit}
            disabled={isLoading}
            isLoading={isLoading}
            mt
            mtValue={theme.spacing.l}
            mb
            mbValue={theme.spacing.l}
          >
            Continue
          </Button>
        </Block>
      </SignUpContent>
    </SignUpWrap>
  );
};

export default StepFour;
