import { FC, useEffect, useState } from 'react';
import {
  StaleBtnGroup,
  StaleContainer,
  Paragraph,
  StalePopup,
} from 'components';
import { Wrapper } from './StaleCancellingBalance.styles';
import { useStoreActions, useStoreState } from 'state';
import { IInvoice } from 'types';
import {
  Notify,
  parseIntoCurrencyString,
  parseIntoCurrencyStringWithSymbol,
} from 'utils';
import dayjs from 'dayjs';
import InlineLoader from '../InlineLoader/InlineLoader';
import { RowInfo } from '../RowInfo/RowInfo.styles';
import Button from '../Button/Button';

interface OwnProps {
  invoice?: IInvoice;
  prebookExchangeId?: string;
  onClose: () => void;
}

const StaleCancellingBalance: FC<OwnProps> = ({
  onClose,
  invoice,
  prebookExchangeId,
}) => {
  const { rateContractById } = useStoreState(
    (state) => state.RateContractsState
  );
  const { currencyByCode } = useStoreState((state) => state.CurrenciesState);
  const { getRateContractCancelValue } = useStoreActions(
    (actions) => actions.RateContractsState
  );

  const [isLoading] = useState(false);
  const [cancelValue, setCancelValue] = useState<number | null>(null);
  const rateContractId = invoice ? invoice.contractId : prebookExchangeId;
  const rateContract = rateContractId ? rateContractById(rateContractId) : null;
  const sellCurrency = rateContract
    ? currencyByCode(rateContract.sellCurrency)
    : null;

  const onCancelInvoiceContractRate = async () => {
    Notify.info('Please contact support to cancel this prebooking');
    onClose();
  };

  const onCancelPrebookRate = async () => {
    Notify.info('Please contact support to cancel this prebooking');
    onClose();
  };

  useEffect(() => {
    if (rateContractId) {
      getRateContractCancelValue({
        contractId: rateContractId,
      }).then((data) => {
        if (data?.data.success) {
          setCancelValue(data.data.cancelValue);
        }
      });
    }
  }, [getRateContractCancelValue, rateContractId]);

  if (!rateContract) {
    return null;
  }

  return (
    <StalePopup
      title="Cancelling the balance of your prebooking"
      theme="red"
      width="439px"
      minHeight="auto"
      onClose={onClose}
    >
      <Wrapper>
        <StaleContainer>
          <Paragraph mb variant="bold">
            Please confirm you want to cancel this balance
          </Paragraph>

          <RowInfo>
            <Paragraph>Remaining amount</Paragraph>
            <Paragraph>{`${rateContract.buyCurrency} ${parseIntoCurrencyString(
              rateContract.remainingBuyAmount
            )}`}</Paragraph>
          </RowInfo>

          <RowInfo>
            <Paragraph>Rate</Paragraph>
            <Paragraph>{rateContract.rate}</Paragraph>
          </RowInfo>

          <RowInfo>
            <Paragraph>Use by</Paragraph>
            <Paragraph>{`${dayjs(rateContract.expiryDate).format(
              'DD MMM YYYY'
            )}`}</Paragraph>
          </RowInfo>

          <RowInfo>
            <Paragraph>Cancelation value</Paragraph>
            <Paragraph>
              {cancelValue === null || !sellCurrency ? (
                <InlineLoader />
              ) : (
                `${parseIntoCurrencyStringWithSymbol(
                  cancelValue,
                  sellCurrency.symbol
                )}`
              )}
            </Paragraph>
          </RowInfo>

          <RowInfo>
            <Paragraph>Your refund</Paragraph>
            <Paragraph>
              {cancelValue === null ? (
                <InlineLoader />
              ) : (
                `${sellCurrency?.symbol}${parseIntoCurrencyString(
                  Math.max(0, cancelValue)
                )}`
              )}
            </Paragraph>
          </RowInfo>

          {cancelValue !== null && cancelValue > 0 && (
            <Paragraph>
              We will refund the amount within 5 business days to your bank
              account as instructed.
            </Paragraph>
          )}
        </StaleContainer>

        <StaleBtnGroup
          container={false}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Button
            disabled={cancelValue === null}
            onClick={
              invoice ? onCancelInvoiceContractRate : onCancelPrebookRate
            }
            isLoading={isLoading}
          >
            Confirm cancellation
          </Button>

          <Button variant="link" onClick={onClose}>
            Not now
          </Button>
        </StaleBtnGroup>
      </Wrapper>
    </StalePopup>
  );
};

export default StaleCancellingBalance;
