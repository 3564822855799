import { AxiosPrivateFirebaseInstance } from 'settings';
import memoize from 'lodash.memoize';
import { IInvoice, IResponse, IAttachment, Nullable } from 'types';
import {
  IXeroAccount,
  IIntegrationPermissions,
  TXeroSettingsInput,
} from 'types/integrations';

export const getXeroStateToken = memoize(async (_: Nullable<string>) => {
  try {
    const { data } = await AxiosPrivateFirebaseInstance.put<
      IResponse<{ state: string }>
    >('/xero/statetoken');

    return data.data;
  } catch (error) {
    console.warn(error.message);
    return;
  }
});

export const xeroDisconnect = async () =>
  AxiosPrivateFirebaseInstance.post<{}>('/xero/disconnect');

interface IXeroSettingsPayload {
  settings: TXeroSettingsInput;
}
export const saveXeroSettings = async (payload: IXeroSettingsPayload) => {
  const { settingsType, ...rest } = payload.settings;
  return AxiosPrivateFirebaseInstance.post<IResponse<IXeroSettingsPayload>>(
    `/xero/settings`,
    { settings: { ...rest } }
  );
};

interface IXeroPermissionsPayload {
  permissions: IIntegrationPermissions;
}

export const saveXeroPermissions = async (payload: IXeroPermissionsPayload) =>
  AxiosPrivateFirebaseInstance.post<IResponse<IXeroPermissionsPayload>>(
    `/xero/permissions`,
    payload
  );

export const getXeroAccountDetails = async () =>
  AxiosPrivateFirebaseInstance.get<IResponse<Array<IXeroAccount>>>(
    `xero/accounts`
  );

export const createXeroAccount = async (payload: { currency: string }) =>
  AxiosPrivateFirebaseInstance.post<IResponse<Array<IXeroAccount>>>(
    `xero/accounts`,
    payload
  );

export const getXeroInvoice = async (xeroInvoiceId: string) => {
  const xeroResponse = await AxiosPrivateFirebaseInstance.get<
    IResponse<IInvoice & { hasAttachments: boolean }>
  >(`/xero/invoices/${xeroInvoiceId}`);

  return xeroResponse.data.success ? xeroResponse.data.data : undefined;
};

export const getXeroInvoiceAttachments = async (xeroInvoiceId: string) => {
  const xeroResponse = await AxiosPrivateFirebaseInstance.get<
    IResponse<IAttachment[]>
  >(`/xero/invoices/${xeroInvoiceId}/attachments`);

  return xeroResponse.data.success ? xeroResponse.data.data : undefined;
};

export const getXeroInvoiceAttachment = async ({
  xeroInvoiceId,
  fileName,
  mimeType,
}: {
  xeroInvoiceId: string;
  fileName: string;
  mimeType: string;
}) => {
  const params = new URLSearchParams();
  params.append('fileName', fileName);
  params.append('contentType', mimeType);

  const xeroResponse = await AxiosPrivateFirebaseInstance.get<
    IResponse<string>
  >(`/xero/invoices/${xeroInvoiceId}/attachment`, { params });

  return xeroResponse.data.success ? xeroResponse.data.data : undefined;
};

export const signUpWithXero = async (tokenSet: any, password: string) =>
  AxiosPrivateFirebaseInstance.post<
    IResponse<{
      email: string;
      password: string;
      userId: string;
    }>
  >('/xero/signup', {
    tokenSet,
    password,
  });

export const newClientWithXero = async (tokenSet: any) =>
  AxiosPrivateFirebaseInstance.post<
    IResponse<{
      companyName: string;
      email: string;
      entityId: string;
    }>
  >('/xero/client', {
    tokenSet,
  });

export interface DecodedToken {
  email: string;
  givenName: string;
  familyName: string;
  tokenSet: any;
}

/**
 * This endpoint swaps the code param for a token and return user details
 */
export const signUpDetails = async (url: string) =>
  AxiosPrivateFirebaseInstance.post<IResponse<DecodedToken>>(
    '/xero/signupdetails',
    {
      url,
    }
  );
