import { PureComponent } from 'react';
import { createPortal } from 'react-dom';
import shortId from 'shortid';

import NotificationMessage from './NotificationMessage/NotificationMessage';

export interface NotificationType {
  id: string;
  color?: string;
  message?: any;
  duration?: number;
  icon?: string;
}

interface State {
  notifications: NotificationType[];
}

class NotificationsController extends PureComponent<{}, State> {
  constructor(props) {
    super(props);

    this.state = {
      notifications: [],
    };
  }

  public open = ({ message, duration = 5000, color = '', icon = '' }) => {
    if (!message) {
      return;
    }

    const id = shortId.generate();

    this.setState((prevState) => ({
      notifications: [
        ...prevState.notifications,
        { id, message: `${message}`, duration, color, icon },
      ],
    }));

    setTimeout(() => {
      this.setState((prevState) => ({
        notifications: prevState.notifications.filter((item) => item.id !== id),
      }));
    }, duration);
  };

  render() {
    const { notifications } = this.state;

    return createPortal(
      <>
        {notifications.map(({ id, color, message, duration, icon }) => {
          return (
            <NotificationMessage
              key={id}
              color={color}
              message={message}
              duration={duration}
              icon={icon}
            />
          );
        })}
      </>,
      document.body
    );
  }
}

export default NotificationsController;
