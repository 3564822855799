import { Paragraph, Row } from 'components';
import styled from 'styled-components';

export const InfoBoxWrapper = styled(Row)`
  padding: 16px;
  border-radius: 10px;
  align-items: flex-start;
  background: ${({ theme }) => theme.color.greyLight_2};
`;

export const InfoBoxText = styled(Paragraph)`
  flex: 1;
  margin-left: 14px;
`;
