import { Paragraph, ParagraphWithEllipsis } from 'components';
import DateCell from 'components/shared/DateCell/DateCell';
import DirectionCell from 'components/shared/DirectionCell/DirectionCell';
import { Dispatch, SetStateAction } from 'react';
import { Link } from 'react-router-dom';
import { Column, FilterTypes } from 'react-table';
import { INVOICE_STATUSES } from 'types';
import {
  isPastPerformanceItemInvoice,
  isPastPerformanceItemTransfer,
  TPastPerformanceItem,
} from 'types/analyses';
import AmountCell from './components/AmountCell/AmountCell';
import GainLossCell from './components/GainLossCell/GainLossCell';
import RiskContributionCell from './components/RiskContributionCell/RiskContributionCell';
import { TReportsContentType } from './types';

export const filterTypes: FilterTypes<TPastPerformanceItem> = {
  direction: (rows, _, filterValue) => {
    if (filterValue === 'default') {
      return rows;
    }

    if (filterValue === 'transfers') {
      return rows.filter((row) => {
        const value = row.original;

        return value.recordType === 'bankTransfer';
      });
    }

    if (filterValue === 'payables') {
      return rows.filter((row) => {
        const value = row.original;

        if (isPastPerformanceItemInvoice(value)) {
          return value.type === 'Payable';
        } else {
          return value.status === 'TRANSFER OUT';
        }
      });
    }

    if (filterValue === 'receivables') {
      return rows.filter((row) => {
        const value = row.original;

        if (isPastPerformanceItemInvoice(value)) {
          return value.type === 'Receivable';
        } else {
          return value.status === 'TRANSFER IN';
        }
      });
    }

    return rows;
  },
  text: (rows, _, filterValue) => {
    if (!filterValue) {
      return rows;
    }

    return rows.filter((row) => {
      const value = row.original;

      if (
        isPastPerformanceItemInvoice(value) &&
        value.invoiceId.includes(filterValue)
      ) {
        return true;
      }

      if (
        isPastPerformanceItemTransfer(value) &&
        value.bankTransferId.includes(filterValue)
      ) {
        return true;
      }
      if (
        value.contactName?.toLowerCase().includes(filterValue.toLowerCase())
      ) {
        return true;
      }

      return false;
    });
  },
};

const getFxImpactTableValue = (
  item: TPastPerformanceItem,
  contentType: TReportsContentType,
  activeMonth: string
) => {
  if (contentType === 'marketImpact' && isPastPerformanceItemInvoice(item)) {
    return item.monthlyImpactContributions?.[activeMonth]?.fxImpact ?? 0;
  }

  return item.fxImpact + item.fxCost;
};

export const generateReportsTableColumns = ({
  setRecordForFxBreakdown,
  contentType,
  activeMonth,
}: {
  setRecordForFxBreakdown: Dispatch<SetStateAction<TPastPerformanceItem>>;
  contentType: TReportsContentType;
  activeMonth: string;
}): Column<TPastPerformanceItem>[] => {
  return [
    {
      Header: 'Cashflow',
      disableSortBy: true,
      Cell: (props) => {
        const item = props.row.original;
        const isInvoice = isPastPerformanceItemInvoice(item);

        return (
          <ParagraphWithEllipsis
            title={item.invoiceId || item.transferId}
            maxWidth="100px"
          >
            {isInvoice ? (
              <Link to={`/app/invoices/${item.invoiceId}`}>
                {item.reference}
              </Link>
            ) : (
              <Paragraph>{item.reference}</Paragraph>
            )}
          </ParagraphWithEllipsis>
        );
      },
      width: 80,
      minWidth: 55,
    },
    {
      accessor: 'contactName',
      Header: 'Name',
      Cell: (props) => {
        const item = props.row.original;
        const value = props.row.original.contactName;
        return (
          <ParagraphWithEllipsis maxWidth="100px">
            {!!value
              ? value
              : isPastPerformanceItemInvoice(item)
              ? 'Contact is missing'
              : 'TRANSFER'}
          </ParagraphWithEllipsis>
        );
      },
      width: 156,
      minWidth: 136,
    },
    {
      accessor: 'creationDate',
      Header: 'Issued',
      Cell: ({ value }) => <DateCell value={value} />,
      width: 130,
      minWidth: 110,
    },
    {
      accessor: 'paidDate',
      Header: 'Paid',
      Cell: ({ value }) => <DateCell value={value} />,
      width: 120,
      minWidth: 100,
    },
    {
      id: 'status',
      Header: 'Status',
      Cell: (props) => {
        const item = props.row.original;
        if (isPastPerformanceItemTransfer(item)) {
          return <Paragraph>PAID</Paragraph>;
        } else {
          return <Paragraph>{item.status}</Paragraph>;
        }
      },
      width: 140,
      minWidth: 120,
    },
    {
      id: 'direction',
      Header: 'Amount Due',
      disableSortBy: true,
      Cell: ({ row }) => {
        const item = row.original;
        let isReceivable = false;
        if (isPastPerformanceItemTransfer(item)) {
          isReceivable = item.status === 'TRANSFER IN';
        } else {
          isReceivable = item.type === 'Receivable';
        }
        return (
          <>
            <DirectionCell withTitle={false} isReceivable={isReceivable} />
            <AmountCell
              value={row.original.amount}
              currencyCode={row.original.currency}
            />
          </>
        );
      },
      filter: 'direction',
      width: 120,
      minWidth: 100,
    },
    {
      accessor: 'amount',
      Header: 'GBP Value',
      disableSortBy: true,
      Cell: ({ value, row }) => {
        return (
          <AmountCell value={value / row.original.payRate} currencyCode="GBP" />
        );
      },
      width: 120,
      minWidth: 100,
    },
    {
      id: 'riskContribution',
      Header: 'Risk contrib.',
      disableSortBy: true,
      Cell: ({ row }) => (
        <RiskContributionCell
          amount={
            // 0 risk contribution for transfer items
            isPastPerformanceItemTransfer(row.original)
              ? 0
              : row.original.amount / row.original.payRate
          }
          currencyCode="GBP"
          creationDate={row.original.creationDate}
          paidDate={row.original.paidDate}
        />
      ),
      width: 100,
      minWidth: 100,
    },
    {
      accessor: 'fxCost',
      Header: 'FX Gain/Loss',
      sortType: (rowA, rowB) => {
        const a = rowA.original.fxCost + rowA.original.fxImpact;
        const b = rowB.original.fxCost + rowB.original.fxImpact;

        if (a > b) {
          return 1;
        }
        if (b > a) {
          return -1;
        }

        return 0;
      },
      Cell: ({ row }) => {
        const fxImpact = getFxImpactTableValue(
          row.original,
          contentType,
          activeMonth
        );

        return (
          <GainLossCell
            value={fxImpact}
            valueInPerc={
              contentType === 'marketImpact'
                ? undefined
                : row.original.fxCostPercentage +
                  row.original.fxImpactPercentage
            }
            currencyCode="GBP"
            setRecordForFxBreakdown={() =>
              setRecordForFxBreakdown(row.original)
            }
            disableButton={
              row.original.recordType === 'invoice' &&
              row.original.status !== INVOICE_STATUSES.paid
            }
          />
        );
      },
      width: 140,
      minWidth: 140,
    },
  ];
};
