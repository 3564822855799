import { Paragraph } from 'components';
import AmountCell from 'components/shared/AmountCell/AmountCell';
import { Column } from 'react-table';
import { IRiskDashboardItemPerMonthWithDate } from 'types';

export const generateTableColumns = ({
  currencyCode,
}: {
  currencyCode: string;
}): Column<IRiskDashboardItemPerMonthWithDate>[] => {
  return [
    {
      accessor: 'date',
      Header: 'Period',
      Cell: ({ value }) => <Paragraph>{value}</Paragraph>,
      width: 100,
      minWidth: 80,
    },
    {
      accessor: 'payables',
      Header: 'Payables',
      Cell: ({ value }) => (
        <AmountCell amount={value} currencyCode={currencyCode} />
      ),
      width: 100,
      minWidth: 80,
    },
    {
      accessor: 'receivables',
      Header: 'Receivables',
      Cell: ({ value }) => (
        <AmountCell amount={value} currencyCode={currencyCode} />
      ),
      width: 100,
      minWidth: 80,
    },
    {
      accessor: 'purchaseOrders',
      Header: 'Purchase Orders',
      Cell: ({ value }) => (
        <AmountCell amount={value} currencyCode={currencyCode} />
      ),
      width: 100,
      minWidth: 80,
    },
    {
      accessor: 'saleOrders',
      Header: 'Sales Orders',
      Cell: ({ value }) => (
        <AmountCell amount={value} currencyCode={currencyCode} />
      ),
      width: 100,
      minWidth: 80,
    },
    {
      accessor: 'prebooked',
      Header: 'Prebooked with HF',
      Cell: ({ value }) => (
        <AmountCell amount={value} currencyCode={currencyCode} />
      ),
      width: 100,
      minWidth: 80,
    },
    {
      accessor: 'externalHedges',
      Header: '3rd party Hedges',
      Cell: ({ value }) => (
        <AmountCell amount={value} currencyCode={currencyCode} />
      ),
      width: 100,
      minWidth: 80,
    },
    {
      accessor: 'netExposure',
      Header: 'Net total',
      Cell: ({ value }) => (
        <AmountCell amount={value} currencyCode={currencyCode} />
      ),
      width: 100,
      minWidth: 80,
    },
  ];
};
