import React, { FC } from 'react';
import { Wrapper } from './Popups.styles';

import {
  StaleContainer,
  StaleBtnGroup,
  Paragraph,
  StaleTextHint,
  StalePopup,
  StaleInputConvert,
  Subtitle,
} from 'components';
import InfoCard from '../InfoCard/InfoCard';
import ReactSlider from 'react-slider';
import { useStoreState } from 'state';
import { IInvoice } from 'types';
import Button from 'components/shared/Button/Button';

interface OwnProps {
  onClose: () => void;
  invoice: IInvoice;
}

const GoodRateTrack: FC<OwnProps> = ({ onClose }) => {
  const { sellCurrencies } = useStoreState((state) => state.CurrenciesState);

  return (
    <StalePopup
      title="AddressBook #123451"
      theme="grey"
      width="439px"
      headContentAdditional={
        <Paragraph style={{ margin: '0 40px 0 auto' }}>
          Due date 1 Sep, 2021
        </Paragraph>
      }
      onClose={onClose}
    >
      <Wrapper>
        <StaleContainer>
          <div className="convert-info">
            <div className="col">
              <Paragraph>To convert</Paragraph>

              <div className="row">
                <svg width="24px" height="24px" style={{ marginRight: '8px' }}>
                  <use xlinkHref="#eu" />
                </svg>
                <Paragraph variant="bold">€14,437.50</Paragraph>
              </div>
            </div>
            <div className="col">
              <Paragraph>Current</Paragraph>

              <div className="row">
                <svg width="24px" height="24px" style={{ marginRight: '8px' }}>
                  <use xlinkHref="#gb" />
                </svg>
                <Paragraph variant="bold">£14,437.50</Paragraph>
              </div>
            </div>
          </div>

          <InfoCard skin="border">
            <Subtitle variant="bold">
              What is your target amount in GBP?
            </Subtitle>

            <div className="field">
              <StaleInputConvert
                value={9.11537}
                onChange={() => {}}
                currencies={sellCurrencies}
                selectedValue={sellCurrencies[1]}
                onSelect={() => {}}
                onFocus={() => {}}
                onBlur={() => {}}
                error={0 ? 'Amount is too low' : ''}
              />
            </div>

            <div className="tracked-table">
              <div className="col">
                <span>Lowest</span>

                <p>
                  <span>£8,601.51</span>
                </p>
              </div>
              <div className="col" style={{ flex: '1 0 auto' }}>
                <ReactSlider
                  value={35}
                  min={0}
                  max={100}
                  className="cost-range"
                  thumbClassName="example-thumb"
                  trackClassName="example-track"
                  disabled
                  renderThumb={(props) => {
                    return (
                      <div {...props}>
                        <div className="tooltip">Target</div>
                      </div>
                    );
                  }}
                />
              </div>
              <div className="col">
                <span>Highest</span>

                <p>
                  <span>£9,822.00</span>
                </p>
              </div>
            </div>

            <StaleTextHint>Last 6 month rete changes</StaleTextHint>
          </InfoCard>
        </StaleContainer>

        <StaleBtnGroup container={false}>
          <Button>Start tracking</Button>
        </StaleBtnGroup>
      </Wrapper>
    </StalePopup>
  );
};

export default GoodRateTrack;
