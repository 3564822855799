import { PositioningStrategy } from '@popperjs/core';
import { FC, ReactNode, useState } from 'react';
import { createPortal } from 'react-dom';
import OutsideClickHandler from 'react-outside-click-handler';
import { PopperProps, usePopper } from 'react-popper';
import { Link } from 'react-router-dom';
import { useTheme } from 'styled-components';
import Icon from '../Icon/Icon';
import { Trigger, Menu, ThreeDotsButton, Arrow } from './ContextMenu.styles';

export interface ContextMenuItem {
  id: any;
  title: string;
  icon?: string;
  loading?: boolean;
  onClick?: (e) => void;
  link?: string;
}

interface OwnProps
  extends Pick<PopperProps<unknown>, 'modifiers' | 'placement' | 'strategy'> {
  disabled?: boolean;
  strategy?: PositioningStrategy;
  portal?: boolean;
  list: ContextMenuItem[];
  trigger?: ReactNode;
}

const ContextMenu: FC<OwnProps> = ({
  disabled = false,
  placement = 'auto',
  strategy = 'absolute',
  modifiers = [],
  portal = false,
  list,
  trigger,
}) => {
  const theme = useTheme();
  const [show, setShow] = useState(false);
  const [
    referenceElement,
    setReferenceElement,
  ] = useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );
  const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [
      { name: 'arrow', options: { element: arrowElement } },
      {
        name: 'offset',
        options: {
          offset: [0, 8],
        },
      },
      ...modifiers,
    ],
    placement,
    strategy,
  });

  const renderContent = () => {
    const menuContent = (
      <OutsideClickHandler onOutsideClick={() => setShow(false)}>
        <Menu
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
        >
          <Arrow
            ref={setArrowElement}
            style={styles.arrow}
            {...attributes.arrow}
          />

          {list.map((item) => {
            return (
              <ThreeDotsButton
                // @ts-ignore
                as={item.link ? Link : ''}
                key={item.id}
                onClick={(event) => {
                  event.stopPropagation();
                  item?.onClick?.(event);
                  setShow(false);
                }}
                to={item.link}
              >
                {item.icon && (
                  <Icon
                    style={{ marginRight: theme.spacing.xs }}
                    icon={item.icon}
                  />
                )}
                {item.title}
                {item.loading && <Icon icon="loader-ico" />}
              </ThreeDotsButton>
            );
          })}
        </Menu>
      </OutsideClickHandler>
    );

    if (portal) {
      return createPortal(menuContent, document.body);
    }

    return menuContent;
  };

  return (
    <>
      <Trigger
        show={show}
        ref={setReferenceElement}
        disabled={disabled}
        onClick={(e) => {
          e.stopPropagation();

          setShow(true);
        }}
      >
        {trigger ?? <Icon icon="dots-ico" />}
      </Trigger>

      {show && renderContent()}
    </>
  );
};

export default ContextMenu;
